import { useEffect } from 'react';
import { getAuthManager } from 'auth';

const SilentRenew = () => {
  const authManager = getAuthManager();

  useEffect(() => {
    authManager.signinSilentCallback().catch((error) => {
      console.error('Silent renew callback error:', error);
    });
  }, []);

  return null;
};
export default SilentRenew;
