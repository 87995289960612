import React, { useMemo } from 'react';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { HiOutlineMinusSm } from 'react-icons/hi';
import { IoMdAdd } from 'react-icons/io';
import { SpiderTreeNode } from 'store';
import { Box, CircularProgress, ThemeProvider } from '@mui/material';
import { Flex } from '@mantine/core';
import ExcludeActionsMenu from './ExcludeActionsMenu';
import theme from './ExcludeStyleOverrides';
import { hexToObject } from 'helpers';
import getDisabledStatus from '../../utils/getDisabledStatus';
import handleToggle from '../../utils/handleToggle';
import getRuledStatus from '../../utils/getRuledStatus';

type Props = {
  yyTarget: TranslationKey;
  setYYTarget: React.Dispatch<React.SetStateAction<TranslationKey>>;
  spiderTree: SpiderTreeNode[];
  setSpiderTree: React.Dispatch<React.SetStateAction<SpiderTreeNode[]>>;
};
export const ExcludeTree = ({
  yyTarget,
  setYYTarget,
  spiderTree,
  setSpiderTree,
}: Props) => {
  const yyTranslationKey = yyTarget?.translation_key || '';
  const activeTranslationConfig = yyTarget?.translation_config || '';

  const config = hexToObject(activeTranslationConfig);
  const excludeRules = useMemo(
    () => config?.spider_rules?.exclude_uris || [],
    [config?.spider_rules?.exclude_uris]
  );

  const renderTree = (nodes: SpiderTreeNode[]): React.ReactNode =>
    nodes.map(
      ({
        id = 'unknown',
        content_url_hash,
        name: nodeName,
        children_fetched,
        children = [],
        content_url_orig,
        label,
        content_url,
        is_fake,
      }) => {
        const nodeData = {
          id,
          nodeName,
          label,
          content_url_hash,
          content_url_orig,
          content_url,
          children_fetched,
          children,
          is_fake,
        };

        if (id !== 'unknown') {
          const name =
            nodeName === 'fetch' ? (
              <CircularProgress size={20} />
            ) : (
              content_url_orig
            );
          const depth = id.split('-').length;

          const ruledStatusParams = {
            label,
            is_fake,
            content_url_orig,
            id,
            name: typeof name === 'string' ? name : '',
          };

          const { isRuled: nodeIsExcluded = false, relevantRule = '' } =
            getRuledStatus(ruledStatusParams, excludeRules) || {};

          const nodeInfo = JSON.stringify({
            ...nodeData,
            depth,
            nodeIsExcluded,
          });

          const disabledStatusParams = {
            nodeInfo,
            rulesList: excludeRules,
            nodeIsRuled: nodeIsExcluded,
            relevantRule,
          };

          const { isSourceOfChildrenOnlyRule, isDisabledChildNode } =
            getDisabledStatus(disabledStatusParams);

          return children?.length > 0 ? (
            <TreeItem
              data-nodeinfo={nodeInfo}
              key={id}
              data-id={id}
              nodeId={id}
              disabled={isDisabledChildNode}
              label={
                <Flex align="center" justify="space-between">
                  <Box
                    style={{
                      marginRight: '0.5rem',
                      overflow: 'hidden',
                    }}
                  >
                    {name || label}
                  </Box>
                  <ExcludeActionsMenu
                    itemInfo={nodeInfo}
                    isExcluded={nodeIsExcluded}
                    isDisabledChildNode={isDisabledChildNode}
                    currentExcludeRules={excludeRules}
                    isSourceOfChildrenOnlyRule={isSourceOfChildrenOnlyRule}
                    yyTarget={yyTarget}
                    setYYTarget={setYYTarget}
                    spiderTree={spiderTree}
                    setSpiderTree={setSpiderTree}
                  />
                </Flex>
              }
              onChange={(e) =>
                handleToggle(e, yyTranslationKey, spiderTree, setSpiderTree)
              }
            >
              {renderTree(children)}
            </TreeItem>
          ) : id === '0' ? (
            <TreeItem
              data-nodeinfo={nodeInfo}
              key={id}
              nodeId={id}
              label={label}
              onChange={(e) =>
                handleToggle(e, yyTranslationKey, spiderTree, setSpiderTree)
              }
            >
              <></>
            </TreeItem>
          ) : (
            <TreeItem
              data-nodeinfo={nodeInfo}
              key={id}
              data-id={id}
              nodeId={id}
              disabled={isDisabledChildNode}
              label={
                <Flex align="center" justify="space-between">
                  <Box
                    style={{
                      marginRight: '0.5rem',
                      overflow: 'hidden',
                    }}
                  >
                    {label}
                  </Box>
                  <ExcludeActionsMenu
                    itemInfo={nodeInfo}
                    isExcluded={nodeIsExcluded}
                    currentExcludeRules={excludeRules}
                    isDisabledChildNode={isDisabledChildNode}
                    isSourceOfChildrenOnlyRule={isSourceOfChildrenOnlyRule}
                    yyTarget={yyTarget}
                    setYYTarget={setYYTarget}
                    spiderTree={spiderTree}
                    setSpiderTree={setSpiderTree}
                  />
                </Flex>
              }
              onChange={(e) =>
                handleToggle(e, yyTranslationKey, spiderTree, setSpiderTree)
              }
            />
          );
        }
        return <div key="key" />;
      }
    );

  if (yyTranslationKey && spiderTree.length > 0) {
    const [root] = spiderTree;
    const { id, name: nodeName, content_url_hash } = root;
    const nodeData = { id, nodeName, content_url_hash };
    const nodeInfo = JSON.stringify(nodeData);

    return (
      <ThemeProvider theme={theme}>
        <TreeView
          data-nodeinfo={nodeInfo}
          aria-label="rich object"
          defaultCollapseIcon={<HiOutlineMinusSm color="#006CD1" size={24} />}
          defaultExpandIcon={<IoMdAdd color="#006CD1" size={24} />}
          onNodeToggle={(e) =>
            handleToggle(e, yyTranslationKey, spiderTree, setSpiderTree)
          }
        >
          {renderTree(spiderTree)}
        </TreeView>
      </ThemeProvider>
    );
  }
  return <></>;
};

export default ExcludeTree;
