import { asciiToHex } from 'helpers';
import { Xapis } from 'store';

export const saveCustomCss = ({
  target,
  cssValue,
}: {
  target: TranslationKey;
  cssValue: string;
}) => {
  const cssCapturingRegex = new RegExp(
    `(?:\\/\\* LANGUAGE_SELECTOR_POSITION: "[^"]*" LANGUAGE_SELECTOR_THEME: "[^"]*"(?: LANGUAGE_SELECTOR_WIDTH: "[^"]*")* \\*\\/\\n*)*((?:.|\\n)*)`
  );
  const [, cssWithoutDefaultComment] = cssValue.match(cssCapturingRegex) ?? [];

  const custom_css = cssWithoutDefaultComment
    ? asciiToHex(cssWithoutDefaultComment)
    : 'clear';
  const { custom_css_hash = '', staging_custom_css_hash = '' } = target || {};
  const data = {
    custom_css,
    custom_css_hash,
    staging_custom_css: custom_css,
    staging_custom_css_hash,
  };
  const { project_key, translation_key } = target || {};

  return Xapis.ProjectTranslation.put(project_key, translation_key, data);
};
