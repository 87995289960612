import { create } from 'zustand';
import Get from './xapis-wrappers/Fetcher';

export type TrafficRequestsResponse = {
  [translation_key: string]: number;
} & {
  content_url: string;
  content_url_hash: number;
};

export type TrafficRequestsStore = {
  trafficRequests: TrafficRequestsResponse[];
  loading: boolean;
  fetchTrafficRequests: (
    translationKeys: string,
    timeSliceFrom: string,
    failed: { errorMessage?: string; action?: () => void }
  ) => void;
};

export const useTrafficRequestsStore = create<TrafficRequestsStore>()(
  (set, get) => ({
    trafficRequests: [],
    loading: false,
    fetchTrafficRequests: (translationKeys, timeSliceFrom, failed) => {
      const { loading } = get();
      if (loading) return;

      const params = {
        translation_key: translationKeys,
        type: 'url',
        series: 'num_requests',
        rollup: timeSliceFrom === '1 year' ? 'month' : 'day',
        timeslice_from: timeSliceFrom,
      };
      set({ loading: true });
      Get(
        'Metrics',
        ({
          data: { data = [] },
        }: {
          data: { data: TrafficRequestsResponse[] };
        }) => {
          set({ trafficRequests: data });
        },
        {
          errorMessage: failed.errorMessage || '',
          action: () => {
            set({ trafficRequests: [] });
            failed.action && failed.action();
          },
        },
        params,
        () => set({ loading: false })
      );
    },
  })
);
