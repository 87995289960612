import React, { useMemo } from 'react';
import { Box, Flex, Skeleton } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';

export const LoadingSkeleton = ({
  height = 0,
  padding = 0,
}: {
  height?: number;
  padding?: number;
}) => {
  const { ref, height: elementHeight = 0 } = useElementSize();
  const remainingHeight = elementHeight - padding * 2;

  const spaceBetweenSkeletons = 10;
  const skeletonHeight = 8;
  const numOfSkeletons = Math.floor(
    (height || remainingHeight) / (skeletonHeight + spaceBetweenSkeletons)
  );

  const loadingSkeletons = useMemo(() => {
    const rows = [];
    if (height) {
      for (let index = 0; index < numOfSkeletons; index++) {
        rows.push(index);
      }
    } else {
      for (let index = 0; index <= numOfSkeletons; index++) {
        rows.push(index);
      }
    }
    return rows;
  }, [height, numOfSkeletons]);

  return (
    <Box ref={ref} h={height || 'inherit'} style={height ? {} : { flex: 1 }}>
      <Flex
        w="100%"
        h="auto"
        direction="column"
        rowGap={spaceBetweenSkeletons}
        align="center"
        justify="center"
      >
        {loadingSkeletons.map((index) => (
          <Skeleton
            key={index}
            height={skeletonHeight}
            width="100%"
            radius="xl"
          />
        ))}
      </Flex>
    </Box>
  );
};

export default LoadingSkeleton;
