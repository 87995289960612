import { create } from 'zustand';

export type GlobalModalStore = {
  showContactUsModal: boolean;
  setShowContactUsModal: (show: boolean) => void;
};

export const useGlobalModalStore = create<GlobalModalStore>()((set) => ({
  showContactUsModal: false,
  setShowContactUsModal: (show: boolean) => set({ showContactUsModal: show }),
}));

export default useGlobalModalStore;
