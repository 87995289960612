import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type ColorTheme = 'light' | 'dark';

export type ThemeStore = {
  theme: ColorTheme;
  setTheme: (theme: ColorTheme) => void;
};

export const useThemeStore = create<ThemeStore>()(
  persist(
    (set) => ({
      theme: 'light',
      setTheme: (theme) => set(() => ({ theme })),
    }),
    {
      name: 'themeState',
    }
  )
);
export default useThemeStore;
