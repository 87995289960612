import { create } from 'zustand';
import Get from './xapis-wrappers/Fetcher';
import Post from './xapis-wrappers/Poster';
import Put from './xapis-wrappers/Putter';
import Delete from './xapis-wrappers/Deleter';
import { SkuWCreatedAndLastMod } from './types';
import { AxiosResponse } from 'axios';
import { FetcherPromise } from './xapis-wrappers';

export type PaySkuStore = {
  totalRows: number;
  skus: SkuWCreatedAndLastMod[];
  setSkus: (projects: SkuWCreatedAndLastMod[]) => void;
  setTotalRows: (rows: number) => void;
  fetchSkus: (payKey: string, params: object) => void;
  createSku: (
    payKey: string,
    data: Partial<SkuWCreatedAndLastMod>,
    success: (results: AxiosResponse) => void
  ) => void;
  updateSku: (
    payKey: string,
    data: Partial<SkuWCreatedAndLastMod>,
    success: (results: AxiosResponse) => void
  ) => void;
  deleteSku: (
    payKey: string,
    sku: string,
    userKey: string,
    success: (results: AxiosResponse) => void
  ) => void;
};

export const usePaySkuStore = create<PaySkuStore>()((set) => ({
  totalRows: 0,
  skus: [],
  setSkus: (skus) => set(() => ({ skus })),
  setTotalRows: (totalRows) => set(() => ({ totalRows })),
  fetchSkus: (payKey, params) => {
    Get(
      `Pay/SKU/${payKey}`,
      ({
        data: { skus, rows_total },
      }: {
        data: { skus: SkuWCreatedAndLastMod[]; rows_total: number };
      }) => {
        set({ skus });
        set({ totalRows: rows_total });
      },
      { action: () => set({ skus: [] }) },
      params
    );
  },
  createSku: (payKey, data, success) => {
    Post(`Pay/SKU/${payKey}`, data, (results) => success(results), {});
  },
  updateSku: (payKey, data, success) => {
    Put(`Pay/SKU/${payKey}`, data, (results) => success(results), {});
  },
  deleteSku: (payKey, sku, user_key, success) => {
    Delete(
      `Pay/SKU/${payKey}/${sku}`,
      { user_key },
      (results) => success(results),
      {}
    );
  },
}));

export default usePaySkuStore;

export const fetchSkusPromise = (payKey: string) =>
  FetcherPromise(`Pay/SKU/${payKey}`);
