import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { Box, Button, Stack } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useGLGOParams } from 'hooks';
import { LanguageCodeResponse, useCurrentProjectData, Xapis } from 'store';
import { ActiveTranslations } from './ActiveTranslations';
import { InactiveTranslations } from './InactiveTranslations';
import { Header } from '../../../Generic';
import AddLanguagesModal from './AddLanguagesModal';
import { SpecifyModal } from '../SpecifyModal';
import { IoMdAdd } from 'react-icons/io';
import { asciiToHex } from 'helpers';
import {
  addLabelToConfig,
  getTranslationConfig,
  removeLabelFromConfig,
} from './utils';

export const ManageLanguages = () => {
  const { projectKey = '' } = useGLGOParams();
  const { language_codes: languageCodes = [] } =
    useLoaderData() as LanguageCodeResponse;
  const { translations = [], yyTranslation } = useCurrentProjectData();

  const [targets, setTargets] = useState(translations);
  const [showAddLanguagesModal, setShowAddLanguagesModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showSpecifyModal, setShowSpecifyModal] = useState(false);
  const [selectedTranslations, setSelectedTranslations] = useState<
    Partial<LanguageCode>[]
  >([]);

  useEffect(() => {
    setTargets(translations);
  }, [translations]);

  const updateTargets = useCallback(
    (updatedTarget: TranslationKey, selectedTKey: string) => {
      setTargets((prevTargets) => {
        return prevTargets.map((prevTarget) => {
          if (prevTarget.translation_key === selectedTKey) {
            return updatedTarget;
          }
          return prevTarget;
        });
      });
    },
    []
  );

  const translationConfig = getTranslationConfig(
    yyTranslation?.translation_config
  );

  const hasCustomLabels = Boolean(
    Object.keys(
      translationConfig?.staging_translation_rules?.language_selector_labels ??
        {}
    )?.length ||
      Object.keys(
        translationConfig?.translation_rules?.language_selector_labels ?? {}
      )?.length
  );

  const sortedTargets = useMemo(() => {
    return targets.sort((t1, t2) => {
      if (t1.target_lang_name < t2.target_lang_name) return -1;
      if (t1.target_lang_name > t2.target_lang_name) return 1;
      return 0;
    });
  }, [targets]);

  const availableTranslations = useMemo(() => {
    const targetLanguageNames = targets.map(
      ({ target_lang_name = '' }) => target_lang_name
    );
    return languageCodes.filter(
      ({ langcode_name = '' }) => !targetLanguageNames.includes(langcode_name)
    );
  }, [languageCodes, targets]);

  const inactiveTranslations = useMemo(() => {
    const translations = targets.filter(({ is_active = false }) => !is_active);

    return translations.sort((t1, t2) => {
      if (t1.target_lang_name < t2.target_lang_name) return -1;
      if (t1.target_lang_name > t2.target_lang_name) return 1;
      return 0;
    });
  }, [targets]);

  const addInactiveTranslation = useCallback(
    (selectedTranslationKey: string) => {
      if (!yyTranslation) return;

      const updateTargetPayload = {
        is_active: true,
      };
      const translation =
        translations.find(
          (t) => t.translation_key === selectedTranslationKey
        ) ?? ({} as TranslationKey);
      hasCustomLabels &&
        Xapis.ProjectTranslation.put(
          projectKey,
          yyTranslation.translation_key,
          {
            translation_config: asciiToHex(
              JSON.stringify(
                addLabelToConfig(
                  translationConfig,
                  translation,
                  translation.is_live
                )
              )
            ),
            translation_config_hash:
              yyTranslation?.translation_config_hash ?? '',
          }
        );
      Xapis.ProjectTranslation.put(
        projectKey,
        selectedTranslationKey,
        updateTargetPayload
      )
        .then(({ data: updatedTarget = {} }) => {
          if ('is_active' in updatedTarget) {
            updateTargets(updatedTarget, selectedTranslationKey);
            notifications.show({
              message: 'You have successfully added a translation.',
            });
          }
        })
        .catch(() => {
          notifications.show({
            message: 'Unable to add a translation at this time.',
          });
        });
    },
    [
      projectKey,
      updateTargets,
      yyTranslation,
      hasCustomLabels,
      translations,
      translationConfig,
    ]
  );

  if (!yyTranslation) {
    return;
  }

  const removeActiveTranslation = (selectedTranslationKey: string) => {
    if (projectKey && selectedTranslationKey) {
      (hasCustomLabels
        ? Xapis.ProjectTranslation.put(
            projectKey,
            yyTranslation.translation_key,
            {
              translation_config: asciiToHex(
                JSON.stringify(
                  removeLabelFromConfig(
                    translationConfig,
                    translations.find(
                      (t) => t.translation_key === selectedTranslationKey
                    ) ?? ({} as TranslationKey),
                    true
                  )
                )
              ),
              translation_config_hash:
                yyTranslation?.translation_config_hash ?? '',
            }
          )
        : Promise.resolve()
      )
        .then(() =>
          Xapis.ProjectTranslation.put(projectKey, selectedTranslationKey, {
            is_active: false,
          })
        )
        .then(({ data: updatedTarget = {} }) => {
          if ('is_active' in updatedTarget) {
            updateTargets(updatedTarget, selectedTranslationKey);
            notifications.show({
              message: 'You have successfully removed a translation.',
            });
          }
        })
        .catch(() => {
          notifications.show({
            message: 'Unable to remove a translation at this time.',
          });
        });
    } else {
      notifications.show({
        message: 'Unable to remove a translation at this time.',
      });
    }
  };

  const updateTranslationStatus = (
    selectedTranslationKey: string,
    isLive: boolean
  ) => {
    const translation =
      translations.find((t) => t.translation_key === selectedTranslationKey) ??
      ({} as TranslationKey);
    const newConfig = isLive
      ? removeLabelFromConfig(translationConfig, translation, false)
      : addLabelToConfig(translationConfig, translation, true);

    (hasCustomLabels
      ? Xapis.ProjectTranslation.put(
          projectKey,
          yyTranslation.translation_key,
          {
            translation_config: asciiToHex(JSON.stringify(newConfig)),
            translation_config_hash:
              yyTranslation?.translation_config_hash ?? '',
          }
        )
      : Promise.resolve()
    )
      .then(() =>
        Xapis.ProjectTranslation.put(projectKey, selectedTranslationKey, {
          is_live: !isLive,
        })
      )
      .then(({ data: updatedTarget = {} }) => {
        if ('is_active' in updatedTarget) {
          updateTargets(updatedTarget, selectedTranslationKey);
          notifications.show({
            message: 'You have successfully updated a translation.',
          });
        }
      })
      .catch(() => {
        notifications.show({
          message: 'Unable to update translation status at this time.',
        });
      });
  };

  return (
    <>
      <SpecifyModal
        showSpecifyModal={showSpecifyModal}
        setShowSpecifyModal={setShowSpecifyModal}
        selectedTranslations={selectedTranslations}
        setSelectedTranslations={setSelectedTranslations}
      />
      <AddLanguagesModal
        showSpecifyModal={showSpecifyModal}
        setShowSpecifyModal={setShowSpecifyModal}
        setShowAddLanguagesModal={setShowAddLanguagesModal}
        showAddLanguagesModal={showAddLanguagesModal}
        availableTranslations={availableTranslations}
        selectedTranslations={selectedTranslations}
        setSelectedTranslations={setSelectedTranslations}
      />
      <Box maw={{ base: '100%', md: '90%', lg: '75%', xl: '55%' }}>
        <Stack gap={0}>
          <Header
            title="My Languages"
            flexItems={
              <Button
                data-testid="pw-language-add-language-button"
                px={0}
                variant="transparent"
                onClick={() => setShowAddLanguagesModal(true)}
                rightSection={<IoMdAdd size={20} />}
              >
                Add Language
              </Button>
            }
          />
          {targets.length > 0 ? (
            <ActiveTranslations
              targets={sortedTargets}
              removeActiveTranslation={removeActiveTranslation}
              updateTranslationStatus={updateTranslationStatus}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          ) : (
            <></>
          )}
          {inactiveTranslations.length > 0 ? (
            <InactiveTranslations
              targets={sortedTargets}
              inactiveTranslations={inactiveTranslations}
              addInactiveTranslation={addInactiveTranslation}
            />
          ) : (
            <></>
          )}
        </Stack>
      </Box>
    </>
  );
};
