import React from 'react';
import {
  Box,
  Card,
  Divider,
  Flex,
  ScrollArea,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useFieldArray, useForm } from 'react-hook-form';
import { notifications } from '@mantine/notifications';
import { COOKIE, hasValidDeploymentValues } from '../utils';
import FooterButtons from '../FooterButtons';
import { useDeploymentContext } from '../DeploymentContextProvider';

export const ConfigureCookieMethod = () => {
  const {
    loading,
    setLoading,
    setSelectedMethod,
    targets = [],
    updateDeploymentDetails,
  } = useDeploymentContext();

  const defaultTargetValues = targets.map((target) => {
    return {
      ...target,
      deployment_value: target.target_lang_code.toLowerCase() || '',
    };
  });

  const { control, register, handleSubmit, setFocus } = useForm({
    defaultValues: {
      deploymentMethod: COOKIE,
      deploymentName: 'langPref',
      targetValues: defaultTargetValues,
    },
  });
  const { fields: targetValues } = useFieldArray({
    control,
    name: 'targetValues',
  });

  const handleUpdate = async (data: {
    deploymentMethod: string;
    deploymentName: string;
    targetValues: TranslationKey[];
  }) => {
    const {
      deploymentName = '',
      deploymentMethod = '',
      targetValues = [],
    } = data || {};

    const deploymentValues = targetValues.map(
      ({ deployment_value = '' }) => deployment_value
    );

    if (!hasValidDeploymentValues(deploymentValues, COOKIE)) {
      const emptyValueIndex = deploymentValues.indexOf('');
      setFocus(`targetValues.${emptyValueIndex}.deployment_value`, {
        shouldSelect: true,
      });
      notifications.show({
        message: 'A Cookie pattern is required per language',
      });
      return;
    }

    setLoading(true);

    updateDeploymentDetails({
      deploymentName,
      deploymentValues,
      deploymentMethod,
    })
      .then(() => {
        notifications.show({
          message: 'Successfully configured new deployment method!',
        });
      })
      .catch(() => {
        notifications.show({
          message:
            'Oops, something went wrong while configuring new deployment method',
        });
      })
      .finally(() => {
        setSelectedMethod('');
        setLoading(false);
      });
  };

  return (
    <Card mt={30} maw="35rem" p="2rem">
      <Flex rowGap="1.5rem" direction="column">
        <Flex w="100%" align="center" columnGap="0.75rem">
          <Title order={1}>Deployment Methods</Title>
        </Flex>
        <Divider size="xs" orientation="horizontal" color="divider.1" />
        <Flex w="100%" direction="column" rowGap="1rem">
          <Text fz="md" fw={600}>
            Please specify Cookie name you want to use:
          </Text>
          <TextInput
            mx="auto"
            w="100%"
            maw="25rem"
            size="lg"
            defaultValue="langPref"
            {...register('deploymentName')}
          />
        </Flex>
        <Divider size="xs" orientation="horizontal" color="divider.1" />
        <Box>
          <Text fz="md" fw={600}>
            Please specify Cookie pattern per language:
          </Text>
        </Box>
        <Flex w="100%" justify="center">
          <ScrollArea
            w="100%"
            maw="25rem"
            h="14rem"
            pr="1rem"
            scrollbarSize={22}
            type="always"
            offsetScrollbars
            className="scrollArea"
          >
            {targetValues.map(
              (
                { id = '', target_lang_name = '', deployment_value = '' },
                index
              ) => {
                const isLastIndex = index === targetValues.length - 1;
                return (
                  <Box key={id}>
                    <Flex h="5rem">
                      <Flex w="70%" align="center" pr="1rem">
                        <Text truncate>{target_lang_name}</Text>
                      </Flex>
                      <Flex w="30%" align="center" pl="1rem">
                        <TextInput
                          maw="15rem"
                          size="md"
                          {...register(
                            `targetValues.${index}.deployment_value`
                          )}
                          defaultValue={deployment_value}
                        />
                      </Flex>
                    </Flex>
                    {!isLastIndex && (
                      <Divider
                        size="xs"
                        orientation="horizontal"
                        color="divider.1"
                      />
                    )}
                  </Box>
                );
              }
            )}
          </ScrollArea>
        </Flex>
        <Divider size="xs" orientation="horizontal" color="divider.1" />
        <FooterButtons
          loading={loading}
          onCancel={() => {
            setSelectedMethod('');
          }}
          onSave={handleSubmit(handleUpdate)}
        />
      </Flex>
    </Card>
  );
};

export default ConfigureCookieMethod;
