import { AxiosError, AxiosResponse } from 'axios';
import { failure } from 'helpers';
import { PosterPromise } from './xapis-wrappers';
import {
  defaultSubscription,
  usePaySubscriptionStore,
} from './PaySubscriptionStore';

export type PayAddress = {
  address: {
    city: string;
    country: string;
    line1: string;
    line2?: string;
    postalCode: string;
    state: string;
  };
  name: string;
  email: string;
  phone: string;
  saveForLater?: boolean;
};

export const defaultPayAddress = {
  address: {
    city: '',
    country: '',
    line1: '',
    line2: '',
    postalCode: '',
    state: '',
  },
  name: '',
  email: '',
  phone: '',
};

export type Item = {
  sku: string | undefined;
  quantity: number;
};

export const createCheckoutSession = async (
  data: { items: Item[] },
  payKey: string,
  projectKey: string
) =>
  PosterPromise(`Pay/Checkout/${payKey}/${projectKey}`, data)
    .then((response: AxiosResponse) => {
      const { data = {} } = response;
      const { active_subscription = defaultSubscription } = data;
      const { project_key: activeSubscriptionExists } = active_subscription;

      if (activeSubscriptionExists) {
        const { setSubscriptions, subscriptions = [] } =
          usePaySubscriptionStore.getState();

        const [, /*firstSub*/ ...restSubscriptions] = subscriptions;
        const newSubscriptions = [active_subscription, ...restSubscriptions];
        setSubscriptions(newSubscriptions);
      }

      return data;
    })
    .catch((error: AxiosError) => {
      const { response: { data = {} } = {} } = error;
      failure(error, 'Something went wrong when creating a checkout session.');
      return data;
    });

export default createCheckoutSession;
