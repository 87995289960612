import { isSuccessStatus } from 'helpers';
import { getQuickQuote } from '../../quickQuote';
import { queryOptions } from '@tanstack/react-query';

export const queryQuickQuote = (
  shouldQueryQuickQuote: boolean,
  origin_name: string
) =>
  queryOptions({
    queryKey: ['quickQuote', origin_name, shouldQueryQuickQuote],

    queryFn: async () => {
      if (!shouldQueryQuickQuote) return null;
      const response = await getQuickQuote(origin_name);
      if (!response || !isSuccessStatus(response.status) || !response.data) {
        throw new Error('Failed to fetch quick quote data');
      }
      return response.data;
    },
  });
