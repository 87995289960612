import { queryOptions } from '@tanstack/react-query';
import { isSuccessStatus } from 'helpers';
import { xapisEnv } from '../service-wrappers/xapisService';

export type ReleaseNote = {
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
};

export const queryReleaseNotes = () => {
  const url = xapisEnv.getHost.releaseNotesURL;
  return queryOptions({
    queryKey: ['releaseNotes'],
    queryFn: async () => {
      const fields = ['title', 'content'];
      try {
        const response = await fetch(
          `${url}/release?_fields=${fields.join(',')}&order=asc`
        );
        const data = await response.json();
        if (!isSuccessStatus(response.status)) {
          throw new Error('Failed to fetch release notes');
        }

        return data as ReleaseNote[];
      } catch (error) {
        console.error('ERROR from releaseNotes call:', error);
        return [];
      }
    },
  });
};
