import React from 'react';
import { Box, Button, Card, Flex, Text, Title } from '@mantine/core';
import { OrderConfirmationLoaderData } from 'store';
import { ConfirmEnvelope } from 'images';
import { useLoaderData, Link } from 'react-router-dom';
import { useGLGOParams } from 'hooks';
import OrderBackground from './order-bg.svg';

export const OrderConfirmationPage = () => {
  const { currentOrderId, currentOrderThreshold } =
    useLoaderData() as OrderConfirmationLoaderData;
  const { projectId } = useGLGOParams();
  const noOrderFound = currentOrderId.length === 0;

  return (
    <Flex
      mih="calc(100vh - 48px)"
      pt="2rem"
      pb="4rem"
      justify="center"
      direction="column"
      style={{
        backgroundImage: `url(${OrderBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <Card
        mx="auto"
        maw={{ base: '95%', sm: '80%', md: '60%' }}
        p={{ base: '2rem 1rem', xs: '2rem', sm: '3rem' }}
        radius="md"
        shadow="sm"
        withBorder
      >
        <Flex direction="column" align="center" rowGap="2rem">
          {noOrderFound ? (
            <Title order={1} ta="center">
              No Order Found
            </Title>
          ) : (
            <>
              <Box ta="center">
                <Title order={1}>Thank You For Choosing GlobalLink WEB!</Title>
                <Title order={3}>Order #{currentOrderId}</Title>
              </Box>
              <ConfirmEnvelope name="envelope" />
              <Flex
                maw={470}
                direction="column"
                align="center"
                rowGap="1.5rem"
                ta="center"
              >
                <Text>
                  We're activating your subscription for{' '}
                  {currentOrderThreshold.toLocaleString()} words per month.
                  You'll receive a confirmation email once your payment has
                  processed. This should only take a few minutes. Remember to
                  check your spam folder if you do not see the email at first.
                </Text>
                <Text>
                  If you don’t receive a confirmation email after 24 hours,
                  contact us for support at: support@globalinkgo.com.
                </Text>
              </Flex>
            </>
          )}
          <Link to={`/${projectId}/dashboard`}>
            <Button>Back to Dashboard</Button>
          </Link>
        </Flex>
      </Card>
    </Flex>
  );
};
