import { rem } from '@mantine/core';
import {
  ADMIN,
  BILLING,
  DEVELOPER,
  GLGO_ADMIN,
  LINGUIST,
  PROJECT_MANAGER,
  TRANSPERFECT_ADMIN,
  TRANSPERFECT_USER,
} from 'helpers/utils/projectPermissions';

const constants = {
  DASHBOARD_NAME: 'GlobalLink Go',
  HEADER_HEIGHT: rem(60),
  SIDE_NAVBAR_WIDTH: 220,
  LOGO_DESKTOP_WIDTH: 200,
  LOGO_MOBILE_WIDTH: 150,
  GTM_TAG_ID: 'GTM-P2DG2MG6',
};

const internalUsers = [TRANSPERFECT_USER, TRANSPERFECT_ADMIN, GLGO_ADMIN];

export const DASHBOARD_PAGE_USERTYPES = new Set([
  ADMIN,
  PROJECT_MANAGER,
  LINGUIST,
  BILLING,
  DEVELOPER,
  ...internalUsers,
]);
export const LANGUAGES_PAGE_USERTYPES = new Set([
  ADMIN,
  PROJECT_MANAGER,
  LINGUIST,
  ...internalUsers,
]);
export const GLOSSARY_PAGE_USERTYPES = new Set([
  ADMIN,
  PROJECT_MANAGER,
  LINGUIST,
  ...internalUsers,
]);
export const NOTRANSLATE_PAGE_USERTYPES = new Set([
  ADMIN,
  PROJECT_MANAGER,
  LINGUIST,
  DEVELOPER,
  ...internalUsers,
]);

export const TRANSLATEDTRAFFIC_PAGE_USERTYPES = new Set([
  ADMIN,
  PROJECT_MANAGER,
  BILLING,
  ...internalUsers,
]);
export const WORLDTRAFFIC_PAGE_USERTYPES = new Set([
  ADMIN,
  PROJECT_MANAGER,
  BILLING,
  ...internalUsers,
]);
export const LANGUAGESETTINGS_PAGE_USERTYPES = new Set([
  ADMIN,
  PROJECT_MANAGER,
  DEVELOPER,
  ...internalUsers,
]);
export const LANGUAGESELECTOR_PAGE_USERTYPES = new Set([
  ADMIN,
  PROJECT_MANAGER,
  DEVELOPER,
  ...internalUsers,
]);
export const DEPLOYMENT_PAGE_USERTYPES = new Set([
  ADMIN,
  PROJECT_MANAGER,
  DEVELOPER,
  ...internalUsers,
]);
export const INJECTION_PAGE_USERTYPES = new Set([
  ADMIN,
  PROJECT_MANAGER,
  DEVELOPER,
  ...internalUsers,
]);
export const PROJECTSCOPE_PAGE_USERTYPES = new Set([
  ADMIN,
  PROJECT_MANAGER,
  ...internalUsers,
]);
export const USERMANAGEMENT_PAGE_USERTYPES = new Set([
  ADMIN,
  PROJECT_MANAGER,
  BILLING,
  ...internalUsers,
]);
export const PROFILE_PAGE_USERTYPES = new Set([
  ADMIN,
  PROJECT_MANAGER,
  LINGUIST,
  BILLING,
  DEVELOPER,
  ...internalUsers,
]);
export const WORDSSERVED_PAGE_USERTYPES = new Set([
  ADMIN,
  PROJECT_MANAGER,
  BILLING,
  ...internalUsers,
]);
export const MTUSAGE_PAGE_USERTYPES = new Set([
  ADMIN,
  PROJECT_MANAGER,
  BILLING,
  ...internalUsers,
]);
export const BILLING_PAGE_USERTYPES = new Set([
  ADMIN,
  BILLING,
  ...internalUsers,
]);

const C = constants;
export default C;
