import moment from 'moment';

/*
 *  takes a time string such as "2024-02-28 20:50:54" and returns formatted date "Feb 28, 2024 at 4:35 pm"
 * */

export const renderLocaleDate = (data: string) => {
  if (data !== '') {
    const timestamp = moment.utc(data).local();
    return timestamp.format('ll [at] h:mm a');
  }
  return '';
};

export default renderLocaleDate;
