const formatDollarAmount = (
  amount: string | number,
  options?: Intl.NumberFormatOptions
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    ...(options ?? {}),
  });

  if (typeof amount === 'string') {
    return formatter.format(parseFloat(amount));
  }
  return formatter.format(amount);
};

export default formatDollarAmount;
