import { hexToObject } from './hex';
import { getNoTxRuleHash } from './hash';

export function selectorIsValid(selector: string) {
  if (!selector) return false;
  try {
    document.querySelector(selector);
    return true;
  } catch {
    return false;
  }
}

export function getTranslationConfigAndRules(
  target: TranslationKey | undefined
) {
  const { translation_config = '' } = target || {};

  const translationConfig: TranslationConfig = hexToObject(translation_config);
  const {
    translation_rules: { no_translate = [] } = {},
    translation_rules = {} as TranslationRules,
  } = translationConfig;
  return {
    translationConfig,
    translation_rules,
    no_translate: no_translate ?? [],
  };
}

export function cleanNoTxRules(rules: UTICX[]) {
  if (!rules || rules.length === 0) return [];
  // Filter out invalid and duplicate rules
  const rulesHashes = new Set<number>();
  const filteredRules = rules.filter((rule) => {
    // rule has X selector and it's not valid
    if ((rule as UX).X && !selectorIsValid((rule as UX).X)) {
      return false;
    }
    const hash = getNoTxRuleHash(rule);
    // rule is duplicate
    if (rulesHashes.has(hash)) {
      return false;
    }
    rulesHashes.add(hash);
    return true;
  });
  return filteredRules;
}
