import { useParams, useLocation } from 'react-router-dom';
import { Box, Tooltip } from '@mantine/core';
import { OpenLink } from 'images';
import { isDesktopApp } from 'helpers';

const { DEV } = import.meta.env;
const { DashboardAPI } = window;
const isGLGODesktop = isDesktopApp();

export const OpenContext = ({
  path,
  disabled,
  color,
}: {
  path: string;
  disabled?: boolean;
  color?: string;
}) => {
  const { projectId } = useParams();
  const currentPath = useLocation().pathname;
  const canOpenPath = path && isGLGODesktop && DashboardAPI && projectId;

  return canOpenPath && !disabled ? (
    <Tooltip label={`Open in context${DEV ? ': ' + path : ''}`}>
      <Box
        style={(theme) => ({
          WebkitAppRegion: 'no-drag',
          cursor: 'pointer',
          color: theme.colors.text[8],
          display: 'flex',
          alignItems: 'center',
        })}
        onClick={(e) => {
          e.stopPropagation();
          setTimeout(() => {
            DashboardAPI?.openInContext(path, currentPath);
          }, 500);
        }}
      >
        <OpenLink color={color} size={20} />
      </Box>
    </Tooltip>
  ) : (
    <></>
  );
};
