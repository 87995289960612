import { BsExclude, BsIntersect } from 'react-icons/bs';
import { EverythingIcon } from '../../images/EverythingIcon';

export const scopeInfo = [
  {
    scopeName: 'all',
    scopeDesc:
      'Every page on your domain that has the JavaScript injection in the <head> will display a language selector and will be translated on demand.',
    scopeColor: '#0071DB',
    ScopeIcon: EverythingIcon,
    scopeButtonText: 'View Page Tree',
  },
  {
    scopeName: 'exclude',
    scopeDesc:
      'Every page except the ones you specifically exclude from scope will be translated. New pages that you add to your site will also automatically be in scope.',
    scopeColor: '#637392',
    ScopeIcon: BsExclude,
    scopeButtonText: 'Manage Exclusion List',
  },
  {
    scopeName: 'include',
    scopeDesc:
      'Only the pages you specifically include will be translated. Any new pages you add to your site will be out of scope unless you specifically add them to the project.',
    scopeColor: '#167934',
    ScopeIcon: BsIntersect,
    scopeButtonText: 'Manage Inclusion List',
  },
];

export const defaultScopeDetails = {
  scopeName: 'all',
  scopeDesc: '',
  scopeColor: '#071D49',
  ScopeIcon: EverythingIcon,
  scopeButtonText: 'View Page Tree',
};
