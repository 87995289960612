export const hexToAscii = (hexString: string) => {
  if (!hexString) {
    return '';
  }
  try {
    return decodeURIComponent(hexString.toString().replace(/(..)/g, '%$1'));
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const hexToObject = (hexString: string) => {
  if (!hexString) {
    return {};
  }

  try {
    return JSON.parse(hexToAscii(hexString));
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const asciiToHex = (str: string) => {
  const utf8Encoder = new TextEncoder();
  return utf8Encoder
    .encode(str)
    .reduce((acc, val) => acc + val.toString(16).padStart(2, '0'), '');
};

export const objToHex = <T>(obj: T) => {
  try {
    const str = JSON.stringify(obj);
    const utf8Encoder = new TextEncoder();
    return utf8Encoder
      .encode(str)
      .reduce((acc, val) => acc + val.toString(16).padStart(2, '0'), '');
  } catch (e) {
    console.error('Error converting object to hex', e);
    return '';
  }
};
