import axios, { AxiosResponse } from 'axios';
import { failure } from 'helpers';
import getAxiosUrlAndConfig from './getAxiosUrlAndConfig';

/**
 *
 * @param endpoint
 * @param data
 * @param success
 * @param failed
 * @param moreParams
 * @param cleanup
 * @constructor
 */
export const Putter = (
  endpoint: string,
  data: object,
  success: (response: AxiosResponse) => void,
  failed: { errorMessage?: string; action?: () => void },
  moreParams: object = {},
  cleanup?: () => void
) => {
  const { url, config } = getAxiosUrlAndConfig(endpoint, moreParams);

  axios
    .put(url, data, config)
    .then((results) => {
      success(results);
    })
    .catch((error) => {
      failure(error, failed.errorMessage);
      failed.action && failed.action();
    })
    .finally(() => cleanup && cleanup());
};

export default Putter;
