import { Button } from '@mantine/core';
import { FaTrash } from 'react-icons/fa';
import React from 'react';

type Props = {
  onClick: () => void;
  [prop: string]: unknown;
};
const TableDeleteButton = (props: Props) => {
  return (
    <Button
      styles={{
        root: {
          backgroundColor: 'transparent',
          border: 'none',
          padding: 0,
          minWidth: 'auto',
        },
      }}
      variant="transperent"
      {...props}
    >
      <FaTrash size={14} color={'var(--mantine-color-font1-1)'} />
    </Button>
  );
};
export default TableDeleteButton;

// styles={{
//   root: {
//     backgroundColor: 'transparent',
//     border: 'none',
//     padding: 0,
//     minWidth: 'auto',
//   },
// }}
// variant="subtle"
