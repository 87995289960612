import { Params } from 'react-router-dom';
import { decodeProjectId } from 'helpers';
import { xapisEnv } from '../../service-wrappers/xapisService';
import { queryClient } from '../../queries/queryClient';
import { querySubscriptions } from '../../queries/shared/querySubscriptions';
import { querySkus } from '../../queries/shared/querySkus';
import {
  queryBillingHistory,
  querySources,
} from '../../queries/billingQueries';
import { queryQuickQuote } from '../../queries/shared/queryQuickQuote';
import { projectQuery } from '../../queries/projectQuery';

export type BillingLoaderData = {
  origin_name: string;
  shouldQueryQuickQuote: boolean;
};

export async function billingLoader({
  params,
}: {
  params: Params<string>;
  request: Request;
}): Promise<BillingLoaderData> {
  const { projectId } = params;
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }

  const { payKey } = xapisEnv.getHost;

  const projectKey = decodeProjectId(projectId);
  const projectResponse = await queryClient.ensureQueryData(
    projectQuery(projectKey)
  );
  const subscription_status =
    projectResponse.project?.subscription_status ?? 'expired';
  const origin_name = projectResponse.project?.origin_name ?? '';

  const subscriptions = await queryClient.ensureQueryData(
    querySubscriptions(payKey, projectKey, subscription_status === 'expired')
  );

  const shouldQueryQuickQuote =
    subscriptions &&
    subscriptions.length > 0 &&
    subscriptions[0].price === 0 &&
    origin_name?.length > 0;

  // we don't await this one and it will sometimes trigger React suspense
  queryClient.ensureQueryData(
    queryQuickQuote(shouldQueryQuickQuote, origin_name)
  );

  await queryClient.ensureQueryData(querySkus(payKey));
  await queryClient.ensureQueryData(querySources(payKey));
  await queryClient.ensureQueryData(queryBillingHistory(payKey, projectKey));

  return { origin_name, shouldQueryQuickQuote };
}
