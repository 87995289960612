/**
 * Takes shorthand ISO 8601 timestamp strings (from Xapis or XPay),
 * and transforms them to pretty versions to be displayed in the UI.
 *
 * IMPORTANT: timestampString must be in ISO 8601 format, ex. "2021-01-26"
 *
 * @param timestampString - ex. "2022-11-13"
 * @return string - ex. "November 13, 2022"
 */
const getPrettyDate = (timestampString: string): string => {
  if (!timestampString || timestampString === 'N/A') {
    return 'N/A';
  }

  // Make sure to include the 'T00:00:00' or else it'll subtract 1 day from the date (JS bug with ISO format)
  const nextRenewalDate = new Date(timestampString.concat('T00:00:00'));

  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(nextRenewalDate);
};

export default getPrettyDate;
