import React, { useCallback, useMemo } from 'react';
import { Card, Flex, Progress, Text, Tooltip } from '@mantine/core';
import { WordsServedUrlsResponse } from 'store';
import { useGetNavigationStatus } from 'hooks';
import { MRT_ColumnDef } from 'mantine-react-table';
import WordsServedTable from './WordsServedTable';
import { getBasicColumn } from '../utils';

export type WordsServedUrl = {
  content_url: string;
  wordsServed: number;
};
type Props = {
  urls: WordsServedUrlsResponse[];
};
const WordsServedUrls = ({ urls }: Props) => {
  const loading = useGetNavigationStatus().isNavigatingInPlace;

  const accumulatedWordsServedUrls: WordsServedUrl[] = useMemo(() => {
    return urls.map((urlObj) => {
      const wordsServed = Object.entries(urlObj).reduce(
        (accum, [key, value]) => {
          if (
            key !== 'content_url' &&
            key !== 'content_url_hash' &&
            typeof value === 'number'
          )
            return accum + value;
          return accum;
        },
        0
      );

      const formatUrl = (url: string) => {
        const cleanedUrl = url.startsWith('//') ? url.slice(2) : url;
        return cleanedUrl.replace(/\//g, '/​');
      };

      return {
        content_url: formatUrl(urlObj.content_url || '') || '',
        wordsServed,
      };
    });
  }, [urls]);

  const totalWordsServed: number = useMemo(() => {
    return accumulatedWordsServedUrls.reduce(
      (accum, { wordsServed }) => accum + wordsServed,
      0
    );
  }, [accumulatedWordsServedUrls]);

  const getWordsServedPercentage = useCallback(
    (wordsServed: number) => {
      return Math.round((wordsServed * 100) / totalWordsServed) || 0;
    },
    [totalWordsServed]
  );

  const filteredAndSortedWordsServedUrls: WordsServedUrl[] = useMemo(() => {
    return accumulatedWordsServedUrls
      .filter(({ wordsServed }) => getWordsServedPercentage(wordsServed) >= 1)
      .sort((url1, url2) =>
        url1.wordsServed < url2.wordsServed
          ? 1
          : url1.wordsServed > url2.wordsServed
            ? -1
            : 0
      );
  }, [accumulatedWordsServedUrls, getWordsServedPercentage]);

  const columns: MRT_ColumnDef<WordsServedUrl>[] = [
    {
      accessorKey: 'content_url',
      header: 'Pages',
      Cell: ({ cell }) => {
        const contentUrl = cell.getValue() as string;
        return (
          <Tooltip position="top" label={contentUrl}>
            <Text truncate>{contentUrl}</Text>
          </Tooltip>
        );
      },
    },
    {
      ...getBasicColumn('wordsServed'),
      header: 'Words Served',
      Cell: ({ cell }) => {
        const wordsServed = cell.getValue() as number;
        return (
          <Tooltip label={wordsServed.toLocaleString()}>
            <Text fw={700} truncate>
              {wordsServed.toLocaleString()}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      ...getBasicColumn(''),
      id: 'percentage',
      Cell: ({ row }) => {
        const wordsServedPercentage = getWordsServedPercentage(
          row.original.wordsServed
        );
        return (
          <Tooltip label="This is the percentage of total traffic to this page over the specified time period.">
            <Flex w="100%" align="center" gap={10}>
              <Progress value={wordsServedPercentage} w="100%" />
              <Text fw={700}>{wordsServedPercentage}%</Text>
            </Flex>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Card p={0}>
      <WordsServedTable
        data={filteredAndSortedWordsServedUrls}
        columns={columns}
        loading={loading}
      />
    </Card>
  );
};

export default WordsServedUrls;
