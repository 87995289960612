/**
 * Returns a set with all the elements in leftSet that also exist in the rightSet
 *
 * Example:
 *  const set1 = new Set(['bat', 'cat', 'sat']);
 *  const set2 = new Set(['bat', 'mower']);
 *  createIntersectionSet(set1, set2) = Set(['bat'])
 *
 * @param leftSet
 * @param rightSet
 */
const createIntersectionSet = <T>(
  leftSet: Set<T>,
  rightSet: Set<T>
): Set<T> => {
  const interSectionSet = new Set<T>();
  leftSet.forEach((element: T) => {
    if (rightSet.has(element)) {
      interSectionSet.add(element);
    }
  });
  return interSectionSet;
};

export default createIntersectionSet;
