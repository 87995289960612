import {
  Box,
  Button,
  Flex,
  ScrollArea,
  Text,
  Image,
  Title,
} from '@mantine/core';
import { xapisEnv } from 'store';
import { useSignin } from 'auth';
import { Link } from 'react-router-dom';
import { dashboardRC } from 'helpers';
import GLWEBLogoColor from 'images/logos/glweb-single-level-logo.svg';
import AccoladesWrapper from './AccoladesWrapper';
import { AnchorLink } from 'ui';

export const LoginOrSignUp = ({ redirectUrl }: { redirectUrl?: string }) => {
  const { glgoMarketingUrl } = xapisEnv.getHost;
  const termsOfServiceUrl = `${glgoMarketingUrl}/terms`;
  const privacyPolicyUrl = `${glgoMarketingUrl}/privacy`;

  const signin = useSignin(redirectUrl || dashboardRC.PAGE_ROOT);

  return (
    <>
      <AccoladesWrapper>
        <Flex h="100vh" direction="column" justify="center">
          <ScrollArea scrollbars="y" type="auto">
            <Flex
              w="100%"
              mih={550}
              direction="column"
              justify="center"
              align="center"
              rowGap="3rem"
              px="1rem"
            >
              <Flex direction="column" align="center" rowGap="2rem">
                <Image
                  src={GLWEBLogoColor}
                  width={280}
                  alt="GlobalLink WEB logo"
                  style={{
                    objectFit: 'cover',
                    width: '17.5rem',
                    height: ' auto',
                  }}
                />
                <Title order={2} fw={500} ta="center" maw="25rem">
                  <Text c="inherit" fz="inherit" fw={700} span>
                    Go multilingual
                  </Text>{' '}
                  in minutes with the{' '}
                  <Text fz="inherit" fw={700} c="text.8" span>
                    global leader
                  </Text>{' '}
                  in localization services.
                </Title>
              </Flex>
              <Flex
                w="100%"
                maw="24rem"
                align="center"
                direction="column"
                rowGap="1.5rem"
              >
                <Button
                  h={47}
                  w="100%"
                  variant="outline"
                  fz="1rem"
                  onClick={signin}
                >
                  Log In
                </Button>
                <Flex w="100%" align="center">
                  <Box w="37.5%" h={3} bg="background2.7" />
                  <Box w="25%">
                    <Text c="font1.1" fw={600} ta="center">
                      OR
                    </Text>
                  </Box>
                  <Box w="37.5%" h={3} bg="background2.7" />
                </Flex>
                <Flex
                  w="100%"
                  direction="column"
                  align="center"
                  rowGap="0.5rem"
                >
                  <Link
                    style={{ width: '100%' }}
                    to={dashboardRC.PAGE_CREATE_ACCOUNT}
                  >
                    <Button h={47} w="100%" fz="1rem">
                      Sign Up
                    </Button>
                  </Link>
                </Flex>
                <Text ta="center" fz="0.75rem" fw={500}>
                  By signing up, you agree to the{' '}
                  <AnchorLink
                    fw="inherit"
                    fz="inherit"
                    to={termsOfServiceUrl}
                    target="_blank"
                    td="none"
                  >
                    Terms of Service
                  </AnchorLink>{' '}
                  and{' '}
                  <AnchorLink
                    fw="inherit"
                    fz="inherit"
                    to={privacyPolicyUrl}
                    target="_blank"
                    td="none"
                  >
                    Privacy Policy
                  </AnchorLink>
                  .
                </Text>
              </Flex>
            </Flex>
          </ScrollArea>
        </Flex>
      </AccoladesWrapper>
    </>
  );
};

export default LoginOrSignUp;
