import { flags, hexToAscii } from 'helpers';

export const addLabelToConfig = (
  config: {
    staging_translation_rules?: {
      language_selector_labels?: Record<string, string>[];
    };
    translation_rules?: {
      language_selector_labels?: Record<string, string>[];
    };
  },
  translation: TranslationKey,
  isLive: boolean
) => {
  const safeLabels = (labels?: Record<string, string>[]) => labels || [];

  //add labels based on showFlag and showRegion
  const exampleLabel = Object.values(
    safeLabels(config.staging_translation_rules?.language_selector_labels)[0] ||
      {}
  )[0] as string;

  const flagRegExp = /\p{So}\p{So}/gu;
  const regionRegExp = /\s*[（(].*$/;

  const showFlag = flagRegExp.test(exampleLabel);
  const showRegion = regionRegExp.test(exampleLabel);

  const basicLabel =
    translation.target_native_name ||
    translation.target_lang_name ||
    translation.target_lang_code;
  const labelWithoutRegion = basicLabel.replace(/\s*[（(].*$/, '');
  const flag = showFlag
    ? flags[translation.target_lang_code as keyof typeof flags]
    : '';

  const finalizedLabel = showRegion
    ? flag + basicLabel
    : flag + labelWithoutRegion;

  const newLabel = { [translation.deployment_value]: finalizedLabel };

  const stagingLabels = safeLabels(
    config.staging_translation_rules?.language_selector_labels
  );
  const liveLabels = safeLabels(
    config.translation_rules?.language_selector_labels
  );

  const stagingHasLabel = stagingLabels.some(
    (l) => Object.keys(l)[0] === translation.deployment_value
  );
  const liveHasLabel = liveLabels.some(
    (l) => Object.keys(l)[0] === translation.deployment_value
  );

  const newLiveLabels =
    isLive && !liveHasLabel ? [...liveLabels, newLabel] : liveLabels;
  const newStagingLabels = stagingHasLabel
    ? stagingLabels
    : [...stagingLabels, newLabel];

  return {
    ...config,
    staging_translation_rules: {
      ...config.staging_translation_rules,
      language_selector_labels: newStagingLabels,
    },
    translation_rules: {
      ...config.translation_rules,
      language_selector_labels: newLiveLabels,
    },
  };
};

export const removeLabelFromConfig = (
  config: {
    staging_translation_rules?: {
      language_selector_labels?: Record<string, string>[];
    };
    translation_rules?: {
      language_selector_labels?: Record<string, string>[];
    };
  },
  translation: TranslationKey,
  isInactive: boolean
) => {
  // to handle possible undefined returns due to making above arguments optional. always returns an array
  const safeFilter = (labels?: Record<string, string>[]) =>
    labels?.filter(
      (label) => !Object.keys(label).includes(translation.deployment_value)
    ) || [];

  return {
    ...config,
    staging_translation_rules: {
      ...config.staging_translation_rules,
      language_selector_labels: isInactive
        ? safeFilter(config.staging_translation_rules?.language_selector_labels)
        : config.staging_translation_rules?.language_selector_labels || [],
    },
    translation_rules: {
      ...config.translation_rules,
      language_selector_labels: safeFilter(
        config.translation_rules?.language_selector_labels
      ),
    },
  };
};

export const getTranslationConfig = (config: string | undefined) => {
  try {
    return JSON.parse(hexToAscii(config ?? '{}'));
  } catch {
    return {};
  }
};
