// trackEvent only has one required argument (event string) and an optional object we don't need
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fathom: any;
  }
}

export const fathomEventTracker = (eventString: string): void => {
  if (window.fathom) {
    window.fathom.trackEvent(eventString);
  }
};

export default fathomEventTracker;
