import { Box, Button, Flex, Text, Image } from '@mantine/core';
import { useSignin, shouldSkipSSO } from 'auth';
import { dashboardRC } from 'helpers';
import GLGOLogoColor from 'images/logos/glgo-logo-color.svg';

export const DesktopLogin = ({ redirectUrl }: { redirectUrl?: string }) => {
  const skipSsoMode = shouldSkipSSO();

  const signin = useSignin(redirectUrl || dashboardRC.PAGE_ROOT);

  return (
    <>
      <Box style={{ height: 40, WebkitAppRegion: 'drag' }}></Box>
      <Flex
        w="100%"
        h="100vh"
        mih="20rem"
        direction="column"
        justify="center"
        align="center"
        rowGap="3rem"
        px="1rem"
      >
        <Image w={280} src={GLGOLogoColor} alt="GlobalLinkWeb logo" />
        <Flex
          w="100%"
          align="center"
          justify="center"
          direction="column"
          rowGap="1rem"
        >
          <Button
            h={47}
            w="100%"
            maw="22rem"
            variant="outline"
            onClick={signin}
          >
            {skipSsoMode ? 'Log in (skip SSO)' : 'Log in with browser.'}
          </Button>
          {!skipSsoMode && <Text>You will be redirected here.</Text>}
        </Flex>
      </Flex>
    </>
  );
};
