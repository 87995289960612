import { Navigate, RouteObject } from 'react-router-dom';
import {
  CreateAccount,
  DashboardPage,
  ProjectSetupPage,
  translationsRoutes,
  metricsRoutes,
  settingsRoutes,
  accountRoutes,
  quoteRoutes,
  DowngradeConfirmationPage,
  OrderConfirmationPage,
  UserManagementPage,
  CancellationConfirmationPage,
  DesktopAppPage,
} from 'pages';
import {
  dashboardLoader,
  downgradeConfirmationLoader,
  metricsLoader,
  orderConfirmationLoader,
  projectQueryLoader,
  quotesLoader,
  cancellationConfirmationLoader,
} from 'store';
import { dashboardRC } from 'helpers';
import PostLogin from './PostLogin';
import SilentRenew from './SilentRenew';
import { ErrorView } from './ErrorView';
import { PostLogout } from './PostLogout';
import { Logout } from './Logout';
import StoreDebug from './StoreDebug';
import NavigationLayout from './NavigationLayout';
import { Root } from './Root';
import { ErrorBoundary, PageOutlet } from 'ui';
import { DevLogin } from 'auth';
import { RestrictedRoute } from 'pages';
import { USERMANAGEMENT_PAGE_USERTYPES } from './constants';
import ProjectOutletWrapper from './ProjectOutletWrapper';
export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorView />,
    children: [
      {
        element: <NavigationLayout />,
        errorElement: <ErrorView />,
        children: [
          {
            path: ':projectId',
            element: <ProjectOutletWrapper />,
            id: 'project',
            loader: projectQueryLoader,
            children: [
              {
                index: true,
                element: <Navigate to={dashboardRC.PAGE_DASHBOARD} replace />,
              },
              {
                path: dashboardRC.PAGE_DASHBOARD,
                element: <DashboardPage />,
                id: 'dashboard',
                loader: dashboardLoader,
              },
              {
                path: dashboardRC.PAGE_TRANSLATIONS,
                id: 'translations',
                element: (
                  <PageOutlet
                    parentRoute={dashboardRC.PAGE_TRANSLATIONS}
                    redirectRoute={dashboardRC.PAGE_LANGUAGES}
                  />
                ),
                errorElement: <ErrorBoundary />,
                children: [...translationsRoutes],
              },
              {
                path: dashboardRC.PAGE_METRICS,
                id: 'metrics',
                element: (
                  <PageOutlet
                    parentRoute={dashboardRC.PAGE_METRICS}
                    redirectRoute={dashboardRC.PAGE_TRANSLATED_TRAFFIC}
                  />
                ),
                loader: metricsLoader,
                errorElement: <ErrorBoundary />,
                children: [...metricsRoutes],
              },
              {
                path: dashboardRC.PAGE_SETTINGS,
                id: 'settings',
                element: (
                  <PageOutlet
                    parentRoute={dashboardRC.PAGE_SETTINGS}
                    redirectRoute={dashboardRC.PAGE_LANGUAGE_SETTINGS}
                  />
                ),
                errorElement: <ErrorBoundary />,
                shouldRevalidate: () => {
                  return true;
                },
                children: [...settingsRoutes],
              },
              {
                path: dashboardRC.PAGE_ACCOUNT,
                id: 'account',
                element: (
                  <PageOutlet
                    parentRoute={dashboardRC.PAGE_ACCOUNT}
                    redirectRoute={dashboardRC.PAGE_MY_PROFILE}
                  />
                ),
                errorElement: <ErrorBoundary />,
                children: [...accountRoutes],
              },
              {
                path: dashboardRC.PAGE_QUOTES,
                id: 'quotes',
                element: (
                  <PageOutlet
                    parentRoute={dashboardRC.PAGE_QUOTES}
                    redirectRoute="project"
                  />
                ),
                loader: quotesLoader,
                errorElement: <ErrorBoundary />,
                children: [...quoteRoutes],
              },
              {
                path: dashboardRC.PAGE_STORE_DEBUG,
                element: <StoreDebug />,
              },
              {
                path: dashboardRC.PAGE_ORDER_CONFIRMATION,
                element: <OrderConfirmationPage />,
                loader: orderConfirmationLoader,
              },
              {
                path: dashboardRC.PAGE_DOWNGRADE_CONFIRMATION,
                element: <DowngradeConfirmationPage />,
                loader: downgradeConfirmationLoader,
              },
              {
                path: dashboardRC.PAGE_CANCELLATION_CONFIRMATION,
                element: <CancellationConfirmationPage />,
                loader: cancellationConfirmationLoader,
              },
              {
                path: dashboardRC.PAGE_USER_MANAGEMENT,
                element: (
                  <RestrictedRoute allowedRoles={USERMANAGEMENT_PAGE_USERTYPES}>
                    <UserManagementPage />
                  </RestrictedRoute>
                ),
              },
              {
                path: dashboardRC.PAGE_DESKTOP_APP,
                element: <DesktopAppPage />,
              },
            ],
          },
        ],
      },
      {
        path: dashboardRC.PAGE_CREATE_PROJECT,
        element: <ProjectSetupPage />,
      },
    ],
  },
  {
    path: dashboardRC.PAGE_CREATE_ACCOUNT,
    element: <CreateAccount />,
    errorElement: <ErrorView />,
  },
  {
    path: dashboardRC.PAGE_LOGIN_REDIRECT,
    element: <PostLogin />,
  },
  {
    path: dashboardRC.PAGE_SILENT_RENEW,
    element: <SilentRenew />,
  },
  {
    path: dashboardRC.PAGE_LOGOUT,
    element: <Logout />,
  },
  {
    path: dashboardRC.PAGE_DEV_LOGIN,
    element: <DevLogin />,
  },
  {
    path: dashboardRC.PAGE_LOGOUT_REDIRECT,
    element: <PostLogout />,
  },
];
