export const CrownOutlineIcon = ({
  width,
  height,
  color,
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg
    width={width || '16'}
    height={height || '13'}
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1.25L11 6.5L14.75 3L13.25 11.75H2.75L1.25 3L5 6.5L8 1.25Z"
      stroke={color || '#FBC30C'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CrownOutlineIcon;
