import React from 'react';
import { Text } from '@mantine/core';
import { xapisEnv } from 'store';
import { TbExternalLink } from 'react-icons/tb';
import CustomModal from '../../../CustomModal';
import { GLWEB_SUPPORT_URL } from 'helpers';
import { AnchorLink } from '../../../Generic';

export default function UpgradeToPlusModal({
  opened,
  setOpened,
}: {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { glgoMarketingUrl = '' } = xapisEnv.getHost;
  const contactUsUrl = `${glgoMarketingUrl}/about-us#contact-us-form`;

  return (
    <>
      <CustomModal
        size="lg"
        title="Upgrade to gain access to this feature!"
        opened={opened}
        onClose={() => setOpened(false)}
        footerActions={[
          { label: 'Contact Us', to: contactUsUrl, target: '_blank' },
        ]}
      >
        <Text fz="md">
          You will need to upgrade to a higher service tier to gain access to
          different deployment methods, such as Folders or Sub-Domains.{' '}
          <AnchorLink
            to={`${GLWEB_SUPPORT_URL}/article/130-what-are-the-different-deployment-methods`}
            target="_blank"
            fz="inherit"
            display="inline-flex"
          >
            Learn more.
            <TbExternalLink size={20} />
          </AnchorLink>
          <br />
          <br />
          These other deployment methods are necessary to enable multi-lingual
          SEO. Our team will help you get started.
        </Text>
      </CustomModal>
    </>
  );
}
