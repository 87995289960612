import React from 'react';
import { Flex, Text } from '@mantine/core';

type Props = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
};
const PersonalInformation = ({ firstName, lastName, phoneNumber }: Props) => {
  const formatPhone = () => {
    if (phoneNumber) {
      let phoneValue: string;
      if (
        phoneNumber.substring(0, 2) === '+1' ||
        phoneNumber.substring(0, 2) === '1-'
      ) {
        phoneValue = phoneNumber.substring(3);
      } else {
        phoneValue = phoneNumber;
      }

      return `(${phoneValue.substring(0, 3)}) ${phoneValue.substring(3, 6)}-${phoneValue.substring(6, 11)}`;
    }
    return '';
  };

  return (
    <>
      <Flex w="100%" direction={{ base: 'column', sm: 'row' }}>
        <Flex direction="column" w={{ xs: '100%', sm: '50%' }} py="0.5rem">
          <Text fw={700} mb="0.75rem">
            First Name
          </Text>
          <Text>{firstName}</Text>
        </Flex>
        <Flex direction="column" w="50%" py="0.5rem">
          <Text fw={700} mb="0.75rem">
            Last Name
          </Text>
          <Text>{lastName}</Text>
        </Flex>
      </Flex>
      <Flex w="100%" justify="space-between">
        <Flex direction="column" pt="1rem">
          <Text fw={700} mb="0.75rem">
            Phone Number
          </Text>
          <Text>{formatPhone()}</Text>
        </Flex>
      </Flex>
    </>
  );
};

export default PersonalInformation;
