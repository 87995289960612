import { Box } from '@mantine/core';
import { useEditorColors } from 'helpers';

import { TagIcon, QTag } from '../../icons/TagIcons';

type TagProps = {
  kind?: 'sc' | 'ec';
  type?: string;
  mode?: string;
};

export const Tag = ({
  kind = 'sc',
  type = 'span',
  mode = 'hide',
}: TagProps) => {
  // Don't show tags if not in active or focus mode
  // Except for <q> tag
  const colors = useEditorColors().jliffEditor.tags;

  if (mode === 'hide' && !(type === 'q')) {
    return null;
  }

  const color =
    colors.color[mode as keyof (typeof colors)['color']] || 'inherit';
  const fill =
    colors.fill[mode as keyof (typeof colors)['fill']] || 'transparent';

  const icon =
    type === 'q' ? <QTag kind={'none'} /> : <TagIcon kind={kind} tag={type} />;

  return (
    <Box
      component="span"
      c={color}
      display={'inline-flex'}
      style={{
        color,
        fill,
        transform: `translateY(3px)`,
      }}
    >
      {icon}
    </Box>
  );
};
