import { create } from 'zustand';
import Get from './xapis-wrappers/Fetcher';
import {
  filterNodes,
  filterRootTree,
  mergeChildren,
} from './spiderTreeFilters';
import SpiderTreeNode from './SpiderTreeNode';
import FetcherPromise from './xapis-wrappers/FetcherPromise';
import { AxiosResponse } from 'axios';

export type SpiderTreeStore = {
  tree: SpiderTreeNode[];
  setTree: (tree: SpiderTreeNode[]) => void;
  fetchSpiderTree: (tKey: string) => void;
  fetchMoreSpiderTreeNodes: (tKey: string, rowData: SpiderTreeNode) => void;
  grabNodeChildren(tKey: string, hash: string): Promise<AxiosResponse>;
};

export const useSpiderTreeStore = create<SpiderTreeStore>()((set, get) => ({
  tree: [],
  setTree: (tree: SpiderTreeNode[]) => set({ tree }),
  fetchSpiderTree: (tKey) => {
    Get(
      `SpiderTree/${tKey}`,
      ({ data }) => {
        const tree = filterRootTree(data.data);
        set(() => ({ tree }));
      },
      {}
    );
  },
  fetchMoreSpiderTreeNodes: (tKey, rowData) => {
    const { content_url_hash, id } = rowData;
    const state = get();

    Get(
      `SpiderTree/${tKey}/${content_url_hash}`,
      ({ data }) => {
        const { tree: last } = state;
        const filteredData = filterNodes(data.data, id) as SpiderTreeNode[];
        const crawlerTreeNext = mergeChildren(filteredData, id, last);

        set(() => ({ tree: crawlerTreeNext }));
      },
      { action: () => set({ tree: [] }) }
    );
  },
  grabNodeChildren: (tKey, hash) =>
    FetcherPromise(`SpiderTree/${tKey}/${hash}`),
}));

export default useSpiderTreeStore;
