import React, { useMemo } from 'react';
import { Box, Flex, Progress, Text, Title, Tooltip } from '@mantine/core';
import { WorldTrafficResponse } from 'store';
import { DataWrapper } from '../../Generic';
import { useGetNavigationStatus } from 'hooks';
import { sortByAscOrDescOrder } from 'helpers/utils/sorting';

type Props = {
  worldTraffic: WorldTrafficResponse[];
};
const WorldTraffic = ({ worldTraffic }: Props) => {
  const loading = useGetNavigationStatus().isNavigatingInPlace;

  const tooltipLabel =
    'This card shows traffic to your website from countries around the world and percentages indicating the share of total traffic from that country for the language and time period specified.\n' +
    '\n' +
    'Note that this card does not show traffic related to translations. Traffic from any country could be viewing your site in any of the languages available. E.g., ' +
    'not all traffic from the United States is necessarily viewing the English version of your website.';

  const totalTrafficCount = useMemo(() => {
    return worldTraffic.reduce((accum, { count = 0 }) => accum + count, 0);
  }, [worldTraffic]);

  const calculateTrafficPercentage = (count: number) => {
    if (totalTrafficCount > 0) {
      return Math.round((count / totalTrafficCount) * 100);
    }
    return 0;
  };

  const sortedWorldTraffic = useMemo(() => {
    return sortByAscOrDescOrder(worldTraffic, 'count', 'desc').slice(0, 6);
  }, [worldTraffic]);

  return (
    <>
      <Tooltip label={tooltipLabel}>
        <Title
          order={3}
          w="fit-content"
          tt="uppercase"
          data-testid="pw-ms-world-traffic-title"
        >
          WORLD TRAFFIC
        </Title>
      </Tooltip>
      <Box h={140} mt="0.75rem" data-testid="pw-ms-world-traffic-container">
        <DataWrapper loading={loading} noDataFound={worldTraffic.length === 0}>
          {sortedWorldTraffic.map(
            ({ count = 0, label = 'Unknown', country_name = 'Unknown' }) => {
              const trafficPercentage = calculateTrafficPercentage(count);
              return trafficPercentage >= 1 ? (
                <Flex
                  key={label}
                  mih={23}
                  w="100%"
                  align="center"
                  columnGap="0.75rem"
                >
                  <Box w="35%">
                    <Tooltip label={country_name}>
                      <Text maw="fit-content" fw={600} fz="xs" truncate>
                        {country_name}
                      </Text>
                    </Tooltip>
                  </Box>
                  <Progress flex={1} size="lg" value={trafficPercentage} />
                  <Flex w={32} justify="flex-end">
                    <Text fw={700}>{trafficPercentage}%</Text>
                  </Flex>
                </Flex>
              ) : (
                <React.Fragment key={label} />
              );
            }
          )}
        </DataWrapper>
      </Box>
    </>
  );
};

export default WorldTraffic;
