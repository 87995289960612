/**
 * Returns a set with all the elements in leftSet that don't exist in the rightSet
 *
 * Example:
 *  const set1 = new Set(['bat', 'cat', 'sat']);
 *  const set2 = new Set(['bat', 'mower']);
 *  createDifferenceSet(set1, set2) = Set(['cat', 'sat'])
 *
 * @param leftSet
 * @param rightSet
 */
const createDifferenceSet = <T>(leftSet: Set<T>, rightSet: Set<T>): Set<T> => {
  const differenceSet = new Set<T>();
  leftSet.forEach((element: T) => {
    if (!rightSet.has(element)) {
      differenceSet.add(element);
    }
  });
  return differenceSet;
};

export default createDifferenceSet;
