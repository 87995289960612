import { useRouteLoaderData } from 'react-router-dom';
import {
  useCurrentProjectData,
  queryAccountMTUsage,
  AccountMTLoaderResponse,
} from 'store';
import { Box, Card, Divider } from '@mantine/core';
import { Header } from '../../../Generic';
import React from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { QueryMultiSelector } from '../../selectors-filters/QueryMultiSelector';
import { filterTargets } from '../../gometrics/trafficUtils';
import AccountMTUsageChart from './AccountMTUsageChart';
import DateRangePicker from '../../selectors-filters/DateRangePicker';

export const AccountMTUsage = () => {
  const { tKeys, timeSliceFrom, timeSliceTo } = useRouteLoaderData(
    'mt-usage'
  ) as AccountMTLoaderResponse;

  const { translations }: { translations: TranslationKey[] } =
    useCurrentProjectData();

  const { data: mtUsageData } = useSuspenseQuery(
    queryAccountMTUsage(tKeys, timeSliceFrom, timeSliceTo)
  );

  return (
    <Box maw={{ base: '100%', md: '90%', lg: '85%', xl: '75%' }}>
      <Header
        wrapContent
        title="Monthly Translate API Usage"
        tooltipLabel="The number of new words that were sent to a machine translation engine for the filtered time period/language(s)."
        flexItems={
          <>
            <QueryMultiSelector
              options={filterTargets(translations)}
              selectAll
              encodeKeys
            />
            <DateRangePicker
              timeSliceFrom={timeSliceFrom}
              timeSliceTo={timeSliceTo}
            />
          </>
        }
      />
      <Card p={0}>
        <Divider />
        <AccountMTUsageChart mtUsageData={mtUsageData} />
      </Card>
    </Box>
  );
};

export default AccountMTUsage;
