import { Xapis, ProjectResponse } from '../xapis-wrappers/xapis';
import { LoaderFunctionArgs } from 'react-router-dom';
import { decodeProjectId } from 'helpers';
import useTranslatedTrafficStore from '../TranslatedTrafficStore';
import { fetchWorldTrafficPromise } from '../WorldTrafficStore';
import { decodeId, filterTranslationKeys } from 'helpers';

export async function metricsLoader({ params, request }: LoaderFunctionArgs) {
  const { projectId } = params;
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }
  const projectKey = decodeProjectId(projectId);
  const response: ProjectResponse = await Xapis.Project.get(projectKey);

  const { translations = [] } = response.project || {};

  const translationKeys = filterTranslationKeys(translations);

  const data = {
    targets: translations,
    urls: [] as string[],
    timeSlices: [] as string[],
    timeSliceFrom: '',
    worldTraffic: [],
    ...response,
  };

  const url = new URL(request.url);
  const keys = url.searchParams.getAll('t');

  const timeSliceFrom = url.searchParams.get('d') || '1 month';
  data.timeSliceFrom = timeSliceFrom;

  if (keys.length > 0 && keys.includes('0')) return data;

  const keysInUrl = keys.map((k) => decodeId(k));

  const getTranslatedTraffic = async () => {
    let timeSlices = [];
    let urls = [];

    const fetchTranslatedTraffic =
      useTranslatedTrafficStore.getState().fetchTranslatedTraffic;
    const keys = keysInUrl.length ? keysInUrl : translationKeys;

    try {
      const [timeSliceResponse, urlsResponse] = await Promise.allSettled([
        fetchTranslatedTraffic(keys, timeSliceFrom, 'timeseries'),
        fetchTranslatedTraffic(keys, timeSliceFrom, 'url'),
      ]);

      if (
        timeSliceResponse.status === 'fulfilled' &&
        timeSliceResponse.value.status === 200
      ) {
        const { data } = timeSliceResponse.value.data;
        timeSlices = data;
      }

      if (
        urlsResponse.status === 'fulfilled' &&
        urlsResponse.value.status === 200
      ) {
        const { data } = urlsResponse.value.data;
        urls = data;
      }
    } catch (error) {
      console.error(
        'error in getTranslatedTraffic fn in metricsLoader:',
        error
      );
    }

    return { timeSlices, urls };
  };

  const getWorldTraffic = async () => {
    const concatenatedKeysOffProject = translationKeys.join(',');
    const concatenatedKeysOffUrl = keysInUrl.join(',');
    const keys =
      concatenatedKeysOffUrl.length > 0
        ? concatenatedKeysOffUrl
        : concatenatedKeysOffProject;

    const response = await fetchWorldTrafficPromise(keys, timeSliceFrom);

    if (response?.status !== 200) return [];

    return response?.data.data;
  };

  const { timeSlices, urls } = await getTranslatedTraffic();
  const worldTraffic = await getWorldTraffic();

  data.timeSlices = timeSlices;
  data.urls = urls;
  data.timeSliceFrom = timeSliceFrom;
  data.worldTraffic = worldTraffic;

  return data;
}
