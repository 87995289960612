import {
  billingLoader,
  selectPlanLoader,
  usageLoader,
  accountMTUsageLoader,
} from 'store';
import {
  BillingInformation,
  CancelPlan,
  ErrorBoundary,
  MyProfile,
  SelectPlan,
  WordsServedUsageDetails,
  AccountMTUsage,
  editProfileAction,
} from 'ui';
import {
  dashboardRC,
  GLGO_PROJECT_TYPE,
  GLWEB_PROJECT_TYPE,
  OLJS_PROJECT_TYPE,
} from 'helpers';
import { RestrictedRoute } from 'pages';
import {
  BILLING_PAGE_USERTYPES,
  MTUSAGE_PAGE_USERTYPES,
  WORDSSERVED_PAGE_USERTYPES,
} from 'glweb/src/constants';

// type RouteObjectWithRequiredPath = Omit<RouteObject, 'path'> & { path: string };

export const routes = [
  {
    path: dashboardRC.PAGE_MY_PROFILE,
    element: <MyProfile />,
    errorElement: <ErrorBoundary />,
    action: editProfileAction,
  },
  {
    path: dashboardRC.PAGE_WORDS_SERVED_USAGE_DETAILS,
    id: 'usage',
    element: (
      <RestrictedRoute
        allowedRoles={WORDSSERVED_PAGE_USERTYPES}
        allowedProjectTypes={new Set([GLGO_PROJECT_TYPE])}
      >
        <WordsServedUsageDetails />
      </RestrictedRoute>
    ),
    loader: usageLoader,
    errorElement: <ErrorBoundary />,
  },
  {
    path: dashboardRC.PAGE_MT_USAGE_DETAILS,
    id: 'mt-usage',
    element: (
      <RestrictedRoute
        allowedRoles={MTUSAGE_PAGE_USERTYPES}
        allowedProjectTypes={new Set([GLWEB_PROJECT_TYPE, OLJS_PROJECT_TYPE])}
      >
        <AccountMTUsage />
      </RestrictedRoute>
    ),
    loader: accountMTUsageLoader,
    errorElement: <ErrorBoundary />,
  },
  {
    path: dashboardRC.PAGE_BILLING_INFORMATION,
    id: 'billing',
    errorElement: <ErrorBoundary />,
    loader: billingLoader,
    children: [
      {
        index: true,
        element: (
          <RestrictedRoute allowedRoles={BILLING_PAGE_USERTYPES}>
            <BillingInformation />
          </RestrictedRoute>
        ),
        errorElement: <ErrorBoundary />,
      },
      {
        path: dashboardRC.PAGE_MANAGE_SUBSCRIPTION,
        children: [
          {
            index: true,
            element: (
              <RestrictedRoute allowedRoles={BILLING_PAGE_USERTYPES}>
                <SelectPlan />
              </RestrictedRoute>
            ),
            loader: selectPlanLoader,
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'cancel',
            element: (
              <RestrictedRoute allowedRoles={BILLING_PAGE_USERTYPES}>
                <CancelPlan />
              </RestrictedRoute>
            ),
            errorElement: <ErrorBoundary />,
          },
        ],
      },
    ],
  },
];
