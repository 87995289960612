import FileSaver from 'file-saver';

type Matches = {
  groups: Record<string, string>;
};
export const fileSaver = (
  fileName: string,
  headers: Record<string, string>,
  data: Blob
) => {
  let filename = fileName;
  let mimeType = 'application/zip';
  if (
    headers['content-disposition'] &&
    headers['content-disposition'].includes('filename')
  ) {
    const matches =
      headers['content-disposition'].match(
        /filename\*?=['"]?(?:UTF-\d['"]*)?(?<file>[^;\r\n"']*)['"]?;?/
      ) || [];
    const { groups: { file = '' } = {} } = matches as Matches;
    if (file) {
      filename = file;
    }
  }
  if (headers['content-type']) {
    mimeType = headers['content-type'];
  }
  const blob = new Blob([data], { type: mimeType });
  FileSaver.saveAs(blob, filename);
};

export default fileSaver;
