import { create } from 'zustand';

export type AuthStore = {
  token: string;
  setToken: (data: string) => void;
};

export const useAuthStore = create<AuthStore>()((set) => ({
  token: '',
  setToken: (newToken) => set(() => ({ token: newToken })),
}));
