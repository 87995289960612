import { AccountMTUsageResponse } from 'store';
import React, { useState } from 'react';
import { Flex, Group, Radio, Stack, useMantineTheme } from '@mantine/core';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip as ReTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import moment from 'moment';
import CustomDot from '../../recharts/CustomDot';
import CustomGraphTooltip from './CustomGraphTooltip';
import { compactFormatter } from 'helpers';
import { AxisDomain } from 'recharts/types/util/types';
import { NoDataFoundPlaceHolder } from '../../../Generic';
import { calculateMaxYAxis, formatChartDateLabel } from './utils';

const AccountMTUsageChart = ({
  mtUsageData,
}: {
  mtUsageData: AccountMTUsageResponse;
}) => {
  const [isCumulative, setIsCumulative] = useState(true);
  const hasData = mtUsageData.length > 0;

  const blue = useMantineTheme().colors.blue[7];

  const timeslice = mtUsageData[0]?.timeslice ?? '';
  const format = (() => {
    if (timeslice.split(' ').length === 2) {
      return 'Hourly';
    }
    if (timeslice.split('-').length === 2) {
      return 'Monthly';
    }
    return 'Daily';
  })();

  const normalizedData = mtUsageData.map((d) => ({
    timeslice: d.timeslice,
    num_words_engine: Number(d.num_words_engine ?? 0),
  }));

  const accumulatedData = mtUsageData.reduce(
    (acc, data, index) => [
      ...acc,
      {
        timeslice: data.timeslice,
        num_words_engine:
          (acc[index - 1]?.num_words_engine ?? 0) +
          Number(data?.num_words_engine ?? 0),
      } as {
        timeslice: string;
        num_words_engine: number;
      },
    ],
    [] as {
      timeslice: string;
      num_words_engine: number;
    }[]
  );

  const { yAxisMaxCumulative, yAxisMaxDaily } = calculateMaxYAxis(
    accumulatedData,
    mtUsageData
  );

  const date = new Date();
  const todaysDate = moment(date).format('YYYY-MM-DD') || '';

  const yAxis = (
    <YAxis
      stroke="black"
      axisLine={true}
      tickLine={false}
      tickFormatter={compactFormatter}
      domain={
        hasData
          ? [0, isCumulative ? yAxisMaxCumulative : yAxisMaxDaily]
          : ('auto' as unknown as AxisDomain)
      }
    />
  );

  const xAxis = (
    <XAxis
      dataKey="timeslice"
      stroke="black"
      tickFormatter={formatChartDateLabel}
      tickMargin={10}
    />
  );

  const colors = (
    <defs>
      <linearGradient id="wordsEngine" x1="0" y1="0" x2="0" y2="1">
        <stop offset="5%" stopColor={blue} stopOpacity={0.5} />
        <stop offset="95%" stopColor={blue} stopOpacity={0} />
      </linearGradient>
    </defs>
  );

  const Radios = () => (
    <Flex justify="space-between">
      <Radio.Group value={isCumulative.toString()}>
        <Group>
          <Radio
            onClick={() => setIsCumulative(true)}
            value="true"
            label="Cumulative"
          />
          <Radio
            onClick={() => setIsCumulative(false)}
            value="false"
            label={format}
          />
        </Group>
      </Radio.Group>
    </Flex>
  );

  if (!hasData) {
    return (
      <Stack p={30}>
        <Radios />
        <Flex w="100%" h={500} align="center" justify="center">
          <NoDataFoundPlaceHolder height={240} />
        </Flex>
      </Stack>
    );
  }

  return (
    <Stack p={30}>
      <Radios />
      {isCumulative ? (
        <ResponsiveContainer height={500} width="100%">
          <AreaChart
            margin={{ top: 50, right: 5, bottom: 0 }}
            data={accumulatedData}
          >
            {colors}
            {yAxis}
            {xAxis}
            <CartesianGrid vertical={false} />

            <Area
              dataKey="num_words_engine"
              fill="url(#wordsEngine)"
              stroke={blue}
              strokeWidth={4}
              dot={<CustomDot date={todaysDate} />}
              activeDot={{ r: 5, fill: blue, strokeWidth: 0 }}
              name="New Words"
            />

            <ReTooltip content={<CustomGraphTooltip />} cursor={false} />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <></>
      )}
      {!isCumulative ? (
        <ResponsiveContainer height={500} width="100%">
          <BarChart
            margin={{ top: 50, right: 5, left: 0, bottom: 0 }}
            data={normalizedData}
          >
            <ReTooltip content={<CustomGraphTooltip />} cursor={false} />
            <CartesianGrid vertical={false} />

            {colors}
            {yAxis}
            {xAxis}
            <Bar
              dataKey="num_words_engine"
              fill="url(#wordsEngine)"
              stroke={blue}
              strokeWidth={1}
              name="New Words"
              activeBar={<Rectangle fill={blue} stroke={blue} opacity={0.7} />}
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <></>
      )}
    </Stack>
  );
};
export default AccountMTUsageChart;
