import axios from 'axios';
import getAxiosUrlAndConfig from './getAxiosUrlAndConfig';
import { ResponseType } from './Fetcher';

/**
 *
 * @param endpoint
 * @param params
 * @param responseType
 * @param signal
 * @return Promise<AxiosResponse<any>>
 */
export const FetcherPromise = (
  endpoint: string,
  params: object = {},
  responseType?: ResponseType,
  signal?: AbortSignal
) => {
  const { url, config } = getAxiosUrlAndConfig(
    endpoint,
    params,
    signal,
    responseType
  );

  return axios.get(url, config).catch((error) => {
    console.error('AXIOS_ERROR:', error);
    throw error;
  });
};

export default FetcherPromise;
