import { useMantineColorScheme, useMantineTheme } from '@mantine/core';

export function useNoTxColors() {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const colors = theme.colors;
  const { blue } = colors;
  //             0         1        2       3       4         5        6        7        8        9
  // blue:   [#F5FAFF, #E9F1FD, #D0DBF4, #CBDDF9, #A6D4FF, #90A0D3, #5672BC, #0071DB, #2C49AC, #071D49]
  // gray:   [#FAFBFF, #EFF4FB, #E4E7FA, #E6E6E8, #D6D9EC, #B9C8E3, #A0A7D6, #8F9DBA, #637392, #252540]

  type NoTxColors = typeof lightTheme;

  const lightTheme = {
    icons: {
      sorted: blue[7],
      unsorted: blue[9],
    },
    table: {
      font: { selected: blue[8], normal: blue[9] },
      background: { normal: theme.white, selected: blue[1] },
    },
  } as const;

  const darkTheme: NoTxColors = {
    ...lightTheme,
  } as const;

  return colorScheme === 'dark' ? darkTheme : lightTheme;
}

export type NoTxColors = ReturnType<typeof useNoTxColors>;
