import React from 'react';
import { Box, Flex } from '@mantine/core';
import QuickAction from './QuickAction';
import { useEditorColors } from 'helpers';

export type Action = {
  icon: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  active?: boolean;
  label?: React.ReactNode;
};

type BarProps = {
  actions: Action[];
};

export const ActionBar = ({ actions }: BarProps) => {
  const colors = useEditorColors().jliffActions;

  return (
    <Flex columnGap={2} align="center">
      {actions.map((action, index) => {
        const {
          icon = <></>,
          label = '',
          active = false,
          disabled = false,
          onClick = () => undefined,
        } = action;

        return (
          <Box key={index}>
            <QuickAction
              height={30}
              width={30}
              icon={
                <Flex p={0} m={0} c="blue.8" align="center" justify="center">
                  {icon}
                </Flex>
              }
              backgroundColor={colors.background}
              isActive={active}
              isDisabled={disabled}
              onClick={() => onClick()}
              label={label as React.ReactNode}
            />
          </Box>
        );
      })}
    </Flex>
  );
};
