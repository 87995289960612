/* eslint-disable react-refresh/only-export-components */
import { createContext, useContext, useState } from 'react';
import {
  BOTTOM_LEFT_POSITION,
  DARK_THEME,
  LanguageSelectorPosition,
  LanguageSelectorTheme,
} from './LanguageSelectorAppearances';
import { NOOP } from 'helpers';

type Context = {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  isNextButtonDisabled: boolean;
  setIsNextButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  projectDomain: string;
  setProjectDomain: React.Dispatch<React.SetStateAction<string>>;
  selectedTheme: LanguageSelectorTheme;
  setSelectedTheme: React.Dispatch<React.SetStateAction<LanguageSelectorTheme>>;
  selectedPosition: LanguageSelectorPosition;
  setSelectedPosition: React.Dispatch<
    React.SetStateAction<LanguageSelectorPosition>
  >;
  selectedSourceLangCode: string | null;
  setSelectedSourceLangCode: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  selectedTargetLangCodes: string[];
  setSelectedTargetLangCodes: React.Dispatch<React.SetStateAction<string[]>>;
  isCreatingProject: boolean;
  setIsCreatingProject: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultContext = {
  activeStep: 0,
  setActiveStep: NOOP,
  isNextButtonDisabled: true,
  setIsNextButtonDisabled: NOOP,
  projectDomain: '',
  setProjectDomain: NOOP,
  selectedTheme: DARK_THEME,
  setSelectedTheme: NOOP,
  selectedPosition: BOTTOM_LEFT_POSITION,
  setSelectedPosition: NOOP,
  selectedSourceLangCode: '',
  setSelectedSourceLangCode: NOOP,
  selectedTargetLangCodes: [],
  setSelectedTargetLangCodes: NOOP,
  isCreatingProject: false,
  setIsCreatingProject: NOOP,
};

const SetupStepsContext = createContext<Context>(defaultContext);

type Props = {
  children?: React.ReactNode;
};

function ProjectSetupProvider({ children }: Props) {
  const [activeStep, setActiveStep] = useState(0);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [projectDomain, setProjectDomain] = useState('');
  const [selectedTheme, setSelectedTheme] =
    useState<LanguageSelectorTheme>(DARK_THEME);
  const [selectedPosition, setSelectedPosition] =
    useState<LanguageSelectorPosition>(BOTTOM_LEFT_POSITION);
  const [selectedSourceLangCode, setSelectedSourceLangCode] = useState<
    string | null
  >('EN-US');
  const [selectedTargetLangCodes, setSelectedTargetLangCodes] = useState<
    string[]
  >([]);
  const [isCreatingProject, setIsCreatingProject] = useState(false);

  return (
    <SetupStepsContext.Provider
      value={{
        activeStep,
        setActiveStep,
        isNextButtonDisabled,
        setIsNextButtonDisabled,
        projectDomain,
        setProjectDomain,
        selectedTheme,
        setSelectedTheme,
        selectedPosition,
        setSelectedPosition,
        selectedSourceLangCode,
        setSelectedSourceLangCode,
        selectedTargetLangCodes,
        setSelectedTargetLangCodes,
        isCreatingProject,
        setIsCreatingProject,
      }}
    >
      {children}
    </SetupStepsContext.Provider>
  );
}

function useSetupStepsContext() {
  const context = useContext(SetupStepsContext);
  if (!context) throw new Error('Not inside the Provider');
  return context;
}
export { useSetupStepsContext, ProjectSetupProvider };
