import { Contact, useHubSpotStore } from 'store';
import { AxiosResponse } from 'axios';

export const createHubSpotContact = async (
  data: Contact
): Promise<Record<string, string | number>> => {
  const { createContact } = useHubSpotStore.getState();
  const { data: { id: createdId = '', status = '', category = '' } = {} } =
    await createContact(data);
  return { createdId, status, category };
};

export const updateHubSpotContact = async (
  id: string,
  data: Contact
): Promise<AxiosResponse<Contact> | Record<string, never>> => {
  const { updateContact } = useHubSpotStore.getState();
  return await updateContact(id, data);
};

export const getHubSpotContactId = async (email: string): Promise<string> => {
  const { getContact } = useHubSpotStore.getState();
  const { data: { id = '' } = {} } = await getContact(email);
  return id;
};

export const handleHubSpotContact = async (
  data: Contact,
  email = ''
): Promise<AxiosResponse<Contact> | Record<string, unknown>> => {
  const { contactId = '' } = useHubSpotStore.getState();
  try {
    if (!contactId && email) {
      const id = await getHubSpotContactId(email);
      if (id) {
        return await updateHubSpotContact(id, data);
      } else {
        return await createHubSpotContact(data);
      }
    }
    if (contactId) {
      return await updateHubSpotContact(contactId, data);
    }
    return Promise.reject({});
  } catch (error) {
    console.error('ERROR:', error);
    return Promise.reject({});
  }
};

export default handleHubSpotContact;
