import { useContext } from 'react';
import { Flex, Text } from '@mantine/core';
import { SegmentEditorContext } from '../../context/SegmentEditorProvider';
import {
  Filters,
  useDashboardActions,
  useExternalFilter,
  useFilters,
  useFiltersBuffer,
} from '../../store/DashboardStore';
import {
  capitilize,
  getActiveFilters,
} from '../../functions/segmentsFunctions';
import classes from './ActiveFilters.module.css';

export const ActiveFilters = () => {
  const { contextAPI = {} } = useContext(SegmentEditorContext);

  const { removeFilterValue, setFiltersBuffer, handleFilterSelection } =
    useDashboardActions();
  const { onResetFilter } = contextAPI;
  const filtersBuffer = useFiltersBuffer();

  const filterBy = useFilters();
  const externalFilter = useExternalFilter();
  const activeFilters = getActiveFilters(filterBy, externalFilter.length > 0);

  function handleRemoveFilter(filter: string, value: string) {
    removeFilterValue(filter as Filters, value);
    if (filter === 'block') onResetFilter && onResetFilter();
    else if (filter === 'date') {
      setFiltersBuffer({ ...filtersBuffer, date: [null, null] });
    } else handleFilterSelection(filter as Filters, value);
  }

  return (
    <Flex w="100%" py={5} gap={5} direction="row" wrap="wrap">
      {activeFilters.map(([filter, value]) => {
        const label = capitilize(value);
        return (
          <Flex className={classes.activeFilter} key={`${filter}-${value}`}>
            <Text
              fz={12}
              fw={500}
              c="blue.7"
              p="0 3px 2px 0"
              style={{ cursor: 'pointer' }}
              onClick={() => handleRemoveFilter(filter, value)}
            >
              x
            </Text>
            <Text fz={12} fw={500} c="blue.7">{label}</Text>
          </Flex>
        );
      })}
    </Flex>
  );
};
