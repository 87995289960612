import { User as AuthUser } from 'oidc-client-ts';
const { saveXapisUser, saveProjectKey, getLastDTProjectKey } =
  window?.UserManager || {};
// send IPC message to window with user data
export const saveDesktopUser = (user: User) => {
  if (typeof saveXapisUser === 'function') {
    saveXapisUser(user);
  }
};
export const saveDesktopProjectKey = (pKey: string) => {
  if (typeof saveProjectKey === 'function') {
    saveProjectKey(pKey);
  }
};
export const getLastProjectKey = async (): Promise<string> => {
  if (typeof saveProjectKey === 'function') {
    return getLastDTProjectKey();
  }
  return '';
};

export const saveAuthUserInMain = ({
  id,
  user,
}: {
  id: string;
  user: AuthUser | null;
}) => {
  const { saveAuthUser } = window.UserManager || {};
  if (typeof saveAuthUser === 'function') {
    return saveAuthUser({ id, user });
  }
};

export const getAuthUserFromMain = async (
  id: string
): Promise<AuthUser | null> => {
  const { getAuthUser } = window.UserManager || {};
  if (typeof getAuthUser === 'function') {
    return getAuthUser(id);
  }
  return null;
};
