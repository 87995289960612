import { forwardRef } from 'react';
import { PiWarningCircleFill } from 'react-icons/pi';
import { Tooltip } from '@mantine/core';
import { DOMAIN, FOLDER } from '../utils';

type WarningProps = {
  method: typeof FOLDER | typeof DOMAIN;
  color: string;
  hasMultipleValues: boolean;
};

const EmptyValueWarning = forwardRef<HTMLDivElement, WarningProps>(
  ({ method, color, hasMultipleValues }, ref) => (
    <Tooltip
      maw={250}
      label={`Warning: If this field is empty or not a correct ${method} then the language will not appear in the language selector.`}
      offset={{ mainAxis: 20, crossAxis: -128 }}
    >
      <div ref={ref}>
        <PiWarningCircleFill
          color={color}
          cursor="pointer"
          size="16"
          style={{
            position: 'absolute',
            top: hasMultipleValues ? 12.5 : 32,
            left: -22,
          }}
        />
      </div>
    </Tooltip>
  )
);

export default EmptyValueWarning;
