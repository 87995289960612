import { useLoaderData } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';
import { DashboardLoaderResponse, MTUsageResponse, queryMTUsage } from 'store';
import { Box, Card, Group } from '@mantine/core';
import MTUsageHeader from './MTUsageHeader';
import MTUsageChart from './MTUsageChart';
import React, { ReactNode } from 'react';
import moment from 'moment/moment';

export type MTUsageType = {
  timeslice: string;
  count: number;
};

const MTUsage = ({
  thresholdValue,
  header,
}: {
  header: ReactNode;
  thresholdValue: number;
}) => {
  const { concatenatedKey } = useLoaderData() as DashboardLoaderResponse;
  const { data }: { data: MTUsageResponse } = useSuspenseQuery(
    queryMTUsage(concatenatedKey)
  );

  const mTUsageData = data.map(
    ({ num_words_mt, timeslice }) =>
      ({ count: Number(num_words_mt), timeslice }) as MTUsageType
  );

  const date = new Date();
  const todaysDate = moment(date).format('YYYY-MM-DD') || '';

  const accumulatedMTUsageData = mTUsageData.reduce(
    (acc, { count, timeslice }, index) => {
      acc[index] = {
        timeslice,
        count: (acc[index - 1]?.count ?? 0) + count,
      } as MTUsageType;
      return acc;
    },
    [] as Array<MTUsageType>
  );

  return (
    <Box>
      <Group>{header}</Group>
      <Card
        h={{ base: 830, xs: 575 }}
        p={30}
        data-testid="pw-mt-usage-languages-container"
      >
        <>
          <MTUsageHeader
            todaysDate={todaysDate}
            mTUsageData={mTUsageData}
            accumulatedMTUsageData={accumulatedMTUsageData}
          />
          <MTUsageChart
            todaysDate={todaysDate}
            accumulatedMTUsageData={accumulatedMTUsageData}
            thresholdValue={thresholdValue}
          />
        </>
      </Card>
    </Box>
  );
};

export default MTUsage;
