export const flags = {
  'AA-DJ': '🇪🇹 ', //Afar (Djibouti)
  'AA-ER': '🇪🇹 ', //Afar (Eritrea)
  'AA-ET': '🇪🇹 ', //Afar (Ethiopia)
  'AB-XM': '🇦🇲 ', //Abkhazian (Universal)
  'AF-NA': '🇿🇦 ', //Afrikaans (Namibia)
  'AF-ZA': '🇿🇦 ', //Afrikaans (South Africa)
  'AK-XM': '🇬🇭 ', //Akan (Universal)
  'AM-ET': '🇪🇹 ', //Amharic (Ethiopia)
  'AN-ES': '🇪🇸 ', //Aragonese (Spain)
  'AR-AE': '🇶🇦 ', //Arabic (United Arab Emirates)
  'AR-BH': '🇶🇦 ', //Arabic (Bahrain)
  'AR-DZ': '🇶🇦 ', //Arabic (Algeria)
  'AR-EG': '🇶🇦 ', //Arabic (Egypt)
  'AR-IL': '🇶🇦 ', //Arabic (Israel)
  'AR-IQ': '🇶🇦 ', //Arabic (Iraq)
  'AR-JO': '🇶🇦 ', //Arabic (Jordan)
  'AR-KW': '🇶🇦 ', //Arabic (Kuwait)
  'AR-LB': '🇶🇦 ', //Arabic (Lebanon)
  'AR-LY': '🇶🇦 ', //Arabic (Libya)
  'AR-MA': '🇶🇦 ', //Arabic (Morocco)
  'AR-OM': '🇶🇦 ', //Arabic (Oman)
  'AR-QA': '🇶🇦 ', //Arabic (Qatar)
  'AR-SA': '🇸🇦 ', //Arabic (Saudi Arabia)
  'AR-SD': '🇸🇦 ', //Arabic (Sudan)
  'AR-SY': '🇸🇦 ', //Arabic (Syria)
  'AR-TN': '🇸🇦 ', //Arabic (Tunisia)
  'AR-XM': '🇸🇦 ', //Arabic (Universal)
  'AR-XY': '🇸🇦 ', //Arabic (Modern)
  'AR-YE': '🇸🇦 ', //Arabic (Yemen)
  'AS-IN': '🇮🇳 ', //Assamese (India)
  'AV-XM': '🇲🇩 ', //Avaric (Universal)
  'AY-BO': '🇲🇲 ', //Aymara (Bolivia)
  'AY-PE': '🇲🇲 ', //Aymara (Peru)
  'AZ-CYRL-AZ': '🇦🇿 ', //Azerbaijani-Cyrillic (Azerbaijan)
  'AZ-LATN-AZ': '🇦🇿 ', //Azerbaijani-Latin (Azerbaijan)
  'BA-RU': '🇷🇺 ', //Bashkir (Russia)
  'BE-BY': '🇧🇾 ', //Belarusian (Belarus)
  'BG-BG': '🇧🇬 ', //Bulgarian (Bulgaria)
  'BH-XM': '🇮🇳 ', //Bihari languages (Universal)
  'BI-XM': '🇻🇺 ', //Bislama (Universal)
  'BM-ML': '🇲🇱 ', //Bambara (Mali)
  'BN-BD': '🇧🇩 ', //Bengali (Bangladesh)
  'BN-IN': '🇮🇳 ', //Bengali (India)
  'BO-XM': '🇨🇳 ', //Tibetan (Universal)
  'BR-FR': '🇫🇷 ', //Breton (France)
  'BS-BA': '🇧🇦 ', //Bosnian (Bosnia and Herzegovina)
  'CA-ES': '🇲🇩 ', //Catalan (Spain)
  'CE-XM': '🇷🇺 ', //Chechen (Universal)
  'CEB-PH': '🇵🇭 ', //Cebuano (Philippines)
  'CEB-XDA': '🇵🇭 ', //Cebuano (Davao)
  'CH-XM': '🇲🇵 ', //Chamorro (Universal)
  'CO-XM': '🇫🇷 ', //Corsican (Universal)
  'CR-XM': '🇨🇦 ', //Cree (Universal)
  'CS-CZ': '🇨🇿 ', //Czech (Czech Republic)
  'CV-RU': '🇷🇺 ', //Chuvash (Russia)
  'CY-GB': '🇬🇧 ', //Welsh (United Kingdom)
  'DA-DK': '🇩🇰 ', //Danish (Denmark)
  'DE-AT': '🇩🇪 ', //German (Austria)
  'DE-BE': '🇩🇪 ', //German (Belgium)
  'DE-CH': '🇩🇪 ', //German (Switzerland)
  'DE-DE': '🇩🇪 ', //German (Germany)
  'DE-GR': '🇩🇪 ', //German (Greece)
  'DE-LI': '🇩🇪 ', //German (Liechtenstein)
  'DE-LU': '🇩🇪 ', //German (Luxembourg)
  'DV-MV': '🇲🇻 ', //Dhivehi (Maldives)
  'DZ-BT': '🇧🇹 ', //Dzongkha (Bhutan)
  'EE-GH': '🇱🇸 ', //Ewe (Ghana)
  'EL-CY': '🇬🇷 ', //Greek (Cyprus)
  'EL-GR': '🇬🇷 ', //Greek (Greece)
  'EN-029': '🇺🇸 ', //English (Caribbean)
  'EN-AE': '🇺🇸 ', //English (United Arab Emirates)
  'EN-AU': '🇦🇺 ', //English (Australia)
  'EN-BZ': '🇺🇸 ', //English (Belize)
  'EN-CA': '🇨🇦 ', //English (Canada)
  'EN-CY': '🇺🇸 ', //English (Cyprus)
  'EN-EG': '🇺🇸 ', //English (Egypt)
  'EN-GB': '🇬🇧 ', //English (United Kingdom)
  'EN-GH': '🇺🇸 ', //English (Ghana)
  'EN-ID': '🇺🇸 ', //English (Indonesia)
  'EN-IE': '🇬🇧 ', //English (Ireland)
  'EN-IN': '🇺🇸 ', //English (India)
  'EN-JM': '🇺🇸 ', //English (Jamaica)
  'EN-MT': '🇺🇸 ', //English (Malta)
  'EN-MY': '🇺🇸 ', //English (Malaysia)
  'EN-NG': '🇺🇸 ', //English (Nigeria)
  'EN-NZ': '🇦🇺 ', //English (New Zealand)
  'EN-PH': '🇺🇸 ', //English (Philippines)
  'EN-SG': '🇺🇸 ', //English (Singapore)
  'EN-TT': '🇺🇸 ', //English (Trinidad and Tobago)
  'EN-US': '🇺🇸 ', //English (United States)
  'EN-XM': '🇺🇸 ', //English (Universal)
  'EN-ZA': '🇺🇸 ', //English (South Africa)
  'EN-ZW': '🇺🇸 ', //English (Zimbabwe)
  'EO-XM': '🇵🇱 ', //Esperanto (Universal)
  'ES-AR': '🇪🇸 ', //Spanish (Argentina)
  'ES-BO': '🇪🇸 ', //Spanish (Bolivia)
  'ES-BZ': '🇪🇸 ', //Spanish (Belize)
  'ES-CL': '🇪🇸 ', //Spanish (Chile)
  'ES-CO': '🇪🇸 ', //Spanish (Colombia)
  'ES-CR': '🇪🇸 ', //Spanish (Costa Rica)
  'ES-CU': '🇪🇸 ', //Spanish (Cuba)
  'ES-DO': '🇪🇸 ', //Spanish (Dominican Republic)
  'ES-EC': '🇪🇸 ', //Spanish (Ecuador)
  'ES-ES': '🇪🇸 ', //Spanish (Spain)
  'ES-GQ': '🇪🇸 ', //Spanish (Equatorial Guinea)
  'ES-GT': '🇪🇸 ', //Spanish (Guatemala)
  'ES-HN': '🇪🇸 ', //Spanish (Honduras)
  'ES-LA': '🇪🇸 ', //Spanish (Latin America)
  'ES-MX': '🇲🇽 ', //Spanish (Mexico)
  'ES-NI': '🇪🇸 ', //Spanish (Nicaragua)
  'ES-PA': '🇪🇸 ', //Spanish (Panama)
  'ES-PE': '🇪🇸 ', //Spanish (Peru)
  'ES-PR': '🇪🇸 ', //Spanish (Puerto Rico)
  'ES-PY': '🇪🇸 ', //Spanish (Paraguay)
  'ES-SV': '🇪🇸 ', //Spanish (El Salvador)
  'ES-US': '🇪🇸 ', //Spanish (United States)
  'ES-UY': '🇪🇸 ', //Spanish (Uruguay)
  'ES-VE': '🇪🇸 ', //Spanish (Venezuela)
  'ES-XM': '🇪🇸 ', //Spanish (Universal)
  'ET-EE': '🇪🇪 ', //Estonian (Estonia)
  'EU-ES': '🇪🇸 ', //Basque (Spain)
  'FA-IR': '🇮🇷 ', //Persian (Iran)
  'FF-02': '🇳🇪 ', //Fulah (Africa)
  'FI-FI': '🇫🇮 ', //Finnish (Finland)
  'FJ-FJ': '🇫🇯 ', //Fijian (Fiji)
  'FO-FO': '🇩🇰 ', //Faroese (Faroe Islands)
  'FR-BE': '🇫🇷 ', //French (Belgium)
  'FR-BF': '🇫🇷 ', //French (Burkina Faso)
  'FR-BJ': '🇫🇷 ', //French (Benin)
  'FR-CA': '🇨🇦 ', //French (Canada)
  'FR-CD': '🇫🇷 ', //French (Democratic Republic of the Congo)
  'FR-CG': '🇫🇷 ', //French (Congo)
  'FR-CH': '🇫🇷 ', //French (Switzerland)
  'FR-CI': '🇫🇷 ', //French (Ivory Coast)
  'FR-CM': '🇫🇷 ', //French (Cameroon)
  'FR-DZ': '🇫🇷 ', //French (Algeria)
  'FR-FR': '🇫🇷 ', //French (France)
  'FR-GA': '🇫🇷 ', //French (Gabon)
  'FR-GN': '🇫🇷 ', //French (Guinea)
  'FR-GP': '🇫🇷 ', //French (Guadeloupe)
  'FR-GY': '🇫🇷 ', //French (Guyana)
  'FR-HT': '🇫🇷 ', //French (Haiti)
  'FR-LU': '🇫🇷 ', //French (Luxembourg)
  'FR-MA': '🇫🇷 ', //French (Morocco)
  'FR-MC': '🇫🇷 ', //French (Monaco)
  'FR-MG': '🇫🇷 ', //French (Madagascar)
  'FR-ML': '🇫🇷 ', //French (Mali)
  'FR-MQ': '🇫🇷 ', //French (Martinique)
  'FR-NC': '🇫🇷 ', //French (New Caledonia)
  'FR-PF': '🇫🇷 ', //French (French Polynesia)
  'FR-RE': '🇫🇷 ', //French (Réunion)
  'FR-SC': '🇫🇷 ', //French (Seychelles)
  'FR-SN': '🇫🇷 ', //French (Senegal)
  'FR-TN': '🇫🇷 ', //French (Tunisia)
  'FR-VC': '🇫🇷 ', //French (Saint Vincent and the Grenadines)
  'FY-NL': '🇳🇱 ', //Frisian (Netherlands)
  'GA-IE': '🇮🇪 ', //Irish (Ireland)
  'GD-GB': '🇬🇧 ', //Scottish Gaelic (United Kingdom)
  'GL-ES': '🇪🇸 ', //Galician (Spain)
  'GN-005': '🇵🇪 ', //Guaraní (South America)
  'GU-IN': '🇮🇳 ', //Gujarati (India)
  'GV-XM': '🇲🇲 ', //Manx (Universal)
  'HA-02': '🇧🇫 ', //Hausa (Africa)
  'HE-IL': '🇮🇱 ', //Hebrew (Israel)
  'HI-IN': '🇮🇳 ', //Hindi (India)
  'HIL-PH': '🇵🇭 ', //Hiligaynon/Ilonggo (Philippines)
  'HMN-XM': '🇲🇲 ', //Hmong (Universal)
  'HO-PG': '🇵🇬 ', //Hiri Motu (Papua New Guinea)
  'HR-HR': '🇭🇷 ', //Croatian (Croatia)
  'HT-HT': '🇭🇹 ', //Haitian (Haiti)
  'HU-HU': '🇭🇺 ', //Hungarian (Hungary)
  'HY-AM': '🇦🇲 ', //Armenian Eastern (Armenia)
  'HYW-AM': '🇹🇷 ', //Armenian Western (Armenia)
  'HZ-02': '🇲🇩 ', //Herero (Africa)
  'IA-XM': '🇵🇱 ', //Interlingua (Universal)
  'ID-ID': '🇮🇩 ', //Indonesian (Indonesia)
  'IE-XM': '🇵🇱 ', //Interlingue (Universal)
  'IG-02': '🇳🇬 ', //Igbo (Africa)
  'II-CN': '🇨🇳 ', //Nuosu/Sichuan Yi (China)
  'IK-XM': '🇺🇸 ', //Inupiaq (Universal)
  'ILO-PH': '🇵🇭 ', //Ilocano (Philippines)
  'IO-XM': '🇵🇱 ', //Ido (Universal)
  'IS-IS': '🇮🇸 ', //Icelandic (Iceland)
  'IT-CH': '🇮🇹 ', //Italian (Switzerland)
  'IT-IT': '🇮🇹 ', //Italian (Italy)
  'IU-XM': '🇨🇦 ', //Inuktitut (Universal)
  'JA-JP': '🇯🇵 ', //Japanese (Japan)
  'JV-ID': '🇮🇩 ', //Javanese (Indonesia)
  'KA-GE': '🇬🇪 ', //Georgian (Georgia)
  'KAR-MM': '🇲🇲 ', //Karen (Myanmar)
  'KG-02': '🇨🇩 ', //Kongo (Africa)
  'KI-02': '🇰🇪 ', //Kikuyu (Africa)
  'KJ-02': '🇦🇴 ', //Kwanyama (Africa)
  'KK-KZ': '🇰🇿 ', //Kazakh (Kazakhstan)
  'KL-GL': '🇬🇸 ', //Kalaallisut (Greenland)
  'KM-KH': '🇰🇭 ', //Central Khmer (Cambodia)
  'KN-IN': '🇮🇳 ', //Kannada (India)
  'KO-KR': '🇰🇷 ', //Korean (Republic of Korea)
  'KOK-IN': '🇮🇳 ', //Konkani (India)
  'KR-02': '🇨🇭 ', //Kanuri (Africa)
  'KS-XM': '🇵🇰 ', //Kashmiri (Universal)
  'KU-TR': '🇮🇶 ', //Kurdish (Turkey)
  'KV-RU': '🇷🇺 ', //Komi (Russia)
  'KW-GB': '🇬🇧 ', //Cornish (United Kingdom)
  'KY-KG': '🇲🇳 ', //Kirghiz (Kyrgyzstan)
  'LA-XM': '', //Latin (Universal)
  'LB-LU': '🇱🇺 ', //Luxembourgish (Luxembourg)
  'LG-02': '🇺🇬 ', //Ganda (Africa)
  'LI-XM': '🇳🇱 ', //Limburgish (Universal)
  'LN-CD': '🇧🇬 ', //Lingala (Democratic Republic of the Congo)
  'LO-LO': '🇱🇦 ', //Lao (Laos)
  'LT-LT': '🇱🇹 ', //Lithuanian (Lithuania)
  'LU-CD': '🇨🇩 ', //Luba-Katanga (Democratic Republic of the Congo)
  'LUO-02': '🇺🇬 ', //Luo (Africa)
  'LV-LV': '🇱🇻 ', //Latvian (Latvia)
  'MG-MG': '🇲🇬 ', //Malagasy (Madagascar)
  'MH-MH': '🇲🇭 ', //Marshallese (Marshall Islands)
  'MI-NZ': '🇳🇿 ', //Maori (New Zealand)
  'MK-MK': '🇲🇰 ', //Macedonian (Macedonia)
  'ML-IN': '🇮🇳 ', //Malayalam (India)
  'MN-MN': '🇲🇳 ', //Mongolian (Mongolia)
  'MR-IN': '🇮🇳 ', //Marathi (India)
  'MS-BN': '🇲🇾 ', //Malay (Brunei Darussalam)
  'MS-MY': '🇲🇾 ', //Malay (Malaysia)
  'MS-SG': '🇲🇾 ', //Malay (Singapore)
  'MT-MT': '🇲🇹 ', //Maltese (Malta)
  'MY-MM': '🇲🇲 ', //Burmese (Myanmar)
  'NA-XM': '🇳🇷 ', //Nauru (Universal)
  'ND-ZA': '🇿🇼 ', //North Ndebele (South Africa)
  'NE-NP': '🇿🇼 ', //Nepali (Nepal)
  'NG-02': '🇿🇼 ', //Ndonga (Africa)
  'NL-BE': '🇧🇪 ', //Dutch (Belgium)
  'NL-NL': '🇳🇱 ', //Dutch (Netherlands)
  'NN-NO': '🇳🇴 ', //Norwegian Nynorsk (Norway)
  'NO-NO': '🇳🇴 ', //Norwegian Bokmål (Norway)
  'NR-ZA': '🇿🇼 ', //South Ndebele (South Africa)
  'NSO-ZA': '🇿🇦 ', //Northern Sotho (South Africa)
  'NUS-02': '🇸🇸 ', //Nuer (Africa)
  'NV-XM': '🇺🇸 ', //Navajo (Universal)
  'NY-MW': '🇿🇲 ', //Nyanja (Malawi)
  'OC-FR': '🇫🇷 ', //Occitan (France)
  'OJ-XM': '🇨🇦 ', //Ojibwa (Universal)
  'OM-ET': '🇪🇹 ', //Oromo (Ethiopia)
  'OR-IN': '🇮🇳 ', //Oriya (India)
  'OS-XM': '🇦🇲 ', //Ossetian (Universal)
  'PA-IN': '🇮🇳 ', //Punjabi-Gurmukhi (India)
  'PA-PK': '🇵🇰 ', //Punjabi-Shahmukhi (Pakistan)
  'PI-IN': '', //Pali (India)
  'PL-PL': '🇵🇱 ', //Polish (Poland)
  'PRS-AF': '🇦🇫 ', //Persian-Dari (Afghanistan)
  'PS-AF': '🇵🇰 ', //Pashto (Afghanistan)
  'PT-AO': '🇵🇹 ', //Portuguese (Angola)
  'PT-BR': '🇧🇷 ', //Portuguese (Brazil)
  'PT-CV': '🇵🇹 ', //Portuguese (Cabo Verde)
  'PT-GQ': '🇵🇹 ', //Portuguese (Equatorial Guinea)
  'PT-GW': '🇵🇹 ', //Portuguese (Guinea-Bissau)
  'PT-MO': '🇵🇹 ', //Portuguese (Macau)
  'PT-MZ': '🇵🇹 ', //Portuguese (Mozambique)
  'PT-PT': '🇵🇹 ', //Portuguese (Portugal)
  'PT-ST': '🇵🇹 ', //Portuguese (Sao Tome and Principe)
  'PT-TL': '🇵🇹 ', //Portuguese (Timor-Leste)
  'QU-BO': '🇧🇴 ', //Quechua (Bolivia)
  'QU-EC': '🇧🇴 ', //Quechua (Ecuador)
  'QUC-GT': '🇲🇽 ', //K'iche' (Guatemala)
  'RM-CH': '🇨🇭 ', //Romansh (Switzerland)
  'RN-BI': '🇲🇬 ', //Rundi (Burundi)
  'RO-MD': '🇷🇴 ', //Romanian (Moldova)
  'RO-RO': '🇷🇴 ', //Romanian (Romania)
  'RU-BY': '🇷🇺 ', //Russian (Belarus)
  'RU-DE': '🇷🇺 ', //Russian (Germany)
  'RU-EE': '🇷🇺 ', //Russian (Estonia)
  'RU-IL': '🇷🇺 ', //Russian (Israel)
  'RU-KZ': '🇷🇺 ', //Russian (Kazakhstan)
  'RU-LT': '🇷🇺 ', //Russian (Lithuania)
  'RU-LV': '🇷🇺 ', //Russian (Latvia)
  'RU-RU': '🇷🇺 ', //Russian (Russia)
  'RU-UA': '🇷🇺 ', //Russian (Ukraine)
  'RW-RW': '🇰🇲 ', //Kinyarwanda (Rwanda)
  'SA-XM': '', //Sanskrit (Universal)
  'SC-XM': '🇮🇹 ', //Sardinian (Universal)
  'SD-XM': '🇮🇳 ', //Sindhi (Universal)
  'SE-XM': '🇸🇪 ', //Northern Sami (Universal)
  'SG-02': '🇦🇱 ', //Sango (Africa)
  'SI-LK': '🇱🇰 ', //Sinhalese (Sri Lanka)
  'SK-SK': '🇸🇰 ', //Slovak (Slovakia)
  'SL-SI': '🇸🇮 ', //Slovenian (Slovenia)
  'SM-WS': '🇲🇭 ', //Samoan (Samoa)
  'SMI-XM': '🇸🇪 ', //Sami languages (Universal)
  'SN-02': '🇲🇿 ', //Shona (Africa)
  'SO-SO': '🇸🇴 ', //Somali (Somalia)
  'SQ-AL': '🇦🇱 ', //Albanian (Albania)
  'SR-CYRL-BA': '🇷🇸 ', //Serbian-Cyrillic (Bosnia and Herzegovina)
  'SR-CYRL-ME': '🇷🇸 ', //Serbian-Cyrillic (Montenegro)
  'SR-CYRL-RS': '🇷🇸 ', //Serbian-Cyrillic (Serbia)
  'SR-LATN-BA': '🇷🇸 ', //Serbian-Latin (Bosnia and Herzegovina)
  'SR-LATN-ME': '🇷🇸 ', //Serbian-Latin (Montenegro)
  'SR-LATN-RS': '🇷🇸 ', //Serbian-Latin (Serbia)
  'SS-ZA': '🇪🇸 ', //Swati (South Africa)
  'ST-ZA': '🇿🇦 ', //Southern Sotho (South Africa)
  'SU-SD': '🇮🇩 ', //Sundanese (Sudan)
  'SV-FI': '🇸🇪 ', //Swedish (Finland)
  'SV-SE': '🇸🇪 ', //Swedish (Sweden)
  'SW-KE': '🇹🇿 ', //Swahili (Kenya)
  'TA-IN': '🇱🇰 ', //Tamil (India)
  'TA-LK': '🇱🇰 ', //Tamil (Sri Lanka)
  'TA-MY': '🇱🇰 ', //Tamil (Malaysia)
  'TA-SG': '🇱🇰 ', //Tamil (Singapore)
  'TE-IN': '🇮🇳 ', //Telugu (India)
  'TG-TJ': '🇹🇯 ', //Tajik (Tajikistan)
  'TH-TH': '🇹🇭 ', //Thai (Thailand)
  'TI-ER': '🇸🇸 ', //Tigrinya (Eritrea)
  'TI-ET': '🇸🇸 ', //Tigrinya (Ethiopia)
  'TK-TM': '🇹🇲 ', //Turkmen (Turkmenistan)
  'TL-PH': '🇵🇭 ', //Tagalog (Philippines)
  'TN-ZA': '🇧🇼 ', //Tswana (South Africa)
  'TO-TO': '🇹🇴 ', //Tongan (Tonga)
  'TR-DE': '🇹🇷 ', //Turkish (Germany)
  'TR-TR': '🇹🇷 ', //Turkish (Turkey)
  'TS-ZA': '🇿🇦 ', //Tsonga (South Africa)
  'TT-XM': '🇷🇺 ', //Tatar (Universal)
  'TW-GH': '🇬🇭 ', //Twi (Ghana)
  'TY-XM': '🇵🇫 ', //Tahitian (Universal)
  'UG-CYRL-XM': '🇨🇳 ', //Uighur-Cyrillic (Universal)
  'UG-LATN-XM': '🇨🇳 ', //Uighur-Latin (Universal)
  'UK-UA': '🇺🇦 ', //Ukrainian (Ukraine)
  'UR-IN': '🇵🇰 ', //Urdu (India)
  'UR-PK': '🇵🇰 ', //Urdu (Pakistan)
  'UZ-CYRL-UZ': '🇺🇿 ', //Uzbek-Cyrillic (Uzbekistan)
  'UZ-LATN-UZ': '🇺🇿 ', //Uzbek-Latin (Uzbekistan)
  'VE-ZA': '🇿🇦 ', //Venda (South Africa)
  'VI-US': '🇻🇳 ', //Vietnamese (United States)
  'VI-VN': '🇻🇳 ', //Vietnamese (Vietnam)
  'VO-XM': '🇵🇱 ', //Volapük (Universal)
  'WA-BE': '🇧🇪 ', //Walloon (Belgium)
  'WO-02': '🇸🇳 ', //Wolof (Africa)
  'XA-XM': '', //Auto-Detect (Universal)
  'XGS-ES': '🇫🇷 ', //Gascon (Spain)
  'XH-ZA': '🇿🇦 ', //Xhosa (South Africa)
  XX: '', //Fake
  'YI-XM': '🇮🇱 ', //Yiddish (Universal)
  'YO-02': '🇧🇯 ', //Yoruba (Africa)
  YY: '', //Fåkë
  'ZA-CN': '🇨🇳 ', //Zhuang (China)
  'ZH-CN': '🇨🇳 ', //Chinese (China)
  'ZH-HK': '🇨🇳 ', //Chinese (Hong Kong)
  'ZH-MO': '🇨🇳 ', //Chinese (Macau)
  'ZH-MY': '🇨🇳 ', //Chinese (Malaysia)
  'ZH-SG': '🇨🇳 ', //Chinese (Singapore)
  'ZH-TW': '🇹🇼 ', //Chinese (Taiwan)
  'ZH-US': '🇨🇳 ', //Chinese (United States)
  'ZU-ZA': '🇿🇦 ', //Zulu (South Africa)
};
