export const MAC_OS = 'MacOS' as const;
export const WINDOWS_OS = 'Windows' as const;
export const LINUX_OS = 'Linux' as const;
export const ANDROID_OS = 'Android' as const;
export const IOS_OS = 'iOS' as const;
export type OperatingSystem =
  | typeof MAC_OS
  | typeof WINDOWS_OS
  | typeof LINUX_OS
  | typeof ANDROID_OS
  | typeof IOS_OS;

export const desktopAppOS = [MAC_OS, WINDOWS_OS] as const;
export type DesktopOS = (typeof desktopAppOS)[number];
/**
 * this was made to differentiate OS for users in order to give them proper links for GLGO Desktop
 * I'm assuming Linux users will want the windows link. that being said, trying to make this function
 * as general as possible so devs can add other logic based on OS instead of having this solely return a
 * proper download link.
 *
 * @return OperatingSystem
 */
export const detectOperatingSystem = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.includes('mac')) {
    // Examples: ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    return MAC_OS;
  } else if (userAgent.includes('win')) {
    // Examples: ['Win32', 'Win64', 'Windows', 'WinCE']
    return WINDOWS_OS;
  } else if (userAgent.includes('android')) {
    return ANDROID_OS;
  } else if (userAgent.includes('linux')) {
    return LINUX_OS;
  } else if (
    userAgent.includes('iphone') ||
    userAgent.includes('ipad') ||
    userAgent.includes('ipod')
  ) {
    // Examples: ['iPhone', 'iPad', 'iPod']
    return IOS_OS;
  }
  return '';
};
