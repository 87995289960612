import {
  MantineReactTable,
  MRT_RowData,
  useMantineReactTable,
  MRT_ColumnDef,
  MRT_TableOptions,
} from 'mantine-react-table';
import classes from './CustomTable.module.css';
import 'mantine-react-table/styles.css';
import { BoxProps, TableProps, Text } from '@mantine/core';
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from 'react-icons/ti';
import React from 'react';
import { NoDataFoundPlaceHolder } from '../Generic';

interface Props<TData extends MRT_RowData> extends MRT_TableOptions<TData> {
  columns: MRT_ColumnDef<TData>[];
  data: TData[];
  containerProps?: BoxProps;
  tableProps?: TableProps;
  headProps?: BoxProps;
  paperProps?: BoxProps;
  bodyCellProps?: BoxProps;
  bottomToolbarString?: string;
}

export const CustomTable = <TData extends MRT_RowData>({
  columns,
  data,
  containerProps,
  headProps,
  paperProps,
  bodyCellProps,
  bottomToolbarString,
  ...rest
}: Props<TData>) => {
  const heightOffset = 200; //offset for the header

  const table = useMantineReactTable({
    columns,
    data,

    enableColumnActions: false,
    enableRowVirtualization: true,
    enableFilters: false,
    enablePagination: false,
    enableTopToolbar: false,

    mantineBottomToolbarProps: {
      style: { display: 'flex', alignContent: 'center' },
      h: 35,
      mih: 35,
    },
    mantineTableContainerProps: {
      className: classes.scrollbar,
      mah: `calc(90vh - ${heightOffset}px)`,
      bd: 'none',
      mih: 500,
      ...containerProps,
    },
    mantineTableHeadRowProps: { h: 35 },
    mantinePaperProps: { className: classes.paper, ...paperProps },
    mantineTableHeadProps: { className: classes.header, ...headProps },
    mantineTableProps: { highlightOnHover: false },
    mantineTableBodyRowProps: { className: classes.body },
    mantineTableBodyCellProps: { className: classes.cell, ...bodyCellProps },
    renderEmptyRowsFallback: () => <NoDataFoundPlaceHolder height={400} />,
    defaultColumn: { minSize: 40, maxSize: 1000, size: 100 },
    enableStickyHeader: true,
    layoutMode: 'grid',
    state: { density: 'xs' },

    renderBottomToolbarCustomActions: () => (
      <Text fw={700} c="font1.0">
        {`${data.length.toLocaleString()} ${bottomToolbarString || 'Results'}`}
      </Text>
    ),
    icons: {
      IconArrowsSort: () => <TiArrowUnsorted color="#757575" size={14} />,
      IconSortAscending: () => <TiArrowSortedUp color="#0071DB" size={14} />,
      IconSortDescending: () => <TiArrowSortedDown color="#0071DB" size={14} />,
    },
    ...rest,
  });

  return <MantineReactTable table={table} />;
};
export default CustomTable;
