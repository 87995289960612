import React, { createContext, useContext, useState } from 'react';
import { SkuWCreatedAndLastMod } from 'store';

export const RecommendedThresholdContext = createContext<{
  threshold: number;
  setThreshold: React.Dispatch<React.SetStateAction<number>>;
  recommendedPlanType: SkuWCreatedAndLastMod['sku_type'];
}>({
  threshold: 0,
  setThreshold: () => {},
  recommendedPlanType: 'NORMAL',
});

export const useRecommendedThresholdContext = () => {
  const context = useContext(RecommendedThresholdContext);
  if (!context) {
    throw new Error(
      'useRecommendedThresholdContext must be used within a ThresholdProvider'
    );
  }
  return context;
};

export const RecommendedThresholdProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [threshold, setThreshold] = useState(0);
  // threshold = 0 is equivalent to "no sku exists for the threshold that we recommend"
  // Which means that the recommended plan is enterprise
  const recommendedPlanType = threshold > 0 ? 'NORMAL' : 'ENTERPRISE';

  return (
    <RecommendedThresholdContext.Provider
      value={{
        threshold,
        setThreshold,
        recommendedPlanType,
      }}
    >
      {children}
    </RecommendedThresholdContext.Provider>
  );
};

export default RecommendedThresholdProvider;
