import { create } from 'zustand';
import Get from './xapis-wrappers/Fetcher';
import Put from './xapis-wrappers/Putter';
import { AxiosResponse } from 'axios';
import { PutterPromise } from './xapis-wrappers';

export const defaultUser = {
  email: '',
  first_name: '',
  company_name: '',
  last_name: '',
  phone: '',
  user_key: '',
};

export type UserInfo = {
  email: string;
  first_name: string;
  company_name: string;
  last_name: string;
  phone: string;
  readonly user_key: string;
  avatar_url: string;
  can_create_group: number;
  can_create_project: number;
  group_key: string;
  is_active: boolean;
  lastmod_user: string;
  lastmod_utc: string;
};

export type UserStoreType = {
  user: User;
  project_keys?: ProjectKey[];
  setUser: (user: User) => void;
  fetchUser: (userId: string, success?: () => void) => void;
  updateUser: (
    user_key: string,
    data: Partial<UserInfo>,
    success: (results?: AxiosResponse) => void,
    failed: { errorMessage?: string; action?: () => void },
    cleanup?: () => void
  ) => void;
};
// TODO: Fix an entire store to use camelCase over underscores

export const useUserStore = create<UserStoreType>()((set) => ({
  user: {
    email: '',
    first_name: '',
    company_name: '',
    last_name: '',
    phone: '',
    user_key: '',
  },
  project_keys: [],
  setUser: (user) => {
    set({ user, project_keys: user.project_keys || [] });
  },
  fetchUser: (userId, success) => {
    Get(
      `User/${userId}`,
      ({ data: user }: AxiosResponse<User>) => {
        const { project_keys } = user;
        // save user in desktop app
        set({
          user, // TODO: Create filterUser function
          project_keys,
        });
        success && success();
      },
      {}
    );
  },
  updateUser: (user_key, data, success, failed, cleanup) => {
    Put(
      `User/${user_key}`,
      data,
      ({
        data: { first_name, last_name, email: eMail, phone, company_name },
      }: {
        data: Partial<UserInfo>;
      }) => {
        set((state) => ({
          user: {
            email: eMail || state.user?.email,
            first_name: first_name || state.user?.first_name,
            company_name: company_name || state.user?.company_name,
            last_name: last_name || state.user?.last_name,
            phone: phone || state.user?.phone,
            user_key: user_key || state.user?.user_key,
          },
        }));
        success();
      },
      {
        errorMessage: failed.errorMessage || '',
        action: () => {
          failed.action && failed.action();
        },
      },
      {},
      cleanup
    );
  },
}));

export const updateUserInfoPromise = (
  user_key: string,
  data: Partial<UserInfo>
) => {
  return PutterPromise(`User/${user_key}`, data);
};

export default useUserStore;
export const useUser = () => useUserStore((state) => state.user);
export const useUserProjects = () =>
  useUserStore((state) => state.user?.project_keys || []);
export const useUserActions = () =>
  useUserStore((state) => ({
    setUser: state.setUser,
    updateUser: state.updateUser,
  }));
