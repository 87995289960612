import { isSuccessStatus } from 'helpers';
import {
  fetchSubscriptionsPromise,
  Subscription,
} from '../../PaySubscriptionStore';
import { queryOptions } from '@tanstack/react-query';

export const querySubscriptions = (
  payKey: string,
  projectKey: string,
  isExpired = false
) =>
  queryOptions({
    queryKey: ['subscriptions', payKey, projectKey, isExpired],
    queryFn: async () => {
      const response = await fetchSubscriptionsPromise(
        payKey,
        projectKey,
        isExpired
      );
      if (
        !response ||
        !isSuccessStatus(response.status) ||
        !response.data ||
        !response.data.subscriptions
      ) {
        throw new Error('Failed to fetch subscriptions data');
      }
      return response.data.subscriptions as Subscription[];
    },
  });
