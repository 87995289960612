import { chartLanguageColors } from './colors';
import {
  LanguagesMap,
  WordsServedGroupedByTimeSlice,
  WordsServedWithRemainingTimeSlices,
} from './types';
import { WordsServedResponse } from 'store';

export const getWordsServedGroupedByTimeSlice = (
  languagesMap: LanguagesMap = {},
  wordsServed: WordsServedResponse[]
) => {
  let languageColorIndex = 0;
  return wordsServed.reduce(
    (
      accum: WordsServedGroupedByTimeSlice[],
      { timeslice, count, translation_key }
    ) => {
      const language = languagesMap[translation_key] || 'Unknown';
      const prevTimeSliceObj =
        accum.length > 0 &&
        accum.find(
          ({ timeslice: currTimeSlice }: { timeslice: string }) =>
            currTimeSlice === timeslice
        );
      const prevLanguageObjWithColors = accum.find(
        (obj) => obj[language]?.colors
      );
      const languageColors = prevLanguageObjWithColors
        ? prevLanguageObjWithColors[language].colors
        : chartLanguageColors[languageColorIndex++];

      if (prevTimeSliceObj) {
        const { timeslice: timeSliceFound } = prevTimeSliceObj || {};
        const prevIndex = accum.findIndex(
          ({ timeslice }) => timeslice === timeSliceFound
        );
        const prevLanguage = accum.find((obj) => obj[language]);
        if (prevIndex !== -1) {
          if (prevLanguage) {
            accum[prevIndex] = {
              ...prevTimeSliceObj,
              [language]: {
                count: (count += prevLanguage[language].count),
                colors: languageColors,
              },
            };
          }
          const numOfLanguages =
            (accum && Object.keys(accum[prevIndex]).length - 1) || 0;
          if (numOfLanguages < 9) {
            accum[prevIndex] = {
              ...prevTimeSliceObj,
              [language]: { count, colors: languageColors },
            };
          }
        }
      } else {
        accum.push({
          timeslice,
          [language]: { count, colors: languageColors },
        } as WordsServedGroupedByTimeSlice);
      }
      return accum;
    },
    []
  );
};

export const getWordsServedWithRemainingTimeSlices = (
  getFirstTimeSliceWithData: () => string,
  getLastTimeSliceWithData: () => string,
  isYearly: boolean,
  numOfDaysInMonth: number,
  wordsServedGroupedByTimeSlice: WordsServedGroupedByTimeSlice[]
): WordsServedWithRemainingTimeSlices[] => {
  const firstTimeSliceWithData = getFirstTimeSliceWithData();
  const lastTimeSliceWithData = getLastTimeSliceWithData();

  if (firstTimeSliceWithData && lastTimeSliceWithData) {
    const firstTimeSliceDay =
      firstTimeSliceWithData.substring(8, lastTimeSliceWithData.length) || '';
    const firstTimeSliceMonth = firstTimeSliceWithData.substring(5, 7) || '';
    const firstTimeSliceYear = firstTimeSliceWithData.substring(0, 4) || '';

    const lastTimeSliceDay =
      lastTimeSliceWithData.substring(8, lastTimeSliceWithData.length) || '';
    const lastTimeSliceMonth = lastTimeSliceWithData.substring(5, 7) || '';
    const lastTimeSliceYear = lastTimeSliceWithData.substring(0, 4) || '';

    const includesFirstTimeSlice = !isYearly && firstTimeSliceDay === '01';
    const firstTimeSlice = {
      timeslice: `${firstTimeSliceYear}-${firstTimeSliceMonth}-01`,
    };
    const tempWordsServedData =
      !isYearly && !includesFirstTimeSlice
        ? [firstTimeSlice, ...wordsServedGroupedByTimeSlice]
        : [...wordsServedGroupedByTimeSlice];

    let tempLastValue: number =
      Number(!isYearly ? lastTimeSliceDay : lastTimeSliceMonth) + 1;
    const maxValue = !isYearly ? numOfDaysInMonth : 12;
    while (tempLastValue <= maxValue) {
      const currentLastValue: string =
        tempLastValue < 10
          ? '0' + String(tempLastValue)
          : String(tempLastValue);
      const remainingTimeSlice = !isYearly
        ? `${lastTimeSliceYear}-${lastTimeSliceMonth}-${currentLastValue}`
        : `${lastTimeSliceYear}-${currentLastValue}`;
      tempWordsServedData.push({ timeslice: remainingTimeSlice });
      tempLastValue++;
    }
    return tempWordsServedData;
  }
  return wordsServedGroupedByTimeSlice || [];
};
