/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig } from 'axios';
import getUserKeyAndToken from '../xapis-wrappers/getUserKeyAndToken';

/**
 *
 * @param endpoint
 * @param params
 * @param data
 * @param baseUrl
 * @param token
 * @return { url: string, config: AxiosRequestConfig }
 */
const getAxiosUrlAndConfig = (
  baseUrl: string,
  endpoint: string,
  params: object = {},
  data?: any,
  token?: string
) => {
  const { token: xapisToken } = getUserKeyAndToken();

  const url = `${baseUrl}/${endpoint}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token || xapisToken,
    },
    params,
    ...(data && { data }),
  };

  return { url, config };
};

export default getAxiosUrlAndConfig;
