import { useState, useContext } from 'react';
import { Box, Button, Flex, ScrollArea } from '@mantine/core';
import { SegmentEditorContext } from '../../context/SegmentEditorProvider';

import { useEditorColors } from 'helpers';
import { styleSx } from './InsertElement.style';
import classes from './InsertElement.module.css';

type Option = { label: string; value: string };
type Insertables = {
  tag: Option[];
  symbol: Option[];
};
export type Insertable = {
  type: 'tag' | 'symbol';
  values: string[];
};

const elements: Insertables = {
  tag: [
    { label: 'Important Text', value: 'strong' },
    { label: 'Emphasized Text', value: 'em' },
    { label: 'Strikethrough', value: 's' },
    { label: 'Deleted Text', value: 'del' },
    { label: 'Inserted Text', value: 'ins' },
    { label: 'Marked Text', value: 'mark' },
    { label: 'Subscript Text', value: 'sub' },
    { label: 'Superscript Text', value: 'sup' },
    { label: 'Smaller Text', value: 'small' },
    { label: 'Bolded Text', value: 'b' },
    { label: 'Italic Text', value: 'i' },
    { label: 'Underlined Text', value: 'u' },
    { label: 'Bidirectional Isolate', value: 'bdi' },
  ],
  symbol: [
    { label: 'Copyright', value: '©' },
    { label: 'Registered', value: '®' },
    { label: 'Trademark', value: '™' },
    { label: 'Marque De Commerce', value: '🅪' },
    { label: 'Left Guillmets', value: '«' },
    { label: 'Right Guillmets', value: '»' },
    { label: 'En Dash', value: '–' },
    { label: 'Em Dash', value: '—' },
    { label: 'Soft Hyphen', value: '­' },
    { label: 'New Line', value: '\n' },
    { label: 'Non Breaking Space', value: ' ' },
  ],
};

type InsertElementProp = {
  addElement?: (insert: Insertable) => void;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
};

export const InsertElement = ({ addElement, onClose }: InsertElementProp) => {
  const colors = useEditorColors().insertMore;
  const { disableTagEdit } = useContext(SegmentEditorContext).settings;
  const [insert, setInsert] = useState<Insertable>({
    type: 'symbol',
    values: [],
  });
  const list = elements[insert.type];

  const handleSelectValue = (
    ev: React.MouseEvent<HTMLLIElement, MouseEvent>,
    value: string
  ) => {
    ev.preventDefault();
    if (insert.type === 'symbol' || !ev.metaKey) {
      setInsert({ ...insert, values: [value] });
    } else if (insert.type === 'tag') {
      const values = insert.values.includes(value)
        ? insert.values.filter((v) => v !== value)
        : [...insert.values, value];
      setInsert({ ...insert, values });
    }
    return false;
  };

  const handleInsert = () => {
    if (addElement) addElement(insert);
    onClose(false);
  };

  const SelectorButton = ({ type }: { type: Insertable['type'] }) => {
    return (
      <Button
        disabled={disableTagEdit && type === 'tag'}
        variant={insert.type === type ? 'outline' : 'filled'}
        style={styleSx(insert.type, insert.type === type, colors)}
        onMouseDown={(ev) => ev.preventDefault()}
        onClick={() => setInsert({ type, values: [] })}
      >
        {type === 'tag' ? 'Tags' : 'Symbols'}
      </Button>
    );
  };

  return (
    <Flex direction={'column'}>
      <Flex className={classes.selectors}>
        <SelectorButton type={'tag'} />
        <SelectorButton type={'symbol'} />
      </Flex>

      <ScrollArea h={230} type="auto" offsetScrollbars>
        <ul className={classes.list}>
          {list.map((option) => {
            const value =
              insert.type === 'tag' ? `<${option.value}>` : option.value;
            const isInList = insert.values.includes(option.value);
            return (
              <Box
                component="li"
                key={option.value}
                className={classes.item}
                onMouseDown={(ev) => ev.preventDefault()}
                bg={isInList ? colors.list.selectedBackground : 'inherit'}
                c={isInList ? colors.list.hoverText : 'inherit'}
                fw={isInList ? 600 : 'inherit'}
                fz={14}
                style={{
                  borderRight: `1px solid ${colors.border}`,
                }}
                onClick={(ev) => handleSelectValue(ev, option.value)}
              >
                <span
                  className={`${classes.icon} ${insert.type === 'tag' ? classes.tag : ''}`}
                >
                  {value}
                </span>
                <span>{option.label}</span>
              </Box>
            );
          })}
        </ul>
      </ScrollArea>

      <Flex className={classes.insertButton}>
        <Button
          w="100%"
          onClick={handleInsert}
          disabled={!insert.values.length}
        >
          Insert
        </Button>
      </Flex>
    </Flex>
  );
};
