import { SetURLSearchParams } from 'react-router-dom';

export const updateDateParam = (
  value: string,
  params: URLSearchParams,
  setParams: SetURLSearchParams
) => {
  if (value !== '1 month') {
    params.set('d', value);
  } else {
    params.delete('d');
  }
  setParams(params);
};

export const filterTargets = (targets: TranslationKey[] | undefined) => {
  return !targets
    ? []
    : targets
        .filter(
          ({
            source_lang_code: sourceLangCode = '',
            target_lang_code: targetLangCode = '',
          }) => {
            return targetLangCode !== sourceLangCode && targetLangCode !== 'YY';
          }
        )
        .map(
          ({
            translation_key: translationKey = '',
            target_lang_name: targetLangName = '',
          }) => {
            return { label: targetLangName, value: translationKey };
          }
        );
};
