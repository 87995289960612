import { useEffect, useState, useContext } from 'react';
import { Box, Flex, Loader, Radio, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { SegmentEditorContext } from '../../context/SegmentEditorProvider';
import { useEditorColors } from 'helpers';
import { fetchTranslationHistory } from '../../http/dataAPIs';
import {
  formatDate,
  getTranslationSouce,
  isAutoTranslation,
} from '../../functions/segmentsFunctions';
import { JliffViewer } from '../Jliff/JliffViewer';
import { Error } from '../Generic/Error';
import classes from './TranslationHistory.module.css';
import { CustomModal } from 'ui';

type Props = {
  onClose: () => void;
  segment: Segment;
  latest?: Jliff[];
  applyTranslationHistory: (jliffs: Jliff[]) => void;
  showAllTags: boolean;
};

type Translation = {
  name: string;
  id: string;
  date: string;
  label?: string;
  target: Jliff[];
};

export const TranslationHistory = ({
  segment,
  onClose,
  applyTranslationHistory,
  showAllTags,
}: Props) => {
  const { viewOnly, htmlDir = 'auto' } =
    useContext(SegmentEditorContext).settings;
  const { height } = useViewportSize();
  const colors = useEditorColors().history;

  const lastSaved: Translation = {
    name: getTranslationSouce(segment),
    id: segment.translation_utc || 'Last Saved',
    label: 'Current Translation',
    date: formatDate(segment.translation_utc),
    target: segment.target_jliff || segment.segment_jliff,
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState<Translation | null>(null);
  const [translations, setTranslations] = useState<Translation[]>([]);

  const getName = (target: SegmentTranslationHistory) => {
    if (!target.translation_source) return 'Unknown';
    if (isAutoTranslation(target.translation_source))
      return 'Automatic Translation';
    if (target.first_name || target.last_name)
      return `${target.first_name} ${target.last_name}`;
    return target.translation_source;
  };

  const applyTranslation = (jliffs: Jliff[]) => {
    applyTranslationHistory(jliffs);
    onClose();
  };

  useEffect(() => {
    setLoading(true);
    fetchTranslationHistory(segment.segment_hash)
      .then((res) => {
        console.log('TranslationHistory:', res);
        const { status, targets } = res;
        if (status !== 200) {
          setError(true);
          setLoading(false);
        } else {
          const history = targets.map((target) => ({
            name: getName(target),
            id: target.created_utc,
            date: formatDate(target.created_utc),
            target: target.target || [{ text: '' }],
          }));
          setTranslations([lastSaved, ...history]);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Couldn't get translation history: ", err);
        setLoading(false);
        setError(true);
      });
  }, []);

  if (error)
    return (
      <Error onClose={onClose}>
        <Text fw={600}>Unable to fetch translation history</Text>
      </Error>
    );

  const Loading = () => (
    <Flex justify="center" align="center" gap={10} h={100} p={40}>
      <Loader color="blue.7" variant="bars" />
      <Text size="xl">Loading translation history...</Text>
    </Flex>
  );

  const Translation = ({ translation }: { translation: Translation }) => {
    const isSelected = translation.id === selected?.id;
    return (
      <Box
        className={`translation ${classes.translation}`}
        onClick={() => !viewOnly && setSelected(translation)}
        bg={isSelected ? colors.selectedBackground : colors.listBackground}
        style={{
          borderColor: isSelected
            ? `${colors.selectedBorder}`
            : `${colors.listBackground}`,
        }}
      >
        <Radio p={12} checked={isSelected} readOnly />
        <Box p="10px 20px">
          <JliffViewer
            jliffs={translation.target}
            showAllTags={showAllTags}
            isSelected={isSelected}
            htmlDir={htmlDir}
          />
        </Box>
        <Flex className={classes.translatorInfo}>
          {translation.label && (
            <Text color={colors.label} size={'xs'}>
              {translation.label}
            </Text>
          )}
          <Text td="underline" fw={500}>
            {translation.name}
          </Text>
          <Text size="sm">{translation.date}</Text>
        </Flex>
      </Box>
    );
  };

  return (
    <CustomModal
      onClose={onClose}
      opened={true}
      size="lg"
      title="Translation History"
      footerActions={[
        { label: 'Cancel', action: onClose },
        {
          label: 'Apply Translation',
          action: selected
            ? () => applyTranslation(selected.target)
            : undefined,
          disabled: !selected || viewOnly,
        },
      ]}
    >
      {loading ? (
        <Loading />
      ) : (
        <Box className={classes.list}>
          {translations.map((translation) => {
            return (
              <Translation key={translation.id} translation={translation} />
            );
          })}
        </Box>
      )}
    </CustomModal>
  );
};
