import { notifications } from '@mantine/notifications';
import { SpiderTreeNode, Xapis, filterNodes, mergeChildren } from 'store';

export const handleToggle = (
  e: React.SyntheticEvent,
  yyTranslationKey: string,
  spiderTree: SpiderTreeNode[],
  setSpiderTree: React.Dispatch<React.SetStateAction<SpiderTreeNode[]>>
) => {
  const target = e.target as Element;
  if ((target.parentNode as HTMLButtonElement)?.type !== 'button') {
    try {
      const stringInfo = target.closest('li')?.getAttribute('data-nodeinfo');

      if (stringInfo) {
        const nodeInfo = JSON.parse(stringInfo);
        const { content_url_hash, children_fetched, id } = nodeInfo;

        if (!children_fetched) {
          Xapis.SpiderTree.get(yyTranslationKey, content_url_hash)
            .then(({ tree = [] }) => {
              const filteredData = filterNodes(tree, id) as SpiderTreeNode[];
              const newCrawlerTree = mergeChildren(
                filteredData,
                id,
                spiderTree
              );
              setSpiderTree(newCrawlerTree);
            })
            .catch(() => {
              notifications.show({
                message: 'Unable to get spider tree node info at this time.',
              });
            });
        }
      } else {
        throw new Error('String info is null or undefined');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`handle toggle error: ${JSON.stringify(error)}`);
    }
  }
};

export default handleToggle;
