import { Params } from 'react-router-dom';
import { decodeId, filterTranslations, decodeProjectId } from 'helpers';
import { queryClient } from '../../queries/queryClient';
import { queryWordsServedUrls } from '../../queries/usageQueries';
import { projectQuery } from '../../queries/projectQuery';
import { queryWordsServed } from '../../queries/shared/queryWordsServed';

export type UsageLoaderData = {
  timeSliceFrom: string;
  concatenatedKey: string;
};

export async function usageLoader({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) {
  const { projectId } = params;
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }
  const projectKey = decodeProjectId(projectId);
  const projectData = await queryClient.ensureQueryData(
    projectQuery(projectKey)
  );
  const { translations = [] } = projectData.project;
  const translationKeys = filterTranslations(translations || []).map(
    (t) => t.translation_key
  );

  const data = {
    timeSliceFrom: '',
    concatenatedKey: '',
  };

  const url = new URL(request.url);
  const keys = url.searchParams.getAll('t');

  const timeSliceFrom = url.searchParams.get('d');

  if (keys.length > 0 && keys[0] === '0') return data;

  const keysInUrl = keys.map((k) => decodeId(k));

  const maxSelection = 10;
  const firstTenKeys = translationKeys.slice(0, maxSelection);
  const firstTenKeysString = firstTenKeys.join(',');
  const concatenatedKeysOffUrl = keysInUrl.join(',');
  const concatenatedKey =
    concatenatedKeysOffUrl.length > 0
      ? concatenatedKeysOffUrl
      : firstTenKeysString;

  data.concatenatedKey = concatenatedKey;

  const isYearly = timeSliceFrom?.includes('year');
  const rollup = isYearly ? 'month' : 'day';

  await queryClient.ensureQueryData(
    queryWordsServed(concatenatedKey, timeSliceFrom || '1 month', rollup)
  );

  await queryClient.ensureQueryData(
    queryWordsServedUrls(concatenatedKey, timeSliceFrom || '1 month')
  );

  data.timeSliceFrom = timeSliceFrom ?? '1 month';

  return data;
}
