import { useState, useContext } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { SegmentEditorContext } from '../../context/SegmentEditorProvider';

import { Flex, Paper, Popover, Text, UnstyledButton } from '@mantine/core'; // Add these imports
import { useIsModified } from '../../store/SegmentsState';
import { ActionBar } from '../Generic/ActionBar';
import {
  formatDate,
  getTranslationSouce,
  isAutoTranslation,
} from '../../functions/segmentsFunctions';
import {
  GoBack,
  GoForward,
  HistoryIcon,
  UndoIcon,
} from '../../icons/ActionIcons';
import { TranslationHistory } from '../TranslationHistory/TranslationHistory';
import { ConfirmModal } from '../Generic/ConfirmModal';
import QuickAction from '../Generic/QuickAction';
import { useEditorColors } from 'helpers';

type Props = {
  segment: Segment;
  latest?: Jliff[];
  isEdited: boolean;
  onUndoAll: () => void;
  onUndo: () => void;
  onRedo: () => void;
  undoRedoStatus: { canUndo: boolean; canRedo: boolean };
  applyTranslationHistory: (jliffs: Jliff[]) => void;
  deleteSegment: (segmentHash: string) => Promise<void>;
  showAllTags: boolean;
};

export const BottomBar = ({
  segment,
  isEdited,
  onUndo,
  onUndoAll,
  onRedo,
  undoRedoStatus,
  applyTranslationHistory,
  deleteSegment,
  showAllTags,
}: Props) => {
  const colors = useEditorColors();
  const { viewOnly } = useContext(SegmentEditorContext).settings;
  const disableDeleteProTranslation =
    viewOnly ||
    isAutoTranslation(segment.translation_source) ||
    !segment.target_jliff;

  const isModified = useIsModified(segment.segment_hash);
  const translationDate = formatDate(segment.translation_utc || '');
  const { translationMethods } = useContext(SegmentEditorContext);

  const isUsesMt = ['MT', 'DDT'].some((method) =>
    translationMethods.includes(method)
  );

  const deleteModalMessage = isUsesMt
    ? 'This project uses MT backfill. By removing the Human Translation, the segment will be translated by machine.'
    : 'This project is HT only. By removing the Human Translation, the segment will become untranslated.';

  const [showHistory, { open, close }] = useDisclosure(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleDeletePreview = () => {
    setConfirmModalOpen(true);
    setShowDelete(false);
  };

  const confirmDelete = async () => {
    await deleteSegment(segment.segment_hash);
    setConfirmModalOpen(false); // close the modal after confirming
  };

  return (
    <Flex align="center" justify="space-between" p="5px 25px 5px 35px">
      <Flex gap={5} align="center">
        <QuickAction
          height={30}
          width={30}
          icon={<HistoryIcon />}
          backgroundColor={colors.historyActionIcon.background}
          isActive={true}
          isDisabled={!segment.num_history && !isEdited}
          onClick={open}
          label="Translation History"
        />
        <Text fz={12} c="blue.8">
          {translationDate}
          {!!segment.target_jliff && ' by '}
          <Popover
            disabled={disableDeleteProTranslation}
            opened={showDelete}
            shadow="sm"
            position="top-start"
            withArrow
            arrowSize={8}
            arrowOffset={30}
            closeOnEscape={true}
          >
            <Popover.Target>
              <Text
                onClick={() => setShowDelete(true)}
                component="span"
                fz={12}
                fw={600}
                td="underline"
                style={{
                  cursor: disableDeleteProTranslation ? 'default' : 'pointer',
                }}
              >
                {getTranslationSouce(segment)}
              </Text>
            </Popover.Target>
            <Popover.Dropdown>
              <Paper p={10} pb={8} style={{ borderRadius: 5 }}>
                <UnstyledButton
                  onClick={handleDeletePreview}
                  fw={500}
                  fz={12}
                  c="blue.7"
                >
                  Remove PRO Translation
                </UnstyledButton>
              </Paper>
            </Popover.Dropdown>
          </Popover>
        </Text>
      </Flex>
      <ActionBar
        actions={[
          {
            icon: (
              <GoBack
                color={
                  !undoRedoStatus.canUndo
                    ? colors.jliffActions.disabled
                    : colors.jliffActions.active
                }
              />
            ),
            onClick: onUndo,
            disabled: !undoRedoStatus.canUndo,
          },
          {
            icon: (
              <GoForward
                color={
                  !undoRedoStatus.canRedo
                    ? colors.jliffActions.disabled
                    : colors.jliffActions.active
                }
              />
            ),
            onClick: onRedo,
            disabled: !undoRedoStatus.canRedo,
          },
          {
            icon: (
              <UndoIcon
                color={
                  !isModified
                    ? colors.jliffActions.disabled
                    : colors.jliffActions.active
                }
              />
            ),
            onClick: onUndoAll,
            disabled: !isModified,
            label: 'Undo All Changes',
          },
        ]}
      />

      {showHistory && (
        <TranslationHistory
          segment={segment}
          onClose={close}
          applyTranslationHistory={applyTranslationHistory}
          showAllTags={showAllTags}
        />
      )}

      <ConfirmModal
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onCancel={() => setConfirmModalOpen(false)}
        onConfirm={confirmDelete}
        // confirmLabel="Delete"
      >
        <Text fz="1.15rem" ta="center">
          {deleteModalMessage}
        </Text>
      </ConfirmModal>
    </Flex>
  );
};
