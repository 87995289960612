import { useParams, Link, useSearchParams } from 'react-router-dom';
import { Box, Button, Flex, ScrollArea } from '@mantine/core';
import { useCurrentProjectData } from 'store';

import { TranslationCard } from './TranslationCard';
import { dashboardRC, decodeId, encodeKey } from 'helpers';
import { AiFillTool } from 'react-icons/ai';
import { TranslationEditor } from './TranslationEditor';
import { useMemo } from 'react';
import { Header, InactiveTranslations, TranslationStatusHeader } from 'ui';

export const Languages = () => {
  const { projectId } = useParams();

  const { translations } = useCurrentProjectData();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTKey = decodeId(searchParams.get('t') || '');
  const target = translations.find((t) => t.translation_key === selectedTKey);
  const activeTranslations = useMemo(() => {
    const languages = translations.filter(({ is_active = true }) => is_active);

    return languages.sort((t1, t2) => {
      if (t1.target_lang_name < t2.target_lang_name) return -1;
      if (t1.target_lang_name > t2.target_lang_name) return 1;
      return 0;
    });
  }, [translations]);

  const inactiveTranslations = useMemo(() => {
    const languages = translations.filter(
      ({ is_active = false }) => !is_active
    );

    return languages.sort((t1, t2) => {
      if (t1.target_lang_name < t2.target_lang_name) return -1;
      if (t1.target_lang_name > t2.target_lang_name) return 1;
      return 0;
    });
  }, [translations]);

  // target not found but selectedTKey is not empty
  if (selectedTKey && !target) {
    // Redirect to languages tab
    setTimeout(() => {
      setSearchParams({});
    }, 1500);
    return (
      <Box mt={30}>
        Invalid translation key. Please select a language from the list.
      </Box>
    );
  }

  const handleTranslationSelection = (tkey: string) => {
    setSearchParams({ t: encodeKey(tkey) });
  };

  return (
    <Flex h="100%" style={{ flexGrow: 1 }}>
      {target ? (
        <TranslationEditor segmentEditorRef={null} target={target} />
      ) : (
        <Box miw="min(850px, 100%)">
          <Header
            title="Languages"
            flexItems={
              <Link
                to={`/${projectId}/${dashboardRC.PAGE_SETTINGS}/language-settings`}
                style={{ textDecoration: 'none', color: '#0071DB' }}
              >
                <Button
                  px={0}
                  variant="transparent"
                  rightSection={<AiFillTool size={16} />}
                >
                  Manage Languages
                </Button>
              </Link>
            }
          />
          <TranslationStatusHeader
            statusTitle={`Active (${activeTranslations.length.toLocaleString()})`}
            status="active"
          />
          <ScrollArea
            h="calc(100vh - 245px)"
            mt={20}
            data-testid="pw-translations-card-container"
          >
            <Flex direction="column" rowGap={10}>
              {activeTranslations?.length > 0 ? (
                activeTranslations.map((translation) => (
                  <TranslationCard
                    key={translation.translation_key}
                    translation={translation}
                    handleSelection={handleTranslationSelection}
                  />
                ))
              ) : (
                <></>
              )}
            </Flex>
          </ScrollArea>
          <InactiveTranslations
            targets={translations}
            inactiveTranslations={inactiveTranslations}
          />
        </Box>
      )}
    </Flex>
  );
};
