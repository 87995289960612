import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Loader,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import classes from './CancelPlan.module.css';
import { handleCancelSubscription } from '../plan-information/planActions';
import { useUserProvider, xapisEnv } from 'store';
import { useGLGOParams } from 'hooks';
import BackButton from '../shared/BackButton';
import { AnchorLink } from '../../../Generic';

const CancelPlan = () => {
  const { xapisUser } = useUserProvider();
  const payKey = xapisEnv.getHost.payKey;
  const projectKey = useGLGOParams().projectKey as string;
  const navigate = useNavigate();

  const [reason, setReason] = useState('Prefer not to answer');
  const [loading, setLoading] = useState(false);
  const params = {
    payKey,
    projectKey,
    reason,
    xapisUser,
    navigate,
    cleanupOnError: () => setLoading(false),
  };

  const reasons = [
    'Too expensive',
    'Better product elsewhere',
    'Technical issues',
    'Not enough impact',
    "It's temporary, I'll be back",
    'Prefer not to answer',
  ];

  return (
    <Box w="100%" maw={1000}>
      <BackButton text="Manage Subscription" />
      <Card p="1.5rem" shadow="sm" radius="md">
        <Stack gap="1.5rem">
          <Title order={1}>
            Are you sure you want to cancel your subscription?
          </Title>
          <Divider color="divider.0" />
          <Box>
            <RadioGroup
              label="If you have a moment, please let us know why?"
              name="cancelReason"
              onChange={setReason}
              classNames={{
                root: classes.radioGroupRoot,
                label: classes.radioGroupLabel,
              }}
              value={reason}
            >
              <Stack gap="0.75rem">
                {reasons.map((r) => (
                  <Radio
                    key={r}
                    value={r}
                    label={r}
                    classNames={{
                      label: classes.radioLabel,
                      radio: classes.radioRadio,
                    }}
                  />
                ))}
              </Stack>
            </RadioGroup>
          </Box>
          <Divider color="divider.0" />
          <Text>
            After clicking “Confirm Cancellation,” your account will be
            cancelled. Subscription fees are charged in advance and no credits
            or refunds will be issued for partial or prepaid months after
            cancellations. Your current subscription will remain active until
            the expiration date. If you change your mind, you can restart your
            subscription at any time.
          </Text>
          <Flex
            w="100%"
            align="center"
            justify="flex-end"
            columnGap="1rem"
            rowGap="1rem"
            direction={{ base: 'column-reverse', xs: 'row' }}
          >
            <Button
              w={{ base: '100%', xs: 'auto' }}
              disabled={loading}
              onClick={() => handleCancelSubscription({ ...params })}
              variant="outline"
            >
              {loading ? (
                <Loader color={loading ? 'cta1.4' : 'cta1.2'} size="sm" />
              ) : (
                'Confirm Cancellation'
              )}
            </Button>
            <AnchorLink to="../.." w={{ base: '100%', xs: 'auto' }}>
              <Button w="100%">My Account</Button>
            </AnchorLink>
          </Flex>
        </Stack>
      </Card>
    </Box>
  );
};

export default CancelPlan;
