import { useState } from 'react';

import { Flex } from '@mantine/core';
import { NoTxDashboard } from './NoTxDashboard';
import { NoTxRulesTable } from './NoTxRulesTable';

const filterRules = (rules: NoTranslateXRule[], filterBy: RulesFilter) => {
  const { text, showActive } = filterBy;
  if (!text && !showActive) return rules;
  const textFilter = text.toLowerCase();
  return rules.filter((rule) => {
    if (textFilter) {
      const ruleTexts = Object.values(rule.selector);
      if (!ruleTexts.some((t) => t.toLowerCase().includes(textFilter))) {
        return false;
      }
    }
    if (showActive && !rule.isOnPage) return false;
    return true;
  });
};

export type NoTranslateXRule = {
  selector: UX;
  order: number;
  id: number;
  isOnPage?: boolean;
  isSelected?: boolean;
};
export type RulesFilter = {
  text: string;
  showActive: boolean;
};

type Props = {
  rules: NoTranslateXRule[];
  target: TranslationKey | undefined;
  selectRule?: (id: number) => void;
};

export const NoTxEditor = ({ rules, target, selectRule }: Props) => {
  const [filterBy, setFilterBy] = useState<RulesFilter>({
    text: '',
    showActive: false,
  });
  const [loading, setLoading] = useState(false);

  const filteredRules = filterRules(rules, filterBy);

  return (
    <Flex direction="column" gap={20}>
      <NoTxDashboard
        target={target}
        rules={rules}
        setFilterBy={setFilterBy}
        selectRule={selectRule}
        filterBy={filterBy}
        setLoading={setLoading}
      />
      <NoTxRulesTable
        rules={filteredRules}
        yyTarget={target}
        selectRule={selectRule}
        setLoading={setLoading}
        loading={loading}
      />
    </Flex>
  );
};
