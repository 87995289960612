import { MantineTheme } from '@mantine/core';
import { css } from '@emotion/css';

const planContainer = (theme: MantineTheme, isCancelled: boolean) => css`
  font-size: 14px;
  font-weight: 500;
  .planBadge {
    background-color: ${isCancelled
      ? theme.colors?.badge[0]
      : theme.colors?.badge[2]};
    color: ${isCancelled ? theme.colors?.badge[1] : theme.colors?.badge[3]};
  }
  .planType {
    color: ${isCancelled && theme.colors?.text[3]};
  }
  .planDate {
    color: ${isCancelled && theme.colors?.text[6]};
  }
`;

const percentageChange = (theme: MantineTheme, isNegative: boolean) => css`
  font-weight: 700;
  font-size: 22px;
  color: ${isNegative ? theme.colors.text[4] : theme.colors.text[5]};
`;

const languageBadge = (theme: MantineTheme, isLive: boolean) => css`
  width: 50px;
  height: 50px;
  font-weight: 700;
  background-color: ${theme.colors.background[1]};
  color: ${isLive ? theme?.colors?.badge[3] : theme?.colors?.badge[1]};
  border-color: ${isLive ? theme?.colors?.badge[3] : theme?.colors?.badge[1]};
  padding: 0;
  margin: 0;
`;

const languageIcon = (theme: MantineTheme, isLive: boolean) => css`
  float: right;
  width: 10px;
  height: 10px;
  background-color: ${isLive
    ? theme.colors.background[4]
    : theme.colors.background[3]};
  border: ${isLive
    ? `1.5px solid ${theme.colors.border[3]}`
    : `1.5px solid ${theme.colors.border[2]}`};
  border-radius: 50px;
`;

const styles = {
  planContainer,
  percentageChange,
  languageBadge,
  languageIcon,
};

export default styles;
