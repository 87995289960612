import React from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useAuth } from 'auth';
import { useGLGOParams } from 'hooks';
import { glossaryQuery, useUserProvider } from 'store';
import { xapisEnv } from 'store';

import {
  SegmentEditor,
  SegmentEditorRef,
  SegmentEditorProvider,
  SegmentEditorConfig,
} from 'segment-editor';
import { hexToObject } from 'helpers';

type EditorProps = {
  target: TranslationKey;
  segmentEditorRef: React.RefObject<SegmentEditorRef> | null;
};

export function TranslationEditor({ segmentEditorRef, target }: EditorProps) {
  const html_dir = hexToObject(target.target_config).html_dir;

  const token = useAuth().user?.access_token || 'NoToken';
  const user = useUserProvider().xapisUser;
  const { projectKey } = useGLGOParams();

  const { data: glossary } = useSuspenseQuery(glossaryQuery(projectKey || ''));

  if (!target) return <div>Target/Project not defined</div>;

  const targetGlossaryItems = glossary?.glossary_items?.length
    ? // Get glossary items for the current target and set empty legacy rules to 'translate_as'
      glossary.glossary_items
        .filter((g) => g.translation_key === target.translation_key)
        .map((g) => ({
          ...g,
          rule: g.rule || 'translate_as',
        }))
    : [];

  const { xapisUrl } = xapisEnv.getHost;

  const segmentEditorConfig: SegmentEditorConfig = {
    target,
    user,
    glossary: targetGlossaryItems,
    settings: {
      xapisHost: xapisUrl,
      contextMode: false,
      accessToken: token,
      disableTagEdit: false,
      viewOnly: false,
      htmlDir: html_dir,
    },
  };

  return (
    <SegmentEditorProvider config={segmentEditorConfig}>
      <SegmentEditor
        key={`${target.translation_key}`}
        ref={segmentEditorRef}
        contextData={null}
      />
    </SegmentEditorProvider>
  );
}
