import {
  Bar,
  BarChart,
  BarProps,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import React from 'react';
import {
  FilteredTargets,
  WordsServedGroupedByTimeSlice,
  WordsServedWithRemainingTimeSlices,
} from './types';
import { useCurrentProjectData } from 'store';
import { NoDataFoundPlaceHolder } from '../../../Generic';
import { CustomBarChartTooltip } from '../../recharts/CustomBarChartTooltip';
import { Flex } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import moment from 'moment/moment';
import { ActiveShape } from 'recharts/types/util/types';
import { isThresholdEndpointValid } from '../../recharts/utils';

type Props = {
  isYearly: boolean;
  numOfDaysInMonth: number;
  wordsServedGroupedByTimeSlice: WordsServedGroupedByTimeSlice[];
  wordsServedWithRemainingTimeSlices: WordsServedWithRemainingTimeSlices[];
  getLastTimeSliceWithData: () => string;
  filteredTargets: FilteredTargets[];
  getLanguageColor: (label: string, colorType: string) => string | undefined;
};
export const WordsServedTimeSeriesChart = ({
  isYearly,
  numOfDaysInMonth,
  wordsServedGroupedByTimeSlice,
  wordsServedWithRemainingTimeSlices,
  getLastTimeSliceWithData,
  filteredTargets,
  getLanguageColor,
}: Props) => {
  const isMobileSize = useMediaQuery('(max-width: 991px)');
  const threshold_value = useCurrentProjectData().project.threshold_value || 0;
  const lastTimeSliceWithData = getLastTimeSliceWithData();
  const getFirstTimeSlice = () =>
    wordsServedWithRemainingTimeSlices[0]?.timeslice || '';
  const getLastTimeSlice = () =>
    wordsServedWithRemainingTimeSlices[
      wordsServedWithRemainingTimeSlices.length - 1
    ]?.timeslice || '';

  const getTick = (
    axis: string,
    props: {
      x: number;
      y: number;
      cx: number;
      cy: number;
      payload: Record<string, string>;
    }
  ) => {
    const { x = 0, y = 0, payload: { value = '' } = {} } = props || {};

    const isTickValid = (value: string) => {
      const index = wordsServedGroupedByTimeSlice?.length - 1;
      const lastOriginalTimeSlice =
        wordsServedGroupedByTimeSlice[index]?.timeslice || '';

      const lastTimeSliceValue = new Date(lastOriginalTimeSlice) || '';
      const currentTimeSliceValue = new Date(value) || '';

      const maxTimeSliceValue = !isYearly ? numOfDaysInMonth.toString() : '12';

      if (value.includes(maxTimeSliceValue)) return true;
      return lastTimeSliceValue >= currentTimeSliceValue;
    };

    const formatTimeSliceTick = (value: string) => {
      if (isYearly) {
        return moment(value).format('YYYY/MM') || '';
      }
      return moment(value).format('MM/DD') || '';
    };
    const formatTickCommas = (value: string) => value.toLocaleString() || '';

    if (axis === 'x') {
      if (!isTickValid(value)) return <text />;

      const isLastValue = value === getLastTimeSlice();
      const rotation = `rotate(${!isLastValue ? -40 : 0}, ${x}, ${y})`;

      return (
        <text
          x={x}
          y={y}
          dy={20}
          dx={-30}
          fill="#071D49"
          fontSize={16}
          fontWeight={500}
          textAnchor="middle"
          dominantBaseline="central"
          transform={rotation}
        >
          {formatTimeSliceTick(value)}
        </text>
      );
    }
    return (
      <text
        x={x}
        y={y}
        dx={-20}
        fill="#071D49"
        fontSize={16}
        fontWeight={500}
        textAnchor="end"
      >
        {formatTickCommas(value)}
      </text>
    );
  };

  const hasValidThresholdEndpoint = isThresholdEndpointValid(
    lastTimeSliceWithData,
    numOfDaysInMonth,
    isYearly
  );

  const getThresholdEndpoint = () => {
    const lastTimeSlice = getLastTimeSlice();
    if (lastTimeSlice && lastTimeSliceWithData) {
      if (hasValidThresholdEndpoint) {
        let extendedTimeSlice: string | string[] =
          lastTimeSliceWithData.split('-');
        const extendedNumOfTimeSlices = 1;
        extendedTimeSlice[!isYearly ? 2 : 1] = String(
          Number(extendedTimeSlice[!isYearly ? 2 : 1]) + extendedNumOfTimeSlices
        );
        extendedTimeSlice[!isYearly ? 2 : 1] =
          Number(extendedTimeSlice[!isYearly ? 2 : 1]) < 10
            ? '0' + String(extendedTimeSlice[!isYearly ? 2 : 1])
            : extendedTimeSlice[!isYearly ? 2 : 1];
        extendedTimeSlice = extendedTimeSlice.join('-');
        return extendedTimeSlice;
      }
      return lastTimeSlice;
    }
    return '';
  };

  if (wordsServedGroupedByTimeSlice.length === 0) {
    return (
      <Flex w="100%" align="center" justify="center">
        <NoDataFoundPlaceHolder />
      </Flex>
    );
  }

  const BarWithBorder = (
    borderHeight: string | number,
    borderColor: string
  ): ActiveShape<BarProps, SVGPathElement> => {
    return (props: Partial<BarProps>) => {
      const { fill, x, y, width, height } = props;
      return (
        <g>
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            stroke="none"
            fill={fill}
          />
          {height && (
            <rect
              x={x}
              y={y}
              width={width}
              height={borderHeight}
              stroke="none"
              fill={borderColor}
            />
          )}
        </g>
      );
    };
  };

  return (
    <ResponsiveContainer height={450} width="100%">
      <BarChart
        height={450}
        data={wordsServedWithRemainingTimeSlices}
        margin={{ top: 40, right: 40 }}
      >
        <YAxis
          stroke="#071D49"
          strokeWidth={2}
          tick={(props) => getTick('y', props)}
          width={150}
          tickLine={false}
        />
        <XAxis
          dataKey="timeslice"
          stroke="#071D49"
          strokeWidth={2}
          height={90}
          interval="preserveStartEnd"
          tick={(props) => getTick('x', props)}
          tickLine={false}
        />
        <Tooltip content={<CustomBarChartTooltip />} cursor={false} />
        {filteredTargets.map(({ label }, index) => {
          const languageColor = getLanguageColor(label, 'fill');
          return (
            <Bar
              key={index}
              stackId="1"
              dataKey={`${label}.count`}
              fill={languageColor || '#000'}
              shape={BarWithBorder(2, '#FFFFFF')}
              barSize={50}
            />
          );
        })}
        {threshold_value && (
          <ReferenceLine
            isFront={true}
            stroke="#D93025"
            strokeDasharray="3 3"
            label={{
              position: 'right',
              value:
                hasValidThresholdEndpoint && !isMobileSize
                  ? 'Threshold Line'
                  : '',
              fontWeight: 700,
              fill: 'black',
            }}
            segment={[
              { x: getFirstTimeSlice(), y: threshold_value },
              {
                x: !isMobileSize ? getThresholdEndpoint() : getLastTimeSlice(),
                y: threshold_value,
              },
            ]}
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default WordsServedTimeSeriesChart;
