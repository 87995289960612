import React, { useContext, useRef, useState, useReducer } from 'react';
import { Box, Flex } from '@mantine/core';
import { SegmentEditorContext } from '../../context/SegmentEditorProvider';
import { TagModes, ElementModes } from '../../types/editor';
import { EditHistoryStore } from '../../store/EditHistoryStore';
import { useEditorColors } from 'helpers';
import { EditableText } from '../Generic/EditableText';
import { Token } from '../Markers/Token';
import { Tag } from '../Markers/Tag';

import { TopBar } from '../JliffActions/TopBar';
import { BottomBar } from '../JliffActions/BottomBar';
import { Insertable } from '../JliffActions/InsertElement';

import { Status } from '../Indicators/Status';
import { GlossaryViolations } from '../Glossary/GlossaryViolations';
import { SegmentIndicators } from '../Indicators/SegmentIndicators';
import { Target } from './Target';
import { StoreApi, useStore } from 'zustand';

import {
  compressJliff,
  createJliffSlice,
  expandJliff,
  findValidDrops,
  getNextId,
  haveTags,
  isFormattingTag,
  isInRange,
  isTag,
  isText,
  isToken,
  jliffsAreEqual,
  mapJliff,
  textifyJliff,
  validateTagsOrder,
} from './jliffFunctions';
import { findGlossaryViolations } from '../../functions/segmentsFunctions';

import { elementStyle, getTextStyle, SegmentStatus } from './Jliff.style';
import {
  useDomAttributes,
  useIsSelected,
  useSegmentStatus,
} from '../../store/SegmentsState';
import { UpdateUnsavedList } from '../../SegmentEditor';
import classes from './JliffEditor.module.css';

export type TextSelection = {
  index: number;
  start: number;
  end: number;
  nodeText: string;
} | null;

type UpdateJliffAction = {
  jliffs: Jliff[];
  action: 'set' | 'add' | 'update' | 'reset';
};

type JliffEditorProps = {
  segment: Segment;
  store: StoreApi<EditHistoryStore>;
  // status: SegmentStatus;
  glossary: GlossaryItem[];
  deleteSegment: (segmentHash: string) => Promise<void>;
  updateUnsavedList: UpdateUnsavedList;
  previewAllSegments?: (segments: Segment[]) => void;
  highlightText?: string;
};

type ActiveState = {
  id: string;
  tag: TagModes;
  element: ElementModes;
  index: number;
};

export const JliffEditor = ({
  segment,
  // status,
  store,
  glossary,
  updateUnsavedList,
  deleteSegment,
  previewAllSegments,
  highlightText,
}: JliffEditorProps) => {
  const jliffStore = useStore(store);

  const { undoRedo } = jliffStore;
  if (!undoRedo) console.error('JliffEditor: undoRedo is null');

  // Force rerender when undo/redo changes
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const isCompositionActive = useRef(false);
  const deferredUpdates = useRef<UpdateJliffAction | null>(null);

  const { viewOnly, htmlDir } = useContext(SegmentEditorContext).settings;

  const colors = useEditorColors().jliffEditor;
  const isSelected = useIsSelected(segment.segment_hash);
  // if (isSelected) console.log('Selected:', segment);

  const segmentStatus = useSegmentStatus(segment.segment_hash);
  const glossaryViolations = findGlossaryViolations(
    compressJliff(undoRedo.presentJliff),
    glossary
  );
  const lastSaved = undoRedo.lastSaved;
  const [showAllTags, setShowAllTags] = useState(false);
  // TextMode = true -> Edit text; false -> reposition tags
  const [isTextMode, setIsTextMode] = useState<boolean>(true);
  const isDragMode = !isTextMode;
  let jliffArray = isTextMode
    ? textifyJliff(undoRedo.presentJliff)
    : expandJliff(undoRedo.presentJliff);
  const resetActiveState: ActiveState = {
    id: '',
    tag: 'hide',
    element: 'edit',
    index: -1,
  };
  const [active, setActive] = useState(resetActiveState);

  const undoRedoStatus = undoRedo.status;

  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(
    undefined
  );
  const [draggedRange, setDraggedRange] = useState<{
    from: number;
    to: number;
  }>({ from: -1, to: -1 });

  const { tagMap, indexMap } = mapJliff(jliffArray);
  const activeTags = indexMap[active.index]
    ? tagMap[indexMap[active.index]]?.tags || []
    : [];

  const dragFrom = useRef<{ index: number; size: number }>({
    index: 0,
    size: 0,
  });
  const dragTo = useRef<number | undefined>(undefined);
  const validDrop = useRef<boolean[]>(
    new Array(jliffArray.length + 1).fill(true)
  );
  const textSelection = useRef<TextSelection>(null);

  // useEffect(() => {
  //   if (!isSelected) {
  //     // console.log('Unselected:', segment.segment_hash, undoRedo);
  //     setActive(resetActiveState);
  //     setShowAllTags(false);
  //     textSelection.current = null;
  //   } else {
  //     console.log('Selected:', segment.segment_hash);
  //     // Find last text element or assume 0
  //     const lastText = jliffArray.reduce(
  //       (acc: number, jliff: Jliff, i) =>
  //         isText(jliff) && jliff.text ? i : acc,
  //       0
  //     );

  //     if (isText(jliffArray[lastText])) {
  //       // Make it active
  //       setActive((prev) => ({ ...prev, index: lastText }));
  //       // And set the caret at the end of the text
  //       const text = jliffArray[lastText].text || '';
  //       textSelection.current = {
  //         index: lastText,
  //         start: text.length || 0,
  //         end: text.length || 0,
  //         nodeText: text,
  //       };
  //     }
  //   }
  // }, [isSelected]);

  if (!isSelected)
    return (
      <Target
        jliffs={jliffArray}
        segment={{ ...segment, target_jliff: jliffArray }}
        segmentStatus={segmentStatus}
        glossaryErrors={glossaryViolations}
        filterText={highlightText || ''}
        statusCode={segment.status_code}
      />
    );

  const updateJliffArray = (
    jliffs: Jliff[],
    action: 'set' | 'add' | 'update' | 'reset'
  ) => {
    // console.log('Update jliffArray', action, jliffs);
    // 'set' = set jliffArray only (no undo/redo)
    // 'add' = add to undo/redo and set jliffArray
    // 'update' = update presentJliff and set jliffArray

    if (isCompositionActive.current) {
      deferredUpdates.current = { jliffs, action };
      return;
    }
    const compressed = compressJliff(jliffs);
    const isDifferent = !jliffsAreEqual(compressed, lastSaved);
    updateUnsavedList(segment.segment_hash, {
      type: isDifferent ? 'SET_JLIFF' : 'REMOVE_JLIFF',
      payload: { target: compressed },
    });

    if (action === 'add') {
      undoRedo.add(compressed);
    } else if (action === 'update') {
      undoRedo.presentJliff = compressed;
    } else if (action === 'reset') {
      undoRedo.reset(compressed);
    }

    previewAllSegments &&
      previewAllSegments([{ ...segment, target_jliff: compressed }]);
    // Keep Jliff structure based on editing mode
    jliffArray = isTextMode
      ? textifyJliff(compressed)
      : expandJliff(compressed);

    forceUpdate();
  };

  const updateSetToLive = (setToLive: boolean) => {
    updateUnsavedList(segment.segment_hash, {
      type: 'SET_TO_LIVE',
      payload: { setToLive: setToLive },
    });
  };

  const updateTextElement = (index: number) => {
    return (text: string, addToUndo: boolean) => {
      const jliffs = [...jliffArray];
      if (isText(jliffs[index])) {
        jliffs[index] = { text };
        const add = addToUndo || !undoRedoStatus.canUndo;
        updateJliffArray(jliffs, add ? 'add' : 'update');
      } else {
        console.log('Error updating text element: Not a text element');
      }
    };
  };

  // --------------------------------------------------
  // Undo/Redo functions
  // --------------------------------------------------
  const handleUndo = () => {
    if (!undoRedoStatus.canUndo) {
      console.log('No more undo');
      return;
    }
    setActive(resetActiveState);
    const jliffs = undoRedo.undo();
    if (jliffs) {
      updateJliffArray(jliffs, 'set');
    } else {
      console.log('Undo error: no jliffs');
      updateJliffArray([...undoRedo.lastSaved], 'set');
    }
  };

  const handleRedo = () => {
    if (!undoRedoStatus.canRedo) {
      console.log('No more redo');
      return;
    }
    setActive(resetActiveState);

    const jliffs = undoRedo.redo();
    if (jliffs) {
      updateJliffArray(jliffs, 'set');
    } else {
      console.log('Redo error: no jliffs');
    }
  };

  const undoAllChanges = () => {
    setActive(resetActiveState);
    updateJliffArray([...lastSaved], 'reset');
  };

  // --------------------------------------------------
  // Events handlers
  // --------------------------------------------------
  function captureActiveIndex(index: number, inActiveRange: boolean) {
    if (!inActiveRange) setActive({ ...resetActiveState, index: index });
    else if (active.index !== index)
      setActive((prev) => ({ ...prev, index: index }));
  }

  function switchEditMode(mode?: 'text' | 'drag') {
    if (viewOnly) return; // Don't switch to drag mode in view only mode
    // Switch between "text" and "drag" modes
    // If mode is not given, switch modes.
    const switchToText = mode ? mode === 'text' : !isTextMode;
    // Don't switch if it's already in the right mode
    if (switchToText === isTextMode) return;

    if (isTextMode) {
      console.log('Switch to drag mode');
      const expanded = expandJliff(jliffArray);

      setActive((prev) => ({ ...prev, index: -1 }));
      // setJliffArray(expanded);
      jliffArray = expanded;
    } else {
      // Drag mode
      const compressed = textifyJliff(jliffArray);
      console.log('Switch to text mode');
      // setJliffArray(compressed);
      jliffArray = compressed;
    }
    setIsTextMode(!isTextMode);
  }

  // Called on MouseDown and Click on a tag or placeholder
  // Set the tag/token mode ('focus', 'show', 'hide')
  // And the element mode ('edit', 'move', 'resize')
  const handleModes = (ev: React.MouseEvent, id: string) => {
    const event = `${ev.type}${ev.detail}`;
    if (ev.type === 'mousedown') switchEditMode('drag');

    if (!id) {
      setActive(resetActiveState);
    }
    // Same tag id already in 'focus' mode => no change
    else if (
      event === 'mousedown1' &&
      active.id === id &&
      active.tag === 'focus'
    ) {
      return false;
    } else if (event === 'mousedown1' || (event === 'click1' && !ev.shiftKey)) {
      setActive((prev) => {
        if (prev.id === id && prev.element === 'resize' && prev.tag === 'focus')
          return prev;
        return { ...prev, id, tag: 'focus', element: 'resize' };
      });
    } else if (
      tagMap[id].canMove &&
      (event === 'mousedown2' || (event === 'click1' && ev.shiftKey))
    ) {
      setActive((prev) => ({ ...prev, id, tag: 'focus', element: 'move' }));
    } else {
      return false;
    }
  };

  function handleHotKeys(ev: KeyboardEvent) {
    ev.preventDefault();
    console.log('Hot key:', ev.key);
  }

  function saveTextSelection(index: number) {
    return (type?: string) => {
      if (type === 'insertLineBreak' && textSelection.current) {
        const { start } = textSelection.current;
        textSelection.current.start = start + 1;
        textSelection.current.end = textSelection.current.start;
        return;
      }
      const selection = window.getSelection();
      if (selection && selection.getRangeAt(0)) {
        const range = selection.getRangeAt(0);
        if (
          range.startContainer !== range.endContainer ||
          range.startContainer?.nodeType !== Node.TEXT_NODE
        ) {
          console.log('saveTextSelection: Range not supported');
          textSelection.current = null;
          return;
        }
        textSelection.current = {
          index,
          start: range.startOffset,
          end: range.endOffset,
          nodeText: range.startContainer.nodeValue || '',
        };
      } else {
        console.log('saveTextSelection: window.getSelection() is null');
        textSelection.current = null;
      }
    };
  }
  // --------------------------------------------------
  // Jliff manipulation functions
  // Add/Remove elements
  // --------------------------------------------------

  const addElement = (insert: Insertable) => {
    console.log('addElement', insert);
    const selection = textSelection.current;
    if (!selection) {
      console.log('Selection is empty, aborting');
      return;
    }

    console.log('Selection:', selection);
    const jliffs = [...jliffArray];
    const { index, start, end, nodeText } = selection;
    const text = jliffs[index].text || '';
    if (text !== nodeText) {
      console.log('Warning: text mismatch');
    }

    if (insert.type === 'symbol') {
      const symbol = insert.values[0];

      const newText = text.slice(0, start) + symbol + text.slice(end);
      jliffs[index] = { text: newText };
      const newStart = start + symbol.length;
      textSelection.current = {
        index,
        start: newStart,
        end: newStart,
        nodeText: newText,
      };
      console.log('New selection:', textSelection.current);
    } else if (insert.type === 'tag' && end - start > 0) {
      const tags = insert.values;
      const [before, selected, after] = [
        text.slice(0, start),
        text.slice(start, end),
        text.slice(end),
      ];
      // find the last id in jliffArray
      const slice = createJliffSlice(
        getNextId(jliffArray),
        tags,
        selected,
        before,
        after
      );
      if (slice.length > 0) {
        jliffs.splice(selection.index, 1, ...slice);
      }
      textSelection.current = null;
      setActive(resetActiveState);
    } else {
      console.log('Invalid insert:', insert, selection);
      return;
    }
    updateJliffArray(jliffs, 'add');
  };

  function removeElement(tag: string) {
    if (!indexMap[active.index]) {
      console.log('Error: no active index or active index has no tags');
      return;
    }
    // Find all tag ids from tagMap where active.index is between from and to and tag is the same as the one to remove
    const tagIds = Object.keys(tagMap).filter((id) => {
      const { from, to } = tagMap[id].range;
      return (
        tagMap[id].canDelete &&
        tag === tagMap[id].type &&
        from <= active.index &&
        active.index <= to
      );
    });

    const userAddedTags = tagIds.filter((id) => /^a/.test(id));
    const originalTags = tagIds.filter((id) => /^\d/.test(id));
    // Nothing to remove
    if (!userAddedTags.length && !originalTags.length) return;
    console.log('removeElement', tag, active.index, tagMap);
    // First remove user added tags
    let newJliff = [...jliffArray];

    console.log('Remove tagId', userAddedTags, originalTags);
    if (userAddedTags.length) {
      const indexesToRemove = userAddedTags.reduce((acc, id) => {
        const { from, to } = tagMap[id].range;
        return [...acc, from, to];
      }, [] as number[]);
      // filter jliffArray to remove the indexes
      newJliff = jliffArray.filter((_, i) => !indexesToRemove.includes(i));
    } else {
      console.log('removeElement: No user added tags to remove');
    }
    if (originalTags.length) {
      console.log('remove original:', originalTags);
      // Regenerate tagMap
      // Then move original tags so they don't enclose text
      originalTags.forEach((id) => {
        const { tagMap } = mapJliff(newJliff);
        const { from, to } = tagMap[id].range;
        console.log('New map:', tagMap);
        const removed = newJliff.splice(to, 1)[0];
        newJliff.splice(from + 1, 0, removed);
        console.log(newJliff);
      });
    }
    setActive(resetActiveState);
    updateJliffArray(newJliff, 'add');
  }

  function getTagMode(tag: string, tagId: string): TagModes {
    if (!isSelected) return 'hide';
    if (!showAllTags && isFormattingTag(tag)) return 'hide';
    return tagId === active.id ? active.tag : 'show';
  }

  // ------------------------------------------------
  // Drag and Drop functions
  // ------------------------------------------------
  function resetDragAndDrop() {
    validDrop.current = new Array(jliffArray.length + 1).fill(true);
    dragFrom.current = { index: 0, size: 0 };
    dragTo.current = undefined;
    setHoveredIndex(undefined);
    setDraggedRange({ from: -1, to: -1 });
  }

  function handleDragStart(
    ev: React.DragEvent<HTMLElement>,
    range: { from: number; to: number }
  ) {
    if (isTextMode) return false;
    resetDragAndDrop();

    const { from, to } = range;
    const size = to - from + 1;

    if (size > 1 && active.element === 'resize') {
      console.log('Drag error: Move element in resize mode not allowed');
      return false;
    }

    dragFrom.current = { index: from, size: size };
    dragTo.current = from;

    if (size === 1) {
      validDrop.current = findValidDrops(jliffArray, from);
    }
    setDraggedRange({ from, to });
  }

  function handleDragEnter(ev: React.DragEvent<HTMLElement>, i: number) {
    ev.preventDefault();
    setHoveredIndex(undefined);

    const from = dragFrom.current.index;
    const maxTarget = validDrop.current.length - 1;
    const target = i > from ? Math.min(maxTarget, i + 1) : i;

    if (validDrop.current[target]) dragTo.current = target;
    else if (validDrop.current[target + 1]) dragTo.current = target + 1;
    else if (validDrop.current[target - 1]) dragTo.current = target - 1;

    // If target is in the dragged range, don't set the drop target
    const inRange = i >= from && i < from + dragFrom.current.size;
    if (!inRange) setHoveredIndex(dragTo.current);
  }

  function handleDrop(ev: React.DragEvent<HTMLElement>) {
    // ev.preventDefault();
    if (isTextMode) return false;

    if (dragTo.current === undefined) {
      // console.log('Error: no drag destination');
      resetDragAndDrop();
      return false;
    }

    if (dragTo.current === dragFrom.current.index) {
      // console.log('Abort: drag to same location');
      resetDragAndDrop();
      return false;
    }

    const [to, from, size] = [
      dragTo.current || 0,
      dragFrom.current.index,
      dragFrom.current.size,
    ];

    if (to !== from && to !== from + 1) {
      const newJliff = [...jliffArray];
      const draggedEl = newJliff.splice(from, size);
      const insert = from > to ? to : to - size;
      newJliff.splice(insert, 0, ...draggedEl);

      // check the order of jliff tags
      if (!validateTagsOrder(newJliff)) {
        console.warn('Error: incorect tags order:', newJliff);
      }

      updateJliffArray(newJliff, 'add');
    }

    resetDragAndDrop();
    return false;
  }

  function handleCompositionEnd(ev: React.SyntheticEvent<HTMLSpanElement>) {
    if (deferredUpdates.current) {
      updateJliffArray(
        deferredUpdates.current.jliffs,
        deferredUpdates.current.action
      );
      deferredUpdates.current = null;
    }
  }

  function jliffToJsx(jliffs: Jliff[]) {
    const elements = jliffs.map((jliff, i) => {
      const tagId = indexMap[i];
      const tags = tagMap[tagId]?.tags || [];
      const isDragged = draggedRange.from <= i && i <= draggedRange.to;

      // TEXT element
      if (isText(jliff)) {
        let inRange = true;
        let elementMode: ElementModes = 'edit';
        if (active.id) {
          if (!tagMap[active.id]?.range)
            console.log('Error: no range', active, tagMap);
          inRange = isInRange(i, tagMap[active.id]?.range);
          elementMode = inRange ? active.element : elementMode;
        }

        const isActive = !viewOnly && active.index === i;
        return (
          <Box
            component="span"
            key={i}
            onDragEnter={
              isDragMode ? (ev) => handleDragEnter(ev, i) : undefined
            }
            onBlur={(ev) => ev.preventDefault()}
            onDragEnd={isDragMode ? handleDrop : undefined}
            onMouseDown={() => switchEditMode('text')}
            onMouseUp={() => captureActiveIndex(i, inRange)}
            style={elementStyle(
              colors,
              hoveredIndex === i,
              isDragged,
              elementMode
            )}
          >
            <EditableText
              className={classes.text}
              value={jliff.text}
              sx={getTextStyle(colors, tags)}
              setValue={isActive ? updateTextElement(i) : undefined}
              selection={isActive ? textSelection.current : null}
              addElement={addElement}
              onCompositionStart={() => (isCompositionActive.current = true)}
              onCompositionEnd={(ev) => {
                isCompositionActive.current = false;
                handleCompositionEnd(ev);
              }}
              saveSelection={saveTextSelection(i)}
              onRedo={handleRedo}
              onUndo={handleUndo}
            />
          </Box>
        );
      }
      // Token (placeholder)
      if (isToken(jliff)) {
        const isBr = jliff.subType === 'mx:br';
        const isLegacy = !('type' in jliff); // JLIFF don't have 'type' attribute
        // Ignore 'ph' with no 'equiv' unless it's a BR or legacy
        const dontShow = !isLegacy && !jliff.equiv && !isBr;
        if (dontShow) return null;

        const tagMode = getTagMode('ph', tagId);

        let elementMode: ElementModes = 'edit';
        if (active.id) {
          if (active.id === tagId) {
            elementMode = 'edit';
          } else {
            const inRange = isInRange(i, tagMap[active.id].range);
            elementMode = inRange ? active.element : elementMode;
          }
        }

        const text = jliff.equiv;
        const kind = isBr ? 'br' : jliff.disp || 'misc';
        const dragRange = tagMap[tagId].range;

        return (
          <Box
            key={i}
            component="span"
            className="token"
            style={elementStyle(
              colors,
              hoveredIndex === i,
              isDragged,
              elementMode
              // tags
            )}
            onDragStart={
              isDragMode ? (ev) => handleDragStart(ev, dragRange) : undefined
            }
            onDragEnter={
              isDragMode ? (ev) => handleDragEnter(ev, i) : undefined
            }
            onDragOver={(ev) => ev.preventDefault()}
            onDragEnd={isDragMode ? handleDrop : undefined}
            draggable={isDragMode}
            onMouseDown={(ev) => handleModes(ev, tagId)}
            onMouseUp={() => captureActiveIndex(i, true)}
            onClick={(ev) => handleModes(ev, tagId)}
          >
            <Token text={text} kind={kind} mode={tagMode} formats={tags} />
          </Box>
        );
      }
      // HTML tag
      if (isTag(jliff)) {
        // Starting/Closing html tags
        const kind = jliff.kind as 'sc' | 'ec';

        // Workaround: XAPIS bug - elements with no ID
        const tag = tagMap[tagId]?.type || 'span';
        const canMove = tagMap[tagId]?.canMove || true;

        const tagMode = getTagMode(tag, tagId);

        let elementMode: ElementModes = 'edit';
        if (active.id) {
          if (!tagMap[active.id]?.range)
            console.log('Error: no range', active, tagMap);
          const inRange = isInRange(i, tagMap[active.id]?.range);
          elementMode = inRange ? active.element : elementMode;
        }
        // IF in 'move' mode, drag the whole element
        const dragRange =
          elementMode === 'move' && canMove
            ? tagMap[tagId].range
            : { from: i, to: i };

        return (
          <Box
            key={i}
            component="span"
            className="tag"
            style={elementStyle(
              colors,
              hoveredIndex === i,
              isDragged,
              elementMode
            )}
            draggable={isDragMode}
            onMouseDown={(ev) => handleModes(ev, tagId)}
            onMouseUp={() => captureActiveIndex(i, true)}
            onClick={(ev) => handleModes(ev, tagId)}
            onDragEnter={
              isDragMode ? (ev) => handleDragEnter(ev, i) : undefined
            }
            onDragStart={
              isDragMode ? (ev) => handleDragStart(ev, dragRange) : undefined
            }
            onDragEnd={isDragMode ? handleDrop : undefined}
          >
            <Tag kind={kind} type={tag} mode={tagMode} />
          </Box>
        );
      }

      console.warn('Jliff Editor: No handler for', jliff);
      return null;
    });
    if (isSelected && isDragMode) {
      const index = elements.length;
      const lastEl = (
        <span
          className={classes.end}
          key={index}
          onDragOver={(ev) => ev.preventDefault()}
          onDragEnter={(ev) => handleDragEnter(ev, index)}
        />
      );
      elements.push(lastEl);
    }
    return elements;
  }

  const jliffElements = jliffToJsx(jliffArray);

  return (
    <Flex direction="column">
      {isSelected && (
        <TopBar
          isTarget={true}
          haveTags={haveTags(jliffArray)}
          addElement={addElement}
          removeElement={removeElement}
          activeTags={activeTags}
          showAllTags={showAllTags}
          setShowAllTags={setShowAllTags}
        />
      )}

      <Flex className={classes.translation}>
        <Flex
          direction={'column'}
          align="center"
          justify={'flex-start'}
          pl={5}
          gap={2}
        >
          <Status
            segment={segment}
            onSetToLive={updateSetToLive}
            // isSelected={isSelected}
            // status={segment.status_code}
          />
          <GlossaryViolations
            violations={glossaryViolations}
            isSelected={isSelected}
          />
        </Flex>
        <div
          className={classes.elements}
          dir={htmlDir || 'auto'}
          onDragOver={(ev) => ev.preventDefault()}
        >
          {jliffElements}
        </div>
        <SegmentIndicators status={segmentStatus} />
      </Flex>
      {isSelected && (
        <BottomBar
          segment={segment}
          onUndoAll={undoAllChanges}
          onRedo={handleRedo}
          onUndo={handleUndo}
          undoRedoStatus={undoRedoStatus}
          isEdited={!jliffsAreEqual(lastSaved, jliffArray)}
          deleteSegment={deleteSegment}
          applyTranslationHistory={(jliffs) => updateJliffArray(jliffs, 'add')}
          showAllTags={showAllTags}
        />
      )}
    </Flex>
  );
};
