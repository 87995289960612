import React, { useState } from 'react';
import { Button } from '@mantine/core';
import { useMantineTheme } from '@mantine/core';
import { CrownOutlineIcon } from 'images';
import { useDeploymentContext } from './DeploymentContextProvider';
import UpgradeToPlusModal from './UpgradeToPlusModal';

type Props = {
  shouldUpgradeToPlus: boolean;
};

export default function ChangeMethodButton({ shouldUpgradeToPlus }: Props) {
  const { colors } = useMantineTheme();
  const { setShowChangeMethodModal, setShowForm } = useDeploymentContext();

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  return (
    <>
      <UpgradeToPlusModal
        opened={showUpgradeModal}
        setOpened={setShowUpgradeModal}
      />
      {shouldUpgradeToPlus ? (
        <Button
          ta="left"
          bg="background2.9"
          leftSection={<CrownOutlineIcon color={colors.icon[6]} />}
          onClick={() => setShowUpgradeModal(true)}
        >
          Upgrade to Plus
        </Button>
      ) : (
        <Button
          variant="outline"
          onClick={() => {
            setShowChangeMethodModal(true);
            setShowForm(false);
          }}
        >
          Edit
        </Button>
      )}
    </>
  );
}
