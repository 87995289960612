import React from 'react';
import { Box, Button, Group, Stack, Text, Progress, Menu } from '@mantine/core';
import { FaCrown } from 'react-icons/fa';
import { Subscription, useRecommendedThresholdContext, xapisEnv } from 'store';
import {
  dashboardRC,
  getRemainingSubscribedWordsServed,
  getRemainingSubscriptionDays,
  isDesktopApp,
} from 'helpers';
import { useGLGOParams } from 'hooks';
import { LinkWrapper } from '../../Generic';

const Card = ({
  subscription,
  isExpired,
}: {
  subscription: Subscription;
  isExpired: boolean;
}) => {
  const projectId = useGLGOParams()?.projectId;
  const { glgoDashboardUrl } = xapisEnv.getHost;
  const isGLGODesktop = isDesktopApp();
  const { next_renewal_date = '', words_served = 0 } = subscription || {};

  const defaultFreeTrialDays = 30;
  const remainingDays = getRemainingSubscriptionDays(next_renewal_date);
  const daysUsed = defaultFreeTrialDays - remainingDays;
  const daysProgress = Math.round((daysUsed / defaultFreeTrialDays) * 100);

  const defaultThreshold = 500000;
  const remainingWordsServed = getRemainingSubscribedWordsServed(
    isExpired ? undefined : Number(words_served),
    defaultThreshold
  );
  const wordsServedProgress = isExpired
    ? 100
    : Math.round((Number(words_served) / defaultThreshold) * 100);

  const recommendedThreshold = useRecommendedThresholdContext().threshold;
  const goLink = `${isGLGODesktop ? glgoDashboardUrl : ''}/${projectId}/${dashboardRC.PAGE_ACCOUNT}/${dashboardRC.PAGE_BILLING_INFORMATION}/${dashboardRC.PAGE_MANAGE_SUBSCRIPTION}${recommendedThreshold > 0 ? `?threshold=${recommendedThreshold}` : ''}`;

  return (
    <Stack
      w="100%"
      p="0.75rem"
      bg="#FAF0E6"
      style={{ borderRadius: 10 }}
      gap="0.35rem"
    >
      <Group w="100%" align="center" justify="space-between" wrap="nowrap">
        <Text fw={700}>Free Trial</Text>
        <LinkWrapper
          to={isGLGODesktop ? undefined : goLink}
          onClick={
            isGLGODesktop
              ? () => window.DashboardAPI.openUrlExternal(goLink)
              : undefined
          }
        >
          <Button
            h="2rem"
            mih="fit-content"
            miw="fit-content"
            px="0.75rem"
            leftSection={<FaCrown color="#F6D94B" size={14} />}
            variant="light"
            fw={700}
            styles={{
              section: {
                marginRight: 3,
              },
            }}
          >
            GO
          </Button>
        </LinkWrapper>
      </Group>
      <Text fz="xs" fw={600}>
        {daysUsed} of 30 days
      </Text>
      <Progress h={10} value={daysProgress} />
      <Text fz="xs" fw={600}>
        {remainingWordsServed.toLocaleString('en-US')} Words Remaining
      </Text>
      <Progress h={10} value={wordsServedProgress} />
    </Stack>
  );
};

export default function FreeTrialUsageCard({
  isCompact,
  subscription,
  isExpired,
}: {
  isCompact: boolean;
  subscription: Subscription;
  isExpired: boolean;
}) {
  if (isCompact) {
    return (
      <Menu offset={{ mainAxis: 15 }} trigger="click" position="right-start">
        <Menu.Target>
          <Button
            px="0.65rem"
            py="0.25rem"
            miw="fit-content"
            mx="auto"
            variant="light"
            style={{ borderRadius: 6 }}
            styles={{
              inner: {
                height: 'fit-content',
              },
            }}
          >
            <FaCrown color="#F6D94B" size={18} />
          </Button>
        </Menu.Target>
        <Menu.Dropdown p={0}>
          <Card subscription={subscription} isExpired={isExpired} />
        </Menu.Dropdown>
      </Menu>
    );
  }

  return (
    <Box w="100%" px="0.65rem">
      <Card subscription={subscription} isExpired={isExpired} />
    </Box>
  );
}
