export const validDomainRegex = new RegExp(
  /^(?!-)[A-Za-z0-9-]+([-.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
);

const isValidDomain = (domain: string) => {
  if (!domain || typeof domain !== 'string') {
    return false;
  }
  return validDomainRegex.test(domain);
};

export default isValidDomain;
