import axios from 'axios';
import getAxiosUrlAndConfig from './getAxiosUrlAndConfig';

/**
 *
 * @param endpoint
 * @param params
 * @constructor
 */
export const DeleterPromise = (endpoint: string, params: object = {}) => {
  const { url, config } = getAxiosUrlAndConfig(endpoint, params);

  return axios.delete(url, config).catch((error) => {
    console.error('AXIOS_ERROR:', error);
    throw error;
  });
};

export default DeleterPromise;
