import { queryOptions, useSuspenseQueries } from '@tanstack/react-query';
import {
  calculateMonthlyPageViews,
  getRecommendedPlan,
  isSuccessStatus,
} from 'helpers';
import { getQuickQuote } from '../quickQuote';
import { querySubscriptions } from './shared/querySubscriptions';
import { Subscription } from '../PaySubscriptionStore';
import { fetchSkusPromise } from '../PaySkuStore';
import { SkuWCreatedAndLastMod } from '../types';

type QuickQuoteData = {
  spider_job: { average_words_per_page: number };
  traffic: { results: { visits: string; pages_per_visit: string } };
};

export const queryRecommendedPlan = (
  url: string,
  payKey: string,
  threshold?: string
) =>
  queryOptions({
    queryKey: ['recommendedPlan', url, payKey, threshold],
    queryFn: async () => {
      const skusResponse = await fetchSkusPromise(payKey);
      if (
        !skusResponse ||
        !isSuccessStatus(skusResponse.status) ||
        !skusResponse.data ||
        !skusResponse.data.skus
      ) {
        throw new Error('Failed to get skus data');
      }

      const thresholdNumber = Number(threshold);
      if (!!threshold && thresholdNumber) {
        const idealPlan = skusResponse.data.skus.find(
          (s: SkuWCreatedAndLastMod) => s.threshold_value === thresholdNumber
        );

        if (idealPlan) {
          return {
            name: idealPlan.name,
            threshold_value: thresholdNumber,
            skus: skusResponse.data.skus,
          };
        } else {
          const recommendedPlan = getRecommendedPlan(
            thresholdNumber,
            skusResponse.data.skus
          );
          return {
            name: recommendedPlan.name,
            threshold_value: recommendedPlan.threshold_value,
            skus: skusResponse.data.skus,
          };
        }
      }

      const quickQuoteResponse = await getQuickQuote(url);
      if (
        !quickQuoteResponse ||
        !isSuccessStatus(quickQuoteResponse.status) ||
        !quickQuoteResponse.data
      ) {
        throw new Error('Failed to get quick quote data');
      }

      const { spider_job, traffic } = quickQuoteResponse.data as QuickQuoteData;
      const average_words_per_page = spider_job?.average_words_per_page || 0;
      const results = traffic?.results || {
        visits: '0',
        pages_per_visit: '0',
      };

      const monthlyViews = calculateMonthlyPageViews(
        results.visits,
        results.pages_per_visit
      );

      const estimatedWordsServed = Math.round(
        average_words_per_page * monthlyViews * 0.1
      );

      const recommendedPlan = getRecommendedPlan(
        estimatedWordsServed,
        skusResponse.data.skus
      );

      return {
        name: recommendedPlan.name,
        threshold_value: recommendedPlan.threshold_value,
        skus: skusResponse.data.skus,
      };
    },
  });

export const useSelectPlanQueries = (payKey: string, projectKey: string) => {
  const queries = [querySubscriptions(payKey, projectKey)] as const;
  const results = useSuspenseQueries({ queries });

  return {
    subscriptions: results[0].data as Subscription[],
  };
};
