import { Params } from 'react-router-dom';
import { decodeId, decodeProjectId, filterTranslationKeys } from 'helpers';
import { queryClient } from '../../queries/queryClient';
import { projectQuery } from '../../queries/projectQuery';
import { queryAccountMTUsage } from '../../queries/accountQueries';

export type AccountMTLoaderResponse = {
  tKeys: string;
  timeSliceFrom: string;
  timeSliceTo: string | undefined;
};

export async function accountMTUsageLoader({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) {
  const { projectId } = params;
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }
  const projectKey = decodeProjectId(projectId);

  const projectData = await queryClient.ensureQueryData(
    projectQuery(projectKey)
  );

  const { translations = [] } = projectData.project;

  const url = new URL(request.url);
  const timeSliceFrom = url.searchParams.get('from') || '1 month';
  const timeSliceTo = url.searchParams.get('to') || undefined;

  const translationKeysOffProject = filterTranslationKeys(translations);
  const keysInUrl = url.searchParams.getAll('t');
  const decodedkeysInUrl = keysInUrl.map((key) => decodeId(key));
  const concatenatedKey =
    decodedkeysInUrl.length > 0
      ? decodedkeysInUrl.join(',')
      : translationKeysOffProject.join(',');

  await queryClient.ensureQueryData(
    queryAccountMTUsage(concatenatedKey, timeSliceFrom, timeSliceTo)
  );

  return {
    tKeys: concatenatedKey,
    timeSliceFrom,
    timeSliceTo,
  } as AccountMTLoaderResponse;
}
export default accountMTUsageLoader;
