import React from 'react';
import { Button } from '@mantine/core';
import { dashboardRC, isDesktopApp, NOOP } from 'helpers';
import { useGLGOParams } from 'hooks';
import { useRecommendedThresholdContext, xapisEnv } from 'store';
import { LinkWrapper } from '../../Generic';

type Props = {
  text?: string;
};

const SubscribeButton = ({ text = 'Subscribe' }: Props) => {
  const projectId = useGLGOParams()?.projectId;
  const threshold = useRecommendedThresholdContext().threshold;
  const isGLGODesktop = isDesktopApp();
  const { glgoDashboardUrl } = xapisEnv.getHost;

  const link = `${isGLGODesktop ? glgoDashboardUrl : ''}/${projectId}/${dashboardRC.PAGE_ACCOUNT}/billing-information/manage-subscription${threshold > 0 ? `?threshold=${threshold}` : ''}`;

  const buttonAction = isGLGODesktop
    ? () => window.DashboardAPI.openUrlExternal(link)
    : NOOP;

  return (
    <LinkWrapper isGLGODesktop={isGLGODesktop} to={link}>
      <Button variant="light" onClick={buttonAction}>
        {text}
      </Button>
    </LinkWrapper>
  );
};

export default SubscribeButton;
