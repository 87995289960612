import { useMantineColorScheme, useMantineTheme } from '@mantine/core';

export function useEditorColors() {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const colors = theme.colors;
  const { blue, gray, dark } = colors;

  //             0           1          2          3          4          5          6          7          8          9
  // blue:   ['#F5FAFF', '#E9F1FD', '#D0DBF4', '#CBDDF9', '#A6D4FF', '#90A0D3', '#5672BC', '#0071DB', '#163E9D', '#071D49']
  // gray:   ['#FAFBFF', '#EFF4FB', '#E4E7FA', '#E6E6E8', '#D6D9EC', '#B9C8E3', '#A0A7D6', '#8F9DBA', '#637392', '#252540']
  // dark:   ['#C1C2C5', '#A6A7AB', '#909296', '#5c5f66', '#373A40', '#2C2E33', '#25262b', '#1A1B1E', '#141517', '#101113']
  // green:  ['#ebfbee', '#d3f9d8', '#b2f2bb', '#8ce99a', '#69db7c', '#51cf66', '#40c057', '#37b24d', '#2f9e44', '#2b8a3e']
  // orange: ['#fff4e6', '#ffe8cc', '#ffd8a8', '#ffc078', '#ffa94d', '#ff922b', '#fd7e14', '#f76707', '#e8590c', '#d9480f']
  // yellow: ['#fff9db', '#fff3bf', '#ffec99', '#ffe066', '#ffd43b', '#fcc419', '#fab005', '#f59f00', '#f08c00', '#e67700']

  type EditorColors = typeof lightTheme;

  const globalColors = {
    light: {
      clickable: blue[7],
    },
    dark: {},
  };

  const lightTheme = {
    dashboard: {
      text: blue[8],
      viewOnly: theme.white,
      count: blue[7],
      border: blue[3],
    },
    segmentHeader: {
      border: gray[2],
      text: blue[8],
      targetBackground: blue[1],
      targetText: gray[8],
    },
    searchBar: {
      placeholder: blue[6],
    },
    filterMenu: {
      button: blue[1],
    },
    filterBadge: {
      text: blue[7],
      background: blue[1],
    },
    datePicker: {
      text: blue[9],
      placeholder: blue[6],
      border: blue[2],
    },
    historyActionIcon: {
      background: blue[1],
    },
    tooltip: {
      light: {
        background: blue[0],
        title: blue[8],
        text: blue[9],
        icon: blue[8],
        close: gray[7],
        activeIcon: blue[7],
        border: blue[5],
        red: '#E50B0B',
      },
      dark: {
        text: theme.white,
        background: blue[9],
        border: blue[9],
      },
    },
    notification: {
      success: theme.colors.green[7],
      error: theme.colors.red[7],
      warning: theme.colors.orange[7],
      info: blue[7],
    },
    jliffEditor: {
      textColor: blue[9],
      anchor: blue[7],
      mark: theme.colors.yellow[3],
      background: theme.white,
      privateColor: gray[8],
      border: {
        selected: blue[3],
        unsaved: gray[4],
        failSave: '#D93025',
        saved: '#40C057',
        none: gray[1],
      },
      tags: {
        fill: { show: gray[3], focus: blue[8] },
        color: { show: blue[8], focus: theme.white },
      },
    },
    tokens: {
      background: { hide: 'transparent', show: gray[3], focus: blue[8] },
      textColor: { hide: 'inherit', show: blue[8], focus: theme.white },
    },
    insertMore: {
      button: {
        text: blue[7],
        background: theme.white,
      },
      activeButton: {
        text: blue[7],
        background: '#DFECFF',
        border: blue[7],
      },
      background: theme.white,
      border: gray[2],
      list: {
        hoverText: blue[9],
        background: theme.white,
        selectedBackground: gray[1],
      },
    },
    indicators: {
      gray: gray[8],
      fill: theme.white,
      success: '#40C057',
      error: '#D93025',
    },
    sortSegments: {
      listBackground: theme.white,
      itemBorder: gray[1],
      hoverBackground: gray[1],
      iconColor: blue[7],
    },
    status: {
      Live: { color: '#14CD5E', border: '#008535' },
      'In Progress': { color: '#8AC7FF', border: '#0071DB' },
      'No Translate': { color: '#FF7373', border: '#9B0000' },
      'In Client Review': { color: '#FFD600', border: '#D05800' },
      Untranslated: { color: '#EAF0FF', border: '#637392' },
      Other: { color: '#D398E7', border: '#5B096F' },
      background: gray[0],
      unsaved: gray[5],
      text: globalColors.light.clickable,
      border: gray[2],
    },
    glossary: {
      icon: blue[8],
      violations: '#ba2929',
    },
    tagSwitch: {
      off: blue[3],
      on: blue[7],
      disabled: gray[7],
    },
    jliffActions: {
      background: blue[3],
      disabled: gray[7],
      active: blue[8],
      quickActions: {
        color: blue[7],
        background: theme.white,
        border: blue[3],
        active: gray[3],
        hover: gray[1],
      },
      popover: {
        border: blue[3],
        arrow: blue[6],
      },
      tooltip: {
        text: theme.white,
        background: blue[9],
      },
    },
    history: {
      listBackground: gray[0],
      listBorder: gray[4],
      title: blue[7],
      label: blue[7],
      source: blue[7],
      selectedBackground: theme.white,
      selectedBorder: gray[1],
    },
    wait: {
      textColor: blue[7],
    },
  } as const;

  const darkTheme: EditorColors = {
    ...lightTheme,
    jliffEditor: {
      textColor: dark[0],
      anchor: blue[7],
      mark: blue[7],
      background: dark[6],
      privateColor: gray[2],
      border: {
        selected: blue[4],
        unsaved: gray[4],
        failSave: '#D93025',
        saved: '#40C057',
        none: gray[1],
      },
      tags: {
        fill: { show: dark[3], focus: dark[0] },
        color: { show: theme.white, focus: blue[7] },
      },
    },
    tokens: {
      background: { hide: 'transparent', show: dark[3], focus: dark[0] },
      textColor: { hide: 'inherit', show: theme.white, focus: blue[7] },
    },
  } as const;

  return colorScheme === 'dark' ? darkTheme : lightTheme;
}

export type EditorColors = ReturnType<typeof useEditorColors>;
