import { create } from 'zustand';
import fileSaver from 'helpers/utils/fileSaver';
import { defaultAxiosResponse, FetcherPromise } from './xapis-wrappers';
import { AxiosError, AxiosResponse } from 'axios';
import failure from 'helpers/failure';
import useProjectsStore from './ProjectsStore';
import usePayKeyStore from './PayKeyStore';

type InvoiceFileResponse = {
  createdTime: string;
  fileName: string;
  id: string;
  liveMode: boolean;
  purpose: string;
  size: number;
  title: string;
  type: string;
  url: string;
};
type InvoicePDFResponse = {
  id: string;
  liveMode: boolean;
  url: string;
};
type ItemResponse = {
  amount: number;
  availableToRefundAmount: number;
  discount: {
    amountOff: number;
    quantity: number;
  };
  duties: {
    amount: number;
  };
  fees: {
    amount: number;
    taxAmount: number;
  };
  id: string;
  importerTax: {
    amount: number;
  };
  productDetails: {
    countryOfOrigin: string;
    description: string;
    eccn: string;
    id: string;
    name: string;
    taxCode: string;
  };
  quantity: number;
  shipping: {
    amount: number;
    taxAmount: number;
  };
  skuId: string;
  state: string;
  stateTransitions: {
    created: string;
    fulfilled: string;
  };
  subscriptionInfo: {
    autoRenewal: boolean;
    billingAgreementId: string;
    freeTrial: boolean;
    subscriptionId: string;
  };
  tax: {
    amount: number;
    rate: number;
  };
};
type CapturesResponse = {
  amount: number;
  createdTime: string;
  id: string;
  state: string;
};
type ChargesResponse = {
  amount: number;
  captured: boolean;
  captures: CapturesResponse[];
  createdTime: string;
  currency: string;
  id: string;
  refunded: boolean;
  sourceId: string;
  state: string;
  type: string;
};
type SourcesResponse = {
  amount: number;
  creditCard: {
    brand: string;
    expirationMonth: number;
    expirationYear: number;
    lastFourDigits: string;
  };
  id: string;
  owner: {
    address: {
      city: string;
      country: string;
      line1: string;
      postalCode: string;
      state: string;
    };
    email: string;
    firstName: string;
    lastName: string;
  };
  type: string;
};

export type OrderResponse = {
  availableToRefundAmount: number;
  billTo: {
    address: {
      city: string;
      country: string;
      line1: string;
      postalCode: string;
      state: string;
    };
    email: string;
    name: string;
    phone: string;
  };
  browserIp: string;
  cancelledAmount: number;
  capturedAmount: number;
  checkoutId: string;
  createdTime: string;
  currency: string;
  customerId: string;
  customerType: string;
  email: string;
  fraudStateTransitions: {
    passed: string;
  };
  id: string;
  invoiceFiles: InvoiceFileResponse[];
  invoicePDFs: InvoicePDFResponse[];
  items: ItemResponse[];
  liveMode: boolean;
  locale: string;
  metadata: {
    environment: string;
    pay_key: string;
    project_key: string;
  };
  payment: {
    charges: ChargesResponse[];
    session: {
      amountContributed: number;
      amountRemainingToBeContributed: number;
      clientSecret: string;
      id: string;
      state: string;
    };
    sources: SourcesResponse[];
  };
  requestToBeForgotten: boolean;
  sellingEntity: {
    id: string;
    name: string;
  };
  state: string;
  stateTransitions: {
    accepted: string;
    complete: string;
    fulfilled: string;
  };
  subtotal: number;
  totalAmount: number;
  totalDiscount: number;
  totalDuty: number;
  totalFees: number;
  totalImporterTax: number;
  totalShipping: number;
  totalTax: number;
  updatedTime: string;
};

type InvoiceFile = {
  createdTime: string;
  fileName: string;
  id: string;
  liveMode: boolean;
  purpose: string;
  size: number;
  title: string;
  type: string;
  url: string;
};

const defaultInvoiceFile = {
  createdTime: '',
  fileName: '',
  id: '',
  liveMode: false,
  purpose: '',
  size: 0,
  title: '',
  type: '',
  url: '',
};

type Item = {
  amount: number;
  availableToRefundAmount: number;
  discount: {
    amountOff: number;
    quantity: number;
  };
  duties: {
    amount: number;
  };
  fees: {
    amount: number;
    taxAmount: number;
  };
  id: string;
  importerTax: {
    amount: number;
  };
  productDetails: {
    countryOfOrigin: string;
    description: string;
    eccn: string;
    id: string;
    name: string;
    taxCode: string;
  };
  quantity: number;
  shipping: {
    amount: number;
    taxAmount: number;
  };
  skuId: string;
  state: string;
  stateTransitions: {
    created: string;
    fulfilled: string;
  };
  subscriptionInfo: {
    autoRenewal: boolean;
    billingAgreementId: string;
    freeTrial: boolean;
    subscriptionId: string;
  };
  tax: {
    amount: number;
    rate: number;
  };
};

const defaultItem = {
  amount: 0,
  availableToRefundAmount: 0,
  discount: {
    amountOff: 0,
    quantity: 0,
  },
  duties: {
    amount: 0,
  },
  fees: {
    amount: 0,
    taxAmount: 0,
  },
  id: '',
  importerTax: {
    amount: 0,
  },
  productDetails: {
    countryOfOrigin: '',
    description: '',
    eccn: '',
    id: '',
    name: '',
    taxCode: '',
  },
  quantity: 0,
  shipping: {
    amount: 0,
    taxAmount: 0,
  },
  skuId: '',
  state: '',
  stateTransitions: {
    created: '',
    fulfilled: '',
  },
  subscriptionInfo: {
    autoRenewal: false,
    billingAgreementId: '',
    freeTrial: false,
    subscriptionId: '',
  },
  tax: {
    amount: 0,
    rate: 0,
  },
};

export type BillingHistory = {
  id: string;
  updatedTime: string;
  item: Item;
  capturedAmount: number;
  invoiceFile: InvoiceFile;
  invoiceFiles?: InvoiceFile[];
};

const parseBillingHistory = (orders: OrderResponse[]): BillingHistory[] =>
  orders.map((order) => {
    const {
      capturedAmount = 0,
      id = '',
      invoiceFiles = [],
      items = [],
      updatedTime = '',
    } = order;

    const [firstInvoiceFile = defaultInvoiceFile] = invoiceFiles;
    const [firstItem = defaultItem] = items;

    return {
      id,
      updatedTime,
      item: firstItem,
      capturedAmount,
      invoiceFile: firstInvoiceFile,
    };
  });

export type PayInvoiceStore = {
  billingHistory: BillingHistory[];
  loading: boolean;
  fetchBillingHistory: () => Promise<AxiosResponse>;
  downloadInvoice: (
    invoiceFileId: string,
    projectKey: string
  ) => Promise<AxiosResponse>;
};

export const usePayInvoiceStore = create<PayInvoiceStore>()((set) => ({
  billingHistory: [],
  loading: false,
  fetchBillingHistory: () => {
    const { payKey = '' } = usePayKeyStore.getState();
    const { activeProject: { projectKey = '' } = {} } =
      useProjectsStore.getState();

    set({ loading: true });
    return FetcherPromise(`Pay/Invoice/${payKey}/${projectKey}`)
      .then((response: AxiosResponse) => {
        const {
          data: { orders = [] },
        }: {
          data: {
            orders: OrderResponse[];
          };
        } = response || {};

        const parsedBillingHistory = parseBillingHistory(orders);
        set({ billingHistory: parsedBillingHistory });

        return response;
      })
      .catch((error: AxiosError) => {
        const { response = defaultAxiosResponse } = error;

        set({ billingHistory: [] });
        failure(error, 'Unable to get billing history at this time.');

        return response;
      })
      .finally(() => set({ loading: false }));
  },
  downloadInvoice: (invoiceFileId: string, projectKey: string) => {
    const { payKey = '' } = usePayKeyStore.getState();

    set({ loading: true });
    return FetcherPromise(
      `Pay/Invoice/${payKey}/${projectKey}/${invoiceFileId}`,
      {},
      'blob'
    )
      .then((response: AxiosResponse) => {
        const { data, headers = {} } = response || {};
        if (data) {
          // Default fileName, will instead use the fileName generated from XPay if it exists (ex. 'vat-invoice5960978751047971851.pdf')
          const fileName = 'Invoice.pdf';
          const blob = new Blob([data], { type: 'application/pdf' });
          fileSaver(fileName, headers as Record<string, string>, blob);
        }
        return response;
      })
      .catch((error: AxiosError) => {
        const { response = defaultAxiosResponse } = error;
        failure(error, 'Something went wrong with downloading the invoice.');
        return response;
      })
      .finally(() => set({ loading: false }));
  },
}));

export default usePayInvoiceStore;

export const fetchBillingHistoryPromise = (
  payKey: string,
  projectKey: string
) => FetcherPromise(`Pay/Invoice/${payKey}/${projectKey}`);
