export const isThresholdEndpointValid = (
  lastTimeSliceWithData: string,
  numOfDaysInMonth: number,
  isYearly = false
) => {
  const lastValue = Number(lastTimeSliceWithData.split('-')[isYearly ? 1 : 2]);
  return isYearly ? lastValue < 8 : lastValue < numOfDaysInMonth - 5;
};

export const getYAxisMaxValueAndWidth = <
  T extends { count: number | null | undefined }[],
>(
  timesliceData: T,
  increment?: number
) => {
  if (!(timesliceData.length > 0 && 'count' in timesliceData[0])) {
    return {
      yAxisMaxValue: 0,
      yAxisWidth: 0,
    };
  }

  const maxValue = Math.max(...timesliceData.map(({ count }) => count || 0));
  const maxValueCharArray = maxValue.toString().split('');

  const maxValueWithTrailingZeros: string = maxValueCharArray
    .map((char, index) => {
      if (index === 0) {
        return String(Number(char) + (increment || 1));
      }
      if (char !== ',') return '0';
      return '';
    })
    .join('');
  const maxValueNumber = Number(maxValueWithTrailingZeros);

  return {
    yAxisMaxValue: maxValueNumber,
    yAxisWidth: maxValueNumber.toString().split('').length * 20,
  };
};
