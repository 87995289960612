import { escapeRegExp } from 'helpers';

export const createRule = (type: string, uri: string) => {
  uri = escapeRegExp(uri);

  // special case wherein a child rule is applied via parent with no end slash (e.g. "/account")
  // this means that the "children" are all just query params, as opposed to actual separate pages
  // there occasionally are "duplicate" parents that actually do have real children (e.g. "/account/" or "account/")
  // in this case, to properly isolate our rule we need to match all children that do not contain a slash after the uri
  // pattern to do so is either ^${uri}[^/] or ^${uri}[^/].+
  const isQueryParamParent =
    uri[0] === '/' &&
    uri.at(-1) !== '/' &&
    ['parentAndChildren', 'childrenOnly'].includes(type);

  switch (type) {
    case 'parentAndChildren':
      return isQueryParamParent ? `^${uri}[^/]*` : `^${uri}`;
    case 'self':
      return `^${uri.at(-1) !== '/' ? `${uri}/` : uri}?$`;
    case 'childrenOnly':
      return isQueryParamParent ? `^${uri}[^/].+` : `^${uri}.+`;
    default:
      break;
  }
};

export default createRule;
