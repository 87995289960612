import { ReactElement } from 'react';
import { Flex, Text, Title } from '@mantine/core';
import { useEditorColors } from 'helpers';
import {
  CurrencyIcon,
  CalculatorIcon,
  CalendarIcon,
  TimeIcon,
  CustomIcon,
  TimeZoneIcon,
  LineBreak,
} from '../../icons/TokenIcons';
import { TagModes } from '../../types/editor';
import { Tip } from '../Generic/Tip';
import { VscSymbolMisc } from 'react-icons/vsc';

import classes from './Token.module.css';
import { capitilize } from '../../functions/segmentsFunctions';
import { getTextStyle } from '../Jliff/Jliff.style';

type MarkersProps = {
  kind?: string;
  text?: string;
  formats?: string[];
  mode?: TagModes;
};

export const Token = ({
  kind = 'misc',
  text,
  mode = 'hide',
  formats = [],
}: MarkersProps) => {
  const formatsColors = useEditorColors().jliffEditor;
  // const colors = useEditorColors().tokens;
  const iconClassName = classes[`${mode}Icon`];
  const textClassName = classes[`${mode}Text`];

  const isFocused = mode === 'focus';

  const tokenIcons: { [key: string]: ReactElement } = {
    br: <LineBreak />,
    currency: <CurrencyIcon />,
    time: <TimeIcon />,
    timezone: <TimeZoneIcon />,
    'date-time': <CalendarIcon />,
    number: <CalculatorIcon />,
    'custom-token': <CustomIcon />,
    misc: <VscSymbolMisc />,
  };

  const tokenLabels: { [key: string]: string } = {
    br: 'Line Break',
    'custom-token': 'Custom',
  };

  const Info = ({ kind }: { kind: string }) => {
    const colors = useEditorColors().tooltip.light;

    return (
      <>
        <Flex gap={10} align="center">
          {tokenIcons[kind]}
          <Title c={colors.title} order={6}>
            {tokenLabels[kind] || capitilize(kind)} Token
          </Title>
        </Flex>
        <Text lh={1.2} c={colors.text} mt={5} fw="normal">
          This is a placeholder
          <br /> which cannot be edited.
        </Text>
      </>
    );
  };

  return (
    <>
      <Tip label={<Info kind={kind} />} disabled={isFocused} variant="light">
        {text ? (
          <Text span className={`${classes.text} ${textClassName}`}>
            {text}
          </Text>
        ) : (
          <Text
            span
            className={`${classes.icon} ${iconClassName}`}
            style={{ ...getTextStyle(formatsColors, formats) }}
          >
            {tokenIcons[kind]}
          </Text>
        )}
      </Tip>
      {kind === 'br' && <br />}
    </>
  );
};
