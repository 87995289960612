import React, { useEffect } from 'react';
import { Box, Button, Checkbox, Flex, Title } from '@mantine/core';
import { useSegmentEditorContext } from '../../context/SegmentEditorProvider';
import { DatePickerInput } from '@mantine/dates';

import {
  FilterBy,
  FilterOption,
  Filters,
  filters,
  useDashboardActions,
  useFilters,
  useFiltersBuffer,
  useFiltersOptions,
} from '../../store/DashboardStore';

import classes from './FilterSegments.module.css';

const filtersLabels: { [filter in Filters]: string } = {
  status: 'Status',
  source: 'Translation Source',
  type: 'Type',
};

type Props = {
  filterCount: (filters: FilterBy) => number;
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ContextFilters = ({ filterCount, setShowFilters }: Props) => {
  const { contextAPI } = useSegmentEditorContext();
  const { onResetFilter } = contextAPI || {};

  const filtersOptions = useFiltersOptions();
  const filtersBuffer = useFiltersBuffer();
  const { setFilterBy, setFiltersBuffer, handleFilterSelection } =
    useDashboardActions();
  const filterBy = useFilters();
  const allAutomaticTranslations = !filtersOptions.source?.find(
    (o) => o.label === 'Human'
  );
  const disableDateFilter =
    allAutomaticTranslations ||
    (!!filtersBuffer.source.length && !filtersBuffer.source.includes('Human'));

  function handleDateSelection(dates: [Date | null, Date | null]) {
    setFiltersBuffer({ ...filtersBuffer, date: dates });
  }

  const handleApplyFilters = () => {
    setFilterBy({ ...filtersBuffer });
    setShowFilters(false);
  };

  const handleClearFilters = () => {
    // setSelected('');
    setFiltersBuffer({ source: [], status: [], type: [], date: [null, null] });
    if (typeof onResetFilter === 'function') onResetFilter();
  };

  useEffect(() => {
    // Reset filtersBuffer when filters are reset (e.g. when blocks are changed)
    if (JSON.stringify(filterBy) === JSON.stringify(filtersBuffer)) return;
    setFiltersBuffer({ ...filterBy });
  }, [filterBy]);

  const segmentsCount = filterCount(filtersBuffer);
  const countLabel = segmentsCount > 99 ? '99+' : segmentsCount;

  const FilterName = ({ label }: { label: string }) => (
    <Title order={6} pt={8}>
      {label}
    </Title>
  );

  return (
    <>
      <Box style={{ borderRadius: 15 }} px={20} py={12} bg="#FFFFFF">
        {filters.map((filter) => {
          const options: FilterOption[] = filtersOptions[filter];
          return (
            <Box key={filter}>
              <FilterName label={filtersLabels[filter] || filter} />
              {options.map(({ value, label, count }) => {
                const isChecked =
                  filtersBuffer[filter as Filters]?.includes(value);
                return (
                  <Checkbox
                    key={value}
                    c="blue.9"
                    label={`${label} (${count})`}
                    checked={isChecked}
                    disabled={options.length === 1}
                    onChange={() =>
                      handleFilterSelection(filter as Filters, value)
                    }
                  />
                );
              })}
            </Box>
          );
        })}
        <FilterName label="Date" />
        <DatePickerInput
          classNames={{
            label: classes.datePickerLabel,
            input: classes.datePickerInput,
          }}
          disabled={disableDateFilter}
          type="range"
          size="xs"
          label="Filter by translation date(s)"
          // placeholder="Pick dates range"
          allowSingleDateInRange
          clearable
          value={filtersBuffer.date}
          onChange={handleDateSelection}
          maxDate={new Date()}
          popoverProps={{ position: 'top' }}
        />
      </Box>
      <Flex w="100%" p={10} gap={10}>
        <Button variant="outline" onClick={handleClearFilters}>
          Clear All
        </Button>
        <Button
          onClick={handleApplyFilters}
          disabled={!segmentsCount}
          styles={{
            label: {
              flexGrow: 1,
              width: '100%',
              gap: 5,
              justifyContent: 'space-between',
            },
          }}
          style={{
            flexGrow: 1,
          }}
        >
          <Box>Apply</Box>
          <Box>{`(${segmentsCount})`}</Box>
        </Button>
      </Flex>
    </>
  );
};
