import React, { useCallback } from 'react';
import { Flex, Image, Text, Stepper, Box } from '@mantine/core';
import nextGLGOIcon from './images/next-steps-icon.svg';
import GLGOIcon from './images/steps-icon.svg';
import { useSetupStepsContext } from './ProjectSetupProvider';
import PersonOne from './images/get-started-person-one.svg';
import PersonTwo from './images/get-started-person-two.svg';
import { useMediaQuery } from '@mantine/hooks';
import classes from './StepsTimeline.module.css';

export const StepsTimeline = () => {
  const isMobileSize = useMediaQuery('(max-width: 575px');
  const isDesktopSize = useMediaQuery('(min-width: 1200px)');
  const { activeStep } = useSetupStepsContext();

  const getStepperIcon = useCallback(
    (label: string, type: string, index: number, isStepActive: boolean) => {
      const isNext = type === 'next';
      const icon = isNext ? nextGLGOIcon : GLGOIcon;

      return (
        <Flex
          w="max-content"
          justify="center"
          wrap="wrap"
          pt={{ base: 0, xs: 35 }}
        >
          <Flex w="100%" justify="center" align="center">
            <Image
              src={icon}
              width={isMobileSize ? 30 : 50}
              height={isMobileSize ? 36 : 60}
              opacity={isNext ? 0.3 : 1}
              alt={`Project Setup: step ${index + 1} icon`}
            />
            <Text
              pos="absolute"
              fw={700}
              fz={{ base: '0.8rem', xs: '0.85rem' }}
              c="font1.0"
            >
              {index + 1}
            </Text>
          </Flex>
          <Text
            w={{ base: '4rem', xs: '7rem' }}
            mt={{ base: '0.5rem', lg: '1rem' }}
            lh={{ base: '1rem', sm: '1.25rem' }}
            fz={{ base: '0.8rem', xs: '0.95rem' }}
            ta="center"
            c={isStepActive ? 'text.8' : 'font1.0'}
            opacity={isNext ? 0.6 : 1}
            fw={700}
          >
            {label}
          </Text>
        </Flex>
      );
    },
    [isMobileSize]
  );

  const getSeparatorClassName = () => {
    switch (true) {
      case activeStep === 1:
        return classes.activeFirstSeparator;
      case activeStep % 1 !== 0 && activeStep < 1:
        return classes.firstSeparator;
      case activeStep % 1 !== 0 && activeStep > 1:
        return classes.secondSeparator;
      case activeStep >= 2:
        return classes.activeSeparator;
      default:
        return '';
    }
  };

  return (
    <Box
      w="100%"
      px="2rem"
      bg={{ base: 'background.1', lg: 'transparent' }}
      pos={
        isDesktopSize
          ? activeStep === 0
            ? 'relative'
            : 'absolute'
          : 'relative'
      }
      top={isDesktopSize ? (activeStep === 0 ? 0 : '4rem') : 0}
      style={{ zIndex: 998 }}
    >
      <Flex
        w="100%"
        h={{ base: '5rem', sm: '8rem', lg: 'auto' }}
        align="center"
        justify="center"
        pos="relative"
      >
        {isDesktopSize && activeStep === 0 && (
          <Flex
            pos="absolute"
            w="100%"
            maw="21.5rem"
            top="-7.75rem"
            justify="space-between"
          >
            <Image width={100} src={PersonOne} alt="person taking steps" />
            <Image
              width={100}
              src={PersonTwo}
              alt="another person taking steps"
            />
          </Flex>
        )}
        <Stepper
          w="100%"
          maw={{ base: '100%', xs: '25rem', sm: '36rem' }}
          color="text.8"
          active={activeStep}
          classNames={{
            stepIcon: classes.stepIcon,
            separator: `${classes.defaultSeparator} ${getSeparatorClassName()}`,
          }}
        >
          {['Create Project', 'Project Setup', 'Add Snippet'].map(
            (label, index) => {
              const isFirstStep = label === 'Create Project' && activeStep < 1;
              const isSecondStep =
                label === 'Project Setup' && activeStep >= 1 && activeStep < 2;
              const isThirdStep = label === 'Add Snippet' && activeStep >= 2;
              const isStepActive = isFirstStep || isSecondStep || isThirdStep;

              return (
                <Stepper.Step
                  key={label}
                  icon={getStepperIcon(label, 'next', index, isStepActive)}
                  progressIcon={getStepperIcon(
                    label,
                    'progress',
                    index,
                    isStepActive
                  )}
                  completedIcon={getStepperIcon(
                    label,
                    'completed',
                    index,
                    isStepActive
                  )}
                  disabled
                />
              );
            }
          )}
        </Stepper>
      </Flex>
    </Box>
  );
};

export default StepsTimeline;
