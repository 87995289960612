/**
 * Returns a set with all the elements in any of the Sets
 *
 * Example:
 *  const set1 = new Set(['bat', 'cat', 'sat']);
 *  const set2 = new Set(['bat', 'mower']);
 *  const set3 = new Set(['hat', 'mower']);
 *
 *  createUnionSet(set1, set2, set3) = Set(['bat', 'cat', 'sat', 'mower', 'hat'])
 *
 * @param set1
 * @param otherSets
 */
const createUnionSet = <T>(set1: Set<T>, ...otherSets: Set<T>[]): Set<T> => {
  const unionSet = new Set<T>([...set1]);
  otherSets.forEach((s) => s.forEach((entry) => unionSet.add(entry)));
  return unionSet;
};

export default createUnionSet;
