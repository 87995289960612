import { useEffect } from 'react';
import { useSetupStepsContext } from '../ProjectSetupProvider';
import { LanguageCodesStore, useLanguageCodesStore } from 'store';

export default function useSelectedLanguages() {
  const { fetchLanguageCodes } = useLanguageCodesStore(
    (state: LanguageCodesStore) => state
  );
  const { setIsNextButtonDisabled, selectedTargetLangCodes } =
    useSetupStepsContext();

  useEffect(() => {
    if (selectedTargetLangCodes.length > 0) {
      setIsNextButtonDisabled(false);
    } else {
      setIsNextButtonDisabled(true);
    }
  }, [selectedTargetLangCodes.length, setIsNextButtonDisabled]);

  useEffect(() => {
    fetchLanguageCodes();
  }, [fetchLanguageCodes]);
}
