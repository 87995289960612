import { useEffect, useState, useRef, useMemo } from 'react';
import { useCurrentProjectData } from 'store';
import { BsMenuButtonWideFill } from 'react-icons/bs';
import { Button, Tooltip } from '@mantine/core';
import { hexToAscii } from 'helpers';

const SELECTOR_STYLE_ID = 'glwebSelectorCustomCss';

export const LangSelectorPreview = ({
  css,
  showButton = true,
}: {
  css: string;
  showButton?: boolean;
}) => {
  const { translations, yyTranslation } = useCurrentProjectData();
  const deploymentValues = useMemo(() => {
    const getLabels = () => {
      try {
        return JSON.parse(hexToAscii(yyTranslation?.translation_config ?? ''))
          .translation_rules?.language_selector_labels as Record<
          string,
          string
        >[];
      } catch {
        return [] as Record<string, string>[];
      }
    };

    const labels = getLabels();
    const deploymentValues = translations.map((t) => t.deployment_value);

    const deployedLabels =
      labels?.filter((l) =>
        deploymentValues.some(
          (k) => k.toLowerCase() === Object.keys(l)[0]?.toLowerCase()
        )
      ) ?? [];

    return deployedLabels?.length > 0
      ? deployedLabels.map((l) => Object.values(l)[0] ?? '')
      : translations.map(
          (t) =>
            t.target_native_name || t.target_lang_name || t.target_lang_code
        );
  }, [translations, yyTranslation]);
  const [showLangSelectorPreview, setShowLangSelectorPreview] =
    useState(!showButton);
  const hasSelectorCss = css.includes('language-selector');
  const isMoxieInjected = useRef(
    !!document.getElementById('GLGOLanguageSelector')
  );
  const disablePreview = !hasSelectorCss || isMoxieInjected.current;
  const [selectedValue, setSelectedValue] = useState(
    deploymentValues[Math.floor(Math.random() * deploymentValues.length)]
  );
  const [opened, setOpened] = useState(true);

  const injectCss = (css: string) => {
    const customCssEl = document.getElementById(SELECTOR_STYLE_ID);
    if (customCssEl) {
      customCssEl.innerHTML = css;
    } else {
      const style = document.createElement('style');
      style.id = SELECTOR_STYLE_ID;
      style.innerHTML = css;
      document.head.appendChild(style);
    }
  };

  useEffect(() => {
    if (disablePreview) return;
    injectCss(css);
    return () => {
      const customCssEl = document.getElementById(SELECTOR_STYLE_ID);
      if (customCssEl) {
        customCssEl.remove();
      }
    };
  }, [css, disablePreview]);

  useEffect(() => {
    //make sure selected value is also changed when the deployment values change
    setSelectedValue(
      deploymentValues[Math.floor(Math.random() * deploymentValues.length)]
    );
  }, [deploymentValues]);

  // Disable preview if no selector css or if Moxie is injected
  if (disablePreview) return <div />;

  return (
    <>
      {showButton && (
        <Tooltip
          label={
            showLangSelectorPreview
              ? 'Close Language selector preview'
              : 'Show language selector preview'
          }
        >
          <Button
            variant="transparent"
            onClick={() => setShowLangSelectorPreview((p) => !p)}
          >
            <BsMenuButtonWideFill size={24} />
          </Button>
        </Tooltip>
      )}
      {showLangSelectorPreview && (
        <div
          id="LanguageSelectorPreview"
          className="OneLinkNoTx GLGO-language-selector"
        >
          <div
            className={`oljs-select-selected${opened ? ' oljs-select-arrow-active' : ''}`}
            onClick={() => setOpened((o) => !o)}
          >
            {selectedValue}
          </div>
          <div
            className={`oljs-select-items${opened ? '' : ' oljs-select-hide'}`}
          >
            {deploymentValues.map((value, index) => {
              const isSelected = value === selectedValue;
              return (
                <div
                  key={value + index}
                  className={`oljs-select-item${isSelected ? ' oljs-same-as-selected' : ''}`}
                  onClick={() => {
                    setSelectedValue(value);
                    setOpened(false);
                  }}
                >
                  {value}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
