import React from 'react';
import { Card, Divider, Grid, Group } from '@mantine/core';
import { CardInterface } from '../../../../types';
import WorldTraffic from './WorldTraffic';
import TranslatedTraffic from './translated-traffic/TranslatedTraffic';
import WordsServed from './words-served/WordsServed';
import {
  TranslatedTrafficTimeslice,
  WordsServedResponse,
  WorldTrafficResponse,
} from 'store';
import classes from './MetricsSummary.module.css';

type Props = CardInterface & {
  prevTimeSlices: TranslatedTrafficTimeslice[];
  timeSlices: TranslatedTrafficTimeslice[];
  worldTraffic: WorldTrafficResponse[];
  wordsServed: WordsServedResponse[];
  prevWordsServed: WordsServedResponse[];
};

const MobileDivider = (
  <Divider
    className={classes.mobileDivider}
    size="sm"
    orientation="horizontal"
  />
);

export const MetricsSummary = ({
  header,
  prevTimeSlices,
  timeSlices,
  worldTraffic,
  wordsServed,
  prevWordsServed,
}: Props) => (
  <>
    <Group>{header}</Group>
    <Card>
      <Grid py="0.75rem">
        <Grid.Col
          className={classes.summaryCol}
          px={30}
          py={0}
          span={{ base: 12, md: 4 }}
        >
          <TranslatedTraffic
            prevTimeSlices={prevTimeSlices}
            timeSlices={timeSlices}
          />
        </Grid.Col>
        {MobileDivider}
        <Grid.Col
          className={classes.worldTrafficCol}
          px={30}
          py={0}
          span={{ base: 12, md: 4 }}
        >
          <WorldTraffic worldTraffic={worldTraffic} />
        </Grid.Col>
        {MobileDivider}
        <Grid.Col
          className={classes.summaryCol}
          px={30}
          py={0}
          span={{ base: 12, md: 4 }}
        >
          <WordsServed
            wordsServed={wordsServed}
            prevWordsServed={prevWordsServed}
          />
        </Grid.Col>
      </Grid>
    </Card>
  </>
);

export default MetricsSummary;
