import React, { useState } from 'react';
import {
  Button,
  Divider,
  ScrollArea,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import { TabletNavItem, NavItem } from './NavItem';
import FreeTrialUsageCard from './FreeTrialUsageCard';
import {
  dashboardRC,
  isDesktopApp,
  NavLink as NavLinkType,
  accountLink,
  NOOP,
  GLWEB_SUPPORT_URL,
} from 'helpers';
import {
  useCurrentProjectData,
  useGlobalModalStore,
  GlobalModalStore,
  defaultSubscription,
  useUserProvider,
} from 'store';
import { Link, useLocation } from 'react-router-dom';
import {
  MdContactSupport,
  MdDoubleArrow,
  MdLogout,
  MdOutlineArrowRightAlt,
} from 'react-icons/md';
import { useCurrentProjectType, useViewSize } from 'hooks';
import { IoMdNotifications } from 'react-icons/io';
import NotificationsMenu from './NotificationsMenu';
import classes from './SideNav.module.css';
import cheet from 'cheet.js';
import { useLogout } from 'auth';
import { hasPageViewPermissions } from 'helpers/utils/projectPermissions';

type Props = {
  links: NavLinkType[];
  setIsDesktopToggled: React.Dispatch<React.SetStateAction<boolean>>;
  isDesktopToggled: boolean;
  toggleMobile: () => void;
};

export default function SideNav({
  links,
  setIsDesktopToggled,
  isDesktopToggled,
  toggleMobile,
}: Props) {
  const { colors } = useMantineTheme();
  const { pathname } = useLocation();
  const { setShowContactUsModal } = useGlobalModalStore(
    (state: GlobalModalStore) => state
  );

  const logout = useLogout();
  const { isMobileSize, isTabletSize } = useViewSize();

  const [showQuoteLink, setShowQuoteLink] = useState(false);
  const [showDebugLink, setShowDebugLink] = useState(false);
  const [showMobileNotifications, setShowMobileNotifications] = useState(false);

  if (!isMobileSize) {
    (cheet as unknown as (str: string, cb: () => void) => void)(
      'ctrl d b g',
      () => {
        setShowDebugLink(!showDebugLink);
      }
    );
    (cheet as unknown as (str: string, cb: () => void) => void)(
      'ctrl shift q',
      () => {
        setShowQuoteLink(!showQuoteLink);
      }
    );
  }

  const { project, subscriptions } = useCurrentProjectData();
  const { isGLGOProject, isGLWEBProject, isGLGOEnterprise, isGLWEBEnterprise } =
    useCurrentProjectType();
  const isGLWEBEnterpriseWithoutSku =
    isGLWEBEnterprise && !project.subscription_sku;
  const isGLGODesktop = isDesktopApp();
  const {
    xapisUser: { user_key: userKey },
  } = useUserProvider();

  const { projectUserPermissionMap } = useCurrentProjectData();
  const permissions: Set<string> =
    projectUserPermissionMap[userKey]?.rolePermissionSet ?? new Set();

  const filteredAccountLinks: NavLinkType = {
    ...accountLink,
    pages: (accountLink.pages ?? []).filter(({ path, userTypes }) => {
      switch (true) {
        case userTypes && !hasPageViewPermissions(permissions, userTypes):
        case (isGLGODesktop ||
          isGLGOEnterprise ||
          isGLWEBEnterpriseWithoutSku) &&
          path === dashboardRC.PAGE_BILLING_INFORMATION:
        case isGLWEBProject &&
          path === dashboardRC.PAGE_WORDS_SERVED_USAGE_DETAILS:
        case isGLGOProject && path === dashboardRC.PAGE_MT_USAGE_DETAILS:
          return false;
        default:
          return true;
      }
    }),
  };

  const mobileLinks: NavLinkType[] = [
    {
      icon: <IoMdNotifications size={20} />,
      label: 'Notifications',
      action: () => setShowMobileNotifications(true),
    },
    {
      icon: <MdContactSupport size={20} />,
      label: 'Contact Support',
      pages: [
        {
          label: 'Help',
          link: GLWEB_SUPPORT_URL,
          target: '_blank',
        },
        {
          label: 'Contact Us',
          action: () => setShowContactUsModal(true),
        },
      ],
    },
    { ...filteredAccountLinks },
    {
      icon: <MdLogout size={20} />,
      label: dashboardRC.PAGE_TITLE_LOGOUT,
      action: logout,
    },
  ];

  const showAccountLinks =
    !isMobileSize && pathname.includes(`/${dashboardRC.PAGE_ACCOUNT}`);

  const filteredLinks = showAccountLinks
    ? [filteredAccountLinks]
    : [...links, ...(isMobileSize ? mobileLinks : [])].filter(
        ({ label, userTypes }) => {
          switch (true) {
            case !showDebugLink && label === dashboardRC.PAGE_TITLE_DEBUG:
            case !showQuoteLink && label === dashboardRC.PAGE_TITLE_QUOTES:
            case userTypes && !hasPageViewPermissions(permissions, userTypes):
              return false;
            default:
              return true;
          }
        }
      );

  const showTabletNavItem = !isMobileSize && (isDesktopToggled || isTabletSize);

  const [activeSubscription = defaultSubscription] = subscriptions;
  const showFreeTrialUsage = activeSubscription.price == 0;
  const isSubscriptionExpired = project.subscription_status === 'expired';

  if (isMobileSize && showMobileNotifications) {
    return (
      <NotificationsMenu
        showMobileNotifications={showMobileNotifications}
        setShowMobileNotifications={setShowMobileNotifications}
      />
    );
  }

  return (
    <Stack
      flex={1}
      gap="0.75rem"
      mt={10}
      mb={55}
      style={{ overflow: 'hidden' }}
      data-testid="sideNav"
    >
      {showAccountLinks && (
        <>
          <Link to="/" style={{ margin: '0 auto 0 auto' }}>
            {showTabletNavItem ? (
              <Button
                px="0.65rem"
                py="0.25rem"
                miw="fit-content"
                mx="auto"
                variant="transparent"
                c="font1.0"
              >
                <MdOutlineArrowRightAlt size={24} className="flip" />
              </Button>
            ) : (
              <Button
                w="100%"
                c="font1.0"
                fz="0.85rem"
                px={0}
                variant="transparent"
                leftSection={
                  <MdOutlineArrowRightAlt size={24} className="flip" />
                }
              >
                Back to Dashboard
              </Button>
            )}
          </Link>
          <Divider color="divider1.0" />
        </>
      )}
      <ScrollArea scrollbarSize={12} type="auto" scrollbars="y">
        <Stack
          gap={showTabletNavItem ? '0.5rem' : 0}
          px="0.65rem"
          fz="sm"
          c="font1.0"
        >
          {filteredLinks.map((link) => {
            return showTabletNavItem ? (
              <TabletNavItem key={link.label} classes={classes} link={link} />
            ) : (
              <NavItem
                key={link.label}
                classes={classes}
                link={link}
                toggleMobile={isMobileSize ? toggleMobile : NOOP}
                isMobileSize={isMobileSize}
              />
            );
          })}
        </Stack>
      </ScrollArea>
      {showFreeTrialUsage && (
        <>
          <Divider color="divider1.0" />
          <FreeTrialUsageCard
            isCompact={showTabletNavItem}
            subscription={activeSubscription}
            isExpired={isSubscriptionExpired}
          />
        </>
      )}
      <Button
        display={{ base: 'none', lg: 'block' }}
        p="0.25rem"
        variant="transparent"
        pos="absolute"
        bottom={15}
        right={isTabletSize || isDesktopToggled ? 15 : 20}
        onClick={() => setIsDesktopToggled((prev) => !prev)}
      >
        <MdDoubleArrow
          color={colors.font1[0]}
          size={24}
          className={isDesktopToggled ? undefined : 'flip'}
        />
      </Button>
    </Stack>
  );
}
