import React, { useState } from 'react';
import {
  Flex,
  Text,
  UnstyledButton,
  ScrollArea,
  useMantineTheme,
  Title,
} from '@mantine/core';
import { IoMdAdd } from 'react-icons/io';
import PaymentMethods from './PaymentMethods';
import { dashboardRC } from 'helpers';
import { handleAddCardButtonClick } from './planActions';
import { PaymentSource, useUserProvider } from 'store';
import { AnchorLink } from '../../../Generic';

type Props = {
  default_source_id: string;
  sources: PaymentSource[];
  cards: PaymentSource[];
  setCards: React.Dispatch<React.SetStateAction<PaymentSource[]>>;
  openPayModal: () => void;
};

const ManagePaymentMethods = ({
  default_source_id,
  sources,
  cards,
  setCards,
  openPayModal,
}: Props) => {
  const { colors } = useMantineTheme();

  const { phone } = useUserProvider().xapisUser;

  const [defaultSourceId, setDefaultSourceId] = useState(default_source_id);

  return (
    <>
      <Flex justify="space-between" align="center" columnGap="1rem" mb="1.5rem">
        <Title order={3} tt="uppercase">
          Payment Method
        </Title>
        {sources.length > 0 && (
          <UnstyledButton
            onClick={() => handleAddCardButtonClick(phone || '', openPayModal)}
          >
            <IoMdAdd color={colors.text[8]} size={24} />
          </UnstyledButton>
        )}
      </Flex>
      <ScrollArea type="auto" offsetScrollbars h={200}>
        <PaymentMethods
          cards={cards}
          setCards={setCards}
          defaultSourceId={defaultSourceId}
          setDefaultSourceId={setDefaultSourceId}
        />
      </ScrollArea>
      <Text fz="xs" ta="center" mt="0.75rem">
        <AnchorLink
          fz="inherit"
          c="inherit"
          fw={600}
          target="_blank"
          to={`${dashboardRC.DR_STORE}/DisplayDRAboutDigitalRiverPage/eCommerceProvider.Digital%20River%20Inc.`}
        >
          Digital River Inc.
        </AnchorLink>{' '}
        is the authorized reseller and merchant of the products and services
        offered within this store.
        <br />
        <AnchorLink
          fz="inherit"
          c="inherit"
          fw={600}
          target="_blank"
          to={`${dashboardRC.DR_STORE}/DisplayDRPrivacyPolicyPage/eCommerceProvider.Digital%20River%20Inc.`}
        >
          Privacy Policy
        </AnchorLink>
        &nbsp;|&nbsp;
        <AnchorLink
          fz="inherit"
          c="inherit"
          fw={600}
          target="_blank"
          to={`${dashboardRC.DR_STORE}/DisplayDRTermsAndConditionsPage/eCommerceProvider.Digital%20River%20Inc.`}
        >
          Terms of Sale
        </AnchorLink>
        &nbsp;|&nbsp;
        <AnchorLink
          fz="inherit"
          c="inherit"
          fw={600}
          target="_blank"
          to={`${dashboardRC.DR_STORE}/DisplayCCPAPage`}
        >
          Your California Policy Rights
        </AnchorLink>
        &nbsp;|&nbsp;
        <AnchorLink
          fz="inherit"
          c="inherit"
          fw={600}
          target="_blank"
          to={`${dashboardRC.DR_STORE}/DisplayDRCookiesPolicyPage/eCommerceProvider.Digital%20River%20Inc.`}
        >
          Cookies
        </AnchorLink>
        &nbsp;|&nbsp;
        <AnchorLink
          fz="inherit"
          c="inherit"
          fw={600}
          target="_blank"
          to={`${dashboardRC.DR_STORE}/DisplayDRContactInformationPage/eCommerceProvider.Digital%20River%20Inc.`}
        >
          Legal Notice
        </AnchorLink>
      </Text>
    </>
  );
};

export default ManagePaymentMethods;
