import React from 'react';
import { Switch } from '@mantine/core';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';

type Props = {
  needsDeploymentValue: boolean;
  is_live: boolean;
  updateTranslationStatus: (
    selectedTranslationKey: string,
    isLive: boolean
  ) => void;
  translation_key: string;
};

const DeployStatusToggle = ({
  needsDeploymentValue,
  is_live,
  updateTranslationStatus,
  translation_key,
}: Props) => {
  return (
    <Switch
      size="sm"
      disabled={needsDeploymentValue}
      checked={is_live}
      color={is_live ? 'text3.0' : 'text3.1'}
      thumbIcon={
        is_live ? (
          <HiOutlineEye
            style={{
              width: '16px',
              height: '12px',
            }}
          />
        ) : (
          <HiOutlineEyeOff
            style={{
              color: '#EAF0FF',
              width: '16px',
              height: '12px',
            }}
          />
        )
      }
      styles={(theme) => ({
        track: {
          maxWidth: 46,
          maxHeight: 20,
          minWidth: 'auto',
        },
        thumb: {
          backgroundColor: `${is_live ? theme.colors.border[3] : theme.colors.background2[4]}`,
          cursor: 'pointer',
          height: '16px',
          width: '16px',
        },
      })}
      style={{ cursor: needsDeploymentValue ? 'not-allowed' : 'pointer' }}
      onClick={() => updateTranslationStatus(translation_key, is_live)}
    />
  );
};

export default DeployStatusToggle;
