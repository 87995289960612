import { Flex } from '@mantine/core';
import { JliffViewer } from './JliffViewer';
import { Status } from '../Indicators/Status';
import { GlossaryViolations } from '../Glossary/GlossaryViolations';
import { SegmentIndicators } from '../Indicators/SegmentIndicators';
import { SegmentStatus } from './Jliff.style';
import classes from './Target.module.css';
import { useContext } from 'react';
import { SegmentEditorContext } from '../../context/SegmentEditorProvider';

type Props = {
  jliffs: Jliff[];
  segment: Segment;
  segmentStatus: SegmentStatus;
  glossaryErrors: GlossaryItem[];
  filterText: string;
  statusCode?: string;
};
export const Target = ({
  jliffs,
  segment,
  filterText,
  segmentStatus,
  glossaryErrors,
  statusCode,
}: Props) => {
  const { settings } = useContext(SegmentEditorContext);
  const isSelected = false; // Component is always not selected

  return (
    <Flex className={classes.translation}>
      <Flex
        direction={'column'}
        align="center"
        justify={'flex-start'}
        pl={5}
        gap={2}
      >
        <Status segment={segment} />
        <GlossaryViolations
          violations={glossaryErrors}
          isSelected={isSelected}
        />
      </Flex>
      <JliffViewer
        jliffs={jliffs}
        showAllTags={false}
        filterText={filterText}
        isSelected={isSelected}
        htmlDir={settings.htmlDir}
      />
      <SegmentIndicators status={segmentStatus} />
    </Flex>
  );
};
