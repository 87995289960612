import { create } from 'zustand';
import Get from './xapis-wrappers/Fetcher';
import Post from './xapis-wrappers/Poster';
import Put from './xapis-wrappers/Putter';
import { PayProjectResponse } from './types';
import { AxiosResponse } from 'axios';

export type PayProjectStore = {
  totalRows: number;
  payProjects: PayProjectResponse[];
  setPayProjects: (projects: PayProjectResponse[]) => void;
  setTotalRows: (rows: number) => void;
  fetchPayProjects: (params: object) => void;
  createPayProject: (
    data: Partial<PayProjectResponse>,
    success: (results: AxiosResponse) => void
  ) => void;
  updatePayProject: (
    payKey: string,
    data: Partial<PayProjectResponse>,
    success: (results: AxiosResponse) => void
  ) => void;
};

export const usePayProjectStore = create<PayProjectStore>()((set) => ({
  totalRows: 0,
  payProjects: [],
  setPayProjects: (projects) => set(() => ({ payProjects: projects })),
  setTotalRows: (totalRows) => set(() => ({ totalRows })),
  fetchPayProjects: (params) => {
    Get(
      `Pay/Project`,
      ({
        data: { projects },
      }: {
        data: { projects: PayProjectResponse[]; rows_total: number };
      }) => {
        set({ payProjects: projects });
        // set({ totalRows: rows_total })
        set({ totalRows: projects.length });
      },
      { action: () => set({ payProjects: [] }) },
      params
    );
  },
  createPayProject: (data, success) => {
    Post(`Pay/Project`, data, (results) => success(results), {});
  },
  updatePayProject: (payKey, data, success) => {
    Put(`Pay/Project/${payKey}`, data, (results) => success(results), {});
  },
}));

export default usePayProjectStore;
