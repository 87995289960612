import React from 'react';
import { CustomModal } from 'ui';

type ConfirmModalProps = {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  children?: React.ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
};

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  onClose,
  onCancel,
  onConfirm,
  children,
  confirmLabel = 'Apply',
  cancelLabel = 'Cancel',
}) => {
  return (
    <CustomModal
      opened={open}
      onClose={onClose}
      title="Remove Human Translation"
      footerActions={[
        { label: cancelLabel, action: onCancel },
        { label: confirmLabel, action: onConfirm },
      ]}
    >
      {children}
    </CustomModal>
  );
};
