import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type DebugStore = {
  debug: boolean;
  setDebug: (debug: boolean) => void;
};

export const useDebugStore = create<DebugStore>()(
  persist(
    (set) => ({
      debug: false,
      setDebug: (debug) => set(() => ({ debug })),
    }),
    {
      name: 'debugState',
    }
  )
);
export default useDebugStore;
