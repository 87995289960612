import { SVGAttributes } from 'react';

type IconProps = {
  svg: { path: string; attr?: SVGAttributes<SVGElement> };
  size?: string | number;
  onClick?: () => void;
};

type ActionProps = {
  onClick?: () => void;
  size?: string;
  color?: string;
};

const Icon = ({ svg, size, onClick }: IconProps) => {
  const { path, attr } = svg;
  return (
    <svg
      onClick={() => onClick && onClick()}
      width={size || attr?.width || '20'}
      height={size || attr?.height || '20'}
      viewBox={attr?.viewBox || '0 0 20 20'}
      fill={attr?.fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d={path}
          stroke={attr?.stroke || 'currentColor'}
          strokeWidth={attr?.strokeWidth || 1}
          strokeLinecap={attr?.strokeLinecap || 'round'}
          strokeLinejoin={attr?.strokeLinejoin || 'round'}
        />
      </g>
    </svg>
  );
};

export const X = ({ size, color }: ActionProps) => {
  const svg = {
    path: 'M5 5 15 15M5 15 15 5',
    attr: {
      strokeWidth: 2.5,
      stroke: color || 'gray',
    },
  };

  return <Icon svg={svg} size={size} />;
};
export const CheckMark = ({ size, color }: ActionProps) => {
  const svg = {
    path: 'M5 10.1C6.3 11.4 7.7 12.8 10 16.9 11.6 11.5 14.2 6.5 17 3',
    attr: {
      strokeWidth: 2.5,
      stroke: color || 'green',
    },
  };

  return <Icon svg={svg} size={size} />;
};
