import React, { useCallback, useMemo } from 'react';
import { Header } from '../../Generic';
import { getTimeSliceOptions } from '../utils';
import TranslatedTrafficTable from './TranslatedTrafficTable';
import TranslatedTrafficChart from './TranslatedTrafficChart';
import { useRouteLoaderData, useSearchParams } from 'react-router-dom';
import { filterTargets, updateDateParam } from './trafficUtils';
import {
  ProjectResponse,
  TranslatedTrafficTimeslice,
  TranslatedTrafficUrl,
} from 'store';
import { QueryMultiSelector } from '../selectors-filters/QueryMultiSelector';
import DateFilter from '../selectors-filters/DateFilter';
import { useGetNavigationStatus } from 'hooks';
import { Stack } from '@mantine/core';

export type MetricsLoaderData = ProjectResponse & {
  targets: TranslationKey[];
  translationKeys: string[];
  timeSlices: TranslatedTrafficTimeslice[];
  urls: TranslatedTrafficUrl[];
  timeSliceFrom: string;
  worldTraffic: Array<{
    count: number;
    country_name: string;
    label: string;
  }>;
};

export const TranslatedTraffic = () => {
  const { targets, timeSlices, urls, timeSliceFrom } = useRouteLoaderData(
    'metrics'
  ) as MetricsLoaderData;
  const loading = useGetNavigationStatus().isNavigatingInPlace;

  const filteredTargets = useMemo(() => filterTargets(targets), [targets]);
  const filteredTranslationKeys = useMemo(
    () => filteredTargets.map(({ value }) => value),
    [filteredTargets]
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSelectedDate = useCallback(
    (value: string) => {
      updateDateParam(value, searchParams, setSearchParams);
    },
    [searchParams, setSearchParams]
  );

  return (
    <>
      <Header
        wrapContent
        title="Translated Traffic"
        tooltipLabel="The chart and table below show details about translated traffic - i.e., visits to your website in one of your translation languages."
        flexItems={
          <>
            <QueryMultiSelector
              encodeKeys
              selectAll
              shouldRevalidate
              options={filteredTargets}
            />
            <DateFilter
              timeSliceOptions={getTimeSliceOptions()}
              timeSliceFrom={timeSliceFrom}
              onSelect={handleSelectedDate}
            />
          </>
        }
      />
      <Stack gap={20}>
        <TranslatedTrafficChart
          timeSliceFrom={timeSliceFrom}
          timeSlices={timeSlices}
          loading={loading}
        />
        <TranslatedTrafficTable
          translationKeys={filteredTranslationKeys}
          urls={urls}
          loading={loading}
        />
      </Stack>
    </>
  );
};

export default TranslatedTraffic;
