import React from 'react';
import { Button, Flex } from '@mantine/core';
import { useMantineTheme } from '@mantine/core';
import { Link } from 'react-router-dom';
import {
  MAC_GLGO_DESKTOP_DMG,
  WIN_GLGO_DESKTOP_EXE,
  MAC_OS,
  WINDOWS_OS,
  DesktopOS,
} from 'helpers';
import { FaApple, FaWindows } from 'react-icons/fa6';

const links: { [key in DesktopOS]: string } = {
  [MAC_OS]: MAC_GLGO_DESKTOP_DMG,
  [WINDOWS_OS]: WIN_GLGO_DESKTOP_EXE,
};
const icons: { [key in DesktopOS]: React.ReactNode } = {
  [MAC_OS]: <FaApple size={35} />,
  [WINDOWS_OS]: <FaWindows size={35} />,
};

export const DownloadDesktopAppCard = ({ os }: { os: DesktopOS }) => {
  const colors = useMantineTheme().colors;

  return (
    <Flex
      w="50%"
      px="1rem"
      pt="1rem"
      pb="1.75rem"
      bg="background.0"
      direction="column"
      align="center"
      justify="center"
      rowGap="1rem"
      style={{
        border: `1px solid ${colors.border[1]}`,
        borderRadius: 12,
      }}
    >
      {icons[os]}
      <Link style={{ width: '100%' }} to={links[os]}>
        <Button w="100%">{`${os} Download`}</Button>
      </Link>
    </Flex>
  );
};
