import { SegmentedControl, Tooltip } from '@mantine/core';

type Props = {
  whichSelectorShown: string;
  setWhichSelectorShown: (value: string) => void;
  whichTabShown: string;
  setShowHiddenOverCurrentSelector: (value: boolean) => void;
  isUnsaved: boolean;
  customCss: string;
  cssEditorCss: string;
};

const SelectorVisibilityOptions = ({
  whichSelectorShown,
  setWhichSelectorShown,
  whichTabShown,
  setShowHiddenOverCurrentSelector,
  isUnsaved,
  customCss,
  cssEditorCss,
}: Props) => (
  <SegmentedControl
    size="xs"
    value={whichSelectorShown}
    onChange={(value) => {
      setWhichSelectorShown(value);
      value === 'Current' && setShowHiddenOverCurrentSelector(false);
      value === 'None' && setShowHiddenOverCurrentSelector(true);
    }}
    data={[
      {
        label: (
          <Tooltip label="Show your current selector" offset={15}>
            <span>Current</span>
          </Tooltip>
        ),
        value: 'Current',
      },
      {
        label: (
          <Tooltip label="Hide the selector" offset={15}>
            <span>Off</span>
          </Tooltip>
        ),
        value: 'None',
      },
      {
        label: (
          <Tooltip label="Preview your changes" offset={15}>
            <span>New</span>
          </Tooltip>
        ),
        value: 'New',
        disabled:
          whichTabShown === 'Basic' ? !isUnsaved : customCss === cssEditorCss,
      },
    ]}
  />
);

export default SelectorVisibilityOptions;
