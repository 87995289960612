import { AxiosHeaders, AxiosResponse } from 'axios';

export const defaultAxiosResponse: AxiosResponse<Record<string, never>> = {
  data: {},
  status: 0,
  statusText: '',
  headers: new AxiosHeaders(),
  config: {
    headers: new AxiosHeaders(),
  },
};

export const defaultXapisErrorResponse: AxiosResponse<XapisErrorResponse> = {
  ...defaultAxiosResponse,
  data: {
    message: '',
  },
};
