import { create } from 'zustand';
import { HostEntry, getHostConstants } from 'helpers';

export type BackEndStore = {
  backend: HostEntry;
  setBackEnd: (value: HostEntry) => void;
  setBackEndByLabel: (hostLabel: string) => void;
};

export const useBackEndStore = create<BackEndStore>()((set) => ({
  backend: getHostConstants(),
  setBackEnd: (value: HostEntry) => {
    set(() => ({ backend: value }));
  },
  setBackEndByLabel: (hostLabel) => {
    set(() => ({ backend: getHostConstants(hostLabel) }));
  },
}));
