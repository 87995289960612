import React, { ReactNode, useMemo, useState, forwardRef } from 'react';
import { MultiSelect, MultiSelectProps } from '@mantine/core';
import { AiOutlineSearch } from 'react-icons/ai';
import classes from './MultiSelectAll.module.css';

const SELECT_ALL = 'SELECT_ALL';

type Props =
  | MultiSelectProps
  | {
      data: {
        value: string;
        label: string;
      }[];
      children?: ReactNode;
      onChange?: (value: string[]) => void;
      type: 'Add' | 'Edit';
      withCheckIcon: boolean;
    };

export const MultiSelectAll = forwardRef<
  HTMLInputElement,
  Props & {
    targetOptions: {
      value: string;
      label: string;
    }[];
  }
>(({ children, targetOptions, withCheckIcon, onChange, type }, ref) => {
  const [values, setValues] = useState<string[]>([]);

  const dataWithSelectAll = useMemo(() => {
    if (!targetOptions) return [];

    return targetOptions.length === values.length
      ? targetOptions
      : [
          {
            label: 'SELECT ALL',
            value: SELECT_ALL,
          },
          ...targetOptions,
        ];
  }, [targetOptions, values]);

  const multiSelectAllOnChange = (selectedValues: string[]): void => {
    const lastValue = selectedValues[selectedValues.length - 1];

    const getData = (): string[] => {
      if (lastValue === SELECT_ALL && targetOptions) {
        return targetOptions.map((dataPoint) => {
          if (typeof dataPoint === 'string') {
            return dataPoint;
          }

          if (typeof dataPoint === 'object' && 'value' in dataPoint) {
            const { value } = dataPoint;
            return value;
          }

          return '';
        });
      }
      return selectedValues;
    };

    const newValues = getData();
    setValues(newValues);
    if (typeof onChange === 'function') {
      onChange(newValues);
    }
  };

  const props = { children, onChange };
  return (
    <MultiSelect
      ref={ref}
      classNames={{
        pill: classes.pill,
        option: classes.option,
      }}
      placeholder="Language"
      disabled={type === 'Edit'}
      withCheckIcon={withCheckIcon}
      {...props}
      data={dataWithSelectAll}
      onChange={multiSelectAllOnChange}
      comboboxProps={{
        withinPortal: true,
        zIndex: 99999,
      }}
      value={values}
      rightSection={<AiOutlineSearch size="1.1rem" />}
    >
      {children}
    </MultiSelect>
  );
});

export default MultiSelectAll;
