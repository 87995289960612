import { Xapis } from '../../http/xapis';

// Pagination variables
let pageNumber = 1;
const PAGE_SIZE = 25;
let endReached = false;
let lastFetchId = 0;

const serverParams = ['s', 'u'] as const;
type ServerParams = (typeof serverParams)[number];

const getSearchParams = (
  params: URLSearchParams // Current URL search params
) => {
  const search = new URLSearchParams(params);
  // Leave only server params
  for (const key of search.keys()) {
    if (!serverParams.includes(key as ServerParams)) {
      search.delete(key);
    }
  }
  return search.toString();
};

export const getSegments = async (
  tKey: string,
  page: 'first' | 'next',
  params: URLSearchParams
) => {
  const fetchId = ++lastFetchId;
  pageNumber = page === 'first' ? 1 : pageNumber + 1;
  if (pageNumber === 1) endReached = false;
  if (endReached) return { ignore: true, data: null };
  const baseQuery = new URLSearchParams({
    page: String(pageNumber),
    limit: `${PAGE_SIZE}`,
    ignore_backfill: '1',
    notx: '0',
  }).toString();
  const search = getSearchParams(params);
  const query = search ? `${baseQuery}&${search}` : baseQuery;

  const res = await Xapis.Segment.get(tKey, query).then((res) => {
    if (fetchId !== lastFetchId) {
      return { ignore: true, data: null };
    }
    const { status, data } = res;
    if (status === 200) {
      if (data.num_segments < PAGE_SIZE) endReached = true;
      return { data, ignore: false };
    }
    throw new Error('Failed to fetch segments');
  });
  return res;
};
