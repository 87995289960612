import { RouteObject } from 'react-router-dom';
import {
  languageCodesLoader,
  spiderTreeLoader,
  addLanguagesAction,
} from 'store';
import {
  CurrentScopeHome,
  DeploymentWrapper,
  ManageLanguages,
  CustomCssLanding,
  ScopeUrlsTree,
  SelectorAccordion,
  Injection,
} from 'ui';
import { dashboardRC } from 'helpers';
import { RestrictedRoute } from 'pages';
import {
  DEPLOYMENT_PAGE_USERTYPES,
  INJECTION_PAGE_USERTYPES,
  LANGUAGESELECTOR_PAGE_USERTYPES,
  LANGUAGESETTINGS_PAGE_USERTYPES,
  PROJECTSCOPE_PAGE_USERTYPES,
} from 'glweb/src/constants';

export const routes: Array<RouteObject & { path: string }> = [
  {
    path: dashboardRC.PAGE_LANGUAGE_SETTINGS,
    children: [
      {
        index: true,
        loader: languageCodesLoader,
        action: addLanguagesAction,
        element: (
          <RestrictedRoute allowedRoles={LANGUAGESETTINGS_PAGE_USERTYPES}>
            <ManageLanguages />
          </RestrictedRoute>
        ),
      },
      {
        path: dashboardRC.PAGE_CUSTOM_CSS_EDITOR,
        element: (
          <RestrictedRoute allowedRoles={LANGUAGESETTINGS_PAGE_USERTYPES}>
            <CustomCssLanding />
          </RestrictedRoute>
        ),
      },
    ],
  },
  {
    path: dashboardRC.PAGE_LANGUAGE_SELECTOR,
    element: (
      <RestrictedRoute allowedRoles={LANGUAGESELECTOR_PAGE_USERTYPES}>
        <SelectorAccordion />
      </RestrictedRoute>
    ),
  },
  {
    path: dashboardRC.PAGE_DEPLOYMENT,
    element: (
      <RestrictedRoute allowedRoles={DEPLOYMENT_PAGE_USERTYPES}>
        <DeploymentWrapper />
      </RestrictedRoute>
    ),
  },
  {
    path: dashboardRC.PAGE_INJECTION,
    element: (
      <RestrictedRoute allowedRoles={INJECTION_PAGE_USERTYPES}>
        <Injection />
      </RestrictedRoute>
    ),
  },
  {
    path: dashboardRC.PAGE_PROJECT_SCOPE,
    children: [
      {
        index: true,
        element: (
          <RestrictedRoute allowedRoles={PROJECTSCOPE_PAGE_USERTYPES}>
            <CurrentScopeHome />
          </RestrictedRoute>
        ),
      },
      {
        path: ':yyTranslationId/urls',
        element: (
          <RestrictedRoute allowedRoles={PROJECTSCOPE_PAGE_USERTYPES}>
            <ScopeUrlsTree />
          </RestrictedRoute>
        ),
        loader: spiderTreeLoader,
      },
    ],
  },
];
