import { create } from 'zustand';
import { FetcherPromise, defaultXapisErrorResponse } from './xapis-wrappers';
import { AxiosError, AxiosResponse } from 'axios';
import { failure } from 'helpers';

export type TranslatedTrafficTimeslice = {
  num_requests: string;
  timeslice: string;
};

export type TranslatedTrafficUrl = {
  [translation_key: string]: number;
} & {
  content_url: string;
  content_url_hash: number;
};

type ResponseData = {
  data: TranslatedTrafficTimeslice[] | TranslatedTrafficUrl[];
};

export type TranslatedTrafficStore = {
  loading: boolean;
  prevTranslatedTrafficTimeslices: TranslatedTrafficTimeslice[];
  translatedTrafficTimeslices: TranslatedTrafficTimeslice[];
  translatedTrafficUrls: TranslatedTrafficUrl[];
  fetchTranslatedTraffic: <T>(
    translationKey: T,
    timeSliceFrom: string,
    type: string
  ) => Promise<AxiosResponse>;
  setPrevTranslatedTrafficTimeslices: (
    data: TranslatedTrafficTimeslice[]
  ) => void;
  setTranslatedTrafficTimeslices: (data: TranslatedTrafficTimeslice[]) => void;
  setTranslatedTrafficUrls: (data: TranslatedTrafficUrl[]) => void;
};

export const parseTranslatedTrafficUrls = (urls: TranslatedTrafficUrl[]) => {
  return (
    urls.map((item) => {
      if (item.content_url.startsWith('//')) {
        item.content_url = '/' + item.content_url.slice(2);
      }
      return item;
    }) || []
  );
};

export const useTranslatedTrafficStore = create<TranslatedTrafficStore>()(
  (set) => ({
    loading: false,
    prevTranslatedTrafficTimeslices: [],
    translatedTrafficTimeslices: [],
    translatedTrafficUrls: [],
    fetchTranslatedTraffic: async (translationKey, timeSliceFrom, type) => {
      const newTranslationKey = Array.isArray(translationKey)
        ? translationKey.map((tKey) => tKey).join(',')
        : translationKey;

      const params = {
        type,
        series: 'num_requests',
        rollup: timeSliceFrom === '1 year' ? 'month' : 'day',
        timeslice_from: timeSliceFrom,
        translation_key: newTranslationKey,
      };

      set({ loading: true });

      return FetcherPromise('Metrics', params)
        .then((response: AxiosResponse<ResponseData>) => {
          return response;
        })
        .catch((error: AxiosError<XapisErrorResponse>) => {
          const { response = defaultXapisErrorResponse } = error;
          failure(error);
          return response;
        });
    },
    setPrevTranslatedTrafficTimeslices: (data) => {
      set({
        prevTranslatedTrafficTimeslices: data,
        loading: false,
      });
    },
    setTranslatedTrafficTimeslices: (data) => {
      set({
        translatedTrafficTimeslices: data,
        loading: false,
      });
    },
    setTranslatedTrafficUrls: (data) => {
      set({
        translatedTrafficUrls: data,
        loading: false,
      });
    },
  })
);

export default useTranslatedTrafficStore;

export const fetchTranslatedTrafficPromise = (
  translationKey: string,
  timeSliceFrom: string,
  type: string
) => {
  const newTranslationKey = Array.isArray(translationKey)
    ? translationKey.map((tKey) => tKey).join(',')
    : translationKey;

  const params = {
    type,
    series: 'num_requests',
    rollup: timeSliceFrom === '1 year' ? 'month' : 'day',
    timeslice_from: timeSliceFrom,
    translation_key: newTranslationKey,
  };

  return FetcherPromise('Metrics', params);
};
