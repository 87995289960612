import React from 'react';
import { Box, Card, Group } from '@mantine/core';
import { CardInterface } from '../../../../../types';
import { WordsServedResponse } from 'store';
import moment from 'moment';
import WordsServedUsageHeader from './WordsServedUsageHeader';
import WordsServedUsageChart from './WordsServedUsageChart';
import LoadingSkeleton from '../LoadingSkeleton';
import { getAccumulatedWordsServed, getRemainingTimeslices } from './utils';
import { useGetNavigationStatus } from 'hooks';
import { useIsFirstRender } from '@mantine/hooks';

type Props = CardInterface & {
  monthlyWordsServed: WordsServedResponse[];
  thresholdValue: number;
};

export const WordsServedUsage = ({
  header,
  monthlyWordsServed,
  thresholdValue,
}: Props) => {
  const firstRender = useIsFirstRender();
  const loading = useGetNavigationStatus().isNavigatingInPlace && firstRender;

  const date = new Date();
  const todaysDate = moment(date).format('YYYY-MM-DD') || '';

  const accumulatedWordsServed = getAccumulatedWordsServed(monthlyWordsServed);
  const monthlyWordsServedWithRemaining =
    accumulatedWordsServed.length > 0
      ? [
          ...accumulatedWordsServed,
          ...getRemainingTimeslices(accumulatedWordsServed),
        ].sort(
          ({ timeslice: timesliceA = '' }, { timeslice: timesliceB = '' }) => {
            return timesliceA.localeCompare(timesliceB);
          }
        )
      : [];

  return (
    <Box>
      <Group>{header}</Group>
      <Card
        h={{ base: 830, xs: 575 }}
        p={30}
        data-testid="pw-words-served-usage-languages-container"
      >
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <>
            <WordsServedUsageHeader
              todaysDate={todaysDate}
              thresholdValue={thresholdValue}
              monthlyWordsServed={monthlyWordsServed}
              monthlyWordsServedWithRemaining={monthlyWordsServedWithRemaining}
            />
            <WordsServedUsageChart
              todaysDate={todaysDate}
              monthlyWordsServed={monthlyWordsServed}
              monthlyWordsServedWithRemaining={monthlyWordsServedWithRemaining}
              thresholdValue={thresholdValue}
            />
          </>
        )}
      </Card>
    </Box>
  );
};

export default WordsServedUsage;
