import axios from 'axios';
import getAxiosUrlAndConfig from './getAxiosUrlAndConfig';

/**
 *
 * @param endpoint
 * @param data
 * @param params
 * @return Promise<AxiosResponse<any>>
 */
export const PutterPromise = (
  endpoint: string,
  data: object = {},
  params: object = {}
) => {
  const { url, config } = getAxiosUrlAndConfig(endpoint, params);

  return axios.put(url, data, config).catch((error) => {
    console.error('AXIOS_ERROR:', error);
    throw error;
  });
};

export default PutterPromise;
