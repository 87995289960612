import { Params } from 'react-router-dom';
import { queryClient } from '../queries/queryClient';
// import { projectQuery } from '../queries/projectQuery';
import { glossaryQuery } from '../queries/glossaryQuery';

export async function glossaryLoader({ params }: { params: Params<string> }) {
  const { projectId } = params || {};
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }
  const data = await queryClient.ensureQueryData(glossaryQuery(projectId));
  return data;
}
