import getFullPath from './getFullPath';
import getNodeProperties from './getNodeProperties';

type Params = {
  nodeInfo: string;
  rulesList: string[];
  nodeIsRuled: boolean;
  relevantRule: string;
};

export const getDisabledStatus = ({
  nodeInfo,
  rulesList,
  nodeIsRuled,
  relevantRule,
}: Params) => {
  const { isFake, contentUrl, contentUrlOrig, id, label } =
    getNodeProperties(nodeInfo);

  let isDisabledChildNode = false;
  const pathArray =
    `${!isFake && contentUrl ? new URL(contentUrl).pathname : getFullPath(id || '', label)}`.split(
      '/'
    );
  pathArray.shift();

  let nodeId: string;
  if (isFake) {
    const labelArray = label.split('');
    labelArray.unshift('/');
    nodeId = labelArray.join('');
  } else {
    nodeId = contentUrlOrig;
  }

  const nodeIdentifier = !isFake ? nodeId : getFullPath(id || '', label);
  const isSourceOfChildrenOnlyRule =
    rulesList.indexOf(`^${nodeIdentifier}.+`) !== -1 ||
    rulesList.indexOf(`^${nodeIdentifier}[^/].+`) !== -1;
  const isSourceOfParentAndChildrenRule =
    rulesList.indexOf(`^${nodeIdentifier}`) !== -1 ||
    rulesList.indexOf(`^${nodeIdentifier}[^/]`) !== -1;
  const isQueryParamNode = label[0] === '?';

  if (pathArray.length > 1 || isQueryParamNode) {
    if (nodeIsRuled) {
      const ruleType =
        relevantRule.at(-1) === '+'
          ? 'childrenOnly'
          : relevantRule.at(-1) === '$'
            ? 'self'
            : 'parentAndChildren';

      const isRuleAppliedToChildren = [
        'childrenOnly',
        'parentAndChildren',
      ].includes(ruleType);
      if (isRuleAppliedToChildren && !isSourceOfParentAndChildrenRule)
        isDisabledChildNode = true;
    }
  }

  return { isSourceOfChildrenOnlyRule, isDisabledChildNode };
};

export default getDisabledStatus;
