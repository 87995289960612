import { Box, Button, Flex, Text, Image } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth, fetchXapisUser, useLogout } from 'auth';
import { useUserProvider } from 'store';
import GLGOLogoColor from 'images/logos/glgo-logo-color.svg';

// Component to handle errors in fetching user data from Xapis
// It shows when a user is authenticated but xapis/User fails to fetch
export const Oops = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const logout = useLogout();

  const { user } = useAuth();
  const { setXapisUser } = useUserProvider();

  const email = user?.profile?.email;

  const tryAgain = async () => {
    if (email) {
      const { xapisUser, message } = await fetchXapisUser(email);
      setXapisUser(xapisUser || { user_key: '' });
      if (xapisUser) {
        // Navigate is needed to rerun loaders
        navigate(`${pathname}${search}`);
      } else {
        console.error('tryAgain::fetchXapisUser', { message });
      }
    }
  };

  return (
    <>
      <Box style={{ height: 40, WebkitAppRegion: 'drag' }}></Box>
      <Flex
        w="100%"
        mih={550}
        direction="column"
        justify="center"
        align="center"
        rowGap="3rem"
        px="1rem"
      >
        <Image
          src={GLGOLogoColor}
          width={280}
          alt="GlobalLinkWeb logo"
          style={{
            objectFit: 'cover',
            width: '17.5rem',
            height: ' auto',
          }}
        />
        <Flex w="100%" align="center" direction="column" rowGap="1.5rem">
          <Text fz="md" ta="center">
            Oops ... something went wrong with getting your projects.
          </Text>
          {email && (
            <Button h={47} w="100%" maw="22rem" onClick={tryAgain}>
              Try again
            </Button>
          )}
          <Text>
            {email
              ? 'If the problem persists, please logout and login again'
              : 'Please logout and login again'}
          </Text>
          <Button
            h={47}
            w="100%"
            maw="22rem"
            variant="outline"
            onClick={logout}
          >
            Logout
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
