import { create } from 'zustand';

export const filters = ['status', 'source', 'type'] as const;
export type Filters = (typeof filters)[number];

export const initialFilters: { [key in Filters]: string[] } & {
  date: [Date | null, Date | null];
} = {
  source: [],
  status: [],
  type: [],
  date: [null, null],
};
const initialOptions: { [filter in Filters]: FilterOption[] } = {
  source: [],
  status: [],
  type: [],
};

export type FiltersOptions = { [filter in Filters]: FilterOption[] };

export type FilterBy = typeof initialFilters;
export type FilterOption = { label: string; value: string; count: number };

export type NotificationType = 'success' | 'error' | 'info' | 'warning';
export type DashboardStore = {
  notification: { text: string; type: NotificationType };
  filterBy: FilterBy;
  externalFilter: string[];
  filterOptions: FiltersOptions;
  filtersBuffer: FilterBy;
  actions: {
    setNotification: (text: string, type: NotificationType) => void;
    addFilterValue: (filter: Filters, value: string) => void;
    removeFilterValue: (filter: Filters, value: string) => void;
    handleFilterSelection: (filter: Filters, value: string) => void;
    resetNotification: () => void;
    setFiltersOptions: (options: FiltersOptions) => void;
    setFiltersBuffer: (filtersBuffer: FilterBy) => void;
    setFilterBy: (filterBy: FilterBy) => void;
    resetFilters: () => void;
    setExternalFilter: (hashes: string[]) => void;
  };
};

const useDashboardStore = create<DashboardStore>((set, get) => ({
  notification: { text: '', type: 'info' },
  filterBy: { ...initialFilters },
  filterOptions: initialOptions,
  filtersBuffer: { ...initialFilters },
  externalFilter: [],
  actions: {
    setNotification: (text: string, type: NotificationType) =>
      set({ notification: { text, type } }),
    addFilterValue: (filter: Filters, value: string) => {
      const filters = get().filterBy;
      const values = filters[filter as Filters];
      if (!values.includes(value)) {
        values.push(value);
        set({ filterBy: { ...filters, [filter]: values } });
      }
    },
    removeFilterValue: (filter: Filters | 'date', value: string) => {
      const filters = get().filterBy;
      if (filter === 'date') {
        set({ filterBy: { ...filters, date: [null, null] } });
        return;
      }
      let values = filters[filter as Filters];
      if (values && values.includes(value)) {
        values = values.filter((v) => v !== value);
        set({ filterBy: { ...filters, [filter]: values } });
      }
    },
    handleFilterSelection: (filter: Filters, value: string) => {
      let values = get().filtersBuffer[filter] || [];
      if (values.includes(value)) {
        values = values.filter((v) => v !== value);
      } else {
        values = [...values, value];
      }
      get().actions.setFiltersBuffer({
        ...get().filtersBuffer,
        [filter]: values,
      });
    },
    setFiltersBuffer: (filtersBuffer) => set({ filtersBuffer }),
    setFilterBy: (filterBy) => set({ filterBy }),
    resetFilters: () => set({ filterBy: { ...initialFilters } }),
    setExternalFilter: (hashes) => {
      const current = get().externalFilter;
      // If no change, don't update
      if (
        current.length === hashes.length &&
        hashes.every((hash) => current.includes(hash))
      )
        return;
      set({ externalFilter: hashes });
    },
    setFiltersOptions: (options: FiltersOptions) =>
      set({ filterOptions: options }),
    resetNotification: () => set({ notification: { text: '', type: 'info' } }),
  },
}));

export const useNotifiction = () =>
  useDashboardStore((state: DashboardStore) => state.notification);
export const useFilters = (): FilterBy =>
  useDashboardStore((state: DashboardStore) => state.filterBy);
export const useFiltersBuffer = () =>
  useDashboardStore((state: DashboardStore) => state.filtersBuffer);
export const useExternalFilter = () =>
  useDashboardStore((state: DashboardStore) => state.externalFilter);
export const useFiltersOptions = () =>
  useDashboardStore((state: DashboardStore) => state.filterOptions);
export const useDashboardActions = () =>
  useDashboardStore((state: DashboardStore) => state.actions);
