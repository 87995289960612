import React, { Suspense, useState } from 'react';
import { useLoaderData, Link, useSearchParams } from 'react-router-dom';
import { useGLGOParams } from 'hooks';
import {
  DashboardLoaderResponse,
  defaultSubscription,
  useDashboardQueries,
  useCurrentProjectData,
  useRecommendedThresholdContext,
  xapisEnv,
  WordsServedResponse,
  QuickQuoteResponse,
} from 'store';
import { Box, Card, Grid, Group } from '@mantine/core';
import {
  DashboardCard,
  DateFilter,
  filterTargets,
  getStatusBannerBody,
  getTimeSliceOptions,
  LoadingSkeleton,
  MetricsSummary,
  MyLanguages,
  PlanDetails,
  StatusBanner,
  updateDateParam,
  WordsServedEstimate,
  WordsServedUsage,
  GoLiveModal,
  Header,
  RestrictedComponent,
} from 'ui';
import {
  dashboardRC,
  GLGO_PROJECT_TYPE,
  GLWEB_PROJECT_TYPE,
  isDesktopApp,
  OLJS_PROJECT_TYPE,
} from 'helpers';
import { HiPencil } from 'react-icons/hi';
import { QueryMultiSelector } from 'ui';
import MTUsage from 'ui/components/glgo/dashboard/godashboard/mt-usage/MTUsage';
import { useCurrentProjectType } from 'hooks/glgo/useCurrentProjectType';
import MyContacts from 'ui/components/glgo/dashboard/godashboard/MyContacts';

const WordsServedUsageHeader = ({ showBlur }: { showBlur?: boolean }) => (
  <Header
    variant="h2"
    blur={showBlur}
    title="Words Served Usage"
    tooltipLabel="This chart shows your total words served for the month, today's increase,
                      and the projected total for the month based on your usage up to today. You can view historical
                      data and more filters in your Account Settings."
  />
);

const MTUsageHeader = ({ showBlur }: { showBlur?: boolean }) => (
  <Header
    variant="h2"
    blur={showBlur}
    title="MT Usage"
    tooltipLabel="This chart shows your total machine translation usage for the month as well as today's increase."
  />
);

type QuickQuoteProps = {
  isFreePlan: boolean;
  thresholdValue: number;
  monthlyWordsServed: WordsServedResponse[];
  quickQuote: QuickQuoteResponse;
};

const QuickQuote = ({
  isFreePlan,
  thresholdValue,
  monthlyWordsServed,
  quickQuote,
}: QuickQuoteProps) => {
  const [showEstimate, setShowEstimate] = useState(isFreePlan);

  const isQuickQuoteCached =
    quickQuote?.qmessage && quickQuote.qmessage.includes('result was cached');

  return showEstimate && isQuickQuoteCached ? (
    <WordsServedEstimate
      quickQuote={quickQuote}
      header={<WordsServedUsageHeader showBlur={true} />}
      setShowEstimate={setShowEstimate}
    />
  ) : (
    <WordsServedUsage
      thresholdValue={thresholdValue}
      monthlyWordsServed={monthlyWordsServed}
      header={<WordsServedUsageHeader />}
    />
  );
};

export const DashboardPage = () => {
  const { concatenatedKey, timeSliceFrom, shouldQueryQuickQuote } =
    useLoaderData() as DashboardLoaderResponse;

  const { projectId, projectKey = '' } = useGLGOParams();
  const { payKey } = xapisEnv.getHost;

  const [searchParams, setSearchParams] = useSearchParams();
  const [showGoLiveModal, setShowGoLiveModal] = useState(false);
  const thresholdFromEstimate = useRecommendedThresholdContext().threshold;
  const { project } = useCurrentProjectData();
  const isGLGODesktop = isDesktopApp();

  const {
    subscriptions,
    monthlyWordsServed,
    prevWordsServed,
    wordsServed,
    worldTraffic,
    translatedTraffic,
    quickQuote,
  } = useDashboardQueries({
    projectKey: projectKey!,
    payKey,
    concatenatedKey,
    timeSliceFrom,
    shouldQueryQuickQuote,
    origin_name: project?.origin_name || '',
  });

  const { translations: targets = [] } = project;

  const activeProjectStatus = project?.project_status || '';
  const isGLWEBEnterprisePlan = project?.project_type === 'OLJS';
  const isGLGOEnterprisePlan = project?.project_type === 'GLGO-ENTERPRISE';
  const isExpiredPlan = project?.subscription_status === 'expired';
  const hasSku = Boolean(project.subscription_sku);
  const isGLWEBEnterpriseWithoutSku = !hasSku && isGLWEBEnterprisePlan;

  const { isGLWEBProject } = useCurrentProjectType();

  const {
    threshold_value = 0,
    words_served = 0,
    price = 0,
  } = subscriptions[0] || {};

  const hasActiveSubscription = !isExpiredPlan && subscriptions.length > 0;
  const isFreePlan = price === 0;

  const thresholdValue = hasActiveSubscription ? threshold_value : 0;
  const wordsServedAmount = hasActiveSubscription
    ? parseInt(words_served || defaultSubscription.words_served)
    : 0;

  const filteredTargets = filterTargets(project.translations || []);

  const statusBannerBody = getStatusBannerBody(
    isExpiredPlan,
    isFreePlan,
    activeProjectStatus,
    setShowGoLiveModal,
    projectId || '',
    thresholdFromEstimate
  );

  const handleSelectedDate = (value: string) => {
    updateDateParam(value, searchParams, setSearchParams);
  };

  if (!project || !projectKey) {
    return <></>;
  }

  return (
    <>
      <GoLiveModal
        activeProjectKey={projectKey}
        setOpened={setShowGoLiveModal}
        opened={showGoLiveModal}
      />
      {statusBannerBody && (
        <StatusBanner
          statusBody={statusBannerBody}
          statusType={isExpiredPlan ? 'subscription' : 'translations'}
        />
      )}
      <Grid
        mt={{ base: statusBannerBody ? 20 : 0, sm: statusBannerBody ? 10 : 0 }}
      >
        <Grid.Col span={12}>
          <Grid>
            <Grid.Col span={{ base: 12, lg: isGLWEBProject ? 9 : 12 }}>
              <DashboardCard noPadding={isGLWEBProject ? 'left' : undefined}>
                <MetricsSummary
                  timeSlices={translatedTraffic.slices}
                  prevTimeSlices={translatedTraffic.prevSlices}
                  worldTraffic={worldTraffic}
                  wordsServed={wordsServed}
                  prevWordsServed={prevWordsServed}
                  header={
                    <Header
                      wrapContent
                      variant="h2"
                      title="Metrics Summary"
                      tooltipLabel="This section of your dashboard presents summary statistics related to your project.
                      You can filter by language and time period. For a more detailed breakdown, you can go to the Metrics tab."
                      flexItems={
                        <>
                          <QueryMultiSelector
                            encodeKeys
                            selectAll
                            shouldRevalidate
                            options={filteredTargets}
                          />
                          <DateFilter
                            timeSliceOptions={getTimeSliceOptions()}
                            timeSliceFrom={timeSliceFrom}
                            onSelect={handleSelectedDate}
                          />
                        </>
                      }
                    />
                  }
                />
              </DashboardCard>
            </Grid.Col>
            <RestrictedComponent
              allowedProjectTypes={
                new Set([OLJS_PROJECT_TYPE, GLWEB_PROJECT_TYPE])
              }
            >
              <Grid.Col span={{ base: 12, lg: 3 }}>
                <DashboardCard noPadding="right">
                  <MyContacts
                    header={<Header variant="h2" title="Contacts" />}
                  />
                </DashboardCard>
              </Grid.Col>
            </RestrictedComponent>
          </Grid>
        </Grid.Col>
        <Grid.Col span={12}>
          <Grid>
            <Grid.Col span={{ base: 12, lg: 7 }}>
              <DashboardCard noPadding="left">
                <Suspense
                  fallback={
                    <Box>
                      <Group>
                        <RestrictedComponent
                          allowedProjectTypes={
                            new Set([OLJS_PROJECT_TYPE, GLWEB_PROJECT_TYPE])
                          }
                        >
                          <MTUsageHeader />
                        </RestrictedComponent>
                        <RestrictedComponent
                          allowedProjectTypes={new Set([GLGO_PROJECT_TYPE])}
                        >
                          <WordsServedUsageHeader />
                        </RestrictedComponent>
                      </Group>
                      <Card h={500} p={30}>
                        <LoadingSkeleton />
                      </Card>
                    </Box>
                  }
                >
                  <RestrictedComponent
                    allowedProjectTypes={
                      new Set([OLJS_PROJECT_TYPE, GLWEB_PROJECT_TYPE])
                    }
                  >
                    <MTUsage header={<MTUsageHeader />} thresholdValue={0} />
                  </RestrictedComponent>
                  <RestrictedComponent
                    allowedProjectTypes={new Set([GLGO_PROJECT_TYPE])}
                  >
                    <QuickQuote
                      isFreePlan={isFreePlan}
                      thresholdValue={thresholdValue}
                      monthlyWordsServed={monthlyWordsServed}
                      quickQuote={quickQuote}
                    />
                  </RestrictedComponent>
                </Suspense>
              </DashboardCard>
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 5 }}>
              <Grid>
                {isGLGOEnterprisePlan || isGLWEBEnterpriseWithoutSku ? (
                  <></>
                ) : (
                  <>
                    <Grid.Col span={12}>
                      <DashboardCard noPadding="right">
                        <PlanDetails
                          isExpiredPlan={isExpiredPlan}
                          subscriptions={subscriptions}
                          wordsServedAmount={wordsServedAmount}
                          header={
                            <Header
                              variant="h2"
                              title="Plan Details"
                              tooltipLabel="This shows your current subscription package and your subscription’s next renewal date. You can manage your subscription in your Account Settings."
                              flexItems={
                                !isGLGODesktop && (
                                  <Box data-testid="pw-plan-details-edit-button">
                                    <Link
                                      to={`/${projectId}/${dashboardRC.PAGE_ACCOUNT}/billing-information`}
                                    >
                                      <HiPencil className="cardActionIcon" />
                                    </Link>
                                  </Box>
                                )
                              }
                            />
                          }
                        />
                      </DashboardCard>
                    </Grid.Col>
                  </>
                )}
                <Grid.Col span={12}>
                  <DashboardCard noPadding="right">
                    <MyLanguages
                      targets={targets}
                      subscriptions={subscriptions}
                      header={
                        <Header
                          variant="h2"
                          title="My Languages"
                          tooltipLabel="This shows the languages your website can be translated into.
                            To add or remove languages, and to manage other settings, go to your Project Settings."
                          flexItems={
                            <Box data-testid="pw-my-languages-edit-button">
                              <Link
                                to={`/${projectId}/${dashboardRC.PAGE_SETTINGS}/language-settings`}
                              >
                                <HiPencil className="cardActionIcon" />
                              </Link>
                            </Box>
                          }
                        />
                      }
                    />
                  </DashboardCard>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default DashboardPage;
