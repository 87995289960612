import { Box, Flex, Text, Image, useMantineTheme, Title } from '@mantine/core';
import InContextEditor from './in-context-editor.svg';
import { ImCheckmark } from 'react-icons/im';
import { DownloadDesktopAppCard } from 'ui';
import { desktopAppOS } from 'helpers';

export const features = [
  'See translations in real-time',
  'Make edits directly on page',
  'Ensure translations fit seamlessly',
];

export const DesktopAppPage = () => {
  const colors = useMantineTheme().colors;

  return (
    <Box>
      <Flex w="100%" columnGap="2rem" justify={'center'}>
        <Flex
          w={{ base: '100%', md: 'fit-content' }}
          maw={{ base: '100%', md: '50%' }}
          direction="column"
          rowGap="2rem"
        >
          <Flex mt={16} direction="column" rowGap="0.75rem">
            <Title order={3} c="text3.5" lts={3} fw={500} tt="uppercase">
              IN-CONTEXT EDITING
            </Title>
            <Title order={1} fw={400}>
              <Text fz="inherit" fw={700} span>
                Editor
              </Text>{' '}
              for true in-context viewing.
            </Title>
          </Flex>
          <Flex direction="column" rowGap="0.5rem">
            {features.map((feature) => (
              <Text
                key={feature}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 15,
                }}
              >
                <ImCheckmark size={16} color={colors.text3[5]} />
                {feature}
              </Text>
            ))}
          </Flex>
          <Text maw="30rem">
            The contextual editor allows you to view and edit translations
            directly on your website, in context. You can also tweak CSS,
            visually exclude content from translation, and much more.
          </Text>
          <Flex w="100%" maw={{ base: '100%', xs: '28rem' }} columnGap="1rem">
            {desktopAppOS.map((os) => (
              <DownloadDesktopAppCard key={os} os={os} />
            ))}
          </Flex>
        </Flex>
        <Flex
          justify="flex-start"
          align="center"
          display={{ base: 'none', md: 'flex' }}
          style={{ flex: 1 }}
        >
          <Image
            fit="cover"
            maw="35rem"
            style={{ flex: 1 }}
            src={InContextEditor}
            alt="In-context editing suite app preview"
          />
        </Flex>
      </Flex>
    </Box>
  );
};
