import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Loader,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from 'react-hook-form';
import { AccountForm } from './types';
import { xapisEnv } from 'store';
import AccountPasswordInput from './AccountPasswordInput';
import { IoMdCloseCircle } from 'react-icons/io';
import { handleCreateAccount } from './handleProjectSetupSteps';
import classes from './CreateAccountForm.module.css';
import GLA from '../../googleAnalytics';
import fathomEventTracker from '../../fathomAnalytics';
import { useAuth, AuthContextProps } from 'auth';
import { AnchorLink } from '../../Generic';

export const CreateAccountForm = () => {
  const {
    sendTagEvent,
    EVENT_FORM_SUBMISSION,
    EVENT_ACTION_SIGNUP_PAGE,
    EVENT_ACTION_LOGIN,
    EVENT_LABEL_CREATE_ACCOUNT_FORM,
  } = GLA;
  const auth = useAuth();

  const { glgoMarketingUrl = '' } = xapisEnv.getHost;

  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [isAlreadyCreated, setIsAlreadyCreated] = useState(false);

  const onSubmit = (formParams: AccountForm) =>
    handleCreateAccount(
      formParams,
      isCreatingAccount,
      setIsCreatingAccount,
      setIsAlreadyCreated,
      auth as AuthContextProps
    );

  const {
    register,
    handleSubmit,
    formState: { isValid },
    control,
  } = useForm<AccountForm>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      subscribed_to_marketing: false,
    },
    shouldUseNativeValidation: true,
  });

  const termsOfServiceUrl = `${glgoMarketingUrl}/terms`;
  const privacyPolicyUrl = `${glgoMarketingUrl}/privacy`;

  return (
    <>
      <form
        style={{ width: '100%' }}
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Flex direction="column" align="center" rowGap={5}>
          <TextInput
            classNames={{ input: classes.textInputInput }}
            w="100%"
            required
            label="First name"
            withAsterisk={false}
            {...register('first_name', { required: true })}
          />
          <TextInput
            classNames={{ input: classes.textInputInput }}
            w="100%"
            required
            label="Last name"
            withAsterisk={false}
            {...register('last_name', { required: true })}
          />
          <TextInput
            className={`${isAlreadyCreated ? classes.existingEmail : ''}`}
            classNames={{ input: classes.textInputInput }}
            w="100%"
            required
            label="Email Address"
            type="email"
            withAsterisk={false}
            rightSection={
              isAlreadyCreated ? (
                <IoMdCloseCircle color="red" size={18} />
              ) : (
                <></>
              )
            }
            {...register('email', { required: true })}
          />
          <AccountPasswordInput control={control} register={register} />
        </Flex>
        <Flex w="100%" direction="column" align="center" mt={20} rowGap={30}>
          <Checkbox
            className={classes.termsPolicyCheckbox}
            label={<Text>Send me news and feature updates.</Text>}
            {...register('subscribed_to_marketing', { required: false })}
          />
          <Flex direction="column" gap={15} w="100%">
            <Button
              w="100%"
              h={47}
              disabled={!isValid}
              type="submit"
              onClick={() => {
                sendTagEvent(
                  EVENT_FORM_SUBMISSION,
                  EVENT_ACTION_SIGNUP_PAGE,
                  EVENT_LABEL_CREATE_ACCOUNT_FORM
                );
                fathomEventTracker(
                  'Account creation button - Login/Signup page'
                );
              }}
            >
              {isCreatingAccount ? (
                <Loader color="cta1.6" size={28} />
              ) : (
                <Text c="inherit" fz="inherit" fw="inherit" id="sign-up-button">
                  Sign Up
                </Text>
              )}
            </Button>
            <Text ta="center" fw={500}>
              No credit card required
            </Text>
          </Flex>
          <Flex w="100%" align="center">
            <Divider w="50.5%" size={1} color="divider.1" />
            <Box w="14%">
              <Text fw={600} ta="center">
                OR
              </Text>
            </Box>
            <Divider w="50.5%" size={1} color="divider.1" />
          </Flex>
        </Flex>
      </form>
      <Text>
        Already have an account?&nbsp;&nbsp;
        <Button
          px={0}
          fw={700}
          variant="transparent"
          onClick={() => {
            sendTagEvent(
              EVENT_FORM_SUBMISSION,
              EVENT_ACTION_LOGIN,
              EVENT_LABEL_CREATE_ACCOUNT_FORM
            );
            fathomEventTracker('Log in button - Login/Signup page');
            auth.signinRedirect().then();
          }}
        >
          Log In
        </Button>
      </Text>
      <Text ta="center" fz="xs" fw={500}>
        By signing up, you agree to the{' '}
        <AnchorLink
          fz="inherit"
          fw="inherit"
          to={termsOfServiceUrl}
          target="_blank"
          td="none"
        >
          Terms of Service
        </AnchorLink>{' '}
        and{' '}
        <AnchorLink
          fz="inherit"
          fw="inherit"
          to={privacyPolicyUrl}
          target="_blank"
          td="none"
        >
          Privacy Policy
        </AnchorLink>
        .
      </Text>
    </>
  );
};

export default CreateAccountForm;
