import {
  fetchTranslatedTrafficPromise,
  TranslatedTrafficTimeslice,
} from '../TranslatedTrafficStore';
import {
  fetchMonthlyWordsServedPromise,
  fetchPrevWordsServedPromise,
  WordsServedResponse,
} from '../WordsServedStore';
import {
  fetchWorldTrafficPromise,
  WorldTrafficResponse,
} from '../WorldTrafficStore';
import {
  queryOptions,
  useSuspenseQueries,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { queryWordsServed } from './shared/queryWordsServed';
import { getPrevTimeSliceFrom, isSuccessStatus } from 'helpers';
import { useCurrentProjectData } from './projectQuery';
import { Xapis } from '../xapis-wrappers/xapis';
import { querySubscriptions } from './shared/querySubscriptions';
import { queryQuickQuote } from './shared/queryQuickQuote';
import { QuickQuoteResponse } from '../quickQuote';

export const queryMonthlyWordsServed = (concatenatedKey: string) =>
  queryOptions({
    queryKey: ['monthlyWordsServed', concatenatedKey],

    queryFn: async () => {
      if (!concatenatedKey.length || concatenatedKey === '0') return [];
      const response = await fetchMonthlyWordsServedPromise(concatenatedKey);
      if (
        !response ||
        response.status !== 200 ||
        !response.data ||
        !response.data.data
      ) {
        throw new Error('Failed to fetch monthly words served data');
      }
      return response.data.data;
    },
  });

export const queryPrevWordsServed = (
  concatenatedKey: string,
  prevTimeSlice: string,
  timeSliceFrom: string,
  rollup: string
) =>
  queryOptions({
    queryKey: [
      'prevWordsServed',
      concatenatedKey,
      prevTimeSlice,
      timeSliceFrom,
      rollup,
    ],

    queryFn: async () => {
      if (!concatenatedKey.length || concatenatedKey === '0') return [];
      const response = await fetchPrevWordsServedPromise(
        concatenatedKey,
        prevTimeSlice,
        timeSliceFrom,
        rollup
      );
      if (
        !response ||
        response.status !== 200 ||
        !response.data ||
        !response.data.data
      ) {
        throw new Error('Failed to fetch previous words served data');
      }
      return response.data.data;
    },
  });

export const queryMTUsage = (concatenatedKey: string) =>
  queryOptions({
    queryKey: ['MTUsage', concatenatedKey],
    queryFn: async () => {
      if (!concatenatedKey.length || concatenatedKey === '0') return [];
      const date = new Date();
      const numDays = date.getDate() - 1;
      const { data, status } = await Xapis.Metrics.get({
        rollup: 'day',
        glnow: '1',
        type: 'timeseries',
        translation_key: concatenatedKey,
        series: 'num_words_mt',
        timeslice_from: `${numDays} day`,
      } as Record<string, string>);
      if (!isSuccessStatus(status) || !data) {
        throw new Error('Failed to fetch MTUsage data');
      }
      return data.data;
    },
  });

export type MTUsageResponse = {
  num_words_mt: string;
  timeslice: string;
}[];

export const queryWorldTraffic = (
  concatenatedKey: string,
  timeSliceFrom: string
) =>
  queryOptions({
    queryKey: ['worldTraffic', concatenatedKey, timeSliceFrom],

    queryFn: async () => {
      if (!concatenatedKey.length || concatenatedKey === '0') return [];
      const response = await fetchWorldTrafficPromise(
        concatenatedKey,
        timeSliceFrom
      );
      if (
        !response ||
        response.status !== 200 ||
        !response.data ||
        !response.data.data
      ) {
        throw new Error('Failed to fetch world traffic data');
      }
      return response.data.data as WorldTrafficResponse[];
    },
  });

export const queryTranslatedTraffic = (
  translationKey: string,
  timeSliceFrom: string
) =>
  queryOptions({
    queryKey: ['translatedTraffic', translationKey, timeSliceFrom],

    queryFn: async () => {
      let prevSlices = [] as TranslatedTrafficTimeslice[],
        slices = [] as TranslatedTrafficTimeslice[];
      const prevTimeSliceFrom = getPrevTimeSliceFrom(timeSliceFrom);

      if (translationKey && timeSliceFrom && prevTimeSliceFrom) {
        try {
          const [prevTranslatedTrafficRes, translatedTrafficRes] =
            await Promise.allSettled([
              fetchTranslatedTrafficPromise(
                translationKey,
                prevTimeSliceFrom,
                'timeseries'
              ),
              fetchTranslatedTrafficPromise(
                translationKey,
                timeSliceFrom,
                'timeseries'
              ),
            ]);

          if (
            prevTranslatedTrafficRes.status === 'fulfilled' &&
            prevTranslatedTrafficRes.value.status === 200
          ) {
            const { data } = prevTranslatedTrafficRes.value;
            prevSlices = data.data;
          }

          if (
            translatedTrafficRes.status === 'fulfilled' &&
            translatedTrafficRes.value.status === 200
          ) {
            const { data } = translatedTrafficRes.value;
            slices = data.data;
          }
        } catch (err) {
          console.error('ERROR from Promise.allSettled:', err);
          throw new Error('Failed to fetch translated traffic');
        }
      }

      return { prevSlices, slices } as TranslatedTrafficResponse;
    },
  });

export type TranslatedTrafficResponse = {
  prevSlices: TranslatedTrafficTimeslice[];
  slices: TranslatedTrafficTimeslice[];
};

/**
 * A custom hook that fetches and returns various dashboard-related data.
 *
 * This hook uses `useSuspenseQueries` to fetch multiple sets of data concurrently:
 * - Subscriptions
 * - Monthly words served
 * - Previous words served
 * - Current words served
 * - World traffic
 * - Translated traffic
 * - MTUsage
 *
 * It also uses the `useCurrentProject` hook to get the current project data,
 * which is used to determine if the subscription is expired.
 *
 * @param {Object} params - The parameters for the queries.
 * @param {string} params.projectKey - The key of the current project.
 * @param {string} params.payKey - The pay key associated with the project.
 * @param {string} params.concatenatedKey - The concatenated key for filtering data.
 * @param {string} params.timeSliceFrom - The start time for the data slice.
 *
 * @returns {Object} An object containing the fetched data:
 *   @property {Subscription[]} subscriptions - The project's subscriptions.
 *   @property {any} monthlyWordsServed - Monthly words served data.
 *   @property {any} prevWordsServed - Previous period's words served data.
 *   @property {any} wordsServed - Current period's words served data.
 *   @property {WorldTrafficResponse[]} worldTraffic - World traffic data.
 *   @property {Object} translatedTraffic - Translated traffic data.
 *   @property {TranslatedTrafficTimeslice[]} translatedTraffic.prevSlices - Previous period's translated traffic.
 *   @property {TranslatedTrafficTimeslice[]} translatedTraffic.slices - Current period's translated traffic.
 *   @property {MTUsageResponse} - Month to Date MT data
 *
 * @throws Will throw an error if any of the queries fail to fetch data.
 */
export const useDashboardQueries = (params: {
  projectKey: string;
  payKey: string;
  concatenatedKey: string;
  timeSliceFrom: string;
  shouldQueryQuickQuote: boolean;
  origin_name: string;
}) => {
  const {
    projectKey,
    payKey,
    concatenatedKey,
    timeSliceFrom,
    shouldQueryQuickQuote,
    origin_name,
  } = params;

  const prevTimeSlice = getPrevTimeSliceFrom(timeSliceFrom);
  const rollup = timeSliceFrom.includes('year') ? 'month' : 'day';

  const { project } = useCurrentProjectData();

  const { data: subscriptions } = useSuspenseQuery(
    querySubscriptions(
      payKey,
      projectKey,
      project.subscription_status === 'expired'
    )
  );

  const queries = [
    queryMonthlyWordsServed(concatenatedKey),
    queryPrevWordsServed(concatenatedKey, prevTimeSlice, timeSliceFrom, rollup),
    queryWordsServed(concatenatedKey, timeSliceFrom, rollup),
    queryWorldTraffic(concatenatedKey, timeSliceFrom),
    queryTranslatedTraffic(concatenatedKey, timeSliceFrom),
    queryMTUsage(concatenatedKey),
    queryQuickQuote(shouldQueryQuickQuote, origin_name),
  ] as const;

  const results = useSuspenseQueries({ queries });

  return {
    subscriptions,
    monthlyWordsServed: results[0].data as WordsServedResponse[],
    prevWordsServed: results[1].data as WordsServedResponse[],
    wordsServed: results[2].data as WordsServedResponse[],
    worldTraffic: results[3].data as WorldTrafficResponse[],
    translatedTraffic: results[4].data as TranslatedTrafficResponse,
    MTUsage: results[5].data as MTUsageResponse,
    quickQuote: results[6].data as QuickQuoteResponse,
  };
};
