type ProjectTranslationMap = Record<string, TranslationKey>;

export const filterTranslations = (
  translations: TranslationKey[] | undefined
) => {
  if (!translations) return [];
  return translations.filter(
    (t) =>
      !['YY', 'XX'].includes(t.target_lang_code) &&
      t.target_lang_name !== t.source_lang_name
  );
};
export const activeTranslations = (
  translations: TranslationKey[] | undefined
) => {
  if (!translations) return [];
  return translations.filter((t) => t.is_active);
};

export const filterTranslationKeys = (translations: TranslationKey[]) => {
  return (
    translations
      ?.filter(
        ({ target_lang_code, source_lang_code }) =>
          ![source_lang_code, 'YY'].includes(target_lang_code)
      )
      .map((t) => t.translation_key) || []
  );
};

export const isYYTranslation = (translation: TranslationKey | undefined) => {
  return translation?.target_lang_code === 'YY';
};

export const createTranslationMap = (
  project: ProjectKey | undefined
): ProjectTranslationMap => {
  const { translations = [] } = project || {};

  return translations.reduce(
    (acc: Record<string, TranslationKey>, translation) => {
      const { translation_key } = translation;
      acc[translation_key] = translation;
      return acc;
    },
    {}
  );
};

export function getTargetName(target: TranslationKey | undefined) {
  if (!target) return '';
  return target?.target_lang_name || target?.target_lang_code;
}
