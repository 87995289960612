import React from 'react';
import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Loader,
  Text,
  Title,
} from '@mantine/core';
import { useSetupStepsContext } from './ProjectSetupProvider';
import BrowserWindow from './BrowserWindow';
import { LanguageSelectorStyle } from './LanguageSelectorStyle';
import { handleCreateProject } from './handleProjectSetupSteps';
import { MobileActionHeader } from './MobileActionHeader';
import { useUserProvider } from 'store';
import fathomEventTracker from '../../fathomAnalytics';

export const CustomizeAppearanceSideMenuContent = () => {
  const {
    setActiveStep,
    isCreatingProject,
    setIsCreatingProject,
    selectedSourceLangCode,
    selectedTargetLangCodes,
    selectedTheme,
    selectedPosition,
    projectDomain: domain,
  } = useSetupStepsContext();
  const { xapisUser, setXapisUser } = useUserProvider();

  return (
    <Flex
      w="100%"
      maw={450}
      rowGap="1.5rem"
      direction="column"
      justify="center"
      align="center"
    >
      <Title fz="2rem" order={1}>
        Project Setup
      </Title>
      <Divider w="100%" color="divider.0" size={2} />
      <Text fz="xl" fw={600} ta="center">
        Choose how the language selector appears
      </Text>
      <Text fz="md" fw={500} ta="center">
        (You can edit this anytime after your project is created.)
      </Text>
      <Flex columnGap="1rem">
        <Button
          variant="outline"
          disabled={isCreatingProject}
          onClick={() => setActiveStep((prevStep) => prevStep - 0.5)}
        >
          Back
        </Button>
        <Button
          disabled={isCreatingProject}
          onClick={() => {
            fathomEventTracker('Language Selector - 4');
            handleCreateProject(
              isCreatingProject,
              setIsCreatingProject,
              setActiveStep,
              selectedSourceLangCode,
              selectedTargetLangCodes,
              selectedTheme,
              selectedPosition,
              domain,
              xapisUser,
              setXapisUser
            );
          }}
        >
          {isCreatingProject ? (
            <Loader color="cta1.6" size="sm" />
          ) : (
            <Text
              c="inherit"
              fz="inherit"
              fw="inherit"
              id="language-selector-4"
            >
              Next
            </Text>
          )}
        </Button>
      </Flex>
    </Flex>
  );
};

export const CustomizeAppearanceBodyContent = () => {
  return (
    <Box px="2rem">
      <BrowserWindow>
        <LanguageSelectorStyle />
      </BrowserWindow>
    </Box>
  );
};

export const MobileCustomizeAppearanceStep = () => {
  const {
    setActiveStep,
    isCreatingProject,
    setIsCreatingProject,
    selectedSourceLangCode,
    selectedTargetLangCodes,
    selectedTheme,
    selectedPosition,
    projectDomain: domain,
  } = useSetupStepsContext();
  const { xapisUser, setXapisUser } = useUserProvider();

  return (
    <Card w="100%" maw={{ base: '100%', xs: 650, lg: 450 }} px="1rem" py="2rem">
      <Flex w="100%" h="100%" direction="column" align="center" rowGap="1.5rem">
        <MobileActionHeader title="Project Setup" />
        <Divider w="100%" color="divider.0" size={2} />
        <Flex w="100%" direction="column" rowGap="1rem">
          <Text fz="md" fw={700}>
            Language Selector Style
          </Text>
          <LanguageSelectorStyle />
        </Flex>
        <Button
          w={{ base: '100%', xs: 'auto' }}
          disabled={isCreatingProject}
          onClick={() => {
            fathomEventTracker('Language Selector - 4');
            handleCreateProject(
              isCreatingProject,
              setIsCreatingProject,
              setActiveStep,
              selectedSourceLangCode,
              selectedTargetLangCodes,
              selectedTheme,
              selectedPosition,
              domain,
              xapisUser,
              setXapisUser
            );
          }}
        >
          {isCreatingProject ? (
            <Loader color="cta1.6" size="sm" />
          ) : (
            <Text
              c="inherit"
              fz="inherit"
              fw="inherit"
              id="language-selector-4"
            >
              Next
            </Text>
          )}
        </Button>
      </Flex>
    </Card>
  );
};
