import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { Xapis } from '../xapis-wrappers/xapis';
import { decodeProjectId, isValidKey, isSuccessStatus } from 'helpers';
import { useGLGOParams } from 'hooks';

export const glossaryQuery = (key: string) => {
  const projectKey = isValidKey(key) ? key : decodeProjectId(key);

  return queryOptions({
    queryKey: ['glossary', projectKey],
    queryFn: () => getQueryData(projectKey),
  });
};

async function getQueryData(projectKey: string) {
  const data = await Xapis.Glossary.get(`P${projectKey}`);
  if (!isSuccessStatus(data.status) || !data.glossary) {
    throw new Error('Failed to fetch glossary data');
  }
  return data.glossary;
}

export function useProjectGlossary() {
  const { projectKey } = useGLGOParams();

  if (!projectKey) {
    throw new Error('projectKey is missing from route params');
  }

  const { data: glossary } = useSuspenseQuery(glossaryQuery(projectKey));
  return glossary;
}
