import { notifications } from '@mantine/notifications';
import { asciiToHex, isSuccessStatus } from 'helpers';
import { Xapis } from 'store';

const updateAllAppearances = (
  yyTarget: TranslationKey | undefined,
  updatedCustomCSS: string,
  setShowOverrideCSSModal: React.Dispatch<React.SetStateAction<boolean>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoading(true);
  const hexedUpdatedCss = asciiToHex(updatedCustomCSS);

  if (!yyTarget || !hexedUpdatedCss) {
    notifications.show({
      message: 'Unable to update selector widget at this time.',
    });
    return;
  }

  const {
    translation_key = '',
    project_key = '',
    custom_css_hash = '',
    staging_custom_css_hash = '',
  } = yyTarget;

  Xapis.ProjectTranslation.put(project_key, translation_key, {
    custom_css: hexedUpdatedCss,
    custom_css_hash,
    staging_custom_css: hexedUpdatedCss,
    staging_custom_css_hash,
  })
    .then(({ status }) => {
      if (isSuccessStatus(status)) {
        notifications.show({
          message: 'Successfully updated your selector widget!',
        });
      } else {
        notifications.show({
          message: 'Oops, there was a problem with saving your edits.',
        });
      }
    })
    .catch(() => {
      notifications.show({
        message: 'Unable to update selector widget at this time.',
      });
    })
    .finally(() => {
      setShowOverrideCSSModal(false);
      setLoading(false);
    });
};

export default updateAllAppearances;
