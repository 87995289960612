import { Box } from '@mantine/core';

const tagPaths = {
  a: 'M7.25 9.25 5.75 11C5 12 5.35 13.25 6 14 6.65 14.75 8 15 9 14.25L10.75 12.75M9.25 7.25 11 5.75C12 5 13.25 5.25 14 6 14.75 6.75 15 8 14.25 9L12.75 10.75M15 5M11.5 8.5 8.5 11.5',
  b: 'M7 5H11.3C12 5 12.4 5.4 12.8 6 13 6.5 13 6.5 13.1 7.1 13.1 8 13 8 12.9 8.5 12.6 9 12.4 9.3 11.9 9.5H7V5ZM7 9.5H11.9C12.8 9.9 13.19 10.17 13.6 11 13.73 11.4 13.78 11.71 13.8 12.2 13.8 12.8 13.7 13.3 13.6 13.6 13.1 14.6 12.8 14.7 12 15H7Z',
  span: 'M9 5C7 5 7 5 6.67 9 6.53 9.58 5.6 9.65 5 9.75V10.25C5.59 10.35 6.55 10.46 6.67 11 7 15 7 15 9 15M11 5C13 5 13 5 13.33 8.99 13.51 9.56 14.4 9.65 15 9.75V10.25C14.4 10.35 13.46 10.52 13.33 11.02 13 15 13 15 11 15',
  i: 'M15 5H9M11 15H5M12 5 8 15',
  u: 'M6.5 5V10C6.5 11 7 11.8 7.2 12 8 13 9.6 13 10 13 10.4 13 12 13 12.8 12 13 11.8 13.5 11 13.5 10V5M5 15H15',
} as const;

const tagForm = {
  sc: 'M19.75 10.75 16.25 17C16 17.65 15.65 18 15 18L1.35 18C.65 18 0 17.27 0 16.47V3.47C0 2.57.65 2 1.35 2L15 2C15.65 2 16 2.35 16.25 3L19.75 9.25C20 9.75 20 10.18 19.75 10.75Z',
  ec: 'M.25 9.25 3.75 3C4 2.35 4.35 2 5 2L18.65 2C19.35 2 20 2.73 20 3.53V16.53C20 17.43 19.35 18 18.65 18L5 18C4.35 18 4 17.65 3.75 17L.25 10.75C0 10.25 0 9.82.25 9.25Z',
  none: 'm2 9 0-6c0-.7.3-1 1-1l14 0c.7 0 1 .3 1 1v14c0 .6-.2 1-1 1l-14 0c-.7-0-1-.3-1-1z',
} as const;

type TagForm = keyof typeof tagForm;

type TagIconsProps = {
  tag?: string;
  kind?: TagForm;
};

export const TagIcon = ({ kind = 'none', tag = 'span' }: TagIconsProps) => {
  const skewX = kind === 'sc' ? -1 : 1;
  const path = tagPaths[tag as keyof typeof tagPaths] || tagPaths.span;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={tagForm[kind]} />
      <g transform={`translate(${skewX}, 0)`}>
        <path
          d={path}
          strokeWidth={1.4}
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const QTag = ({ kind = 'sc' }: TagIconsProps) => {
  return (
    <Box
      component="span"
      c="#2C49AC"
      fz="larger"
      style={{
        transform: `translateY(-1px)`,
        whiteSpace: 'pre-wrap',
      }}
    >
      {kind === 'sc' ? <>&ldquo;</> : <>&rdquo;</>}
    </Box>
  );
};
