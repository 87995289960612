import { create } from 'zustand';
import PosterPromise from './service-wrappers/PosterPromise';
import { AxiosError } from 'axios';
import { failure, NOOP } from 'helpers';
import { xapisEnv } from './service-wrappers/xapisService';

type SendContactUsParams = {
  app: string; // ex. GlobalLinkGO
  domain: string; //ex. Staging, RC, Live
  firstName: string;
  lastName: string;
  company?: string;
  email: string;
  phoneNumber?: string;
  how?: string;
  interest?: string;
  source?: string;
  message: string;
};

type SendFeedbackParams = {
  level: string;
  userTitle: string;
  userName: string;
  userKey: string;
  projectKey: string;
  project: string;
  app: string;
  domain: string;
  title: string;
  message: string;
  issueType: string;
};

export type NotifyStore = {
  sendContactUsMessage: (
    data: SendContactUsParams,
    success?: () => void
  ) => void;
  sendFeedbackMessage: (data: SendFeedbackParams, success: () => void) => void;
};

export const useNotifyStore = create<NotifyStore>()(() => ({
  sendContactUsMessage: (data, success = NOOP) => {
    const { notifyUrl } = xapisEnv.getHost;

    return PosterPromise(notifyUrl, '/contactus', data)
      .then(() => success())
      .catch((error: AxiosError) => {
        console.error('ERROR:', error);
        failure(error, 'Something went wrong.');
      });
  },
  sendFeedbackMessage: (data, success) => {
    const { notifyUrl } = xapisEnv.getHost;

    return PosterPromise(notifyUrl, '/logFeedback', data)
      .then(() => success())
      .catch((error: AxiosError) => {
        console.error('ERROR:', error);
        failure(error, 'Something went wrong.');
      });
  },
}));

export default useNotifyStore;
