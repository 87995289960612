import axios, { AxiosResponse } from 'axios';
import { failure } from 'helpers';
import getAxiosUrlAndConfig from './getAxiosUrlAndConfig';

/**
 *
 * @param endpoint
 * @param params
 * @param success
 * @param failed
 * @param cleanup
 * @constructor
 */
export const Deleter = (
  endpoint: string,
  params: object,
  success: (response: AxiosResponse) => void,
  failed: { errorMessage?: string; action?: () => void },
  cleanup?: () => void
) => {
  const { url, config } = getAxiosUrlAndConfig(endpoint, params);

  axios
    .delete(url, config)
    .then((results) => {
      success(results);
    })
    .catch((error) => {
      failure(error, failed?.errorMessage);
      failed.action && failed.action();
    })
    .finally(() => {
      cleanup && cleanup();
    });
};

export default Deleter;
