const compareStrings = (
  stringA: string,
  stringB: string,
  caseInsensitive: boolean
) => {
  return caseInsensitive
    ? stringA.toLowerCase().localeCompare(stringB.toLowerCase())
    : stringA.localeCompare(stringB);
};

/**
 * Sorts an array of strings or objects by key-value in alphabetical order
 */
export const sortAlphabetically = <T extends string | Record<string, unknown>>(
  array: T[],
  key?: T extends Record<string, unknown> ? keyof T : never,
  caseInsensitive: boolean = true
) => {
  return array.sort((valueA, valueB) => {
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return compareStrings(valueA, valueB, caseInsensitive);
    }
    if (key && typeof valueA === 'object' && typeof valueB === 'object') {
      const { [key]: keyValueA } = valueA;
      const { [key]: keyValueB } = valueB;
      if (typeof keyValueA === 'string' && typeof keyValueB === 'string') {
        return compareStrings(keyValueA, keyValueB, caseInsensitive);
      }
    }
    return 0;
  });
};

type SortingOrder = 'asc' | 'desc';
const compareNumbers = (numA: number, numB: number, order: SortingOrder) => {
  if (order === 'asc') {
    return numA - numB;
  }
  return numB - numA;
};

/**
 * Sorts an array of numbers or objects by key-value
 * in the specified order: Ascending or Descending
 */
export const sortByAscOrDescOrder = <
  T extends number | Record<string, unknown>,
>(
  array: T[],
  key?: T extends Record<string, unknown> ? keyof T : never,
  order: SortingOrder = 'asc'
) => {
  return array.sort((valueA, valueB) => {
    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return compareNumbers(valueA, valueB, order);
    }
    if (key && typeof valueA === 'object' && typeof valueB === 'object') {
      const { [key]: keyValueA } = valueA;
      const { [key]: keyValueB } = valueB;
      if (typeof keyValueA === 'number' && typeof keyValueB === 'number') {
        return compareNumbers(keyValueA, keyValueB, order);
      }
    }
    return 0;
  });
};
