import { SVGAttributes } from 'react';

type IconProps = {
  svg: { path: string; attr?: SVGAttributes<SVGElement> };
  size?: number | string;
};

const Icon = ({ svg, size }: IconProps) => {
  const { path, attr } = svg;
  return (
    <svg
      width={size || attr?.width || '20'}
      height={size || attr?.height || '20'}
      viewBox={attr?.viewBox || '0 0 20 20'}
      fill={attr?.fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d={path}
          stroke={attr?.stroke || 'currentColor'}
          strokeWidth={attr?.strokeWidth || 1}
          strokeLinecap={attr?.strokeLinecap || 'round'}
          strokeLinejoin={attr?.strokeLinejoin || 'round'}
        />
      </g>
    </svg>
  );
};

export const NeverTranslateIcon = ({
  size,
  color,
}: {
  size?: number;
  color?: string;
}) => {
  const svg = {
    path: 'M10 5.6 16.1 16.1H3.9L10 5.6ZM10 2.3 1.1 17.7H18.9L10 2.3ZM10.8 13.7H9.2V15.3H10.8V13.7ZM10.8 8.8H9.2V12.9H10.8V8.8Z',
    attr: {
      fill: color || '#D14900',
      width: size || 20,
      height: size || 20,
      stroke: 'none',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const TranslateAsIcon = ({
  size,
  color,
}: {
  size?: number;
  color?: string;
}) => {
  const svg = {
    path: 'M14.8 14.5C13.7 14.5 12.6 14 11.8 13.2L10 11.3 8.2 13.2C7.4 14 6.3 14.5 5.2 14.5 4.1 14.5 3 14 2.2 13.2 1.4 12.3 1 11.2 1 10 1 8.8 1.4 7.7 2.2 6.8 3 6 4.1 5.5 5.2 5.5 6.3 5.5 7.4 6 8.2 6.8L10 8.7 11.8 6.8C12.6 6 13.7 5.5 14.8 5.5 15.9 5.5 17 6 17.8 6.8 18.5 7.7 19 8.8 19 10 19 11.2 18.5 12.3 17.8 13.2 17 14 15.9 14.5 14.8 14.5ZM13 11.9C13.5 12.4 14.1 12.7 14.8 12.7 15.5 12.7 16.1 12.4 16.6 11.9 17 11.4 17.4 10.7 17.4 10 17.4 9.3 17 8.6 16.6 8.1 16.1 7.6 15.5 7.3 14.8 7.3 14.1 7.3 13.5 7.6 13 8.1L11.2 10 13 11.9ZM5.2 7.3C4.5 7.3 3.9 7.6 3.4 8.1 3 8.6 2.7 9.3 2.7 10 2.7 10.7 3 11.4 3.4 11.9 3.9 12.4 4.5 12.7 5.2 12.7 5.9 12.7 6.6 12.4 7 11.9L8.8 10 7 8.1C6.6 7.6 5.9 7.3 5.2 7.3Z',
    attr: {
      fill: color || '#188038',
      width: size || 20,
      height: size || 20,
      stroke: 'none',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const NeverTranslateAsIcon = ({
  size,
  color,
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M10.72 12.16 8.83 10.36 8.83 10.36C10.09 9.01 10.99 7.39 11.44 5.59H13.6V4.24H8.56V2.71H7.21V4.24H2.17V5.59H10.18C9.73 6.94 8.92 8.38 7.84 9.46 7.21 8.74 6.67 8.02 6.22 7.12H4.78C5.32 8.2 6.04 9.37 6.94 10.36L3.25 13.87 4.33 15.04 7.84 11.35 10.09 13.69 10.72 12.16ZM14.77 8.47H13.24L10.09 17.2H11.44L12.25 15.04H15.76L16.48 17.2H17.92L14.77 8.47ZM12.88 13.51 13.96 10.36 15.13 13.51H12.88Z"
          fill="#071D49"
        />
        <path
          d="M10 1.2C5.05 1.2 1.2 5.05 1.2 10 1.09 14.73 5.05 18.8 10 18.8 14.73 18.8 18.69 14.73 18.8 10 18.8 5.05 14.73 1.2 10 1.2ZM10 17.15C6.04 17.15 2.85 13.74 2.85 10 2.85 8.24 3.4 6.81 4.39 5.6L14.18 15.5C12.97 16.38 11.65 17.15 10 17.15ZM15.39 14.18 5.6 4.39C6.81 3.4 8.35 2.85 10 2.85 13.74 2.85 17.15 6.04 17.15 9.89 17.15 11.54 16.38 12.97 15.39 14.18Z"
          fill="#D14900"
        />
      </g>
    </svg>
  );
};
export const MTIcon = ({ size, color }: { size?: number; color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M10.72 12.16 8.83 10.36 8.83 10.36C10.09 9.01 10.99 7.39 11.44 5.59H13.6V4.24H8.56V2.71H7.21V4.24H2.17V5.59H10.18C9.73 6.94 8.92 8.38 7.84 9.46 7.21 8.74 6.67 8.02 6.22 7.12H4.78C5.32 8.2 6.04 9.37 6.94 10.36L3.25 13.87 4.33 15.04 7.84 11.35 10.09 13.69 10.72 12.16ZM14.77 8.47H13.24L10.09 17.2H11.44L12.25 15.04H15.76L16.48 17.2H17.92L14.77 8.47ZM12.88 13.51 13.96 10.36 15.13 13.51H12.88Z"
          fill="#071D49"
        />
        <path
          d="M13 11.8 12.8 10.2 12.6 10.2C12 10 11.5 9.7 11 9.3L10.9 9.2 9.3 9.8 8.6 8.5 9.9 7.5 9.9 7.3C9.8 6.9 9.8 6.5 9.8 6.3 9.8 6 9.8 5.7 9.9 5.4L9.9 5.2 8.6 4.2 9.3 2.9 10.9 3.5 11 3.4C11.4 3 11.9 2.7 12.5 2.5L12.6 2.5 13 .8H14.5L14.7 2.4 14.9 2.4C15.5 2.6 16 2.9 16.5 3.3L16.6 3.4 18.2 2.8 19 4.1 17.7 5.2 17.7 5.3C17.7 5.7 17.8 6 17.8 6.3 17.8 6.6 17.8 6.9 17.7 7.2L17.7 7.4 19 8.4 18.3 9.7 16.7 9.1 16.6 9.2C16.1 9.6 15.6 9.9 15 10.1L14.8 10.1 14.5 11.8H13ZM13.8 4.3C12.7 4.3 11.8 5.2 11.8 6.3S12.7 8.3 13.8 8.3 15.8 7.4 15.8 6.3 14.9 4.3 13.8 4.3ZM5.5 19.3 5.3 17.7 5.1 17.7C4.5 17.5 4 17.2 3.5 16.8L3.4 16.6 1.8 17.2 1.1 15.9 2.4 14.9 2.4 14.7C2.3 14.4 2.3 14 2.3 13.8 2.3 13.5 2.3 13.2 2.4 12.9L2.4 12.7 1.1 11.7 1.8 10.4 3.4 11 3.5 10.9C3.9 10.5 4.4 10.2 5 10L5.1 10 5.4 8.4H6.9L7.1 10 7.3 10C7.9 10.2 8.4 10.5 8.9 10.9L9 11 10.6 10.4 11.4 11.7 10.1 12.8 10.1 12.9C10.2 13.2 10.3 13.5 10.3 13.8 10.3 14.1 10.3 14.4 10.2 14.7L10.2 14.9 11.5 15.9 10.8 17.2 9.2 16.6 9.1 16.7C8.6 17.1 8.1 17.4 7.5 17.6L7.3 17.6 7 19.3H5.5ZM6.3 11.8C5.2 11.8 4.3 12.7 4.3 13.8S5.2 15.8 6.3 15.8 8.3 14.9 8.3 13.8 7.4 11.8 6.3 11.8Z"
          // d="M8.8 18.9 8.5 16.3 8.2 16.3C7.2 16 6.4 15.5 5.6 14.9L5.4 14.7 2.9 15.7 1.8 13.6 3.8 12 3.8 11.7C3.7 11 3.7 10.4 3.7 10.1 3.7 9.6 3.7 9.1 3.8 8.6L3.8 8.3 1.8 6.7 2.9 4.6 5.4 5.6 5.6 5.4C6.2 4.8 7 4.3 8 4L8.2 4 8.8 1.3H11.2L11.5 3.8 11.8 3.8C12.8 4.2 13.6 4.6 14.4 5.3L14.6 5.4 17.1 4.5 18.4 6.6 16.3 8.3 16.3 8.5C16.3 9.1 16.5 9.6 16.5 10.1 16.5 10.6 16.5 11 16.3 11.5L16.3 11.8 18.4 13.4 17.3 15.5 14.7 14.6 14.6 14.7C13.8 15.4 13 15.8 12 16.2L11.7 16.2 11.2 18.9H8.8ZM10.1 6.9C8.3 6.9 6.9 8.3 6.9 10.1S8.3 13.3 10.1 13.3 13.3 11.8 13.3 10.1 11.8 6.9 10.1 6.9Z"
          fill={color || '#D14900'}
          // strokeWidth={3}
          // strokeLinejoin="miter"
          // strokeLinecap="square"
        />
      </g>
    </svg>
  );
};
