import { useLocation } from 'react-router-dom';
import { dashboardRC } from 'helpers';
import { useViewSize } from 'hooks';
import { MantineStyleProps } from '@mantine/core';

export default function useMainOutletStyles(
  isDesktopToggled: boolean
): MantineStyleProps {
  const { pathname } = useLocation();
  const { isTabletSize } = useViewSize();

  const paddingProps = {
    pt: 65,
    pb: 100,
    pr: { base: 10, md: 15 },
    pl: {
      base: 10,
      md: 90,
      lg: isDesktopToggled || isTabletSize ? 90 : 250,
    },
  };

  const isOrderConfirmation = pathname.includes(
    dashboardRC.PAGE_ORDER_CONFIRMATION
  );
  const isDowngradeConfirmation = pathname.includes(
    dashboardRC.PAGE_DOWNGRADE_CONFIRMATION
  );
  const isCancellationConfirmation = pathname.includes(
    dashboardRC.PAGE_CANCELLATION_CONFIRMATION
  );

  if (
    isOrderConfirmation ||
    isDowngradeConfirmation ||
    isCancellationConfirmation
  ) {
    return {
      ...paddingProps,
      pt: 48,
      pb: 0,
      pr: 0,
      pl: {
        base: 0,
        md: 60,
        lg: isDesktopToggled || isTabletSize ? 60 : 220,
      },
    };
  }

  return paddingProps;
}
