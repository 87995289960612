import { useState } from 'react';
import {
  Box,
  Checkbox,
  Combobox,
  Flex,
  Text,
  Pill,
  PillsInput,
  ScrollArea,
  useCombobox,
  useMantineTheme,
  Group,
} from '@mantine/core';
import { useSetupStepsContext } from './ProjectSetupProvider';
import classes from './TargetLanguagesSelector.module.css';
import { AiOutlineSearch } from 'react-icons/ai';
import { NOOP } from 'helpers';

type TargetLanguage = {
  value: string;
  label: string;
};

type Props = {
  targetLanguages: TargetLanguage[];
};

const TargetLanguagesSelector = ({ targetLanguages }: Props) => {
  const colors = useMantineTheme().colors;
  const { selectedTargetLangCodes, setSelectedTargetLangCodes } =
    useSetupStepsContext();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const [search, setSearch] = useState('');

  const handleValueSelect = (val: string) => {
    if (val === 'SELECT_ALL') {
      const allTargetLangCodes = targetLanguages.map(({ value }) => value);
      const isActive =
        selectedTargetLangCodes.length === allTargetLangCodes.length;
      setSelectedTargetLangCodes(isActive ? [] : allTargetLangCodes);
    } else {
      setSelectedTargetLangCodes((current) =>
        current.includes(val)
          ? current.filter((v) => v !== val)
          : [...current, val]
      );
    }
  };

  const handleValueRemove = (val: string) =>
    setSelectedTargetLangCodes((current) => current.filter((v) => v !== val));

  const selectedTargets = targetLanguages.filter((lang) =>
    selectedTargetLangCodes.includes(lang.value)
  );

  const allLangsSelected =
    selectedTargetLangCodes.length ===
    targetLanguages.map((l) => l.value).length;

  const values = selectedTargets.map((item) => (
    <Pill
      m="0.15rem"
      bg="badge.9"
      c="text.1"
      size="sm"
      fw={700}
      key={item.value}
      style={{ cursor: 'pointer' }}
      onClick={() => handleValueRemove(item.value)}
      withRemoveButton
      onRemove={() => handleValueRemove(item.value)}
      tt="uppercase"
    >
      {item.label}
    </Pill>
  ));

  const options = [
    { label: 'Select All', value: 'SELECT_ALL' },
    ...targetLanguages,
  ]
    .filter(({ label }) =>
      label.toLowerCase().includes(search.trim().toLowerCase())
    )
    .map((item) => (
      <Combobox.Option
        value={item.value}
        key={item.value}
        active={selectedTargetLangCodes.includes(item.value)}
      >
        <Group gap="sm" align="flex-start" wrap="nowrap">
          <Checkbox
            mt="0.25rem"
            checked={
              allLangsSelected || selectedTargetLangCodes.includes(item.value)
            }
            classNames={{ root: classes.targetSelectorCheckbox }}
            // leave this empty handler in. needed if checked prop is present
            onChange={NOOP}
            aria-hidden
            tabIndex={-1}
            style={{ pointerEvents: 'none' }}
            radius="xs"
            color="black"
          />
          <Box>
            <Text>{item.label}</Text>
          </Box>
        </Group>
      </Combobox.Option>
    ));

  return (
    <Flex direction="column" gap={30}>
      <Combobox
        classNames={{
          dropdown: classes.optionDropdown,
          option: classes.targetSelectorOption,
        }}
        store={combobox}
        withinPortal={true}
        onOptionSubmit={handleValueSelect}
      >
        <Combobox.DropdownTarget>
          <PillsInput
            size="md"
            classNames={{ input: classes.targetSelectorTextInput }}
            maw="25rem"
            pointer
            onClick={() => combobox.toggleDropdown()}
            rightSection={
              <AiOutlineSearch size="1.2rem" color={colors.border[6]} />
            }
          >
            <Pill.Group>
              <Combobox.EventsTarget>
                <PillsInput.Field
                  classNames={{ field: classes.pillsInputField }}
                  value={search}
                  onBlur={() => combobox.closeDropdown()}
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex();
                    setSearch(event.currentTarget.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace' && search.length === 0) {
                      event.preventDefault();
                      handleValueRemove(
                        selectedTargetLangCodes[
                          selectedTargetLangCodes.length - 1
                        ]
                      );
                    }
                  }}
                  placeholder="Add Languages"
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        <Combobox.Dropdown>
          <ScrollArea.Autosize
            classNames={{ scrollbar: classes.scrollbar }}
            mah={200}
            type="scroll"
          >
            <Combobox.Options>{options}</Combobox.Options>
          </ScrollArea.Autosize>
        </Combobox.Dropdown>
      </Combobox>
      <ScrollArea.Autosize
        className={classes.scrollArea}
        h="7rem"
        p="1rem"
        scrollbarSize={15}
        type="scroll"
      >
        {values}
      </ScrollArea.Autosize>
    </Flex>
  );
};

export default TargetLanguagesSelector;
