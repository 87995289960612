import React from 'react';
import { Menu, NavLink } from '@mantine/core';
import { Link, useLocation, useParams } from 'react-router-dom';
import { MdArrowDropDown } from 'react-icons/md';
import { NavLink as NavLinkType } from 'helpers';
import { LinkWrapper } from '../../Generic';

type Props = {
  classes: CSSModuleClasses;
  link: NavLinkType;
  toggleMobile: () => void;
  isMobileSize: boolean;
};

export const NavItem = ({
  classes,
  link,
  toggleMobile,
  isMobileSize,
}: Props) => {
  const { pathname } = useLocation();
  const { projectId } = useParams();

  const { label, icon, path: parentPath, pages, action: parentAction } = link;
  const isNotifications = label === 'Notifications';

  return (
    <NavLink
      defaultOpened={!isMobileSize}
      label={label}
      leftSection={icon}
      component={parentAction ? undefined : Link}
      to={`/${projectId}/${parentPath}`}
      rightSection={pages && <MdArrowDropDown size={24} />}
      className={classes.navLinkParent}
      active={Boolean(parentPath && pathname.includes(parentPath))}
      onClick={
        pages
          ? undefined
          : () => {
              !isNotifications && toggleMobile();
              parentAction && parentAction();
            }
      }
      childrenOffset={35}
    >
      {pages?.map(({ label, path: childPath, link, target, action }) => (
        <NavLink
          key={label}
          label={label}
          component={action ? undefined : Link}
          to={link ?? `/${projectId}/${parentPath}/${childPath}`}
          target={target}
          className={classes.navLinkChild}
          active={Boolean(childPath && pathname.includes(childPath))}
          onClick={() => {
            toggleMobile();
            action && action();
          }}
        />
      ))}
    </NavLink>
  );
};

export const TabletNavItem = ({
  classes,
  link,
}: Pick<Props, 'link' | 'classes'>) => {
  const { pathname } = useLocation();
  const { projectId } = useParams();

  const { icon, path: parentPath, pages } = link;

  return (
    <Menu
      offset={{ mainAxis: 15 }}
      disabled={!pages}
      trigger="click"
      position="right-start"
    >
      <Menu.Target>
        <NavLink
          label={icon}
          component={Link}
          to={`/${projectId}/${parentPath}`}
          onClick={(e) => pages && e.preventDefault()}
          className={classes.navLinkParent}
          active={Boolean(parentPath && pathname.includes(parentPath))}
          styles={{
            body: {
              paddingTop: '0.25rem',
              paddingBottom: '0.25rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            label: {
              height: 20,
            },
          }}
        />
      </Menu.Target>
      <Menu.Dropdown>
        {pages?.map(({ label, path: childPath }) => {
          const isActive = Boolean(childPath && pathname.includes(childPath));
          return (
            <LinkWrapper
              key={label}
              to={`${projectId}/${parentPath}/${childPath}`}
            >
              <Menu.Item aria-current={isActive} data-active={isActive}>
                {label}
              </Menu.Item>
            </LinkWrapper>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
};
