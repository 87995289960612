import React from 'react';
import { Tooltip } from '@mantine/core';

const FolderMethodTooltip = ({ children }: { children: React.ReactNode }) => (
  <Tooltip
    w={300}
    label="The paths specified here must be exact. For instance, if you
                specify “/fr-fr/” as the translated folder path for French and
                an end-user goes to example.com/fr-fr, the user will see the
                source language site instead of the translated one."
    position="bottom-start"
  >
    {children}
  </Tooltip>
);

export default FolderMethodTooltip;
