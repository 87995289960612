import { Params } from 'react-router-dom';
const validKey = new RegExp(
  /^([A-Z0-9]{4})-([A-Z0-9]{4})-([A-Z0-9]{4})-([A-Z0-9]{4})$/
);
const validId = new RegExp(/[a-z0-9]{16}$/);
const validPID = new RegExp(/^p_[a-z\d]{16}/);
const validTID = new RegExp(/^t_[a-z\d]{16}/);
const idRegex = new RegExp(
  /^([a-z0-9]{4})([a-z0-9]{4})([a-z0-9]{4})([a-z0-9]{4})/
);

export const isValidPID = (pid: string | undefined) =>
  Boolean(pid && validPID.test(pid));

export const isValidId = (id: string | undefined) =>
  Boolean(id && validId.test(id));
export const isValidKey = (key: string | undefined) =>
  Boolean(key && validKey.test(key));

export const encodeKey = (key: string, prefix?: string) => {
  if (!key) return '';
  if (key && validKey.test(key))
    return (
      `${prefix ? prefix + '_' : ''}` + key.replace(/-/g, '').toLowerCase()
    );
  return key;
};

export const decodeId = (id: string = '') => {
  if (validKey.test(id) || !validId.test(id)) {
    return id;
  }

  return id.replace(idRegex, ['$1', '$2', '$3', '$4'].join('-')).toUpperCase();
};

export const encodeProjectKey = (key: string) => {
  return encodeKey(key, 'p');
};

export const decodeProjectId = (id: string) => {
  if (id && validPID.test(id)) return decodeId(id.replace(/^p_/, ''));
  return id;
};
export const decodeTId = (id: string) => {
  if (id && validTID.test(id)) return decodeId(id.replace(/^t_/, ''));
  return id;
};

export const decodeParams = (params: Params): Params => {
  const projectKey = decodeProjectId(params?.projectId || '');
  const translationKey = decodeId(params?.translationId || '');
  return { ...params, projectKey, translationKey };
};
