import { Xapis } from 'store';
import { asciiToHex, hexToObject } from 'helpers';
import { notifications } from '@mantine/notifications';

const handleUpdateRules = (
  projectKey: string,
  rulesList: string[],
  ruleToRemove: string,
  yyTarget: TranslationKey,
  setYYTarget: React.Dispatch<React.SetStateAction<TranslationKey>>
) => {
  const {
    translation_config: translationConfig = '',
    translation_config_hash: translationConfigHash = '',
    translation_key: yyTranslationKey = '',
  } = yyTarget || {};

  const decodedConfig = hexToObject(translationConfig);
  const { scope_preset = '' } = decodedConfig || {};
  const currentScope =
    scope_preset.length > 0 &&
    ['all', 'include', 'exclude'].includes(scope_preset)
      ? scope_preset
      : 'all';

  const updatedRuleList = rulesList.filter(
    (rule: string) => rule !== ruleToRemove
  );

  const updatedSpiderRules =
    currentScope === 'exclude'
      ? { ...decodedConfig.spider_rules, exclude_uris: updatedRuleList }
      : { ...decodedConfig.spider_rules, include_uris: updatedRuleList };

  const updatedConfig = JSON.stringify({
    ...decodedConfig,
    spider_rules: updatedSpiderRules,
  });
  const updatedConfigHex = asciiToHex(updatedConfig);

  Xapis.ProjectTranslation.put(projectKey, yyTranslationKey, {
    translation_config: updatedConfigHex || '',
    translation_config_hash: translationConfigHash,
  })
    .then(({ data }) => {
      if (data) {
        setYYTarget(data);
        notifications.show({
          message: 'Successfully removed rule!',
        });
      }
    })
    .catch(() => {
      notifications.show({
        message: 'Unable to remove rule at this time.',
      });
    });
};

export default handleUpdateRules;
