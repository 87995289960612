import { create } from 'zustand';
import { AxiosError, AxiosResponse } from 'axios';
import PosterPromise from './xapis-wrappers/PosterPromise';
import FetcherPromise from './xapis-wrappers/FetcherPromise';
import PatcherPromise from './xapis-wrappers/PatcherPromise';
import { failure } from 'helpers';

interface ContactError {
  status?: string;
  message?: string;
  correlationId?: string;
  category?: string;
}
export interface Contact extends ContactError {
  id?: string;
  properties?: {
    email?: string;
    firstname?: string;
    lastname?: string;
    website?: string;
    phone?: string;
    estimated_words_served?: number;
    recommended_plan?: string;
    hs_all_assigned_business_unit_ids?: number;
    glgo_account_create_date?: number;
    hs_marketable_status?: boolean;
    hs_marketable_reason_type?: string;
    subscribed_to_marketing?: boolean;
  };
  createdAt?: string;
  updatedAt?: string;
  archived?: boolean;
}

export type HubSpotStore = {
  contactId: string;
  createContact: (
    data: Contact
  ) => Promise<AxiosResponse<Contact> | Record<string, never>>;
  getContact: (
    email: string
  ) => Promise<AxiosResponse<Contact> | Record<string, never>>;
  updateContact: (
    contactId: string,
    data: Contact
  ) => Promise<AxiosResponse<Contact> | Record<string, never>>;
};

export const useHubSpotStore = create<HubSpotStore>()((set) => ({
  contactId: '',
  createContact: async (data) => {
    return PosterPromise('CRM/objects/contacts', data, {})
      .then((response) => {
        const { data: { id = '' } = {} }: { data: Contact } = response;
        set({ contactId: id });
        return response;
      })
      .catch((error: AxiosError) => {
        const { response = {} } = error;
        console.error('ERROR:', error);
        return response;
      });
  },
  getContact: async (email) => {
    return FetcherPromise(`CRM/objects/contacts/${email}`, {
      idProperty: 'email',
    })
      .then((response) => {
        const { data: { id = '' } = {} }: { data: Contact } = response;
        set({ contactId: id });
        return response;
      })
      .catch((error: AxiosError) => {
        const { response = {} } = error;
        console.error('ERROR:', error);
        return response;
      });
  },
  updateContact: async (contactId, data) => {
    return PatcherPromise(`CRM/objects/contacts/${contactId}`, data, {})
      .then((response) => {
        return response;
      })
      .catch((error: AxiosError) => {
        const { response = {} } = error;
        console.error('ERROR:', error);
        failure({ message: 'Unable to update hubspot contact' });
        return response;
      });
  },
}));

export default useHubSpotStore;
