export const generateUUIDv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export function segmentedWordCount(segments: Segment[]) {
  return segments.reduce(
    (sum, segment) => sum + (segment.approx_word_count ?? 0),
    0
  );
}

export function blockHashesForSegmentsOnPage(
  segmentHashes: string[],
  quotePage: QuotePage
): string[] {
  const blockHashes: string[] = [];

  for (const blockHash in quotePage.blockSegments) {
    const segments = quotePage.blockSegments[blockHash];
    if (
      segments.some((segment) => segmentHashes.includes(segment.segment_hash))
    ) {
      blockHashes.push(blockHash);
    }
  }

  return blockHashes;
}

export function pageHTWordCount(page: QuotePage): number {
  let wordCount = 0;
  const processedSegmentHashes = new Set<string>();

  for (const blockHash in page.blockSegments) {
    const segments = page.blockSegments[blockHash];

    for (const segment of segments) {
      if (
        (page.htWholePage ||
          page.htSegmentHashes.includes(segment.segment_hash)) &&
        !processedSegmentHashes.has(segment.segment_hash)
      ) {
        wordCount += segment.approx_word_count ?? 0;
        processedSegmentHashes.add(segment.segment_hash);
      }
    }
  }

  return wordCount;
}

export function quoteHTWordCount(quote: Quote) {
  let wordCount = 0;

  for (const page of quote.pages) {
    wordCount += pageHTWordCount(page);
  }

  return wordCount;
}

export function isPageIncludedInQuote(page: QuotePage): boolean {
  return page.htWholePage || page.htSegmentHashes.length > 0;
}

export function pagesIncludedInQuote(quote: Quote) {
  return quote.pages.filter(isPageIncludedInQuote);
}
