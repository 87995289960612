import React, { useState } from 'react';
import { Flex, Box, Group, Image, UnstyledButton } from '@mantine/core';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import AboutModal from './modals/AboutModal';
import ContactUsModal from '../modals/ContactUsModal';
import ProjectSelector from '../../../../ProjectSelector';
import NotificationsMenu from './NotificationsMenu';
import SupportMenu from './SupportMenu';
import AccountMenu from './AccountMenu';
import GLWEBLogoLight from 'images/logos/glweb-logo-light.svg';
import GLWEBIconLight from 'images/logos/glweb-icon-light.svg';
import { AiOutlineMenu } from 'react-icons/ai';
import { dashboardRC } from 'helpers';

type Props = {
  toggleMobile: () => void;
  setIsDesktopToggled: React.Dispatch<React.SetStateAction<boolean>>;
  isDesktopToggled: boolean;
};

export default function MainHeader({
  toggleMobile,
  setIsDesktopToggled,
  isDesktopToggled,
}: Props) {
  const { projectId } = useParams();
  const [params, setParams] = useSearchParams();
  const viewNotes = params.get('viewNotes');
  const showLatestReleaseNotes = viewNotes !== null && viewNotes === 'latest';

  const [showAboutModal, setShowAboutModal] = useState(false);

  return (
    <>
      <AboutModal
        opened={showAboutModal || showLatestReleaseNotes}
        setOpened={setShowAboutModal}
        params={params}
        setParams={setParams}
        showLatest={showLatestReleaseNotes}
      />
      <ContactUsModal />
      <Group
        w="100%"
        h="100%"
        align="center"
        justify="space-between"
        bg="background1.0"
        px={10}
        data-testid="mainHeader"
      >
        <Flex
          mah="100%"
          align="center"
          columnGap={{ base: 28, lg: isDesktopToggled ? 28 : 35 }}
        >
          <UnstyledButton
            display={{ base: 'block', md: 'none' }}
            h={24}
            c="text.0"
            onClick={toggleMobile}
          >
            <AiOutlineMenu size={24} />
          </UnstyledButton>
          <Link
            style={{ height: 'fit-content' }}
            to={`${projectId}/${dashboardRC.PAGE_DASHBOARD}`}
            aria-label="Go to Dashboard link"
          >
            <Image
              display={{
                base: 'block',
                md: 'none',
                lg: isDesktopToggled ? 'none' : 'block',
              }}
              h={{ base: 20, lg: 26 }}
              src={GLWEBLogoLight}
              alt="GlobalLink WEB full logo"
            />
            <Group
              w={45}
              justify="center"
              hiddenFrom={isDesktopToggled ? undefined : 'lg'}
              visibleFrom="md"
            >
              <Image
                w={45}
                height={30}
                src={GLWEBIconLight}
                alt="GlobalLink WEB icon"
              />
            </Group>
          </Link>
          <Box visibleFrom="md">
            <ProjectSelector />
          </Box>
        </Flex>
        <Group
          display={{ base: 'none', md: 'flex' }}
          mah="100%"
          align="center"
          gap={30}
          c="text.0"
        >
          <NotificationsMenu />
          <SupportMenu />
          <AccountMenu
            setShowAboutModal={setShowAboutModal}
            setIsDesktopToggled={setIsDesktopToggled}
          />
        </Group>
        <Box hiddenFrom="md">
          <ProjectSelector />
        </Box>
      </Group>
    </>
  );
}
