import { queryOptions } from '@tanstack/react-query';
import { fetchWordsServedPromise } from '../../WordsServedStore';
import { isSuccessStatus } from 'helpers';

export const queryWordsServed = (
  concatenatedKey: string,
  timeSliceFrom: string,
  rollup: string
) =>
  queryOptions({
    queryKey: ['wordsServed', concatenatedKey, timeSliceFrom, rollup],

    queryFn: async () => {
      if (!concatenatedKey.length || concatenatedKey === '0') return [];
      const response = await fetchWordsServedPromise(
        concatenatedKey,
        timeSliceFrom,
        rollup
      );
      if (
        !response ||
        !isSuccessStatus(response.status) ||
        !response.data ||
        !response.data.data
      ) {
        throw new Error('Failed to fetch words served data');
      }
      return response.data.data;
    },
  });
