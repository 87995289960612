import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type StateStore = {
  redirectUrl: string;
  logoutReason: string;
  setRedirectUrl: (value: string) => void;
  setLogoutReason: (reason: string) => void;
};
export const useStateStore = create<StateStore>()(
  persist(
    (set) => ({
      redirectUrl: '',
      logoutReason: '',
      setRedirectUrl: (value) => set(() => ({ redirectUrl: value })),
      setLogoutReason: (reason) => set(() => ({ logoutReason: reason })),
    }),
    {
      name: 'ssoState',
      getStorage: () => sessionStorage,
    }
  )
);
export default useStateStore;
