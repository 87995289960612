import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Text, Divider, Title } from '@mantine/core';
import { activeTranslations, decodeId, encodeKey, dashboardRC } from 'helpers';
import { useCurrentProjectData } from 'store';

import { LanguageDropdown } from './LanguageDropdown';
import { LanguageCSSEditor } from './LanguageCSSEditor';
import { FlexCard } from '../../../Generic';

export const CustomCssLanding = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const selectedTkey = decodeId(searchParams.get('t') || '');

  const { translations } = useCurrentProjectData();
  const activeTargets = activeTranslations(translations);

  const target = activeTargets.find((t) => t.translation_key === selectedTkey);
  if (!target) {
    // Redirect to language-settings tab
    setTimeout(() => {
      navigate(`/${projectId}/${dashboardRC.PAGE_SETTINGS}/language-settings`);
    }, 1500);
    return (
      <Box>
        Invalid translation key. Please select a language from the list.
      </Box>
    );
  }

  return (
    <FlexCard gap={20}>
      <Flex direction="column" gap={10} px={15}>
        <Flex gap={20} align="center" wrap="wrap">
          <Title order={1}>Custom CSS Editor</Title>
          <Divider orientation="vertical" color="font1.0" size="xs" />
          <LanguageDropdown
            activeTargets={activeTargets}
            currentTarget={target}
            onSelect={(key) => setSearchParams({ t: encodeKey(key) })}
          />
        </Flex>
        <Text>
          <Text span fw={700}>
            NOTE:
          </Text>{' '}
          Changes made here only apply to pages translated into the selected
          language.
        </Text>
      </Flex>
      <LanguageCSSEditor key={target.translation_key} target={target} />
    </FlexCard>
  );
};
