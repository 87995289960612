const { highlightNoTxInWebsite, removeHighlightInWebsite } =
  window.editorAPI || {};

export function previewNoTxRule(rule: UX) {
  if (typeof highlightNoTxInWebsite === 'function') {
    highlightNoTxInWebsite([rule]);
  }
}

export function resetPreview() {
  if (typeof removeHighlightInWebsite === 'function') {
    removeHighlightInWebsite();
  }
}
