import { useEffect } from 'react';
import { REMARKET_STATS_BASEURL, dashboardRC } from 'helpers';
import { useLocation } from 'react-router-dom';
import getSegNameFromPath from './getSegNameFromPath';

const RemarketStatsScript = () => {
  const { pathname = '' } = useLocation();
  const segName = getSegNameFromPath(pathname.substring(1));

  useEffect(() => {
    if (
      !pathname.includes(dashboardRC.PAGE_CREATE_ACCOUNT) &&
      !pathname.includes(dashboardRC.PAGE_CREATE_PROJECT)
    )
      return;

    if (!segName) {
      console.error('segname is not defined');
      return;
    }

    const script = document.createElement('script');
    script.src = REMARKET_STATS_BASEURL + segName;
    script.defer = true;
    script.id = 'remarket-' + segName;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);

      // remarket script injects iframes, need to wipe when navigating
      const correspondingIframes = document.querySelectorAll(
        '[id^="_ccpx_cont_"]'
      );
      if (correspondingIframes.length) {
        correspondingIframes.forEach((iframe) => {
          document.body.removeChild(iframe);
        });
      }
    };
  }, [pathname, segName]);

  return <></>;
};

export default RemarketStatsScript;
