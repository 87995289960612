import React, { useEffect } from 'react';
import { Outlet, useLoaderData } from 'react-router-dom';
import { ProjectQueryLoaderData } from 'store';

const ProjectOutletWrapper = () => {
  const { projectData } = useLoaderData() as ProjectQueryLoaderData;
  const project = projectData.project;

  useEffect(() => {
    const title = `${project.project_name} | GlobalLink Web`;
    document.title = title;
    window.DashboardAPI &&
      typeof window.DashboardAPI.setWindowTitle === 'function' &&
      window.DashboardAPI.setWindowTitle(title);
  }, [project.project_name]);

  return <Outlet />;
};

export default ProjectOutletWrapper;
