import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Flex, Stack } from '@mantine/core';
import { useCurrentProjectData, useProjectGlossary } from 'store';
import { decodeId } from 'helpers';
import { GlossaryItemMenu } from './GlossaryItemMenu';
import { Header, SearchBar } from '../../../Generic';
import { QueryMultiSelector } from '../../selectors-filters/QueryMultiSelector';
import { GlossaryRow, GlossaryTable } from './GlossaryTable';
import { GlossaryRuleForm } from './GlossaryRuleForm';
import { TRANSLATE_AS } from './glossaryConstants';
import { IoMdAdd } from 'react-icons/io';

export const Glossary = () => {
  const glossary = useProjectGlossary();
  const { translations } = useCurrentProjectData();
  const [loading, setLoading] = useState(false);
  const glossaryItems = glossary?.glossary_items || [];
  const targetsOptions = translations.map(
    ({ translation_key = '', target_lang_name = '' }) => {
      return { label: target_lang_name, value: translation_key };
    }
  );

  const [searchParams] = useSearchParams();
  const selectedIds = searchParams.getAll('t') || [];
  const selectedKeys = selectedIds.map((id) => decodeId(id));

  const [searchText, setSearchText] = useState('');

  /**
   * key: translation key, value: translation name
   */
  const targetNamesMap = useMemo(() => {
    return targetsOptions.reduce((acc, target) => {
      const { value = '', label = '' } = target;
      acc.set(value, label);
      return acc;
    }, new Map<string, string>());
  }, [targetsOptions]);

  const filterGlossaryItems = (glossaryItems: GlossaryItem[]) => {
    const isAllTkeysSelected = selectedKeys.length === 0;
    const isFiltered = !isAllTkeysSelected || searchText.length > 0;

    if (!isFiltered) return glossaryItems;

    return glossaryItems.filter((g) => {
      const isTranslationKeyIncluded =
        selectedKeys.includes(g.translation_key) || isAllTkeysSelected;
      // Simply look inside every single column for the searchText
      return (
        isTranslationKeyIncluded &&
        Object.values(g).some((values) =>
          values.toLocaleString().includes(searchText)
        )
      );
    });
  };

  const parseGlossaryItems = useCallback(
    (glossaryItems: GlossaryItem[]) => {
      return glossaryItems.map((item) => {
        // Legacy terms may not have rule defined
        if (!item.rule) item.rule = TRANSLATE_AS;
        const {
          comment,
          is_case_sensitive,
          is_mt,
          rule,
          source_text,
          source_text_hash,
          target_text,
          translation_key,
        } = item;

        const targetTextString = target_text.join(', ');

        return {
          id: translation_key.concat(source_text_hash), // Needed for "idAccessor" so no errors will appear
          rule,
          terms: source_text + ' as ' + targetTextString,
          isCaseSensitive: is_case_sensitive ? 'Yes' : 'No',
          isMt: is_mt ? 'Yes' : 'No',
          languages: targetNamesMap.get(translation_key) || '',
          comment,
          translation_key,
          source_text_hash,
          manage: (
            <GlossaryItemMenu
              item={item}
              targetOptions={targetsOptions}
              setLoading={setLoading}
            />
          ),
        } as GlossaryRow;
      });
    },
    [targetNamesMap]
  );

  const records = parseGlossaryItems(filterGlossaryItems(glossaryItems));

  return (
    <>
      <Header title="Glossary" />
      <Stack gap={20}>
        <Flex
          mt={16}
          rowGap="1rem"
          wrap={{ base: 'wrap', md: 'nowrap' }}
          direction="row"
          justify="space-between"
        >
          <Flex columnGap="1.25rem" align="center" w={585}>
            <SearchBar
              maxWidth={500}
              searchText={searchText}
              setSearchText={setSearchText}
            />
            <QueryMultiSelector encodeKeys selectAll options={targetsOptions} />
          </Flex>
          <GlossaryRuleForm
            targetsOptions={targetsOptions}
            type="Add"
            buttonProps={{
              px: 0,
              fz: '0.85rem',
              variant: 'transparent',
              c: 'text.8',
              rightSection: <IoMdAdd size={20} />,
            }}
            setLoading={setLoading}
          />
        </Flex>
        <GlossaryTable
          records={records}
          loading={loading}
          setLoading={setLoading}
        />
      </Stack>
    </>
  );
};

export default Glossary;
