export const ConfirmEnvelope = ({ name = '' }: { name: string }) => (
  <svg
    width="88"
    height="74"
    viewBox="0 0 88 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`icon icon-${name}`}
  >
    <path
      d="M44.0005 22.8498L31.4161 12.0002L34.1882 9.62515L44.0005 18.086L65.0503 0L67.8186 2.37847L44.0005 22.8498ZM44.0005 58.1707L1.50152 21.6588L4.26976 19.2804L44.0005 53.4137L83.7276 19.277L86.4958 21.6555L44.0005 58.1707ZM4.2736 73.0304L1.50536 70.652L31.4198 44.9494L34.1883 47.3279L4.2736 73.0304ZM83.7275 73.0304L53.8129 47.3279L56.5851 44.946L86.4996 70.6486L83.7275 73.0304ZM87.0714 73.5216H0.929688V18.7859H25.6759V22.15H4.84527V70.1573H83.1558V22.15H62.3253V18.7859H87.0714V73.5216Z"
      fill="#188038"
    />
  </svg>
);
export default ConfirmEnvelope;
