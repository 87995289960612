import React from 'react';
import { Menu, UnstyledButton } from '@mantine/core';
import { getNodeProperties } from '../../utils/getNodeProperties';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { BsIntersect } from 'react-icons/bs';
import handleAddRule from '../../utils/handleAddRule';
import handleRemoveRule from '../../utils/handleRemoveRule';
import { SpiderTreeNode, useCurrentProjectData } from 'store';
import classes from './IncludeActionsMenu.module.css';

type Props = {
  currentIncludeRules: string[];
  itemInfo: string;
  isIncluded: boolean;
  isDisabledChildNode: boolean;
  isSourceOfChildrenOnlyRule: boolean;
  yyTarget: TranslationKey | undefined;
  setYYTarget: React.Dispatch<React.SetStateAction<TranslationKey>>;
  spiderTree: SpiderTreeNode[];
  setSpiderTree: React.Dispatch<React.SetStateAction<SpiderTreeNode[]>>;
};

type Inclusion = 'self' | 'parentAndChildren' | 'childrenOnly';

export const IncludeActionsMenu = ({
  itemInfo,
  isIncluded,
  isDisabledChildNode,
  currentIncludeRules,
  isSourceOfChildrenOnlyRule,
  yyTarget,
  setYYTarget,
  spiderTree,
  setSpiderTree,
}: Props) => {
  const { project } = useCurrentProjectData();

  const { id, hasChildren, isFake } = getNodeProperties(itemInfo);
  const handleInclude = (type: Inclusion) => {
    handleAddRule(
      type,
      itemInfo,
      'include',
      project,
      yyTarget,
      setYYTarget,
      spiderTree,
      setSpiderTree
    );
  };

  const handleRemove = () => {
    handleRemoveRule(
      itemInfo,
      'include',
      currentIncludeRules,
      project,
      yyTarget,
      setYYTarget
    );
  };

  if (id === '0' || isDisabledChildNode) return <></>;

  return (
    <div
      style={{ position: 'relative', textAlign: 'end', width: 64 }}
      onClick={(e) => e.stopPropagation()}
    >
      <Menu
        position="bottom-end"
        width={150}
        withArrow
        classNames={{
          arrow: classes.arrow,
          divider: classes.divider,
          item: classes.item,
        }}
      >
        <Menu.Target>
          <UnstyledButton
            mr="3.5rem"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {isIncluded || isSourceOfChildrenOnlyRule ? (
              <BsIntersect fill="#5D6B89" style={{ marginLeft: '0.2rem' }} />
            ) : (
              <HiOutlineDotsHorizontal color="#006CD1" size="22px" />
            )}
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown style={{ border: '1.5px solid #5C73BC', padding: 0 }}>
          {isIncluded || isSourceOfChildrenOnlyRule ? (
            <Menu.Item
              onClick={() => handleRemove()}
              color="font1.0"
              fw={500}
              p="0.5rem 0.75rem"
            >
              Undo{' '}
              {isSourceOfChildrenOnlyRule ? 'Include Children' : 'Inclusion'}
            </Menu.Item>
          ) : hasChildren ? (
            <>
              <Menu.Item
                disabled={isFake}
                onClick={() => handleInclude('parentAndChildren')}
                color="font1.0"
                fw={500}
                p="0.5rem 0.75rem"
              >
                Parent & Children
              </Menu.Item>
              <Menu.Divider my={0} />
              <Menu.Item
                disabled={isFake}
                onClick={() => handleInclude('self')}
                color="font1.0"
                fw={500}
                p="0.5rem 0.75rem"
              >
                Parent Only
              </Menu.Item>
              <Menu.Divider my={0} />
              <Menu.Item
                onClick={() => handleInclude('childrenOnly')}
                color="font1.0"
                fw={500}
                p="0.5rem 0.75rem"
              >
                Children Only
              </Menu.Item>
            </>
          ) : (
            <Menu.Item
              onClick={() => handleInclude('self')}
              color="font1.0"
              fw={500}
              p="0.5rem 0.75rem"
            >
              Include Page
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export default IncludeActionsMenu;
