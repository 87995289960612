import React from 'react';
import { Menu, UnstyledButton } from '@mantine/core';
import { MdContactSupport } from 'react-icons/md';
import { GlobalModalStore, useGlobalModalStore } from 'store';
import { LinkWrapper } from '../../Generic';
import { GLWEB_SUPPORT_URL } from 'helpers';

export default function SupportMenu() {
  const { setShowContactUsModal } = useGlobalModalStore(
    (state: GlobalModalStore) => state
  );

  const menuItems = [
    {
      label: 'Help',
      to: GLWEB_SUPPORT_URL,
      target: '_blank',
    },
    {
      label: 'Contact Us',
      action: () => setShowContactUsModal(true),
    },
  ];

  return (
    <Menu
      width={110}
      position="bottom-end"
      offset={{ mainAxis: 13 }}
      closeOnClickOutside
      closeOnEscape
    >
      <Menu.Target>
        <UnstyledButton h={20}>
          <MdContactSupport size={20} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        {menuItems.map(({ label, to, target, action }) => (
          <LinkWrapper key={label} to={to} target={target}>
            <Menu.Item onClick={action}>{label}</Menu.Item>
          </LinkWrapper>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}
