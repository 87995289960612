import { notifications } from '@mantine/notifications';
import { asciiToHex, hexToObject } from 'helpers';
import getNodeProperties from './getNodeProperties';
import { Xapis } from 'store';

export const handleRemoveRule = (
  itemInfo: string,
  scope: 'include' | 'exclude',
  rules: string[],
  project: ProjectKey | undefined,
  yyTarget: TranslationKey | undefined,
  setYYTarget: React.Dispatch<React.SetStateAction<TranslationKey>>
) => {
  const { contentUrlOrig, label, isFake } = getNodeProperties(itemInfo);
  const { project_key: activeProjectKey = '' } = project || {};

  let ruleToRemove = '';
  for (let i = 0; i < rules.length; i++) {
    if (rules[i].includes(isFake ? label : contentUrlOrig)) {
      ruleToRemove = rules[i];
    }
  }

  const decodedConfig = hexToObject(yyTarget?.translation_config || '');
  if (!decodedConfig)
    throw new Error(`error w decoding config. value: ${decodedConfig}`);

  const updatedIncludeList = rules.filter((rule) => rule !== ruleToRemove);

  let updatedConfig = '';
  if (scope === 'exclude') {
    const updatedSpiderRules = {
      ...decodedConfig.spider_rules,
      exclude_uris: updatedIncludeList,
    };
    updatedConfig = JSON.stringify({
      ...decodedConfig,
      spider_rules: updatedSpiderRules,
    });
  } else {
    const updatedSpiderRules = {
      ...decodedConfig.spider_rules,
      include_uris: updatedIncludeList,
    };
    updatedConfig = JSON.stringify({
      ...decodedConfig,
      spider_rules: updatedSpiderRules,
    });
  }

  const updatedConfigHex = asciiToHex(updatedConfig);
  const yyTranslationKey = yyTarget?.translation_key || '';
  const translationConfigHash = yyTarget?.translation_config_hash || '';

  if (activeProjectKey && yyTranslationKey) {
    Xapis.ProjectTranslation.put(activeProjectKey, yyTranslationKey, {
      translation_config: updatedConfigHex,
      translation_config_hash: translationConfigHash,
    })
      .then(({ data }) => {
        if (data) {
          notifications.show({ message: 'Successfully removed the rule!' });
          setYYTarget(data);
        }
      })
      .catch(() => {
        notifications.show({
          message: 'We are unable to remove the selected rule at this time.',
        });
      });
  } else {
    console.error(
      `incorrect value(s) for activeProjectKey: ${activeProjectKey} and/or yyTranslationKey: ${yyTranslationKey}`
    );
  }
};

export default handleRemoveRule;
