import { getUserKey } from './xapis';
import { xapisEnv } from '../service-wrappers/xapisService';

/**
 *
 * @return { baseUrl: string, userKey: string, token: string }
 */
export const getUserKeyAndToken = () => {
  const { xapisUrl } = xapisEnv.getHost;
  const userKey = getUserKey();
  const token = `Bearer ${xapisEnv.getToken || 'FakeToken'}`;

  return {
    baseUrl: xapisUrl,
    userKey,
    token,
  };
};

export default getUserKeyAndToken;
