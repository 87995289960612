import { notifications } from '@mantine/notifications';
import { handleHubSpotContact } from 'integrations';
import { updateUserInfoPromise } from 'store';

type ProfileFormData = {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
};

export const editProfileAction = async ({ request }: { request: Request }) => {
  const formData = await request.formData();
  const email = formData.get('email') as string;
  const user_key = formData.get('user_key') as string;

  const data = {
    first_name: formData.get('first_name'),
    last_name: formData.get('last_name'),
    phone: formData.get('phone'),
    email: formData.get('email'),
  } as ProfileFormData;

  let success = true;
  try {
    const res = await updateUserInfoPromise(user_key, data);
    if (res.status === 201) {
      handleHubSpotContact(
        {
          properties: {
            firstname: data?.first_name || '',
            lastname: data?.last_name || '',
            phone: data?.phone || '',
            email,
            website: (formData.get('origin_name') as string) || '',
          },
        },
        email
      );
      success = true;
      notifications.show({ message: 'Successfully updated your information!' });
    }
  } catch (err) {
    console.error('NEXT_ERROR', err);
    success = false;
    notifications.show({
      message: 'We are unable to update your information at this time.',
    });
  }

  return { success, ...data };
};
