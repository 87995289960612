import { create } from 'zustand';
import Get from './xapis-wrappers/Fetcher';
import { JournalWCurrencyCode, JournalWCurrencyCodeResponse } from './types';

export type JournalStore = {
  totalRows: number;
  journals: JournalWCurrencyCode[];
  setJournals: (journals: JournalWCurrencyCode[]) => void;
  setTotalRows: (totalRows: number) => void;
  fetchJournals: (payKey: string, params: object) => void;
};

export const useJournalsStore = create<JournalStore>()((set) => ({
  totalRows: 0,
  journals: [],
  setJournals: (journals) => set(() => ({ journals })),
  setTotalRows: (totalRows) => set(() => ({ totalRows })),
  fetchJournals: (payKey, params) => {
    Get(
      `Pay/Order/${payKey}?type=journal`,
      ({
        data: { months },
      }: {
        data: { months: JournalWCurrencyCodeResponse[]; totalRows: number };
      }) => {
        const parsedJournalWCurrencyCode: JournalWCurrencyCode[] = months.map(
          (m) => ({
            currencyCode: m.currency_code,
            month: m.month,
            payKey: m.pay_key,
            payProjectName: m.pay_project_name,
            subtotal: m.subtotal,
            tax: m.tax,
            total: m.total,
          })
        );
        set({ journals: parsedJournalWCurrencyCode });
        set({ totalRows: months.length });
      },
      { action: () => set({ journals: [] }) },
      params
    );
  },
}));

export default useJournalsStore;
