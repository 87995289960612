import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // the time a query can be stale before it's considered invalid, RQ default is 0 minutes
      gcTime: 1000 * 60 * 15, // the time inactive queries will be kept in the cache, RQ default is 5 minutes
    },
  },
});
