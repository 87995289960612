import React, { createContext, useContext } from 'react';

export interface SegmentEditorConfig {
  target: TranslationKey;
  user: User;
  glossary: GlossaryItem[];
  settings: {
    xapisHost: string; // XAPIS host origin, 'https://www.onelink-edge.com/' for example
    accessToken: string; // access Token
    contextMode: boolean; // In context mode or not
    viewOnly?: boolean; // View only mode
    disableTagEdit?: boolean; // Disable adding/deleting of (html) tags
    htmlDir?: 'auto' | 'ltr' | 'rtl';
  };
  contextAPI?: {
    onResetFilter?: () => void; // Actions to do when the user resets the filters
    onSelect?: (data: { segmentHash: string; blocks?: string[] }) => void; // Actions to do when the user selects a segment
    onTranslationChange?: (data: {
      segments: PreviewSegment[];
      blocks?: string[];
    }) => void; // Runs when a translation is modified
    // updatedSegments?: (cb: (segments: Segment[]) => void) => void; // Temporary, will be removed
  };
  // Temporary, will be removed
  xapisAPI?: {
    getTranslationHistory?: (
      sHash: string
    ) => Promise<{ status: number; targets: SegmentTranslationHistory[] }>;
    saveSegments?: (segments: Segment[]) => Promise<SegmentContentResponse>;
    deleteSegmentTranslation?: (
      segment: Segment
    ) => Promise<SegmentTranslationDeleteResponse>;
  };
}

const initialConfig: SegmentEditorConfig = {
  target: null as unknown as TranslationKey,
  glossary: [],
  user: { user_key: '' },
  settings: {
    xapisHost: 'http://localhost',
    accessToken: 'DefaultAccessToken',
    contextMode: false,
    viewOnly: false,
    disableTagEdit: false,
  },
  contextAPI: {},
  xapisAPI: {},
};

interface SEContext {
  config: SegmentEditorConfig;
  target: TranslationKey;
  translationKey: string;
  translationMethods: string[];
  user: User;
  glossary: GlossaryItem[];
  userKey: string;
  settings: SegmentEditorConfig['settings'];
  contextAPI: SegmentEditorConfig['contextAPI'];
  xapisAPI?: SegmentEditorConfig['xapisAPI'];
}

export const SegmentEditorContext = createContext<SEContext>({
  config: initialConfig,
  target: null as unknown as TranslationKey,
  glossary: [],
  translationKey: '',
  translationMethods: [],
  user: initialConfig.user,
  userKey: '',
  settings: initialConfig.settings,
  contextAPI: initialConfig.contextAPI,
  xapisAPI: initialConfig.xapisAPI,
});

export const segmenetEditorGlobals = {
  version: '2.0.1',
  appName: 'GLGO Segment Editor',
  translationKey: '',
  userKey: '',
};

export function useSegmentEditorContext() {
  const context = useContext(SegmentEditorContext);
  if (!context) {
    throw new Error(
      'SegmentEditorContext must be used within a SegmentEditorProvider'
    );
  }
  return context;
}

export function SegmentEditorProvider({
  children,
  config,
}: {
  children: React.ReactNode;
  config: SegmentEditorConfig;
}) {
  const { user, glossary, target, settings, contextAPI, xapisAPI } = config;

  settings.viewOnly = settings.viewOnly || target.translation_edit === false;
  const translationMethods = target?.translation_methods
    ? target.translation_methods.split(',')
    : [];

  segmenetEditorGlobals.translationKey = target?.translation_key || '';
  segmenetEditorGlobals.userKey = user.user_key || '';

  return (
    <SegmentEditorContext.Provider
      value={{
        config,
        glossary,
        target,
        translationKey: target?.translation_key || '',
        translationMethods,
        user,
        userKey: user.user_key || '',
        settings,
        contextAPI,
        xapisAPI,
      }}
    >
      {children}
    </SegmentEditorContext.Provider>
  );
}
