import '@mantine/core/styles.layer.css';
import '@mantine/notifications/styles.layer.css';
import './global.css';
import ReactDOM from 'react-dom/client';
import { AuthProvider, initUser, getAuthManager } from 'auth';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { queryClient, UserProvider, RecommendedThresholdProvider } from 'store';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { dashboardTheme, isDesktopApp } from 'helpers';
import C from './constants';
import TagManager from 'react-gtm-module';

import { routes } from './GLGOroutes';
import { Notifications } from '@mantine/notifications';
import { Wait } from 'ui';

const isGLGODesktop = isDesktopApp();
const { GTM_TAG_ID } = C;
const tagManagerParams = { gtmId: GTM_TAG_ID };

TagManager.initialize(tagManagerParams);

const createRouter = isGLGODesktop ? createHashRouter : createBrowserRouter;
const MantineWrapper = ({ children }: { children: React.ReactNode }) => (
  <MantineProvider theme={dashboardTheme || {}}>{children}</MantineProvider>
);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Change this to true to show the React Query DevTools
const showRQDevTools = false && import.meta.env.DEV;
// Render the loader while we wait for the user to be initialized
root.render(
  <MantineWrapper>
    <Wait text="Checking authentication status" />
  </MantineWrapper>
);
// Initialize the user data and render the app
initUser().then((user) => {
  root.render(
    <QueryClientProvider client={queryClient}>
      <AuthProvider userManager={getAuthManager()}>
        <UserProvider user={user}>
          <MantineWrapper>
            <RecommendedThresholdProvider>
              <Notifications />
              <RouterProvider router={createRouter(routes)} />
            </RecommendedThresholdProvider>
          </MantineWrapper>
        </UserProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={showRQDevTools} />
    </QueryClientProvider>
  );
});
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
