export const getPrevTimeSliceFrom = (timeSliceFrom: string) => {
  const numOfTime = timeSliceFrom.substring(0, timeSliceFrom.indexOf(' '));
  const prevNumOfTime = Number(numOfTime) * 2 || '2';
  const dateFormat =
    timeSliceFrom.substring(
      timeSliceFrom.indexOf(' ') + 1,
      timeSliceFrom.length
    ) || 'day';
  return `${prevNumOfTime} ${dateFormat}`;
};

export default getPrevTimeSliceFrom;
