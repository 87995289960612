import { Box, Tooltip } from '@mantine/core';
import { useEditorColors } from 'helpers';

const TooltipLabel = ({
  label,
  backgroundColor,
  maxWidth,
}: {
  label: React.ReactNode;
  backgroundColor: string;
  maxWidth?: number;
}) => {
  return (
    <Box
      maw={maxWidth || 'auto'}
      bg={backgroundColor}
      p="5px 10px"
      style={{
        borderRadius: 5,
      }}
    >
      {label}
    </Box>
  );
};

interface TipProps {
  children: React.ReactNode;
  label: React.ReactNode;
  disabled?: boolean;
  variant?: 'light' | 'dark';
  maxWidth?: number;
}
export const Tip = ({
  children,
  label,
  disabled,
  variant = 'dark',
  maxWidth,
}: TipProps) => {
  if (!['light', 'dark'].includes(variant)) variant = 'dark';
  const colors = useEditorColors().tooltip[variant];

  return (
    <Tooltip
      label={<TooltipLabel label={label} backgroundColor={colors.background} />}
      disabled={!label || disabled}
      c={colors.text}
      multiline
      styles={{
        tooltip: {
          borderRadius: 5,
          maxWidth: maxWidth || 'auto',
        },
        arrow: {
          backgroundColor: colors.border,
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
