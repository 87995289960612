import { useState, useEffect } from 'react';
import { Box, Popover, Flex, Title } from '@mantine/core';
import { useEditorColors } from 'helpers';
import { GlossaryInfo } from './GlossaryInfo';
import { InfoIcon } from '../../icons/IndicatorIcons';
import classes from './GlossaryViolations.module.css';

type Props = {
  violations: GlossaryItem[];
  isSelected: boolean;
};

export type GlossaryViolationsComponent = React.FC<Props>;

export const GlossaryViolations = ({ violations, isSelected }: Props) => {
  const colors = useEditorColors().glossary;
  const [showGlossary, setShowGlossary] = useState(false);

  useEffect(() => {
    if (!isSelected) setShowGlossary(false);
  }, [isSelected]);

  return violations.length ? (
    <Popover
      withArrow
      opened={showGlossary}
      position="top"
      offset={15}
      shadow="md"
      styles={{
        arrow: {
          zIndex: -50,
          border: 'none',
        },
      }}
    >
      <Popover.Target>
        <Box
          onMouseEnter={() => setShowGlossary(true)}
          onMouseLeave={() => setShowGlossary(false)}
          c={colors.violations}
          w={20}
          h={20}
          mt={isSelected ? 8 : 0}
          style={{
            cursor: 'pointer',
            transform: 'rotate(180deg)',
          }}
        >
          <InfoIcon />
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <Flex className={classes.popover}>
          <Title order={6} c={colors.violations} mb={5}>
            Glossary Violations
          </Title>
          <GlossaryInfo glossary={violations} />
        </Flex>
      </Popover.Dropdown>
    </Popover>
  ) : (
    <Box w={20}></Box>
  );
};
