import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, useLogout } from 'auth';
import { Wait } from 'ui';

export const Logout = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const logout = useLogout();
  const { isAuthenticated = false, isLoading = false } = auth;

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      setTimeout(() => {
        logout().finally(() => navigate('/', { replace: true }));
      }, 1500);
    } else {
      navigate('/', { replace: true });
    }
  }, [isAuthenticated, isLoading, logout]);

  return <Wait loader="dots" text="Logging you out ..." />;
};
