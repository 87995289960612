import React from 'react';
import { Button, Flex } from '@mantine/core';

type Props = {
  loading?: boolean;
  disabled?: boolean;
  defaultFormSubmit?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  onBack?: () => void;
  onConfirm?: () => void;
};

const FooterButtons = ({
  loading,
  disabled,
  defaultFormSubmit,
  ...props
}: Props) => {
  const { onCancel, onSave, onBack, onConfirm } = props || {};

  return (
    <Flex columnGap="1rem" justify="flex-end">
      <Button
        variant="outline"
        disabled={loading}
        onClick={() => {
          onCancel && onCancel();
          onBack && onBack();
        }}
      >
        {onCancel ? 'Cancel' : 'Back'}
      </Button>
      <Button
        type="submit"
        disabled={loading || disabled}
        onClick={() => {
          onSave && onSave();
          onConfirm && onConfirm();
        }}
      >
        {defaultFormSubmit || onSave ? 'Save' : 'Continue'}
      </Button>
    </Flex>
  );
};

export default FooterButtons;
