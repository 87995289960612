import { EditorColors } from 'helpers';
import { ElementModes } from '../../types/editor';

// q: `&::before {content: open-quote}; &::after { content: close-quote};`,
const tagStyle: Record<string, Record<string, string>> = {
  b: { fontWeight: 'bold' },
  a: { textDecoration: 'underline' },
  strong: { fontWeight: 'bold' },
  i: { fontStyle: 'italic' },
  em: { fontStyle: 'italic' },
  u: { textDecoration: 'underline' },
  ins: { textDecoration: 'underline' },
  s: { textDecoration: 'line-through' },
  del: { textDecoration: 'line-through' },
  small: { fontSize: 'smaller' },
  big: { fontSize: 'larger' },
  sup: { verticalAlign: 'super', fontSize: 'smaller' },
  sub: { verticalAlign: 'sub', fontSize: 'smaller' },
  mark: { backgroundColor: 'mark' },
};

export const formattingTags = Object.keys(tagStyle);

const borderStyle: Record<ElementModes, string> = {
  edit: 'none',
  move: 'solid none',
  resize: 'dashed none',
};

export const getTextStyle = (
  colors: EditorColors['jliffEditor'],
  // mode: ElementModes,
  formats?: string[]
) => {
  let css: Record<string, string> = {};

  if (!formats) return css;

  formats.forEach((tag) => {
    if (tagStyle[tag]) {
      const style = { ...tagStyle[tag] };
      // merge text decorations
      if (style.textDecoration && css.textDecoration) {
        const decorations = (css.textDecoration as string).split(' ');
        if (!decorations.includes(style.textDecoration as string)) {
          decorations.push(style.textDecoration as string);
          style.textDecoration = decorations.join(' ');
        }
      }
      css = { ...css, ...style };
    }
  });
  css.color = formats.includes('a') ? colors.anchor : 'inherit';
  css.backgroundColor = formats.includes('mark') ? colors.mark : 'inherit';
  css.whiteSpace = 'pre-wrap';

  return css;
};

export type SegmentStatus = {
  isSaved: boolean;
  isSelected: boolean;
  isUnsaved: boolean;
  isFailedSave: boolean;
  isAttribute?: boolean;
};
type stylesProps = {
  segmentStatus: SegmentStatus;
};

const translationBorderColor = (
  colors: EditorColors['jliffEditor'],
  status: SegmentStatus
) => {
  const { selected, unsaved, none } = colors.border;
  const { isSelected, isUnsaved, isFailedSave, isSaved } = status;
  if (isFailedSave) return colors.border.failSave;
  if (isSaved) return colors.border.saved;
  if (isSelected) return selected;
  if (isUnsaved) return unsaved;
  return none;
};

export const elementStyle = (
  colors: EditorColors['jliffEditor'],
  isHovered: boolean,
  isDragged: boolean,
  mode: ElementModes
  // formats?: string[]
): Record<string, string | number> => ({
  marginLeft: isHovered ? '20px' : '0',
  opacity: isDragged ? '0.4' : '1',
  borderStyle: borderStyle[mode],
  borderWidth: 1,
  borderColor: colors.tags.fill.focus,
});
