import { RouteObject } from 'react-router-dom';
import { ErrorBoundary, TranslatedTraffic, WorldTraffic } from 'ui';
import { dashboardRC } from 'helpers';
import { RestrictedRoute } from 'pages';
import {
  TRANSLATEDTRAFFIC_PAGE_USERTYPES,
  WORLDTRAFFIC_PAGE_USERTYPES,
} from 'glweb/src/constants';

export const routes: Array<RouteObject & { path: string }> = [
  {
    path: dashboardRC.PAGE_TRANSLATED_TRAFFIC,
    id: 'translatedTraffic',
    element: (
      <RestrictedRoute allowedRoles={TRANSLATEDTRAFFIC_PAGE_USERTYPES}>
        <TranslatedTraffic />
      </RestrictedRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: dashboardRC.PAGE_WORLD_TRAFFIC,
    id: 'worldTraffic',
    element: (
      <RestrictedRoute allowedRoles={WORLDTRAFFIC_PAGE_USERTYPES}>
        <WorldTraffic />
      </RestrictedRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
];
