import React, { useCallback, useMemo } from 'react';
import { Card, Flex, Progress, Text, Tooltip } from '@mantine/core';
import { TranslatedTrafficUrl } from 'store';
import LoadingSkeleton from '../godashboard/LoadingSkeleton';
import { MRT_ColumnDef } from 'mantine-react-table';
import CustomTable from '../CustomTable';
import { getBasicColumn } from '../utils';

type Props = {
  translationKeys: string[];
  urls: TranslatedTrafficUrl[];
  loading: boolean;
};

const TranslatedTrafficTable = ({ translationKeys, urls, loading }: Props) => {
  const contentUrlMap = useMemo(() => {
    return urls.reduce((map, urlObj) => {
      const { content_url = '' } = urlObj || {};
      const totalTraffic = translationKeys.reduce((accum, tKey) => {
        return (accum += urlObj[tKey] || 0);
      }, 0);
      map.set(content_url, totalTraffic);
      return map;
    }, new Map());
  }, [urls, translationKeys]);

  const totalTranslatedTraffic: number = useMemo(() => {
    return [...contentUrlMap.values()].reduce((accum, count) => {
      return accum + count;
    }, 0);
  }, [contentUrlMap]);

  const getTranslatedTrafficPercentage = useCallback(
    (count: number) => {
      return Math.floor((count * 100) / totalTranslatedTraffic);
    },
    [totalTranslatedTraffic]
  );

  const formatUrl = (url: string) => {
    const cleanedUrl = url.startsWith('//') ? url.slice(2) : url;
    return cleanedUrl.replace(/\//g, '/​');
  };

  const contentUrlTraffic = useMemo(() => {
    return [...contentUrlMap.entries()]
      .map((entry) => {
        const [key, count] = entry || ['', 0];
        return {
          page: formatUrl(key),
          visits: count,
          percentage: getTranslatedTrafficPercentage(count),
        };
      })
      .filter(({ visits = 0, percentage = 0 }) => {
        return visits && percentage;
      })
      .sort((objA, objB) => {
        const { visits: visitsA = 0 } = objA || {};
        const { visits: visitsB = 0 } = objB || {};
        return visitsB - visitsA;
      });
  }, [contentUrlMap, getTranslatedTrafficPercentage]);

  const columns: MRT_ColumnDef<(typeof contentUrlTraffic)[0]>[] = [
    {
      ...getBasicColumn('page', 'pages', true),
    },
    {
      ...getBasicColumn('visits', 'visits'),
      Cell: ({ cell }) => {
        const visits = cell.getValue() as number;
        return (
          <Tooltip position="top" label={visits.toLocaleString()}>
            <Text fw={700} truncate>
              {visits.toLocaleString()}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      ...getBasicColumn('percentage', ''),
      Cell: ({ cell }) => {
        const percentage = cell.getValue() as number;
        return (
          <Tooltip label="This is the percentage of total translated traffic that went to this particular page.">
            <Flex w="100%" align="center" gap={10}>
              <Progress value={percentage} w="100%" />
              <Text fw={700}>{percentage}%</Text>
            </Flex>
          </Tooltip>
        );
      },
    },
  ];

  if (loading) {
    return (
      <Card p={20} h={452}>
        <LoadingSkeleton />
      </Card>
    );
  }

  return (
    <Card p={0}>
      <CustomTable
        data={contentUrlTraffic}
        columns={columns}
        enableBottomToolbar={false}
        paperProps={{ style: { boxShadow: 'none' } }}
        enableSorting={false}
        enableRowVirtualization={false}
        containerProps={{ mih: 450, mah: 450 }}
      />
    </Card>
  );
};

export default TranslatedTrafficTable;
