import React from 'react';
import { Button, Menu, Text } from '@mantine/core';
import { CircleAvatar, LinkWrapper } from '../../Generic';
import { MdArrowDropDown } from 'react-icons/md';
import { useLocation, useParams } from 'react-router-dom';
import { dashboardRC, isDesktopApp } from 'helpers';
import { useLogout } from 'auth';
import { useCurrentProjectData, useUserProvider } from 'store';
import { USERMANAGEMENT_PAGE_USERTYPES } from 'glweb/src/constants';
import { hasPageViewPermissions } from 'helpers/utils/projectPermissions';

type Props = {
  setShowAboutModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDesktopToggled: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function AccountMenu({
  setShowAboutModal,
  setIsDesktopToggled,
}: Props) {
  const { pathname } = useLocation();
  const { projectId } = useParams();
  const logout = useLogout();
  const isDesktop = isDesktopApp();
  const {
    xapisUser: { user_key: userKey },
  } = useUserProvider();

  const { projectUserPermissionMap } = useCurrentProjectData();
  const permissions: Set<string> =
    projectUserPermissionMap[userKey]?.rolePermissionSet ?? new Set();

  const menuItems = [
    {
      label: dashboardRC.PAGE_TITLE_ACCOUNT,
      to: `${projectId}/${dashboardRC.PAGE_ACCOUNT}`,
    },
    {
      label: dashboardRC.PAGE_TITLE_DESKTOP_APP,
      to: `${projectId}/${dashboardRC.PAGE_DESKTOP_APP}`,
    },
    {
      label: dashboardRC.PAGE_TITLE_USER_MANAGEMENT,
      to: `${projectId}/${dashboardRC.PAGE_USER_MANAGEMENT}`,
      userTypes: USERMANAGEMENT_PAGE_USERTYPES,
    },
    {
      label: 'About',
      action: () => setShowAboutModal(true),
    },
    { label: dashboardRC.PAGE_TITLE_LOGOUT, action: logout },
  ].filter(
    ({ label, userTypes }) =>
      !(isDesktop && label === dashboardRC.PAGE_TITLE_DESKTOP_APP) &&
      (!userTypes || hasPageViewPermissions(permissions, userTypes))
  );

  const { xapisUser: { first_name = '', last_name = '' } = {} } =
    useUserProvider();

  const resetSideNav = () => {
    setIsDesktopToggled(false);
  };

  return (
    <Menu
      width={175}
      offset={{ mainAxis: 5 }}
      closeOnClickOutside
      closeOnEscape
    >
      <Menu.Target>
        <Button
          c="text.0"
          px={0}
          variant="transparent"
          leftSection={
            <CircleAvatar name={first_name} backgroundColor="#6ED6CC" />
          }
          rightSection={<MdArrowDropDown size={22} />}
        >
          <Text c="inherit">
            {first_name} {last_name}
          </Text>
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {menuItems.map(({ label, to, action }) => {
          const isActive = Boolean(to && pathname.includes(to));

          return (
            <LinkWrapper onClick={resetSideNav} key={label} to={to}>
              <Menu.Item
                onClick={() => {
                  resetSideNav();
                  action && action();
                }}
                aria-current={isActive}
                data-active={isActive}
              >
                {label}
              </Menu.Item>
            </LinkWrapper>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
}
