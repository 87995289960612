export const NEVER_TRANSLATE = 'never_translate';
export const NEVER_TRANSLATE_AS = 'never_translate_as';
export const TRANSLATE_AS = 'translate_as';
export const glossaryRules = [
  NEVER_TRANSLATE,
  TRANSLATE_AS,
  NEVER_TRANSLATE_AS,
];

export type RuleType = (typeof glossaryRules)[number];
