import {
  GLGO_ENTERPRISE_PROJECT_TYPE,
  GLGO_PROJECT_TYPE,
  GLWEB_PROJECT_TYPE,
  OLJS_PROJECT_TYPE,
} from 'helpers';
import { glgoUser } from 'store';
import { useGLGOParams } from './glgoParams';

export const useCurrentProjectType = () => {
  const { projectKey } = useGLGOParams();

  const projectType = projectKey ? glgoUser.projectType(projectKey) : '';

  const isGLGOProject = projectType === GLGO_PROJECT_TYPE;
  const isGLWEBProject =
    projectType === GLWEB_PROJECT_TYPE || projectType === OLJS_PROJECT_TYPE;
  const isGLWEBEnterprise = projectType === OLJS_PROJECT_TYPE;
  const isGLGOEnterprise = projectType === GLGO_ENTERPRISE_PROJECT_TYPE;

  return {
    isGLGOProject,
    isGLWEBProject,
    isGLGOEnterprise,
    isGLWEBEnterprise,
  };
};
