import React, { useMemo } from 'react';
import { Flex, Title, Card, Button, Text } from '@mantine/core';
import { ConfirmEnvelope } from 'images';
import { Link, useLoaderData } from 'react-router-dom';
import { DowngradeConfirmationLoaderData } from 'store';
import { getPrettyDate } from 'helpers';
import { useGLGOParams } from 'hooks';
import OrderBackground from './order-bg.svg';

export const DowngradeConfirmationPage = () => {
  const { projectId } = useGLGOParams();
  const { activeSubscription } =
    useLoaderData() as DowngradeConfirmationLoaderData;

  const {
    next_renewal_date,
    next_subscription: {
      price: nextSubPrice,
      sku: nextSubSku,
      sku_name: nextSubSkuName,
      threshold_value: nextSubThresholdValue = '',
    } = {},
  } = activeSubscription;

  const prettyRenewalDate = useMemo(
    () => getPrettyDate(next_renewal_date),
    [next_renewal_date]
  );

  return (
    <Flex
      mih="calc(100vh - 48px)"
      pt="2rem"
      pb="4rem"
      justify="center"
      direction="column"
      style={{
        backgroundImage: `url(${OrderBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <Card
        mx="auto"
        maw={{ base: '95%', sm: '80%', md: '60%' }}
        p={{ base: '2rem 1rem', xs: '2rem', sm: '3rem' }}
        radius="md"
        shadow="sm"
        withBorder
      >
        <Flex direction="column" align="center" rowGap="2rem">
          {!nextSubSku ? (
            <Title order={1} ta="center">
              No Downgrade Found
            </Title>
          ) : (
            <>
              <Title order={1} ta="center">
                Thank You For Choosing GlobalLink WEB!
              </Title>
              <ConfirmEnvelope name="envelope" />
              <Flex
                maw={540}
                direction="column"
                align="center"
                rowGap="1.5rem"
                ta="center"
              >
                <Text>
                  Your subscription will be changed to {nextSubSkuName} on{' '}
                  {prettyRenewalDate}, which entitles you to{' '}
                  {nextSubThresholdValue.toLocaleString()} words per month. Your
                  current subscription will continue until the end of this
                  billing cycle. The payment method you have on file will not be
                  charged at this time. At the next billing cycle you will be
                  charged ${nextSubPrice} plus any applicable taxes and fees. A
                  confirmation email has been sent to the account on file.
                </Text>
                <Text>
                  If you don't receive a confirmation email after 24 hours,
                  contact us for support at: support@globalinkgo.com.
                </Text>
              </Flex>
            </>
          )}
          <Link to={`/${projectId}/dashboard`}>
            <Button>Back to Dashboard</Button>
          </Link>
        </Flex>
      </Card>
    </Flex>
  );
};

export default DowngradeConfirmationPage;
