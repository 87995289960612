import { useRef } from 'react';
import { Box, Flex, Highlight } from '@mantine/core';
import { isTag, isText, isToken, mapJliff } from './jliffFunctions';
import { TagModes } from '../../types/editor';
import { getTextStyle } from './Jliff.style';
import { useEditorColors } from 'helpers';
import { Token } from '../Markers/Token';
import { Tag } from '../Markers/Tag';

type JliffProps = {
  jliffs: Jliff[];
  filterText?: string;
  isBlockMode?: boolean;
  domObjects?: DomObject;
  showAllTags: boolean;
  isSelected: boolean;
  htmlDir?: 'auto' | 'ltr' | 'rtl';
};

export const JliffViewer = ({
  jliffs,
  filterText,
  showAllTags,
  isSelected: isSegmentSelected,
  htmlDir = 'auto',
}: JliffProps) => {
  const { tagMap, indexMap } = mapJliff(jliffs);
  const colors = useEditorColors().jliffEditor;
  const textEl = useRef<HTMLSpanElement>(null);

  return (
    <Flex
      className={'JliffViewer'}
      wrap={'wrap'}
      w="100%"
      p={!isSegmentSelected ? '24px 0 10px 0' : '10px 0'}
      align="flex-start"
      style={htmlDir === 'rtl' ? { direction: 'rtl' } : {}}
    >
      {jliffs.map((jliff, i) => {
        const tagId = indexMap[i];
        const tags = tagMap[tagId]?.tags || [];

        if (isText(jliff)) {
          return (
            <Box
              component="span"
              ref={textEl}
              key={i}
              style={{
                ...getTextStyle(colors, tags),
                wordBreak: 'break-word',
                hyphens: 'initial',
              }}
            >
              <Highlight
                fz={!isSegmentSelected ? 16 : 14}
                highlight={filterText || ''}
                display="inline"
              >
                {jliff.text || ''}
              </Highlight>
            </Box>
          );
        }
        if (isToken(jliff)) {
          const isBr = jliff.subType === 'mx:br';
          const isLegacy = !('type' in jliff); // JLIFF don't have 'type' attribute
          // Ignore 'ph' with no 'equiv' unless it's a BR or legacy
          const dontShow = !isLegacy && !jliff.equiv && !isBr;
          if (dontShow) return null;
          const text = jliff.equiv;
          const kind = isBr ? 'br' : jliff.disp || 'misc';
          const mode = showAllTags ? 'show' : 'hide';

          return (
            <Box key={i} component="span" style={getTextStyle(colors, tags)}>
              <Token text={text} kind={kind} mode={mode} />
            </Box>
          );
        }
        if (isTag(jliff)) {
          if (!jliff.id) {
            console.error('Error: No ID for JLIFF element', jliff);
            return null;
          }
          const kind = jliff.kind as 'sc' | 'ec';
          const tag = tagMap[tagId].type;
          const mode: TagModes = showAllTags ? 'show' : 'hide';

          return (
            <Box key={i} component="span">
              <Tag kind={kind} type={tag} mode={mode} />
            </Box>
          );
        }

        console.log('Source jliff: No handler for', jliff);
        return null;
      })}
    </Flex>
  );
};
