import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { useAuth, getAuthManager, fetchXapisUser } from 'auth';
import { useUserProvider } from 'store';
import { dashboardRC, isDesktopApp } from 'helpers';
import { Wait } from 'ui';

const PostLogin = () => {
  const navigate = useNavigate();
  const { setXapisUser } = useUserProvider();

  const auth = useAuth();
  const { isAuthenticated = false, isLoading = false, error, user } = auth;
  const redirectUrl = (auth?.user?.state as { path: string })?.path || '/';
  const location = useLocation();

  useEffect(() => {
    if (isDesktopApp()) {
      const authManager = getAuthManager();
      const url = `${location.pathname}${location.hash}`;
      void (async () =>
        await authManager.signinCallback(url).catch((error) => {
          console.error('PostLogin::signinCallback Error:', error);
        }))();
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      // Fetch user from Xapis
      const { email } = user?.profile || {};
      if (email) {
        void (async () => {
          const { xapisUser } = await fetchXapisUser(email);
          if (xapisUser) {
            setXapisUser(xapisUser);
            navigate(redirectUrl, { replace: true });
          } else {
            notifications.show({
              message: 'Failed to fetch user projects',
              color: 'red',
            });
            navigate(dashboardRC.PAGE_ROOT, { replace: true });
          }
        })();
      } else {
        notifications.show({
          message: 'Unable to get user projects',
          color: 'red',
        });
        console.error('PostLogin::No user email', { user });
        navigate(dashboardRC.PAGE_ROOT, { replace: true });
      }
    }
  }, [isAuthenticated, isLoading]);

  if (error) {
    const { message } = error;
    console.error('auth.error: ', error);
    return <div>Post Login: Oops... {message}</div>;
  }
  return <Wait text="Authenticating user" />;
};

export default PostLogin;
