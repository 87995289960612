import {
  MdDashboard,
  MdSettings,
  MdTimeline,
  MdTranslate,
  MdFormatQuote,
} from 'react-icons/md';
import { BsFillPersonFill } from 'react-icons/bs';
import { IconDeer } from '@tabler/icons';
import { dashboardRC } from 'helpers';
import { HTMLAttributeAnchorTarget } from 'react';

import {
  BILLING_PAGE_USERTYPES,
  DEPLOYMENT_PAGE_USERTYPES,
  GLOSSARY_PAGE_USERTYPES,
  INJECTION_PAGE_USERTYPES,
  LANGUAGES_PAGE_USERTYPES,
  LANGUAGESELECTOR_PAGE_USERTYPES,
  LANGUAGESETTINGS_PAGE_USERTYPES,
  MTUSAGE_PAGE_USERTYPES,
  NOTRANSLATE_PAGE_USERTYPES,
  PROFILE_PAGE_USERTYPES,
  PROJECTSCOPE_PAGE_USERTYPES,
  TRANSLATEDTRAFFIC_PAGE_USERTYPES,
  WORDSSERVED_PAGE_USERTYPES,
  WORLDTRAFFIC_PAGE_USERTYPES,
} from 'glweb/src/constants';
import createUnionSet from '../../../utils/createUnionSet';

export type NavLink = {
  icon: React.ReactNode;
  label: string;
  path?: string;
  action?: () => void;
  pages?: SubNavLink[];
  userTypes?: Set<string>;
};

type SubNavLink = Pick<NavLink, 'label' | 'path' | 'action' | 'userTypes'> & {
  link?: string;
  target?: HTMLAttributeAnchorTarget;
};

export const navLinks: NavLink[] = [
  {
    icon: <MdDashboard size={20} />,
    label: dashboardRC.PAGE_TITLE_DASHBOARD,
    path: dashboardRC.PAGE_DASHBOARD,
  },
  {
    icon: <MdTranslate size={20} />,
    label: dashboardRC.PAGE_TITLE_TRANSLATIONS,
    path: dashboardRC.PAGE_TRANSLATIONS,
    userTypes: createUnionSet(
      NOTRANSLATE_PAGE_USERTYPES,
      GLOSSARY_PAGE_USERTYPES,
      LANGUAGESELECTOR_PAGE_USERTYPES
    ),
    pages: [
      {
        label: dashboardRC.PAGE_TITLE_LANGUAGES,
        path: dashboardRC.PAGE_LANGUAGES,
        userTypes: LANGUAGES_PAGE_USERTYPES,
      },
      {
        label: dashboardRC.PAGE_TITLE_GLOSSARY,
        path: dashboardRC.PAGE_GLOSSARY,
        userTypes: GLOSSARY_PAGE_USERTYPES,
      },
      {
        label: dashboardRC.PAGE_TITLE_NO_TRANSLATE_RULES,
        path: dashboardRC.PAGE_NO_TRANSLATE_RULES,
        userTypes: NOTRANSLATE_PAGE_USERTYPES,
      },
    ],
  },
  {
    icon: <MdTimeline size={20} />,
    label: dashboardRC.PAGE_TITLE_METRICS,
    path: dashboardRC.PAGE_METRICS,
    userTypes: createUnionSet(
      TRANSLATEDTRAFFIC_PAGE_USERTYPES,
      WORLDTRAFFIC_PAGE_USERTYPES
    ),
    pages: [
      {
        label: dashboardRC.PAGE_TITLE_TRANSLATED_TRAFFIC,
        path: dashboardRC.PAGE_TRANSLATED_TRAFFIC,
        userTypes: TRANSLATEDTRAFFIC_PAGE_USERTYPES,
      },
      {
        label: dashboardRC.PAGE_TITLE_WORLD_TRAFFIC,
        path: dashboardRC.PAGE_WORLD_TRAFFIC,
        userTypes: WORLDTRAFFIC_PAGE_USERTYPES,
      },
    ],
  },
  {
    icon: <MdSettings size={20} />,
    label: dashboardRC.PAGE_TITLE_SETTINGS,
    path: dashboardRC.PAGE_SETTINGS,
    userTypes: createUnionSet(
      LANGUAGESETTINGS_PAGE_USERTYPES,
      LANGUAGESELECTOR_PAGE_USERTYPES,
      DEPLOYMENT_PAGE_USERTYPES,
      INJECTION_PAGE_USERTYPES,
      PROJECTSCOPE_PAGE_USERTYPES
    ),
    pages: [
      {
        label: dashboardRC.PAGE_TITLE_LANGUAGE_SETTINGS,
        path: dashboardRC.PAGE_LANGUAGE_SETTINGS,
        userTypes: LANGUAGESETTINGS_PAGE_USERTYPES,
      },
      {
        label: dashboardRC.PAGE_TITLE_LANGUAGE_SELECTOR,
        path: dashboardRC.PAGE_LANGUAGE_SELECTOR,
        userTypes: LANGUAGESELECTOR_PAGE_USERTYPES,
      },
      {
        label: dashboardRC.PAGE_TITLE_DEPLOYMENT,
        path: dashboardRC.PAGE_DEPLOYMENT,
        userTypes: DEPLOYMENT_PAGE_USERTYPES,
      },
      {
        label: dashboardRC.PAGE_TITLE_INJECTION,
        path: dashboardRC.PAGE_INJECTION,
        userTypes: INJECTION_PAGE_USERTYPES,
      },
      {
        label: dashboardRC.PAGE_TITLE_PROJECT_SCOPE,
        path: dashboardRC.PAGE_PROJECT_SCOPE,
        userTypes: PROJECTSCOPE_PAGE_USERTYPES,
      },
    ],
  },
  {
    icon: <IconDeer size={20} />,
    label: dashboardRC.PAGE_TITLE_DEBUG,
    path: dashboardRC.PAGE_STORE_DEBUG,
  },
  {
    icon: <MdFormatQuote size={20} />,
    label: dashboardRC.PAGE_TITLE_QUOTES,
    path: dashboardRC.PAGE_QUOTES,
  },
];

export const accountLink: NavLink = {
  icon: <BsFillPersonFill size={20} />,
  label: dashboardRC.PAGE_TITLE_ACCOUNT,
  path: dashboardRC.PAGE_ACCOUNT,
  pages: [
    {
      label: dashboardRC.PAGE_TITLE_MY_PROFILE,
      path: dashboardRC.PAGE_MY_PROFILE,
      userTypes: PROFILE_PAGE_USERTYPES,
    },
    {
      label: dashboardRC.PAGE_TITLE_WORDS_SERVED_USAGE_DETAILS,
      path: dashboardRC.PAGE_WORDS_SERVED_USAGE_DETAILS,
      userTypes: WORDSSERVED_PAGE_USERTYPES,
    },
    {
      label: dashboardRC.PAGE_TITLE_MT_USAGE_DETAILS,
      path: dashboardRC.PAGE_MT_USAGE_DETAILS,
      userTypes: MTUSAGE_PAGE_USERTYPES,
    },
    {
      label: dashboardRC.PAGE_TITLE_BILLING_INFORMATION,
      path: dashboardRC.PAGE_BILLING_INFORMATION,
      userTypes: BILLING_PAGE_USERTYPES,
    },
  ],
};
