import { Box, Grid, Flex, Divider, Text, Image } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import RemarketStatsScript from '../gosetup/RemarketStatsScript';
import badges from './badges';
import logos from './logos';
import GLWEBLogoColor from 'images/logos/glweb-single-level-logo.svg';
import FiveStars from './images/five-stars.svg';
import classes from './AccoladesWrapper.module.css';

type Props = {
  children: React.ReactNode;
};

const AccoladesWrapper = ({ children }: Props) => {
  const isDesktopSize = useMediaQuery('(min-width: 1200px)');

  return (
    <Box w="100%" bg="background.1">
      <Grid m={0} gutter={10} className={classes.accoladesWrapper}>
        <Grid.Col
          display={isDesktopSize ? 'block' : 'none'}
          span={{ base: 12, lg: 7 }}
          m={0}
          p={0}
        >
          <Flex
            style={{
              borderBottomRightRadius: isDesktopSize ? 20 : 0,
              borderTopRightRadius: isDesktopSize ? 20 : 0,
              background:
                'linear-gradient(80.08deg, rgba(255, 255, 255, 0.2) 1.84%, rgba(236, 216, 255, 0.2) 17.99%, rgba(223, 249, 255, 0.2) 54.65%, rgba(132, 189, 255, 0.2) 89.14%)',
              boxShadow: '2px 4px 4px 0px #003B7140',
            }}
            p="4rem 2rem"
            w="100%"
            h="100vh"
            align="center"
          >
            <Flex
              direction="column"
              align="center"
              justify="center"
              w="100%"
              p="2.5rem"
              h="100%"
              gap={55}
            >
              <Flex direction="column" maw={1280} mx="auto" gap={55}>
                <Flex direction="column" gap={20}>
                  <Box w="100%">
                    <Image
                      maw={270}
                      height="auto"
                      mx="auto"
                      src={GLWEBLogoColor}
                    />
                  </Box>
                  <Flex w="100%" align="flex-start" columnGap={10}>
                    <Flex w="60%" direction="row" gap={10}>
                      <Flex direction="column" justify="flex-start">
                        <Flex gap={10} align="center">
                          <Text c="font1.0" fz={32} lh="normal" fw={700}>
                            4.5
                          </Text>
                          <Image
                            height="auto"
                            maw={64}
                            src={FiveStars}
                            alt="Five stars"
                          />
                        </Flex>
                        <Text
                          c="font1.0"
                          ta={{ base: 'right', xs: 'left' }}
                          fz={11}
                          lh="normal"
                          fw={500}
                        >
                          Out Of 5 <b>G2</b> Rating
                        </Text>
                      </Flex>
                      <Flex align="end" h="100%">
                        <Divider
                          orientation="vertical"
                          size="sm"
                          h="70%"
                          mih="3rem"
                          mt="0.7rem"
                          color="#567FA5"
                          style={{ alignSelf: 'auto' }}
                        />
                      </Flex>
                      <Flex direction="column" justify="flex-start">
                        <Flex columnGap="1rem" align="center">
                          <Text c="font1.0" fz={32} lh="normal" fw={700}>
                            4.6
                          </Text>
                          <Image
                            height="auto"
                            maw={64}
                            src={FiveStars}
                            alt="Five stars"
                          />
                        </Flex>
                        <Text c="font1.0" fz={11} lh="normal" fw={500}>
                          Out Of 5 <b>Capterra</b> Rating
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex w="40%" gap={10} align="center" justify="end">
                      {badges.map((badge) => (
                        <Box key={badge} w="100%" h="100%" maw={60}>
                          <Image src={badge} />
                        </Box>
                      ))}
                    </Flex>
                  </Flex>
                </Flex>
                <Flex direction="column">
                  <Box
                    style={{ border: '1px solid #CBD1F9' }}
                    bg="white"
                    p="2rem"
                  >
                    <Flex direction="column" gap={10} ta="center" w="100%">
                      <Text c="font1.0" fz="lg" fw={800}>
                        Best Translation Solution on the Market
                      </Text>
                      <Text c="font1.0" fz={20} fw={500} maw={490} mx="auto">
                        "GlobalLink makes translating web content to other
                        languages{' '}
                        <Text c="#FC5B1F" fz={20} span fw={700}>
                          simple
                        </Text>{' '}
                        with its{' '}
                        <Text c="#FC5B1F" fz={20} span fw={700}>
                          seamless integration."
                        </Text>
                      </Text>
                      <Box>
                        <Text c="font1.0" fw={800} fz="lg">
                          James S.
                        </Text>
                        <Text c="font1.0" fw={500}>
                          Sr. Director, Web Communications & Marketing
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </Flex>
                <Flex direction="column" gap={20} justify="center">
                  <Text
                    tt="uppercase"
                    fz="xl"
                    fw={500}
                    c="#139DD8"
                    lts={3}
                    ta="center"
                  >
                    Trusted by the best for over 30 years
                  </Text>
                  <Flex
                    className="logos"
                    w="100%"
                    gap={30}
                    wrap="wrap"
                    justify="center"
                  >
                    {logos.map((logo) => (
                      <Image key={logo} w="auto" h={22} mx="auto" src={logo} />
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 5 }}>{children}</Grid.Col>
      </Grid>
      <RemarketStatsScript />
    </Box>
  );
};

export default AccoladesWrapper;
