export const getMTEngine = (mtCode: string) => {
  const mtEngines = {
    ms: 'Microsoft',
    ty: 'GlobalLink AI',
    wl: 'WorldLingo',
    google: 'Google',
    bing: 'Bing',
  } as const;

  const mtCodeLower = mtCode.toLowerCase();
  return mtEngines[mtCodeLower as keyof typeof mtEngines] || 'N/A';
};

export default getMTEngine;
