import { create } from 'zustand';

export type SideNavStore = {
  isNavExpanded: boolean;
  setIsNavExpanded: (isNavExpanded: boolean) => void;
};

export const useSideNavStore = create<SideNavStore>()((set) => ({
  isNavExpanded: false,
  setIsNavExpanded: () =>
    set((state) => ({ isNavExpanded: !state.isNavExpanded })),
}));

export default useSideNavStore;
