import { useEffect, useState } from 'react';
import { AppShell, Overlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MainHeader, SideNav } from 'ui';
import { Outlet } from 'react-router-dom';
import { xapisEnv } from 'store';
import ReactGA4 from 'react-ga4';
import { navLinks } from 'helpers';
import { useViewSize } from 'hooks';
import useMainOutletStyles from './useMainOutletStyles';
import classes from './NavigationLayout.module.css';

export default function NavigationLayout() {
  const { isMobileSize } = useViewSize();

  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure(false);
  const [isDesktopToggled, setIsDesktopToggled] = useState(false);

  const { googleAnalyticsId } = xapisEnv.getHost;

  const mainOutletStyles = useMainOutletStyles(isDesktopToggled);

  useEffect(() => {
    ReactGA4.initialize(googleAnalyticsId);
  }, [googleAnalyticsId]);

  return (
    <>
      <Overlay
        fixed
        zIndex={100}
        display={{ base: mobileOpened ? 'block' : 'none', md: 'none' }}
        style={{ touchAction: 'none' }}
      />
      <AppShell
        withBorder={!isMobileSize}
        header={{ height: 48 }}
        navbar={{
          width: { base: '80%', md: 64, lg: isDesktopToggled ? 64 : 220 },
          breakpoint: 'md',
          collapsed: { mobile: !mobileOpened },
        }}
        classNames={{
          navbar: classes.navbar,
          main: classes.main,
        }}
      >
        <AppShell.Navbar
          w={{ base: '80%', md: 64, lg: isDesktopToggled ? 64 : 220 }}
        >
          <SideNav
            links={navLinks}
            setIsDesktopToggled={setIsDesktopToggled}
            isDesktopToggled={isDesktopToggled}
            toggleMobile={toggleMobile}
          />
        </AppShell.Navbar>
        <AppShell.Header>
          <MainHeader
            toggleMobile={toggleMobile}
            setIsDesktopToggled={setIsDesktopToggled}
            isDesktopToggled={isDesktopToggled}
          />
        </AppShell.Header>
        <AppShell.Main {...mainOutletStyles}>
          <Outlet />
        </AppShell.Main>
      </AppShell>
    </>
  );
}
