import { Xapis } from 'store';
import {
  getTranslationConfigAndRules,
  cleanNoTxRules,
  objToHex,
  getNoTxRuleHash,
} from 'helpers';

// Update no_translate rules with new/edited/deleted rule
// return updated no_translate array
export const getUpdatedNoTxRules = (
  rule: UX | UTIC | undefined,
  id: number | undefined,
  noTranslate: UTICX[]
) => {
  const cleanedRules = cleanNoTxRules(noTranslate);
  // If no rule and id is provided -> remove rule
  if (!rule && id) {
    return cleanedRules.filter((r) => getNoTxRuleHash(r) !== id);
  } else if (rule && !id) {
    // If no id, it's a new rule
    cleanedRules.push(rule as UX);
  } else if (rule && id) {
    const index = cleanedRules.findIndex((r) => getNoTxRuleHash(r) === id);
    if (index > -1) cleanedRules[index] = rule;
    else console.error('Edit rule::No rule found with id:', id);
  }
  return cleanedRules;
};

export const updateNoTxRules = ({
  rule,
  id,
  projectKey,
  target,
}: {
  rule?: UX | UTIC;
  id?: number;
  projectKey: string;
  target: TranslationKey | undefined;
}) => {
  const { translation_key = '', translation_config_hash = '' } = target || {};

  const { translationConfig, translation_rules, no_translate } =
    getTranslationConfigAndRules(target);
  const updatedNoTxRules = getUpdatedNoTxRules(rule, id, no_translate);

  const updatedTranslationRules = {
    ...translation_rules,
    no_translate: updatedNoTxRules,
  };
  const updatedConfig = {
    ...translationConfig,
    translation_rules: updatedTranslationRules,
  };

  const data = {
    translation_config: objToHex(updatedConfig),
    translation_config_hash,
  };

  return Xapis.ProjectTranslation.put(projectKey, translation_key, data);
};
