import { useState, useContext } from 'react';
import { SegmentEditorContext } from '../../context/SegmentEditorProvider';

import { Box, Popover, Text } from '@mantine/core';
import { useEditorColors } from 'helpers';
import { Insertable, InsertElement } from './InsertElement';
import QuickAction from '../Generic/QuickAction';

type Props = {
  addElement?: (insert: Insertable) => void;
};
export const InsertMore = ({ addElement }: Props) => {
  const colors = useEditorColors().jliffActions;
  const [showMore, setShowMore] = useState(false);
  const { viewOnly } = useContext(SegmentEditorContext).settings;

  return (
    <Popover
      withinPortal
      withArrow
      opened={showMore}
      onChange={setShowMore}
      position="bottom-end"
      arrowSize={14}
      arrowOffset={35}
      shadow="md"
      styles={{
        arrow: {
          zIndex: -50,
          border: 'none',
          backgroundColor: colors.popover.arrow,
        },
      }}
    >
      <Popover.Target>
        <Box>
          <QuickAction
            width={30}
            height={30}
            icon={
              <Text fw={500} pb={8} c="blue.8" fz={25}>
                ...
              </Text>
            }
            backgroundColor={colors.background}
            isActive={showMore}
            isDisabled={viewOnly || false}
            onClick={() => setShowMore((o) => !o)}
          />
        </Box>
      </Popover.Target>
      <Popover.Dropdown
        style={{
          borderColor: colors.popover.border,
          borderRadius: 15,
        }}
      >
        <InsertElement addElement={addElement} onClose={setShowMore} />
      </Popover.Dropdown>
    </Popover>
  );
};
