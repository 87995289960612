import ReactGA4 from 'react-ga4';
import TagManager from 'react-gtm-module';

const analyticsEvent = (category: string, action: string, label: string) => {
  ReactGA4.event({
    category,
    action,
    label,
  });
};

/*
For sending google tag manager events.
1. "name" is the name of the action, such as form submission or click
2. "category" will be constant so no worries here unless we need a change
3. "action" is the result of what happened, such as users are adding languages or upgrading/downgrading payment plans
4. "label" is the name of the component/form or whatever the user is doing the action on.
*/

const sendTagEvent = (name: string, action: string, label: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: name, // refer to comment above
      category: 'user_action',
      action: action, // refer to comment above
      label: label, // refer to comment above
    },
  });
};

const constants = {
  //EVENTS
  EVENT_CLICK: 'Click',
  EVENT_FORM_SUBMISSION: 'Form Submission',
  // EVENT ACTIONS
  EVENT_ACTION_ADD_LANGUAGES: 'Add Languages',
  EVENT_ACTION_ADD_PAYMENT_METHOD: 'Add Payment Method',
  EVENT_ACTION_CANCEL: 'Cancel',
  EVENT_ACTION_CHANGE_LANGUAGE: 'Change Language',
  EVENT_ACTION_CHANGE_SCOPE: 'Change Scope',
  EVENT_ACTION_CHANGE_TIMESLICE: 'Change Timeslice',
  EVENT_ACTION_DOWNGRADE_PAYMENT_PLAN: 'Downgrade Payment Plan',
  EVENT_ACTION_DOWNLOAD_WEB_STUIDIO: 'Download GLGO Desktop',
  EVENT_ACTION_ERROR: 'Error',
  EVENT_ACTION_FILTERED_LANGUAGES: 'Filtered Languages',
  EVENT_ACTION_FORM_SUBMISSION: 'Form Submitted',
  EVENT_ACTION_LOGOUT: 'Logout',
  EVENT_ACTION_OPEN_ADD_PAYMENT_METHOD_MODAL: 'Open Add Payment Method Modal',
  EVENT_ACTION_PREVIEW_CLICK: 'Preview Click',
  EVENT_ACTION_SELECTED_NEW_DATE: 'Select New Date',
  EVENT_ACTION_SHOW_CHANGE_SCOPE_MODAL: 'Show Change Scope Modal',
  EVENT_ACTION_UPDATE_WIDGET: 'Update Widget',
  EVENT_ACTION_UPGRADE_PAYMENT_PLAN: 'Upgrade Payment Plan',
  EVENT_ACTION_SIGNUP_PAGE: 'Create Account',
  EVENT_ACTION_CREATE_PROJECT_BUTTON: 'Create Project Button',
  EVENT_ACTION_COPY_SNIPPET: 'Copy Snippet',
  EVENT_ACTION_SKIP: 'Skip',
  EVENT_ACTION_BACK: 'Go Back',
  EVENT_ACTION_LOGIN: 'Login',

  // EVENT CATEGORIES - pages where events happened
  EVENT_CATEGORY_ACCOUNT: 'Account',
  EVENT_CATEGORY_BILLING: 'Billing',
  EVENT_CATEGORY_HOME_DASHBOARD: 'Home Dashboard',
  EVENT_CATEGORY_GLOSSARY: 'Glossary',
  EVENT_CATEGORY_LANGUAGES: 'Languages',
  EVENT_CATEGORY_MANAGE_LANGUAGES: 'Manage Languages',
  EVENT_CATEGORY_PROJECT_SCOPE: 'Scope',
  // Selector Widget
  EVENT_CATEGORY_SELECTOR_WIDGET: 'Customize',
  EVENT_CATEGORY_SNIPPET: 'Snippet',
  EVENT_CATEGORY_TRANSLATED_TRAFFIC: 'Translated Traffic',
  // Words Served Usage Details
  EVENT_CATEGORY_USAGE: 'Usage',
  EVENT_CATEGORY_WORLD_TRAFFIC: 'World Traffic',

  // Event Labels - specifics if needed such as if a modal/form on a page was used.
  EVENT_LABEL_ADD_LANGUAGES_FORM: 'Add Language Form',
  EVENT_LABEL_ADD_PAYMENT_METHOD_FORM: 'Add Payment Method Form',
  EVENT_LABEL_CHANGE_SCOPE_FORM: 'Change Scope Form',
  EVENT_LABEL_GLOSSARY_RULE_FORM: 'Glossary Rule Form',
  EVENT_LABEL_MANAGE_SUBSCRIPTION_FORM: 'Manage Subscription Form',
  EVENT_LABEL_CREATE_ACCOUNT_FORM: 'Create Account Form',
  EVENT_LABEL_CREATE_PROJECT_FORM: 'Create Project',
  EVENT_LABEL_COPY_SNIPPET_FORM: 'Copy Snippet Form',
};

const GLA = {
  analyticsEvent,
  sendTagEvent,
  ...constants,
};

export default GLA;
