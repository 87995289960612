import { SVGAttributes } from 'react';

type IconProps = {
  svg: { path: string; attr?: SVGAttributes<SVGElement> };
  onClick?: () => void;
};

type SvgProps = {
  size?: number;
  color?: string;
  onClick?: () => void;
};

const Icon = ({ svg, onClick }: IconProps) => {
  const { path, attr } = svg;
  return (
    <svg
      width={attr?.width || '20'}
      height={attr?.height || '20'}
      viewBox={attr?.viewBox || '0 0 20 20'}
      fill={attr?.fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        onClick && onClick();
      }}
    >
      <g>
        <path
          d={path}
          stroke={attr?.stroke || 'currentColor'}
          strokeWidth={attr?.strokeWidth || 1}
          strokeLinecap={attr?.strokeLinecap || 'round'}
          strokeLinejoin={attr?.strokeLinejoin || 'round'}
        />
      </g>
    </svg>
  );
};

export const GoBack = () => {
  const svg = {
    path: 'M2 10 7 5M7 15 2 10M18 10 4 10',
    attr: {
      strokeWidth: 2,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};
export const GoForward = () => {
  const svg = {
    path: 'M18 10 13 15M13 5 18 10M2 10 16 10',
    attr: {
      strokeWidth: 2,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const UndoIcon = () => {
  const svg = {
    path: 'M10 18C5.71 18 3 15.23 3 11H4.02C4 14.27 6.57 17 10 17 13.36 17 16 14.33 16 11 16 7.48 13.21 5 10 5L10 8 5 4.5 10 1 10 4C14.47 4 17 6.82 17 11 17 15.23 14.25 18 10 18Z',
    attr: {
      fill: 'currentColor',
      strokeLinejoin: 'miter',
      strokeWidth: 'none',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const Delete = () => {
  const svg = {
    path: 'M11.5 8V13.2M8.5 8V13.2M5.5 5V13.8C5.5 14.6 5.5 15.1 5.7 15.4 5.8 15.7 6 16 7 16H13C14 16 14.2 15.7 14.3 15.4 14.5 15.1 14.5 14.6 14.5 13.8V5M5.5 5H7M5.5 5H4M14.5 5H13M14.5 5H16M7 5H13M7 5C7 4.3 7 3.9 7.1 3.6 7.3 3.3 7.6 3 7.9 2.8 8.2 2.7 8.6 2.7 9.3 2.7H10.8C11.4 2.7 11.8 2.7 12.1 2.8 12.4 3 12.7 3.3 12.9 3.6 13 3.9 13 4.3 13 5',
    attr: {
      strokeWidth: 1.5,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const CloseIcon = ({
  size,
  onClick,
}: {
  size?: number;
  onClick?: () => void;
}) => {
  const svg = {
    path: 'M15 15 5 5 M15 5 5 15',
    attr: {
      width: size || 20,
      height: size || 20,
      fill: 'currentColor',
      strokeLinejoin: 'round',
      strokeWidth: 2,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} onClick={onClick} />;
};

export const PlusIcon = () => {
  const svg = {
    path: 'M10 4 10 16 M4 10 16 10',
    attr: {
      fill: 'currentColor',
      strokeLinejoin: 'round',
      strokeWidth: 2,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const ArrowDown = (props: SvgProps) => {
  const svg = {
    path: 'M6 9 10 13 14 9',
    attr: {
      strokeLinejoin: 'round',
      strokeWidth: 2,
      width: props.size || 20,
      height: props.size || 20,
      stroke: props.color || 'currentColor',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const HistoryIcon = ({ size }: { size?: number }) => {
  const svg = {
    path: 'M10 6V10L12 12M2.7 9.18C2.92 7.31 3.69 5.66 5.01 4.45 6.44 3.13 8.2 2.47 10 2.47 11.83 2.47 13.59 3.13 14.91 4.23 16.34 5.44 17.22 7.2 17.44 8.96 17.66 10.72 17.22 12.59 16.23 14.13 15.13 15.56 13.59 16.66 11.83 17.1 10 17.54 8.2 17.32 6.55 16.44 5.01 15.56 4.1 13.92 3.14 12.48M2.72 16.09 3.12 12.46V12.48L6.74 12.9',
    attr: {
      strokeWidth: 1.8,
      width: size || 20,
      height: size || 20,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const SearchIcon = ({
  size,
  color,
}: {
  size?: number;
  color?: string;
}) => {
  const svg = {
    path: 'M13.5 12H12.7L12.5 11.8C13.4 10.7 13.9 9.3 13.9 8 13.9 6.8 13.6 5.7 12.9 4.7 12.3 3.7 11.3 3 10.2 2.5 9.1 2.1 7.9 2 6.7 2.2 5.6 2.4 4.5 3 3.7 3.8 2.8 4.6 2.2 5.7 2 6.8 1.8 7.9 1.9 9.1 2.3 10.2 2.8 11.3 3.6 12.2 4.6 12.8 5.6 13.5 6.7 13.8 7.9 13.8 9.4 13.8 10.8 13.3 11.8 12.4L12.1 12.6V13.4L16.7 17.8 18.1 16.5 13.5 12ZM7.9 12C5.6 12 3.7 10.2 3.7 8 3.7 5.7 5.6 3.9 7.9 3.9 10.2 3.9 12.1 5.7 12.1 8 12.1 10.2 10.2 12 7.9 12Z',
    attr: {
      width: size || 20,
      height: size || 20,
      fill: color || 'currentColor',
      strokeLinejoin: 'round',
      strokeWidth: 'none',
      stroke: 'none',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const InfoIcon = ({
  size,
  color,
}: {
  size?: number;
  color?: string;
}) => {
  const svg = {
    path: 'M10 1.5C8.3 1.5 6.7 2 5.3 2.9 3.9 3.9 2.8 5.2 2.1 6.7 1.5 8.3 1.3 10 1.7 11.7 2 13.3 2.8 14.8 4 16 5.2 17.2 6.7 18 8.3 18.3 10 18.7 11.7 18.5 13.3 17.9 14.8 17.2 16.1 16.1 17.1 14.7 18 13.3 18.5 11.7 18.5 10 18.5 7.7 17.6 5.6 16 4 14.4 2.4 12.3 1.5 10 1.5ZM10 17.1C8.6 17.1 7.2 16.7 6.1 15.9 4.9 15.1 4 14 3.5 12.7 2.9 11.4 2.8 10 3.1 8.6 3.3 7.2 4 6 5 5 6 4 7.2 3.3 8.6 3.1 10 2.8 11.4 2.9 12.7 3.5 14 4 15.1 4.9 15.9 6.1 16.7 7.2 17.1 8.6 17.1 10 17.1 10.9 16.9 11.9 16.5 12.7 16.2 13.6 15.7 14.4 15 15 14.4 15.7 13.6 16.2 12.7 16.5 11.9 16.9 10.9 17.1 10 17.1ZM10 11.5C9.8 11.5 9.5 11.4 9.3 11.2 9.2 11 9.1 10.8 9.1 10.5V6.3C9.1 6.1 9.2 5.8 9.3 5.6 9.5 5.5 9.8 5.4 10 5.4 10.2 5.4 10.5 5.5 10.7 5.6 10.8 5.8 10.9 6.1 10.9 6.3V10.5C10.9 10.8 10.8 11 10.7 11.2 10.5 11.4 10.2 11.5 10 11.5ZM10 14.6C10.6 14.6 11 14.1 11 13.6 11 13 10.6 12.5 10 12.5 9.4 12.5 8.9 13 8.9 13.6 8.9 14.1 9.4 14.6 10 14.6Z',
    attr: {
      width: size || 20,
      height: size || 20,
      fill: color || 'currentColor',
      strokeLinejoin: 'round',
      strokeWidth: 'none',
      stroke: 'none',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const ViewOnly = ({
  size,
  color,
}: {
  size?: number;
  color?: string;
}) => {
  const svg = {
    path: 'M4.9 5.9 14.1 15.1 4.9 5.9ZM4.9 5.9C4.72 5.4 5.03 5.11 5.51 5.33L14.7 14.49C14.86 15.07 14.64 15.32 14.1 15.1M12 10.3 11.2 9.6 13.7 7.1 12.9 6.3 10.4 8.8 9.7 8 12.9 4.8 15.2 7.1 12 10.3ZM15.8 6.5 13.5 4.2 13.9 3.8C14 3.7 14.1 3.6 14.2 3.6 14.4 3.5 14.5 3.5 14.7 3.5 14.8 3.5 14.9 3.5 15.1 3.6 15.2 3.6 15.3 3.7 15.4 3.8L16.2 4.6C16.4 4.8 16.5 5.1 16.5 5.4 16.5 5.6 16.4 5.9 16.2 6.1L15.8 6.5 15.8 6.5ZM3.8 16.5C3.8 16.5 3.7 16.5 3.7 16.5 3.7 16.5 3.6 16.4 3.6 16.4 3.6 16.4 3.5 16.3 3.5 16.3 3.5 16.2 3.5 16.2 3.5 16.1L4.7 13.4 5 12.7 8.3 9.4 9.1 10.2 5.9 13.3 5.7 13.9 5.3 14.7 6.1 14.3 6.7 14.1 9.8 10.9 10.6 11.7 7.3 15 6.6 15.3 3.9 16.5C3.9 16.5 3.8 16.5 3.8 16.5Z',
    attr: {
      width: size || 20,
      height: size || 20,
      fill: color || 'currentColor',
      stroke: 'none',
      // strokeWidth: 0.5,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const GLGOIcon = ({ size }: { size?: number }) => {
  return (
    <svg
      width={size || '20'}
      height={size || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 11.6922 0.8656 L 10.9155 1.7284 L 9.3056 1.7594 C 6.9444 1.8071 5.5385 2.3692 3.8945 3.9206 C 1.0884 6.5681 0.2947 9.9379 1.6355 13.524 C 1.9869 14.4684 3.2738 16.5819 3.4949 16.5819 C 3.5487 16.5819 4.0108 15.8792 4.5181 15.022 L 5.4422 13.4622 L 5.102 12.456 C 4.3707 10.2975 4.9291 7.9536 6.5107 6.5428 C 7.5312 5.6323 8.6565 5.3006 10.513 5.3624 L 11.9133 5.4074 L 14.093 2.7037 L 16.2728 0 H 12.4688 L 11.6922 0.8628 V 0.8656 Z "
        fill="#0C245D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.6895 18.88 L 9.4384 18.0711 L 10.9909 18.0421 C 13.2677 17.9973 14.6234 17.4703 16.2087 16.016 C 18.9146 13.5339 19.6799 10.3748 18.3871 7.0127 C 18.0481 6.1275 16.8072 4.1461 16.5941 4.1461 C 16.5421 4.1461 16.0967 4.8047 15.6074 5.6084 L 14.7164 7.0707 L 15.0443 8.014 C 15.7495 10.0375 15.2111 12.235 13.6859 13.5576 C 12.702 14.4113 11.6168 14.7222 9.8265 14.6642 L 8.4763 14.6221 L 6.3745 17.1568 L 4.2726 19.6915 H 7.9406 L 8.6895 18.8826 V 18.88Z"
        fill="#1890FF"
      />
    </svg>
  );
};

export const OpenLink = ({ color, onClick }: SvgProps) => {
  const svg = {
    path: 'M8.3 3H5.6C5 3 4 3.1 3.6 3.6 3 4.1 3 5 3 5.6V14.4C3 15.3 3.2 16.1 3.6 16.5 4 17 4.9 17 5.6 17H14.4C15.3 17 16.1 16.9 16.5 16.5 16.88 16.14 17 15.3 17 14.6V12M18 7.4V2M18 2H12.7m5.3 0L11 9',
    attr: {
      width: 20,
      height: 20,
      viewBox: '0 0 20 20',
      strokeWidth: 1.8,
      stroke: color || 'CurrentColor',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} onClick={onClick} />;
};

export const CloseLink = ({ color, onClick }: SvgProps) => {
  const svg = {
    path: 'M9 3H5.6C5 3 4 3.1 3.6 3.6 3 4.1 3 5 3 5.6V14.4C3 15.3 3.2 16.1 3.6 16.5 4 17 4.9 17 5.6 17H14.4C15.3 17 16.1 16.9 16.5 16.5 16.88 16.14 17 15.3 17 14.6V11.01M15.99 4.02 8.04 11.9H14M8 11.92V6',
    attr: {
      width: 20,
      height: 20,
      viewBox: '0 0 20 20',
      strokeWidth: 1.8,
      stroke: color || 'CurrentColor',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} onClick={onClick} />;
};
export const MenuIcon = ({ color, onClick }: SvgProps) => {
  const svg = {
    path: 'M 4 4 H 16ZM 4 10 H 16ZM 4 16 H 16Z',
    attr: {
      width: 20,
      height: 20,
      viewBox: '0 0 20 20',
      strokeWidth: 1.8,
      stroke: color || 'CurrentColor',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} onClick={onClick} />;
};

export const AlertIcon = ({
  size,
  color,
}: {
  size?: number;
  color?: string;
}) => {
  const svg = {
    path: 'M10 1.5C8.3 1.5 6.7 2 5.3 2.9 3.9 3.9 2.8 5.2 2.1 6.7 1.5 8.3 1.3 10 1.7 11.7 2 13.3 2.8 14.8 4 16 5.2 17.2 6.7 18 8.3 18.3 10 18.7 11.7 18.5 13.3 17.9 14.8 17.2 16.1 16.1 17.1 14.7 18 13.3 18.5 11.7 18.5 10 18.5 7.7 17.6 5.6 16 4 14.4 2.4 12.3 1.5 10 1.5ZM10 17.1C8.6 17.1 7.2 16.7 6.1 15.9 4.9 15.1 4 14 3.5 12.7 2.9 11.4 2.8 10 3.1 8.6 3.3 7.2 4 6 5 5 6 4 7.2 3.3 8.6 3.1 10 2.8 11.4 2.9 12.7 3.5 14 4 15.1 4.9 15.9 6.1 16.7 7.2 17.1 8.6 17.1 10 17.1 10.9 16.9 11.9 16.5 12.7 16.2 13.6 15.7 14.4 15 15 14.4 15.7 13.6 16.2 12.7 16.5 11.9 16.9 10.9 17.1 10 17.1ZM10 11.5C9.8 11.5 9.5 11.4 9.3 11.2 9.2 11 9.1 10.8 9.1 10.5V6.3C9.1 6.1 9.2 5.8 9.3 5.6 9.5 5.5 9.8 5.4 10 5.4 10.2 5.4 10.5 5.5 10.7 5.6 10.8 5.8 10.9 6.1 10.9 6.3V10.5C10.9 10.8 10.8 11 10.7 11.2 10.5 11.4 10.2 11.5 10 11.5ZM10 14.6C10.6 14.6 11 14.1 11 13.6 11 13 10.6 12.5 10 12.5 9.4 12.5 8.9 13 8.9 13.6 8.9 14.1 9.4 14.6 10 14.6Z',
    attr: {
      width: size || 20,
      height: size || 20,
      fill: color || 'currentColor',
      stroke: 'none',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};
export const WarningIcon = ({
  size,
  color,
}: {
  size?: number;
  color?: string;
}) => {
  const svg = {
    path: 'M 4 18 L 15.9 18 C 17.3 18 18 16.5 17.6 15.6 L 11.9 3.4 C 11.1 1.8 8.9 1.8 8.1 3.5 L 2.5 15.5 C 2 16.6 2.7 18 4 18 Z M 9.1 4 C 9.5 3.1 10.6 3.1 11 4 L 16.4 15.5 C 16.7 16.4 16.3 17 15.8 17 L 4.3 17 C 3.7 17 3.4 16.3 3.7 15.5 L 9 4.1 Z M 11 14.5 C 11 16 9 16 9 14.5 V 14.5 C 9.1 13.2 11 13.2 11 14.5 Z M 10.7 7.5 C 10.7 6.6 9.3 6.6 9.4 7.5 L 9.3 11.4 C 9.3 12.6 10.6 12.6 10.7 11.6 L 10.7 7.5 Z',
    attr: {
      width: size || 20,
      height: size || 20,
      fill: color || 'currentColor',
      stroke: 'none',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};
export const CssIcon = ({ size, color }: { size?: number; color?: string }) => {
  const svg = {
    path: 'M18 1H10 9L9 1 2.4 6.6 2 7.1V7.7 19.9C2 22 3 23 5 23H19C21 23 22 22 22 20V4.1C22 2 20.5 1 19 1ZM20.5 20C20.5 21 20 21.5 19 21.5H4.9C4 21.5 3.6 21 3.5 20V7.7H7.5C8.7 7.7 9.7 6.8 9.7 5.6V2.5H19C20 2.5 20.5 3 20.5 4V19ZM7.9 12.9C8.1 12.9 8.3 13 8.5 13.3 8.6 13.4 8.7 13.5 8.9 13.5 9.1 13.5 9.3 13.3 9.3 13.1 9.3 13 9.3 12.9 9.2 12.8 9 12.4 8.5 12.1 7.9 12.1 7.1 12.1 6.5 12.4 6.3 13.2 6.2 13.5 6.1 13.7 6.1 14.4 6.1 15.1 6.2 15.3 6.3 15.6 6.5 16.3 7.1 16.7 7.9 16.7 8.5 16.7 9 16.4 9.2 16 9.3 15.9 9.3 15.8 9.3 15.7 9.3 15.4 9.1 15.2 8.9 15.2 8.7 15.2 8.6 15.3 8.5 15.5 8.3 15.7 8.1 15.8 7.9 15.8 7.5 15.8 7.3 15.6 7.2 15.3 7.1 15.2 7.1 15 7.1 14.4 7.1 13.7 7.1 13.6 7.2 13.4 7.3 13.1 7.5 12.9 7.9 12.9ZM12 13.9 11.7 13.9C11.2 13.8 11 13.6 11 13.4 11 13.1 11.2 12.9 11.6 12.9 11.9 12.9 12.2 13 12.4 13.1 12.5 13.2 12.6 13.2 12.7 13.2 12.9 13.2 13.1 13 13.1 12.8 13.1 12.6 13 12.5 12.9 12.4 12.6 12.2 12.1 12.1 11.6 12.1 10.7 12.1 10.1 12.6 10.1 13.4 10.1 14.2 10.6 14.6 11.5 14.8L11.7 14.8C12.2 14.9 12.4 15 12.4 15.3 12.4 15.6 12.1 15.8 11.7 15.8 11.3 15.8 11.1 15.7 10.7 15.5 10.6 15.5 10.5 15.5 10.4 15.5 10.2 15.5 10 15.6 10 15.9 10 16 10.1 16.2 10.2 16.3 10.5 16.5 11 16.7 11.6 16.7 12.8 16.7 13.4 16.1 13.4 15.3 13.4 14.5 12.9 14.1 12 13.9ZM16 13.9 15.7 13.9C15.2 13.8 15 13.6 15 13.4 15 13.1 15.3 12.9 15.6 12.9 15.9 12.9 16.2 13 16.4 13.1 16.5 13.2 16.6 13.2 16.7 13.2 16.9 13.2 17.1 13 17.1 12.8 17.1 12.6 17 12.5 16.9 12.4 16.6 12.2 16.1 12.1 15.6 12.1 14.7 12.1 14.1 12.6 14.1 13.4 14.1 14.2 14.6 14.6 15.5 14.8L15.7 14.8C16.2 14.9 16.4 15 16.4 15.3 16.4 15.6 16.1 15.8 15.7 15.8 15.3 15.8 15.1 15.7 14.7 15.5 14.6 15.5 14.5 15.5 14.4 15.5 14.2 15.5 14 15.6 14 15.9 14 16 14.1 16.2 14.2 16.3 14.5 16.5 15 16.7 15.6 16.7 16.8 16.7 17.4 16.1 17.4 15.3 17.4 14.5 16.9 14.1 16 13.9Z',
    attr: {
      viewBox: '0 0 24 24',
      width: size || 24,
      height: size || 24,
      fill: color || 'currentColor',
      stroke: 'none',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};
export const CompareIcon = ({
  size,
  color,
}: {
  size?: number;
  color?: string;
}) => {
  const svg = {
    path: 'M9 19H3C2 19 1 18 1 17V3C1 2 2 1 3 1H9M11 4V3M11 6V7M11 10V11M11 13V14M11 16V17M13 1H19C20 1 21 2 21 3V17C21 18 20 19 19 19H13M3 4H8M3 7H9M3 10H9 9 9M3 16H9M13 4H18M13 10H19M13 13H19 19M13 16H19',
    attr: {
      viewBox: '0 0 22 20',
      width: size || 22,
      height: size || 22,
      stroke: color || 'currentColor',
      strokeWidth: 1.2,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};
