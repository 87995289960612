import axios from 'axios';
import getAxiosUrlAndConfig from './getAxiosUrlAndConfig';

/**
 *
 * @param baseUrl
 * @param endpoint
 * @param data
 * @param params
 * @param token
 * @return Promise<AxiosResponse<any>>
 */
export const PosterPromise = (
  baseUrl: string,
  endpoint: string,
  data: object = {},
  params: object = {},
  token?: string
) => {
  const { url, config } = getAxiosUrlAndConfig(
    baseUrl,
    endpoint,
    params,
    data,
    token
  );

  return axios.post(url, data, config);
};

export default PosterPromise;
