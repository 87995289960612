import {
  ActionIcon,
  Button,
  Checkbox,
  MantineColorsTuple,
  MantineThemeOverride,
  Modal,
  Popover,
  Radio,
  ScrollArea,
  Tooltip,
  createTheme,
  // localStorageColorSchemeManager,
} from '@mantine/core';
import actionIconClasses from './modules/ActionIcon.module.css';
import modalClasses from './modules/Modal.module.css';
import radioClasses from './modules/Radio.module.css';
import checkboxClasses from './modules/Checkbox.module.css';
import popoverClasses from './modules/Popover.module.css';
import tooltipClasses from './modules/Tooltip.module.css';
import scrollAreaClasses from './modules/ScrollArea.module.css';

import buttonClasses from '../shared/modules/Button.module.css';
import { ctaColors } from '../shared/colors';

// export const colorSchemeManager = localStorageColorSchemeManager({
//   key: 'segment-editor-scheme',
// });

export const segmentEditorTheme: MantineThemeOverride = createTheme({
  // colorScheme: 'light',
  colors: {
    cta1: ctaColors as MantineColorsTuple,
    blue: [
      '#F5FAFF', // 0
      '#E9F1FD', // 1
      '#D0DBF4', // 2
      '#CBDDF9', // 3
      '#A6D4FF', // 4
      '#90A0D3', // 5
      '#5672BC', // 6
      '#0071DB', // 7
      '#2C49AC', // 8
      '#071D49', // 9
    ],
    // blue1: [
    //   '#F5FAFF', 0 -> 0
    //   '#F6F8FF', 1 -> 0
    //   '#E9F1FD', 2 -> 1
    //   '#EAF0FF', 3 -> 1
    //   '#D0DBF4', 4 -> 2
    //   '#CBDDF9', 5 -> 3
    //   '#CBD1F9', 6 -> 3
    //   '#A6D4FF', 7 -> 4
    //   '#90A0D3', 8 -> 5
    //   '#5C73BC', 9 -> 6
    // ],
    // blue2: ['#5672A8', '#2C49AC', '#0071DB', '#00328D', '#071D49'],
    // 0 -> 6
    // 1 -> 8
    // 2 -> 7
    // 3 -> 7
    // 4 -> 9
    gray: [
      '#FAFBFF',
      '#EFF4FB',
      '#E4E7FA',
      '#E6E6E8',
      '#D6D9EC',
      '#B9C8E3',
      '#A0A7D6',
      '#8F9DBA',
      '#637392',
      '#252540',
    ],
  },
  shadows: {
    md: '0px 4px 20px 0px rgba(0, 0, 0, 0.25)',
  },
  components: {
    Button: Button.extend({
      classNames: buttonClasses,
      defaultProps: {
        variant: 'default',
      },
    }),
    ActionIcon: ActionIcon.extend({
      classNames: actionIconClasses,
      defaultProps: {
        loaderProps: { color: 'blue.7' },
      },
    }),
    Modal: Modal.extend({
      classNames: modalClasses,
    }),
    Radio: Radio.extend({
      classNames: radioClasses,
    }),
    Checkbox: Checkbox.extend({
      classNames: checkboxClasses,
      defaultProps: {
        size: 'xs',
      },
    }),
    Popover: Popover.extend({
      classNames: popoverClasses,
      defaultProps: {
        withArrow: true,
        arrowSize: 12,
        offset: 3,
      },
    }),
    Tooltip: Tooltip.extend({
      classNames: tooltipClasses,
      defaultProps: {
        withArrow: true,
        arrowSize: 10,
      },
    }),
    ScrollArea: ScrollArea.extend({
      classNames: scrollAreaClasses,
    }),
  },
});

export default segmentEditorTheme;
