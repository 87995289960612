import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Menu,
  ScrollArea,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useUserProvider, saveDesktopProjectKey } from 'store';
import { MdArrowDropDown, MdSearch } from 'react-icons/md';
import { CircleAvatar } from './components/glgo/Generic';
import { useGLGOParams, useViewSize } from 'hooks';
import { saveSelectedProjectKey } from 'helpers';

export const ProjectSelector = () => {
  const { projectKey } = useGLGOParams();
  const navigate = useNavigate();
  const { colors } = useMantineTheme();
  const { isMobileSize } = useViewSize();

  // const projects = useUserProjects();
  const { userProjects } = useUserProvider();
  userProjects.sort((p1, p2) => p1.project_name.localeCompare(p2.project_name));
  const activeProject = userProjects?.find((p) => p.project_key === projectKey);
  // TODD: Handle the case where activeProject is undefined
  const [searchText, setSearchText] = useState('');
  const searchInputRef = useRef<HTMLInputElement>(null);

  const filteredProjects = userProjects?.filter((p) => {
    const name = p.project_name.toLowerCase();
    const origin = p.origin_name.toLowerCase();
    const search = searchText.toLowerCase();
    const isRevelantProject = !['PROXY', 'GLNOW'].includes(p.project_type);
    // Filter out the active project, proxy & glnow projects, and any project that doesn't match the search
    return (
      [name, origin].some((s) => s.includes(search)) &&
      p.project_key !== projectKey &&
      isRevelantProject
    );
  });

  const handleProjectSelect = (projectKey: string) => {
    saveSelectedProjectKey(projectKey);
    saveDesktopProjectKey(projectKey);
    setSearchText('');
    navigate('.');
  };

  if (activeProject && userProjects.length > 1) {
    const projectName = activeProject.project_name;
    return (
      <Menu
        offset={{ mainAxis: 5 }}
        width={285}
        position="bottom-start"
        data-testid="pw-project-selector"
        onOpen={() => setTimeout(() => searchInputRef.current?.focus(), 10)}
      >
        <Menu.Target>
          <Tooltip
            label={projectName}
            disabled={!isMobileSize && projectName.length <= 20}
            withArrow={false}
          >
            <Button
              p={0}
              maw={400}
              variant="transparent"
              style={{ WebkitAppRegion: 'no-drag' }}
              leftSection={
                <CircleAvatar name={projectName} backgroundColor="#6ED6CC" />
              }
              rightSection={
                isMobileSize ? undefined : (
                  <MdArrowDropDown color={colors.text[0]} size={24} />
                )
              }
            >
              <Text
                display={{ base: 'none', md: 'block' }}
                maw={400}
                fw={700}
                c="text.0"
                truncate
              >
                {projectName}
              </Text>
            </Button>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown>
          <TextInput
            mb={10}
            ref={searchInputRef}
            value={searchText}
            placeholder="Search..."
            rightSection={<MdSearch size={20} />}
            onChange={(e) => {
              // setPrevSearchText(searchText);
              setSearchText(e?.target?.value || '');
            }}
            styles={{
              input: {
                borderRadius: 30,
              },
            }}
          />
          <ScrollArea
            offsetScrollbars
            type="always"
            scrollbars="y"
            scrollbarSize={25}
          >
            <Box mah={145}>
              {filteredProjects.map((project) => {
                const { project_key = '', project_name = '' } = project;
                return (
                  <Menu.Item
                    px={10}
                    py={5}
                    key={project_key}
                    onClick={() => handleProjectSelect(project_key)}
                  >
                    <Text c="inherit" fz="inherit" fw="inherit" truncate>
                      {project_name}
                    </Text>
                  </Menu.Item>
                );
              })}
            </Box>
          </ScrollArea>
        </Menu.Dropdown>
      </Menu>
    );
  }

  if (activeProject && userProjects.length === 1) {
    const projectName = activeProject.project_name;
    return (
      <Tooltip
        label={projectName}
        disabled={!isMobileSize && projectName.length <= 20}
        withArrow={false}
      >
        <Flex align="center" gap={10}>
          <CircleAvatar name={projectName} backgroundColor="#6ED6CC" />
          <Text
            display={{ base: 'none', md: 'block' }}
            maw={200}
            fw={700}
            c="text.0"
            truncate
          >
            {projectName}
          </Text>
        </Flex>
      </Tooltip>
    );
  }

  return <div data-testid="empty" />;
};

export default ProjectSelector;
