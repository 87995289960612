export const EverythingIcon = ({
  width,
  height,
}: {
  width: string;
  height: string;
}) => (
  <svg
    width={width || '21'}
    height={height || '22'}
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 3.47468C0 2.7852 0.276562 2.12396 0.768845 1.63643C1.26113 1.14889 1.92881 0.875 2.625 0.875L13.125 0.875C13.8212 0.875 14.4889 1.14889 14.9812 1.63643C15.4734 2.12396 15.75 2.7852 15.75 3.47468V6.07436H18.375C19.0712 6.07436 19.7389 6.34826 20.2312 6.83579C20.7234 7.32333 21 7.98456 21 8.67404V19.0728C21 19.7622 20.7234 20.4235 20.2312 20.911C19.7389 21.3986 19.0712 21.6724 18.375 21.6724H7.875C7.17881 21.6724 6.51113 21.3986 6.01884 20.911C5.52656 20.4235 5.25 19.7622 5.25 19.0728V16.4731H2.625C1.92881 16.4731 1.26113 16.1992 0.768845 15.7117C0.276562 15.2241 0 14.5629 0 13.8734V3.47468Z"
      fill="#0071DB"
    />
  </svg>
);
