import { useCurrentProjectData, useUserProvider } from 'store';
import { Navigate, Outlet } from 'react-router-dom';
import {
  hasPageViewPermissions,
  UserRole,
} from 'helpers/utils/projectPermissions';

const RestrictedRoute = ({
  allowedRoles,
  allowedProjectTypes,
  children,
}: {
  allowedRoles?: Set<UserRole>;
  allowedProjectTypes?: Set<string>;
  children?: JSX.Element;
}) => {
  const {
    xapisUser: { user_key: userKey },
  } = useUserProvider();

  const {
    projectUserPermissionMap,
    project: { project_type: projectType },
  } = useCurrentProjectData();
  const permissions =
    projectUserPermissionMap[userKey]?.rolePermissionSet ?? new Set();

  if (allowedRoles && !hasPageViewPermissions(permissions, allowedRoles)) {
    return <Navigate to="/" replace />;
  }

  if (allowedProjectTypes && !allowedProjectTypes.has(projectType)) {
    return <Navigate to="/" replace />;
  }

  return children || <Outlet />;
};

export default RestrictedRoute;
