import React from 'react';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip as ReTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomChartTooltip from '../../recharts/CustomChartTooltip';

export const WordsServedChart = ({
  accumulatedWordsServed,
}: {
  accumulatedWordsServed: { timeslice: string; count: number }[];
}) => (
  <ResponsiveContainer width="100%" height={140}>
    <AreaChart data={accumulatedWordsServed || []}>
      <defs>
        <linearGradient id="wordsServed" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#0071DB" stopOpacity={0.5} />
          <stop offset="95%" stopColor="#0071DB" stopOpacity={0} />
        </linearGradient>
      </defs>
      <XAxis hide dataKey="timeslice" />
      <YAxis hide dataKey="count" />
      <Area
        dataKey="count"
        legendType="circle"
        fill="url(#wordsServed)"
        stroke="#0071DB"
        strokeWidth={4}
        activeDot={{ r: 5, fill: '#0071DB', strokeWidth: 0 }}
      />
      <ReTooltip content={<CustomChartTooltip />} cursor={false} />
    </AreaChart>
  </ResponsiveContainer>
);
