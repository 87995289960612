import { jliffsAreEqual } from '../components/Jliff/jliffFunctions';

export class UndoRedo {
  past: Jliff[][];
  future: Jliff[][];
  present: Jliff[];

  constructor(jliffs: Jliff[]) {
    if (!jliffs || jliffs.length === 0)
      throw new Error('UndoRedo: invalid JLIFF array: ' + jliffs);
    this.past = [];
    this.future = [];
    this.present = jliffs;
  }

  get status() {
    return {
      canUndo: this.past.length > 0,
      canRedo: this.future.length > 0,
    };
  }

  get presentJliff() {
    return this.present;
  }
  get lastSaved() {
    return this.past.length ? this.past[0] : this.present;
  }
  get isUnsaved() {
    return !jliffsAreEqual(this.present, this.lastSaved);
  }

  set presentJliff(jliff: Jliff[]) {
    this.present = jliff;
  }

  reset(jliffs: Jliff[]) {
    if (!jliffs || jliffs.length === 0)
      throw new Error('UndoRedo: invalid JLIFF array: ' + jliffs);
    this.past = [];
    this.future = [];
    this.present = jliffs;
  }

  add(jliffs: Jliff[]) {
    if (this.present && jliffsAreEqual(this.present, jliffs)) {
      console.log('UndoRedo.add: jliffs are equal, not adding to stack');
      return;
    }
    if (this.present) {
      this.past.push(this.present);
    }
    this.present = jliffs;
    this.future = [];
  }

  undo() {
    if (this.past.length > 0) {
      const jliffs = this.past.pop();
      if (jliffs) {
        this.present && this.future.push(this.present);
        this.present = jliffs;
        return jliffs;
      }
    }
    return false;
  }

  redo() {
    if (this.future.length > 0) {
      const jliffs = this.future.pop();
      if (jliffs) {
        this.present && this.past.push(this.present);
        this.present = jliffs;
        return jliffs;
      }
    }
    return false;
  }
}
