import React from 'react';
import { Box, Card, Divider, Flex, Text } from '@mantine/core';
import { useDeploymentContext } from '../DeploymentContextProvider';

const DefaultCookieMethod = () => {
  const {
    loading,
    activeProject: { deployment_name: deploymentName = '' } = {},
    targets = [],
  } = useDeploymentContext();

  return (
    <Card p={0} style={{ boxShadow: 'none' }} opacity={loading ? 0.5 : 1}>
      <Flex pl="1.5rem">
        <Box w="30%" pt="1.65rem" pr="0.5rem">
          <Text fw={600}>Cookie Name</Text>
        </Box>
        <Flex w="70%" h="5rem" pl="0.5rem" align="center">
          <Text truncate>{deploymentName}</Text>
        </Flex>
      </Flex>
      <Divider size="xs" orientation="horizontal" color="divider.1" />
      <Flex pl="1.5rem" py="0.5rem">
        <Box w="30%" pt="1.65rem" pr="0.5rem">
          <Text fw={600}>Cookie Value</Text>
        </Box>
        <Box w="70%" pl="0.5rem">
          {targets.map(
            ({ target_lang_name = '', deployment_value = '' }, index) => {
              const isLastIndex = index === targets.length - 1;

              return (
                <Box key={target_lang_name}>
                  <Flex h="5rem" pr="1.5rem">
                    <Flex w="60%" align="center" pr="1rem">
                      <Text truncate>{target_lang_name}</Text>
                    </Flex>
                    <Flex w="40%" align="center" pl="1rem">
                      <Text truncate>{deployment_value}</Text>
                    </Flex>
                  </Flex>
                  {!isLastIndex && (
                    <Divider
                      size="xs"
                      orientation="horizontal"
                      color="divider.1"
                    />
                  )}
                </Box>
              );
            }
          )}
        </Box>
      </Flex>
    </Card>
  );
};

export default DefaultCookieMethod;
