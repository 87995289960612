import { Card, Title } from '@mantine/core';
import SelectorCustomization from './SelectorCustomization';
import { SelectorContentCustomization } from './SelectorContentCustomization';

export const SelectorCard = () => {
  return (
    <Card w="min(991px, 100%)" p={{ base: '1.25rem', md: '1.875rem' }} mt={16}>
      <Title order={1}>Customize Your Selector</Title>
      <SelectorCustomization />
      <SelectorContentCustomization />
    </Card>
  );
};

export default SelectorCard;
