const getNoteText = (isCurrentAPreset: boolean, isPresetSelected: boolean) => {
  if (!isCurrentAPreset && isPresetSelected) {
    return '* Saving will override your current CSS.';
  }
  if (!isPresetSelected) {
    return '* Select a POSITION to save.';
  }
  return '';
};

const getCSS = (
  currentCss: string,
  basicCss: string,
  editorCss: string,
  selector: string,
  tab: string
) => {
  if (selector === 'None') {
    return '';
  }
  if (selector === 'Current') {
    return currentCss;
  }
  if (tab === 'Basic') {
    return basicCss;
  }
  return editorCss;
};

export { getNoteText, getCSS };
