import { create } from 'zustand';
import {
  defaultAxiosResponse,
  DeleterPromise,
  FetcherPromise,
  PosterPromise,
  PutterPromise,
} from './xapis-wrappers';
import { AxiosError, AxiosResponse } from 'axios';
import failure from 'helpers/failure';
import useProjectsStore from './ProjectsStore';
import useUserStore from './UserStore';

const NEVER_TRANSLATE = 'never_translate';
const NEVER_TRANSLATE_AS = 'never_translate_as';
const TRANSLATE_AS = 'translate_as';

type RuleType =
  | typeof NEVER_TRANSLATE
  | typeof NEVER_TRANSLATE_AS
  | typeof TRANSLATE_AS;

type GlossaryItem = {
  comment: string;
  createdUser: string;
  createdUtc: string;
  isCaseSensitive: boolean;
  isMt: boolean;
  lastmodUser: string;
  lastmodUtc: string;
  rule: RuleType;
  sourceText: string;
  sourceTextHash: string;
  targetText: string[];
  translationKey: string;
  translationSource: string;
};

/**
 * Example:
 * glossaryTree: {
 *    15DE-DD77-3D85-F90B: {
 *      7EDC-EC3F-6C2E-1489: [...],
 *      EE9B-4658-D770-92BA: [...]
 *    }
 * }
 */
type GlossaryTree = Record<string, Record<string, GlossaryItem[]>>;

type GlossaryItemResponse = {
  comment: string;
  created_user: string;
  created_utc: string;
  is_case_sensitive: boolean;
  is_mt: boolean;
  lastmod_user: string;
  lastmod_utc: string;
  rule: RuleType;
  source_text: string;
  source_text_hash: string;
  target_text: string[] | string; // Should always be string[], string is an edge case (bug from Xapis)
  translation_key: string;
  translation_source: string;
};

type GlossaryGetResponse = {
  glossary_items: GlossaryItemResponse[];
  rows_filtered: number;
  rows_returned: number;
  rows_total: number;
};

export type CreateGlossaryPayload = {
  rule: RuleType;
  sourceText: string;
  translationSource?: string;
  comment?: string;
  isCaseSensitive?: boolean;
  isMt?: boolean;
  targetText?: string[] | string; // Can do both ["foo", "woo"] and "foo|woo"
};

export type EditGlossaryPayload = {
  sourceText: string;
  comment?: string;
  isCaseSensitive?: boolean;
  isMt?: boolean;
  rule?: RuleType;
  targetText?: string[] | string; // Can do both ["foo", "woo"] and "foo|woo"
  translationSource?: string;
};

type GlossaryPostResponse = {
  created: number;
  glossary_items: GlossaryItemResponse[];
  message: string;
  rows_filtered: number;
  rows_returned: number;
  rows_total: number;
  updated: number;
};

type GlossaryPutResponse = {
  glossary_items: GlossaryItemResponse[];
  message: string;
  rows_filtered: number;
  rows_returned: number;
  rows_total: number;
  updated: number;
};

type GlossaryDeleteResponse = {
  deleted: number;
  glossary_items: GlossaryItemResponse[];
  message: string;
  rows_filtered: number;
  rows_returned: number;
  rows_total: number;
};

const parseTargetText = (targetText?: string | string[]): string[] => {
  if (Array.isArray(targetText)) {
    return targetText;
  }
  if (targetText) {
    return [targetText];
  }
  return [];
};

const parseGlossaryItem = (
  glossaryItem: GlossaryItemResponse
): GlossaryItem => {
  return {
    comment: glossaryItem?.comment || '',
    createdUser: glossaryItem?.created_user || '',
    createdUtc: glossaryItem?.created_utc || '',
    isCaseSensitive: glossaryItem?.is_case_sensitive || false,
    isMt: glossaryItem?.is_mt || false,
    lastmodUser: glossaryItem?.lastmod_user || '',
    lastmodUtc: glossaryItem?.lastmod_utc || '',
    rule: glossaryItem?.rule || TRANSLATE_AS, // Legacy data can have no rule, which is technically TRANSLATE_AS
    sourceText: glossaryItem?.source_text || '',
    sourceTextHash: glossaryItem?.source_text_hash || '',
    targetText: parseTargetText(glossaryItem?.target_text),
    translationKey: glossaryItem?.translation_key || '',
    translationSource: glossaryItem?.translation_source || '',
  };
};

const mergeGlossaryTree = (
  glossaryTree: GlossaryTree,
  glossaryItemsResponse: GlossaryItemResponse[]
): GlossaryTree => {
  const { activeProject: { projectKey = '' } = {} } =
    useProjectsStore.getState();

  const newProjectGlossaryBranch = glossaryItemsResponse.reduce(
    (accTree, glossaryItemResponse) => {
      const glossaryItem = parseGlossaryItem(glossaryItemResponse);
      const { translationKey = '' } = glossaryItem;
      const glossaryList = accTree[translationKey] || [];

      return {
        ...accTree,
        [translationKey]: glossaryList.concat(glossaryItem),
      };
    },
    {} as Record<string, GlossaryItem[]>
  );

  return {
    ...glossaryTree,
    [projectKey]: {
      ...glossaryTree[projectKey],
      ...newProjectGlossaryBranch, // The new translation key values should overwrite the old translation key values
    },
  };
};

export type GlossaryStore = {
  loading: boolean;
  glossaryTree: GlossaryTree;
  rowsTotal: number;
  rowsReturned: number;
  resetGlossaryTree: () => void;
  fetchTranslationGlossary: (
    translationKey: string,
    rowLimit?: number,
    page?: number,
    searchText?: string
  ) => Promise<AxiosResponse<GlossaryGetResponse | unknown>>;
  fetchTranslationsGlossary: (
    translationKeys: string[],
    rowLimit?: number,
    page?: number,
    searchText?: string
  ) => Promise<AxiosResponse<GlossaryGetResponse | unknown>>;
  fetchProjectGlossary: (
    rowLimit?: number,
    page?: number,
    searchText?: string
  ) => Promise<AxiosResponse<GlossaryGetResponse | unknown>>;
  createTranslationGlossary: (
    translationKey: string,
    values: CreateGlossaryPayload
  ) => Promise<AxiosResponse<GlossaryPostResponse | unknown>>;
  createTranslationsGlossary: (
    translationKeys: string[],
    values: CreateGlossaryPayload
  ) => Promise<AxiosResponse<GlossaryPostResponse | unknown>>;
  createProjectGlossary: (
    values: CreateGlossaryPayload
  ) => Promise<AxiosResponse<GlossaryPostResponse | unknown>>;
  editTranslationGlossary: (
    translationKey: string,
    values: EditGlossaryPayload
  ) => Promise<AxiosResponse<GlossaryPutResponse | unknown>>;
  editTranslationsGlossary: (
    translationKeys: string[],
    values: EditGlossaryPayload
  ) => Promise<AxiosResponse<GlossaryPutResponse | unknown>>;
  editProjectGlossary: (
    values: EditGlossaryPayload
  ) => Promise<AxiosResponse<GlossaryPutResponse | unknown>>;
  deleteTranslationGlossary: (
    translationKey: string,
    sourceTextHash: string
  ) => Promise<AxiosResponse<GlossaryDeleteResponse | unknown>>;
  deleteTranslationsGlossary: (
    translationKeys: string[],
    sourceTextHashes: string
  ) => Promise<AxiosResponse<GlossaryDeleteResponse | unknown>>;
  deleteProjectGlossary: (
    sourceTextHashes: string
  ) => Promise<AxiosResponse<GlossaryDeleteResponse | unknown>>;
  deleteTranslationGlossaries: (
    translationKey: string,
    sourceTextHashes: string[]
  ) => Promise<AxiosResponse<GlossaryDeleteResponse | unknown>>;
  deleteTranslationsGlossaries: (
    translationKeys: string[],
    sourceTextHashes: string[]
  ) => Promise<AxiosResponse<GlossaryDeleteResponse | unknown>>;
  deleteProjectGlossaries: (
    sourceTextHashes: string[]
  ) => Promise<AxiosResponse<GlossaryDeleteResponse | unknown>>;
};

/**
 * glossaryTree - For efficiency, stored as { [projectKey]: { [translationKeys]: [...] } }
 * Example:
 * glossaryTree: {
 *    15DE-DD77-3D85-F90B: {
 *      7EDC-EC3F-6C2E-1489: [...],
 *      EE9B-4658-D770-92BA: [...]
 *    }
 * }
 */
export const useGlossaryStore = create<GlossaryStore>()((set, get) => ({
  loading: false,
  glossaryTree: {},
  rowsTotal: 0,
  rowsReturned: 0,
  resetGlossaryTree: () => {
    set({ glossaryTree: {} });
  },
  fetchTranslationGlossary: async (
    translationKey,
    rowLimit = 0,
    page = 1,
    searchText = ''
  ) => {
    const { glossaryTree } = get();

    set({ loading: true });
    return FetcherPromise(`Glossary/${translationKey}`, {
      limit: rowLimit,
      page,
      ...(searchText && { q: searchText }),
    })
      .then((response: AxiosResponse) => {
        const { data }: { data: GlossaryGetResponse } = response;
        const {
          glossary_items: glossaryItems = [],
          rows_total: rowsTotal = 0,
          rows_returned: rowsReturned = 0,
        } = data || {};

        const newGlossaryTree = mergeGlossaryTree(glossaryTree, glossaryItems);

        set({
          glossaryTree: newGlossaryTree,
          rowsTotal,
          rowsReturned,
        });

        return response;
      })
      .catch((error: AxiosError) => {
        const { response = defaultAxiosResponse } = error;
        failure(error, 'Unable to get glossary items at this time.');
        return response;
      })
      .finally(() => set({ loading: false }));
  },
  fetchTranslationsGlossary: async (
    translationKeys,
    rowLimit = 0,
    page = 1,
    searchText = ''
  ) => {
    const { fetchTranslationGlossary } = get();
    return fetchTranslationGlossary(
      translationKeys.join('|'),
      rowLimit,
      page,
      searchText
    );
  },
  fetchProjectGlossary: async (rowLimit = 0, page = 1, searchText = '') => {
    const { fetchTranslationGlossary } = get();
    const { activeProject: { projectKey = '' } = {} } =
      useProjectsStore.getState();

    const specialProjectKey = `P${projectKey}`;
    return fetchTranslationGlossary(
      specialProjectKey,
      rowLimit,
      page,
      searchText
    );
  },
  createTranslationGlossary: async (translationKey, values) => {
    const { glossaryTree } = get();
    const { user: { user_key: userKey } = {} } = useUserStore.getState();
    const {
      rule = '',
      sourceText = '',
      targetText = '',
      comment = '',
      isCaseSensitive = false,
      isMt = false,
    } = values || {};

    set({ loading: true });
    return PosterPromise(`Glossary/${translationKey}`, [
      {
        rule,
        source_text: sourceText,
        translation_source: userKey, // Any Glossary Terms created through the UI will use the userKey to reflect that
        comment,
        is_case_sensitive: isCaseSensitive,
        is_mt: isMt,
        target_text: targetText,
      },
    ])
      .then((response: AxiosResponse) => {
        const { data }: { data: GlossaryPostResponse } = response;
        const { glossary_items: glossaryItems = [] } = data || {};

        const newGlossaryTree = mergeGlossaryTree(glossaryTree, glossaryItems);

        set({
          glossaryTree: newGlossaryTree,
        });

        return response;
      })
      .catch((error: AxiosError) => {
        const { response = defaultAxiosResponse } = error;
        failure(error, 'Unable to create glossary items at this time.');
        return response;
      })
      .finally(() => set({ loading: false }));
  },
  createTranslationsGlossary: async (translationKeys = [], values) => {
    const { createTranslationGlossary } = get();
    return createTranslationGlossary(translationKeys.join('|'), values);
  },
  createProjectGlossary: async (values) => {
    const { createTranslationGlossary } = get();
    const { activeProject: { projectKey = '' } = {} } =
      useProjectsStore.getState();

    const specialProjectKey = `P${projectKey}`;
    return createTranslationGlossary(specialProjectKey, values);
  },
  editTranslationGlossary: async (translationKey, values) => {
    const { glossaryTree } = get();
    const {
      rule,
      sourceText = '',
      targetText,
      translationSource,
      comment,
      isCaseSensitive,
      isMt,
    } = values || {};

    set({ loading: true });
    return PutterPromise(`Glossary/${translationKey}`, [
      {
        source_text: sourceText,
        ...(comment && { comment }),
        ...(isCaseSensitive && { is_case_sensitive: isCaseSensitive }),
        ...(isMt && { is_mt: isMt }),
        ...(rule && { rule }),
        ...(targetText && { target_text: targetText }),
        ...(translationSource && { translation_source: translationSource }),
      },
    ])
      .then((response: AxiosResponse) => {
        const { data }: { data: GlossaryPutResponse } = response;
        const { glossary_items: glossaryItems = [] } = data || {};

        const newGlossaryTree = mergeGlossaryTree(glossaryTree, glossaryItems);

        set({
          glossaryTree: newGlossaryTree,
        });

        return response;
      })
      .catch((error: AxiosError) => {
        const { response = defaultAxiosResponse } = error;
        failure(error, 'Unable to edit glossary items at this time.');
        return response;
      })
      .finally(() => set({ loading: false }));
  },
  editTranslationsGlossary: async (translationKeys = [], values) => {
    const { editTranslationGlossary } = get();
    return editTranslationGlossary(translationKeys.join('|'), values);
  },
  editProjectGlossary: async (values) => {
    const { editTranslationGlossary } = get();
    const { activeProject: { projectKey = '' } = {} } =
      useProjectsStore.getState();

    const specialProjectKey = `P${projectKey}`;
    return editTranslationGlossary(specialProjectKey, values);
  },
  deleteTranslationGlossary: async (translationKey, sourceTextHash) => {
    const { glossaryTree } = get();

    set({ loading: true });
    return DeleterPromise(`Glossary/${translationKey}`, {
      source_text_hash: sourceTextHash,
    })
      .then((response: AxiosResponse) => {
        const { data }: { data: GlossaryDeleteResponse } = response;
        const { glossary_items: glossaryItems = [] } = data || {};

        const newGlossaryTree = mergeGlossaryTree(glossaryTree, glossaryItems);

        set({
          glossaryTree: newGlossaryTree,
        });

        return response;
      })
      .catch((error: AxiosError) => {
        const { response = defaultAxiosResponse } = error;
        failure(error, 'Unable to delete glossary items at this time.');
        return response;
      })
      .finally(() => set({ loading: false }));
  },
  deleteTranslationsGlossary: async (translationKeys = [], sourceTextHash) => {
    const { deleteTranslationGlossary } = get();
    return deleteTranslationGlossary(translationKeys.join('|'), sourceTextHash);
  },
  deleteProjectGlossary: async (sourceTextHash) => {
    const { deleteTranslationGlossary } = get();
    const { activeProject: { projectKey = '' } = {} } =
      useProjectsStore.getState();

    const specialProjectKey = `P${projectKey}`;
    return deleteTranslationGlossary(specialProjectKey, sourceTextHash);
  },
  deleteTranslationGlossaries: async (
    translationKey,
    sourceTextHashes = []
  ) => {
    const { deleteTranslationGlossary } = get();
    return deleteTranslationGlossary(
      translationKey,
      sourceTextHashes.join('|')
    );
  },
  deleteTranslationsGlossaries: async (
    translationKeys,
    sourceTextHashes = []
  ) => {
    const { deleteTranslationsGlossary } = get();
    return deleteTranslationsGlossary(
      translationKeys,
      sourceTextHashes.join('|')
    );
  },
  deleteProjectGlossaries: async (sourceTextHashes = []) => {
    const { deleteProjectGlossary } = get();
    return deleteProjectGlossary(sourceTextHashes.join('|'));
  },
}));

export default useGlossaryStore;
