import React from 'react';
import { Box, Flex, Text, Title, Tooltip } from '@mantine/core';
import { DataWrapper } from '../../../Generic';
import { WordsServedResponse } from 'store';
import { compactFormatter } from 'helpers';
import { WordsServedChart } from './WordsServedChart';
import { useGetNavigationStatus } from 'hooks';
import { getWordsServed } from './utils';

type Props = {
  wordsServed: WordsServedResponse[];
  prevWordsServed: WordsServedResponse[];
};

const WordsServed = ({ wordsServed, prevWordsServed }: Props) => {
  const loading = useGetNavigationStatus().isNavigatingInPlace;

  const tooltipLabel =
    'This card shows the volume of Words Served for the language and time period specified. ' +
    'Words Served are the total number of translated words delivered to visitors to your site.';

  const { accumulatedWordsServed, totalWordsServed } = getWordsServed(
    wordsServed,
    prevWordsServed
  );

  return (
    <>
      <Tooltip label={tooltipLabel}>
        <Title
          order={3}
          tt="uppercase"
          w="fit-content"
          data-testid="pw-ms-words-served-title"
        >
          WORDS SERVED
        </Title>
      </Tooltip>
      <Box h={140} mt="0.75rem" data-testid="pw-ms-words-served-container">
        <DataWrapper loading={loading} noDataFound={wordsServed.length === 0}>
          <Flex w="100%" columnGap={20} align="center">
            <Text fw={700} fz="2.25rem">
              {compactFormatter(totalWordsServed)}
            </Text>
            <WordsServedChart accumulatedWordsServed={accumulatedWordsServed} />
          </Flex>
        </DataWrapper>
      </Box>
    </>
  );
};

export default WordsServed;
