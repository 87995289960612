export const chartLanguageColors = [
  { fill: '#9E4170', stroke: '#FFFFFF' },
  { fill: '#3F8344', stroke: '#FFFFFF' },
  { fill: '#8460D2', stroke: '#FFFFFF' },
  { fill: '#BC5B12', stroke: '#FFFFFF' },
  { fill: '#358082', stroke: '#FFFFFF' },
  { fill: '#637892', stroke: '#FFFFFF' },
  { fill: '#CB4379', stroke: '#FFFFFF' },
  { fill: '#5D3EAB', stroke: '#FFFFFF' },
  { fill: '#D04535', stroke: '#FFFFFF' },
  { fill: '#2777D3', stroke: '#FFFFFF' },
];
