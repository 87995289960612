import React from 'react';
import { MdInfo } from 'react-icons/md';

type InfoIconType = {
  size?: number;
  fill?: string;
};

export const InfoIcon = ({ size, fill }: InfoIconType) => (
  <MdInfo fill={fill || '#000'} size={size || 20} />
);
