import { createTranslationMap } from './translations';
import createIntersectionSet from './createIntersectionSet';

export const TRANSPERFECT_ADMIN = 'TP Admin' as const;
export const TRANSPERFECT_USER = 'TP User' as const;
export const GLGO_ADMIN = 'GLGO Admin' as const;
export const ADMIN = 'Admin' as const;
export const BILLING = 'Billing' as const;
export const DEVELOPER = 'Developer' as const;
export const PROJECT_MANAGER = 'Project Manager' as const;
export const LINGUIST = 'Linguist' as const;

export type UserRole =
  | typeof TRANSPERFECT_ADMIN
  | typeof TRANSPERFECT_USER
  | typeof GLGO_ADMIN
  | typeof ADMIN
  | typeof BILLING
  | typeof DEVELOPER
  | typeof PROJECT_MANAGER
  | typeof LINGUIST
  | '';

export type ProjectUserPermission = User & {
  languagePermissionSet: Set<string>;
  rolePermissionSet: Set<UserRole>;
};
type ProjectUserPermissionMap = Record<string, ProjectUserPermission>;

// TODO: use "...defaultUser" instead, getting this error:
// TODO: Uncaught ReferenceError: Cannot access 'defaultUser' before initialization
//     at projectPermissions.ts:28:45
export const defaultProjectUserPermission = {
  email: '',
  first_name: '',
  company_name: '',
  last_name: '',
  phone: '',
  user_key: '',
  languagePermissionSet: new Set(),
  rolePermissionSet: new Set(),
} as ProjectUserPermission;

// Prefix in the group_key that determines role. Ex: "BILL-ABE1-8813-5A4E" => BILLING
const BILLING_PREFIX = 'BILL';
const DEVELOPER_PREFIX = 'DEVL';
const PROJECT_MANAGER_PREFIX = 'PRMG';

// Special static groups in XAPIS that gives all permissions for all projects and languages
const TPT_ADMIN_GROUP_KEY = 'TPTX-ADMN-GRUP-XXXX';
const TPT_USER_GROUP_KEY = 'TPTX-USER-GRUP-XXXX';
const GLGO_ADMINS_GROUP_KEY = 'BE44-5536-1E57-6064';

const getPrefixGroupKey = (prefix: string, projectKey: string) =>
  prefix.concat(projectKey.slice(projectKey.indexOf('-')));

const getUserRole = (
  projectTranslationMap: Record<string, TranslationKey>,
  projectKey: string,
  groupKey: string
): UserRole => {
  if (projectTranslationMap[groupKey]) {
    return LINGUIST;
  }

  switch (groupKey) {
    case TPT_ADMIN_GROUP_KEY:
      return TRANSPERFECT_ADMIN;
    case TPT_USER_GROUP_KEY:
      return TRANSPERFECT_USER;
    case GLGO_ADMINS_GROUP_KEY:
      return GLGO_ADMIN;
    case projectKey:
      return ADMIN;
    case getPrefixGroupKey(BILLING_PREFIX, projectKey):
      return BILLING;
    case getPrefixGroupKey(DEVELOPER_PREFIX, projectKey):
      return DEVELOPER;
    case getPrefixGroupKey(PROJECT_MANAGER_PREFIX, projectKey):
      return PROJECT_MANAGER;
    default:
      return '';
  }
};

export const getGroupKey = (projectKey: string, userRole: UserRole) => {
  switch (userRole) {
    case TRANSPERFECT_ADMIN:
      return TPT_ADMIN_GROUP_KEY;
    case TRANSPERFECT_USER:
      return TPT_USER_GROUP_KEY;
    case GLGO_ADMIN:
      return GLGO_ADMINS_GROUP_KEY;
    case ADMIN:
      return projectKey;
    case BILLING:
      return getPrefixGroupKey(BILLING_PREFIX, projectKey);
    case DEVELOPER:
      return getPrefixGroupKey(DEVELOPER_PREFIX, projectKey);
    case PROJECT_MANAGER:
      return getPrefixGroupKey(PROJECT_MANAGER_PREFIX, projectKey);
    case LINGUIST:
      return 'Use translation_key instead';
    default:
      return '';
  }
};

export const createProjectUserPermissionMap = (
  project: ProjectKey | undefined,
  projectTranslationMap?: Record<string, TranslationKey>
): ProjectUserPermissionMap => {
  const { groups: projectGroups = [], project_key = '' } = project || {};

  // Create the projectTranslationMap only if necessary, best to pass it into this function
  const localProjectTranslationMap =
    projectTranslationMap || createTranslationMap(project);

  return projectGroups.reduce((acc: ProjectUserPermissionMap, projectGroup) => {
    const { group_key, group_members = [] } = projectGroup;

    const userRole = getUserRole(
      localProjectTranslationMap,
      project_key,
      group_key
    );

    if (userRole) {
      group_members.forEach((groupMember: User) => {
        const { user_key = '' } = groupMember;
        const newUser: ProjectUserPermission = acc[user_key]
          ? { ...acc[user_key] }
          : {
              rolePermissionSet: new Set(),
              languagePermissionSet: new Set(),
              ...groupMember,
            };

        newUser.rolePermissionSet.add(userRole);
        if (userRole === LINGUIST) {
          // It's determined that group_key is the same as an associated translation_key
          newUser.languagePermissionSet.add(group_key);
        }

        acc[user_key] = newUser;
      });
    }

    return acc;
  }, {});
};

export const hasPageViewPermissions = (
  permissionsSet: Set<string>,
  rolesSet: Set<string>
) => Boolean(createIntersectionSet(permissionsSet, rolesSet).size);
