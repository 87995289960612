import { Glossary, NoTranslateLanding } from 'ui';
import { Languages } from './Languages';
import ErrorBoundary from 'ui/components/navigation/dashboard/ErrorBoundary';
import { glossaryLoader } from 'store';
import { dashboardRC } from 'helpers';
import { RestrictedRoute } from 'pages';

import {
  GLOSSARY_PAGE_USERTYPES,
  LANGUAGES_PAGE_USERTYPES,
  NOTRANSLATE_PAGE_USERTYPES,
} from 'glweb/src/constants';

export const routes = [
  {
    path: dashboardRC.PAGE_LANGUAGES,
    loader: glossaryLoader,
    element: (
      <RestrictedRoute allowedRoles={LANGUAGES_PAGE_USERTYPES}>
        <Languages />
      </RestrictedRoute>
    ),
  },
  {
    path: dashboardRC.PAGE_GLOSSARY,
    loader: glossaryLoader,
    element: (
      <RestrictedRoute allowedRoles={GLOSSARY_PAGE_USERTYPES}>
        <Glossary />
      </RestrictedRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: dashboardRC.PAGE_NO_TRANSLATE_RULES,
    element: (
      <RestrictedRoute allowedRoles={NOTRANSLATE_PAGE_USERTYPES}>
        <NoTranslateLanding />
      </RestrictedRoute>
    ),
  },
];
