import { WordsServed } from './types';
import { getEndDate } from '../utils';

const subtractDay = (date: Date, subtractNumber: number): Date => {
  const previousDate = new Date(date);
  previousDate.setUTCDate(previousDate.getUTCDate() - subtractNumber);
  return previousDate;
};

const subtractMonth = (date: Date, subtractNumber: number): Date => {
  const previousDate = new Date(date);
  previousDate.setUTCMonth(previousDate.getUTCMonth() - subtractNumber);
  return previousDate;
};

const subtractYear = (date: Date, subtractNumber: number): Date => {
  const previousDate = new Date(date);
  previousDate.setUTCFullYear(previousDate.getUTCFullYear() - subtractNumber);
  return previousDate;
};

/**
 * Gets the previous date based on the timeSlice given.
 * Example: date = 11/06/2023, timeSlice = "7 day", returns 10/30/2023
 *
 * NOTE #1: All times returned and given will be in UTC
 *
 * @param date
 * @param timeSlice
 */
const getPreviousDate = (date = new Date(), timeSlice = ''): Date => {
  const [subtractNumber = '1', dateType = 'day'] = timeSlice.split(' ');
  switch (dateType) {
    case 'year':
      return subtractYear(date, Number(subtractNumber));
    case 'month':
      return subtractMonth(date, Number(subtractNumber));
    case 'day':
    default:
      return subtractDay(date, Number(subtractNumber));
  }
};

/**
 * Returns a string formatted as YYYY-MM
 * NOTE: Attempting to use moment(date).format('YYYY-MM') would cause errors and duplicate dates, use this function instead
 * @param date
 */
const getFormattedMonth = (date: Date): string => {
  const monthNumber = date.getUTCMonth() + 1; // Starts at 0, ex. 0 = January, 1 = February
  const month = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
  const year = date.getUTCFullYear();
  return `${year}-${month}`;
};

/**
 * Returns a string formatted as YYYY-MM-DD
 * NOTE: Attempting to use moment(date).format('YYYY-MM-DD') would cause errors and duplicate dates, use this function instead
 * @param date
 */
const getFormattedDay = (date: Date): string => {
  const day =
    date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate();
  return `${getFormattedMonth(date)}-${day}`;
};

const getDayArray = (startDate: Date, stopDate: Date): string[] => {
  const dayArray = [];
  const currentDate = new Date(startDate);
  while (currentDate <= stopDate) {
    const dayDateString = getFormattedDay(currentDate);
    dayArray.push(dayDateString);
    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
  }
  return dayArray;
};

const getMonthArray = (startDate: Date, stopDate: Date): string[] => {
  const monthArray = [];
  const currentDate = new Date(startDate);
  while (currentDate <= stopDate) {
    const monthDateString = getFormattedMonth(currentDate);
    monthArray.push(monthDateString);
    currentDate.setUTCMonth(currentDate.getUTCMonth() + 1);
  }
  return monthArray;
};

/**
 * Returns an array of strings with an ordered list of days between today and the timeSlice.
 *
 * NOTE #1: Xapis will not have data for the current day because of roll-ups,
 *          ex. if today is 11/07/2023, Xapis will not have "2023-11-07" data
 * NOTE #2: timeSlice will start on the previous day,
 *          ex. today is 11/07/2023, timeSlice = "7 day", returns data from "2023-11-06" to "2023-10-31"
 *
 * @param timeSlice - Special XAPIS string (ex. "3 day" or "1 month" or "2 year")
 * @return string[] - ex. ["2023-10-04", "2023-10-05", "2023-10-06"]
 */
const getTodayDayArray = (timeSlice: string): string[] => {
  const endDate = getEndDate();
  const previousDate = getPreviousDate(endDate, timeSlice);

  // Extra day buffer for Xapis data
  previousDate.setUTCDate(previousDate.getUTCDate() + 1);

  return getDayArray(previousDate, endDate);
};

/**
 * Returns an array of strings with an ordered list of months between today and the timeSlice.
 *
 * @param timeSlice - Special XAPIS string (ex. "3 day" or "1 month" or "2 year")
 * @return string[] - ex. ["2022-12", "2023-01", "2023-02"]
 */
const getTodayMonthArray = (timeSlice: string): string[] => {
  const endDate = getEndDate();
  const previousDate = getPreviousDate(endDate, timeSlice);
  return getMonthArray(previousDate, endDate);
};

const getMonthToDateDayArray = (date: Date): string[] => {
  const firstDay = new Date(date.getUTCFullYear(), date.getUTCMonth(), 1);
  const lastDay = new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0);
  return getDayArray(firstDay, lastDay);
};

const getYearToDateMonthArray = (date: Date): string[] => {
  const firstDay = new Date(date.getUTCFullYear(), 0);
  const lastDay = new Date(date.getUTCFullYear(), 11);
  return getMonthArray(firstDay, lastDay);
};

/**
 * Turns an array of XAPIS wordsServed results to a hashmap that'll allow O(N) operations.
 *
 * NOTE - This will NOT work if wordsServed has more than 1 translationKey inside its array
 * @param wordsServed
 * @return Map<string, number> - ex. { "2022-12": 244, "2023-01": 223, "2023-02": 122 }
 */
const getWordServedMap = (wordsServed: WordsServed[]): Map<string, number> =>
  wordsServed.reduce((map, wordsServedSlice) => {
    const { count = 0, timeslice = '' } = wordsServedSlice || {};
    // Just in case if we get duplicate timeslice data
    if (map.get(timeslice)) {
      map.set(timeslice, map.get(timeslice) + count);
    } else {
      map.set(timeslice, count);
    }
    return map;
  }, new Map());

type TimeSlice =
  | {
      timeslice: string;
      count: undefined;
    }
  | {
      timeslice: string;
      count: number;
    };

/**
 * Returns all day timeSlices from today to the "timeSliceFrom".
 * Example, if today is 11/2/2023 and timeSliceFrom = "7 day", it will return 8 timeSlices starting from "2023-10-27" to "2023-11-03"
 *
 * @param wordsServed
 * @param timeSliceFrom - Special XAPIS string (ex. "3 day" or "1 month" or "2 year")
 * @return { count: number; timeslice: string; }[] - ex. [{ count: 12, timeslice: "2023-11-07" }, { count: 0, timeslice: "2023-11-08" }]
 */
export const getDayTimeSlices = (
  wordsServed: WordsServed[],
  timeSliceFrom: string
): TimeSlice[] => {
  const wordsServedMap: Map<string, number> = getWordServedMap(wordsServed);
  const dayArray = getTodayDayArray(timeSliceFrom);
  return dayArray.map((dayString) => {
    const count = wordsServedMap.get(dayString) || 0;
    return {
      count,
      timeslice: dayString,
    };
  });
};

/**
 * Returns all month timeSlices from today to the "timeSliceFrom".
 * Example, if today is 11/2/2023 and timeSliceFrom = "1 year", it will return 13 timeSlices starting from "2022-11" to "2023-11"
 *
 * @param wordsServed
 * @param timeSliceFrom - Special XAPIS string (ex. "3 day" or "1 month" or "2 year")
 * @return { count: number; timeslice: string; }[] - ex. [{ count: 12, timeslice: "2023-11" }, { count: 0, timeslice: "2023-12" }]
 */
export const getMonthTimeSlices = (
  wordsServed: WordsServed[],
  timeSliceFrom: string
): TimeSlice[] => {
  const wordsServedMap: Map<string, number> = getWordServedMap(wordsServed);
  const dayArray = getTodayMonthArray(timeSliceFrom);
  return dayArray.map((monthString) => {
    const count = wordsServedMap.get(monthString) || 0;
    return {
      count,
      timeslice: monthString,
    };
  });
};

/**
 * Returns all day timeSlices for the current month.
 * Example, if today is 11/2/2023, it will return 30 timeSlices starting from "2023-11-01" to "2023-11-30"
 *
 * @param wordsServed
 * @return { count: number; timeslice: string; }[]
 */
export const getMonthToDateTimeSlices = (
  wordsServed: WordsServed[]
): TimeSlice[] => {
  const wordsServedMap: Map<string, number> = getWordServedMap(wordsServed);

  const currentDay = getEndDate();
  const dayArray = getMonthToDateDayArray(currentDay);

  const currentDayString = getFormattedDay(currentDay);
  return dayArray.map((dayString) => {
    if (dayString > currentDayString) {
      return {
        count: undefined,
        timeslice: dayString,
      };
    }
    return {
      count: wordsServedMap.get(dayString) || 0,
      timeslice: dayString,
    };
  });
};

/**
 * Returns all month timeSlices for the current year.
 * Example, if today is 11/2/2023, it will return 12 timeSlices starting from "2023-01" to "2023-12"
 *
 * @param wordsServed
 * @return { count: number; timeslice: string; }[]
 */
export const getYearToDateTimeSlices = (
  wordsServed: WordsServed[]
): TimeSlice[] => {
  const wordsServedMap: Map<string, number> = getWordServedMap(wordsServed);

  const currentDay = getEndDate();
  const dayArray = getYearToDateMonthArray(currentDay);

  const currentMonthString = getFormattedMonth(currentDay);
  return dayArray.map((monthString) => {
    if (monthString > currentMonthString) {
      return {
        count: undefined,
        timeslice: monthString,
      };
    }
    return {
      count: wordsServedMap.get(monthString) || 0,
      timeslice: monthString,
    };
  });
};
