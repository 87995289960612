import { PaymentSource, usePaySourceStore } from 'store/PaySourceStore';
import failure from 'helpers/failure';
import { defaultPayAddress, PayAddress } from 'store/createCheckoutSession';
import { xapisEnv } from 'store';

type UpdateSourceResult = PaymentSource & {
  channelId: string; // ex. "0insufrb"
  clientId: string; // ex. "gc"
  creationIp: string; // ex. "73.159.61.71"
  flow: string; // ex. "standard"
  language: string; // ex. "en"
  liveMode: boolean;
  mandate: {
    signedTime: string; // ex. "2023-11-01T14:35:08.556Z"
    terms: string; // ex. "By completing your purchase, you authorize Digital River to automatically renew your purchased license etc..."
  };
  sessionId: string; // ex. "4983c68e-0abd-45ba-a60b-d0300f4d799c"
  updatedTime: string; // ex. "2023-12-12T22:03:11.99Z"
  usage: string; // ex. "subscription"
};

type UpdateSourceResponse = {
  error: {
    errors: {
      message: string;
    }[];
  };
  source: UpdateSourceResult;
};

/**
 * Updates the payment source in Digital River to a new "owner" that is the newAddress
 *
 * Link to further Digital River documentation:
 * https://docs.digitalriver.com/digital-river-api/general-resources/reference/digitalriver-object#updating-sources
 *
 * @param paymentSource
 * Example:
 * {
 *     "amount": 20,
 *     "clientSecret": "08b45b33-09e0-493e-9194-421a0802b830_4b3b04e2-b0b4-4364-8279-d184e9056c69",
 *     "createdTime": "2023-08-13T02:29:15Z",
 *     "creditCard": {
 *         "brand": "Visa",
 *         "expirationMonth": 4,
 *         "expirationYear": 2034,
 *         "lastFourDigits": "1111"
 *     },
 *     "currency": "USD",
 *     "id": "08b45b33-09e0-493e-9194-421a0802b830",
 *     "owner": {
 *         "address": {
 *             "city": "Marj",
 *             "country": "AX",
 *             "line1": "39 Kollo Lane",
 *             "postalCode": "03949"
 *         },
 *         "email": "dudetest3@ridgeware.com",
 *         "firstName": "Dude",
 *         "lastName": "Test"
 *     },
 *     "paymentSessionId": "2a6bfa27-a04c-4f8f-ac3d-53dc2e6a3945",
 *     "reusable": true,
 *     "state": "chargeable",
 *     "type": "creditCard"
 * }
 *
 * @param newAddress
 * Example:
 * {
 *     "address": {
 *         "line1": "97 Chocobo Road",
 *         "city": "Nibelheim",
 *         "postalCode": "03949",
 *         "country": "PL"
 *     },
 *     "name": "Cloud Strife",
 *     "phone": "1112227777",
 *     "email": "dudetest3@ridgeware.com",
 *     "saveForLater": true
 * }
 *
 * @returns {function(*, *): Promise<void|any>}
 */
const updateCreditCardPaymentSourceAddress = async (
  paymentSource: PaymentSource,
  newAddress: PayAddress = defaultPayAddress
): Promise<UpdateSourceResult | string> => {
  try {
    const { digitalRiverKey } = xapisEnv.getHost;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const digitalRiver = new DigitalRiver(digitalRiverKey);

    const { clientSecret = '', id = '' } = paymentSource;

    const { name = '', phone = '' } = newAddress;
    const [firstName, lastName] = name.split(' ');

    const sourceData = {
      clientSecret,
      id,
      owner: {
        ...newAddress,
        firstName,
        lastName,
        phoneNumber: phone,
      },
    };

    return digitalRiver
      .updateSource(sourceData)
      .then((response: UpdateSourceResponse) => {
        const { error, source } = response || {};

        if (error) {
          const { errors = [] } = error;
          return errors.map((error) => {
            const { message = '' } = error;
            failure(error);
            return `ERROR: ${message}`;
          });
        }

        return source;
      })
      .catch((error = {}) => {
        failure(error);
        return `ERROR: ${error}`;
      });
  } catch (error) {
    failure(error);
    return `FUNCTION ERROR: ${error}`;
  }
};

/**
 * Following Digital River documentation, in order to update the payment source for non-credit card, we need to use
 * createSource.
 *
 * https://docs.digitalriver.com/digital-river-api/general-resources/reference/digitalriver-object#createsource-sourcedata
 *
 * "When updating a source, you can only update the owner and the expiration details for Credit Cards.
 * If you need to update a non-Credit Card (creditCard) payment type, use createSource."
 *
 */
const updateOtherPaymentSourceAddress = async (
  paymentSource: PaymentSource,
  newAddress: PayAddress = defaultPayAddress
): Promise<UpdateSourceResult | string> => {
  try {
    const { digitalRiverKey } = xapisEnv.getHost;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const digitalRiver = new DigitalRiver(digitalRiverKey);

    const { type = '' } = paymentSource;
    const paymentMethod = paymentSource[type as keyof PaymentSource];

    if (!paymentMethod) {
      throw new Error(
        `Type "${type}" does not exist in paymentSource: ${JSON.stringify(paymentSource)}`
      );
    }

    const { name = '', phone = '' } = newAddress;
    const [firstName, lastName] = name.split(' ');

    const sourceData = {
      type,
      [type]: paymentMethod,
      owner: {
        ...newAddress,
        firstName,
        lastName,
        phoneNumber: phone,
      },
    };

    return digitalRiver
      .createSource(sourceData)
      .then((response: UpdateSourceResponse) => {
        const { error, source } = response || {};

        if (error) {
          const { errors = [] } = error;
          return errors.map((error) => {
            const { message = '' } = error;
            failure(error);
            return `ERROR: ${message}`;
          });
        }

        return source;
      })
      .catch((error = {}) => {
        failure(error);
        return `ERROR: ${error}`;
      });
  } catch (error) {
    failure(error);
    return `FUNCTION ERROR: ${error}`;
  }
};

export const updatePaymentSourceAddress = async (
  paymentSource: PaymentSource,
  newAddress: PayAddress = defaultPayAddress
): Promise<UpdateSourceResult | string> => {
  try {
    const { type = '' } = paymentSource;
    if (type === 'creditCard') {
      return updateCreditCardPaymentSourceAddress(paymentSource, newAddress);
    }

    if (type === 'payPalBilling') {
      // TODO: Add support for PayPal after Digital River has added Manage Payment Method functionality for PayPal
      /**
       * When attempting to update a PayPal source method, an error such as "A parameter is missing." will pop up showing
       * that "taxAmount" is missing from the source. After speaking with DR team, this error is *normal* because PayPal,
       * as well as other payment methods, have not been properly implemented. From Ana Benson, our account manager:
       *
       * "In speaking with the team, Drop-In currently has this issue. We have only evaluated and implemented support
       * for cards. All other methods were not scoped or vetted for inclusion in Manage Payment Method flows.
       * We will connect with our Product team again to see if it can be updated."
       */
      return 'PayPal updatePaymentSourceAddress ignored, not supported by DR yet...';
    }

    return updateOtherPaymentSourceAddress(paymentSource, newAddress);
  } catch (error) {
    failure(error);
    return `FUNCTION ERROR: ${error}`;
  }
};

/**
 * Updates all payment sources inside the Store's "paymentSources" with XPay's Source API
 * to change all the addresses to "newAddress".
 *
 * Note: This is usually used for Checkouts after completing the first step of inputting a new billing address, we will
 *       update the addresses for *every* saved payment source because we don't know the exact payment method the user
 *       will choose (happens in the subsequent step). This needs to be done because of a limitation of Digital River.
 *
 * @param newAddress
 * Example:
 * {
 *     "address": {
 *         "line1": "97 Chocobo Road",
 *         "city": "Nibelheim",
 *         "postalCode": "03949",
 *         "country": "PL"
 *     },
 *     "name": "Cloud Strife",
 *     "phone": "1112227777",
 *     "email": "dudetest3@ridgeware.com",
 *     "saveForLater": true
 * }
 *
 * @returns {function(*, *): Promise<*>}
 */
export const updateAllPaymentSourceAddresses = async (
  newAddress: PayAddress = defaultPayAddress
) => {
  const { sources = [] } = usePaySourceStore.getState();

  // API calls will be called sequentially rather than in parallel
  return sources.reduce(
    (
      promise: Promise<(UpdateSourceResult | string)[]>,
      paymentSource: PaymentSource
    ) =>
      promise.then((allUpdateResults: (UpdateSourceResult | string)[]) =>
        updatePaymentSourceAddress(paymentSource, newAddress).then(
          (updateResults: UpdateSourceResult | string) =>
            allUpdateResults.concat(updateResults)
        )
      ),
    Promise.resolve([])
  );
};
