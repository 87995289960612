import { Flex, Text } from '@mantine/core';
import { Link, useRouteError } from 'react-router-dom';

const ErrorBoundary = () => {
  const error = useRouteError();
  console.error(error);
  return (
    <Flex direction="column" gap={10}>
      <Text mt="1rem">Whoops, something went wrong.</Text>
      <Link to="..">Go back</Link>
    </Flex>
  );
};

export default ErrorBoundary;
