import {
  Flex,
  Menu,
  Text,
  UnstyledButton,
  ScrollArea,
  Box,
} from '@mantine/core';
import { StatusCircleIcon } from '../../../Generic';
import { IoChevronDown } from 'react-icons/io5';
import { getTargetName } from 'helpers';

type Props = {
  activeTargets: TranslationKey[];
  currentTarget: TranslationKey;
  onSelect: (translationKey: string) => void;
};

export const LanguageDropdown = ({
  activeTargets,
  currentTarget,
  onSelect,
}: Props) => {
  const options = activeTargets.filter(
    (t) => t.translation_key !== currentTarget.translation_key
  );

  return (
    <Menu withinPortal position="bottom-end">
      <Menu.Target>
        <UnstyledButton>
          <Flex align="center" gap={10}>
            <StatusCircleIcon
              status={currentTarget.is_live ? 'live' : 'private'}
            />
            <Text fw={600}>{getTargetName(currentTarget)}</Text>

            <Box miw={14}>
              <IoChevronDown color="#071D49" size={14} />
            </Box>
          </Flex>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <ScrollArea
          offsetScrollbars
          type="always"
          style={{ overflowX: 'hidden' }}
        >
          <Box mah={200}>
            {options.map((target) => (
              <Menu.Item
                key={target.target_lang_name}
                onClick={() => onSelect(target.translation_key)}
              >
                <Flex align="center" gap={10}>
                  <StatusCircleIcon
                    status={target.is_live ? 'live' : 'private'}
                  />
                  <Text fw={600}>{target.target_lang_name}</Text>
                </Flex>
              </Menu.Item>
            ))}
          </Box>
        </ScrollArea>
      </Menu.Dropdown>
    </Menu>
  );
};
