import { Box, Stack, Text } from '@mantine/core';
import CustomModal from '../../../CustomModal';
import { useState } from 'react';
import DOMPurify from 'dompurify';
import { SetURLSearchParams, useRouteLoaderData } from 'react-router-dom';
import { ProjectQueryLoaderData } from 'store';
import { AnchorLink } from '../../../Generic';

type Props = {
  opened: boolean;
  setOpened: (value: ((prevState: boolean) => boolean) | boolean) => void;
  params: URLSearchParams;
  setParams: SetURLSearchParams;
  showLatest: boolean;
};

export default function AboutModal({
  opened,
  setOpened,
  params,
  setParams,
  showLatest,
}: Props) {
  const { releaseNotes = [] } = useRouteLoaderData(
    'project'
  ) as ProjectQueryLoaderData;
  const [showBuildsList, setShowBuildsList] = useState(showLatest);

  const { VITE_APP_VERSION = 'NOT_FOUND', VITE_BUILD_TIMESTAMP = 'NOT_FOUND' } =
    import.meta.env || {};

  const BuildVersionText = () => {
    return (
      <Box mb={20}>
        <Text fz="md" ta="center">
          Build:{' '}
          {releaseNotes.length > 0 ? (
            <AnchorLink
              fz="inherit"
              onClick={() => releaseNotes.length > 0 && setShowBuildsList(true)}
            >
              {VITE_APP_VERSION}
            </AnchorLink>
          ) : (
            <Text fz="inherit" span>
              {VITE_APP_VERSION}
            </Text>
          )}
          <Text fz="inherit" span>
            {' '}
            {VITE_BUILD_TIMESTAMP}
          </Text>
        </Text>
      </Box>
    );
  };

  const handleClose = () => {
    setTimeout(() => {
      setShowBuildsList(false);
    }, 500);
    setOpened(false);
    params.delete('viewNotes');
    setParams(params);
  };

  return (
    <CustomModal
      onClose={handleClose}
      opened={opened}
      title={showBuildsList || showLatest ? 'Release Notes' : 'About'}
    >
      {showBuildsList || showLatest ? (
        <Box mah={{ base: 'auto', md: 350 }} p="0.5rem">
          <Stack gap={5}>
            {releaseNotes.length > 0 ? (
              releaseNotes.map(({ content, title }, i) => {
                const shouldHighlightLatest = i === 0 && showLatest;

                return (
                  <Stack
                    gap={0}
                    key={title.rendered}
                    bg={shouldHighlightLatest ? 'background.7' : 'transparent'}
                    bd={
                      shouldHighlightLatest ? '1px solid background.5' : 'none'
                    }
                    style={{ borderRadius: 10 }}
                    p={10}
                    c="font1.1"
                    fz="md"
                  >
                    <Text fz="inherit" fw={shouldHighlightLatest ? 600 : 400}>
                      {title.rendered}
                      {i === 0 ? ' (current) ' : ''}
                    </Text>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(content.rendered),
                      }}
                    />
                  </Stack>
                );
              })
            ) : (
              <></>
            )}
          </Stack>
        </Box>
      ) : (
        <BuildVersionText />
      )}
    </CustomModal>
  );
}
