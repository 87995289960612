import React from 'react';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { HiOutlineMinusSm } from 'react-icons/hi';
import { IoMdAdd } from 'react-icons/io';
import { SpiderTreeNode } from 'store';
import { Box, CircularProgress, ThemeProvider } from '@mui/material';
import theme from '../../TreeItemOverrides';
import handleToggle from '../../utils/handleToggle';

type Props = {
  yyTarget: TranslationKey | undefined;
  spiderTree: SpiderTreeNode[];
  setSpiderTree: React.Dispatch<React.SetStateAction<SpiderTreeNode[]>>;
};
export const EverythingTree = ({
  yyTarget,
  spiderTree,
  setSpiderTree,
}: Props) => {
  const yyTranslationKey = yyTarget?.translation_key || '';

  const renderTree = (nodes: SpiderTreeNode[]): React.ReactNode =>
    nodes.map((treeNode) => {
      const {
        id = 'unknown',
        content_url_hash,
        name: nodeName,
        children_fetched,
        children = [],
        content_url_orig,
        label,
        content_url,
      } = treeNode;

      const nodeData = {
        id,
        nodeName,
        label,
        content_url_hash,
        content_url_orig,
        content_url,
        children_fetched,
        children,
      };

      if (id !== 'unknown') {
        const name =
          nodeName === 'fetch' ? (
            <CircularProgress size={20} />
          ) : (
            content_url_orig
          );
        const depth = id.split('-').length;

        const nodeInfo = JSON.stringify({ ...nodeData, depth });

        return children?.length > 0 ? (
          <TreeItem
            data-nodeinfo={nodeInfo}
            key={id}
            nodeId={id}
            label={
              <Box style={{ marginRight: '0.5rem', overflow: 'hidden' }}>
                {name || label}
              </Box>
            }
            onChange={(e) =>
              handleToggle(e, yyTranslationKey, spiderTree, setSpiderTree)
            }
          >
            {renderTree(children)}
          </TreeItem>
        ) : id === '0' ? (
          <TreeItem
            data-nodeinfo={nodeInfo}
            key={id}
            nodeId={id}
            label={name || label}
            onChange={(e) =>
              handleToggle(e, yyTranslationKey, spiderTree, setSpiderTree)
            }
          >
            <></>
          </TreeItem>
        ) : (
          <TreeItem
            data-nodeinfo={nodeInfo}
            key={id}
            nodeId={id}
            label={
              <Box style={{ marginRight: '0.5rem', overflow: 'hidden' }}>
                {name || label}
              </Box>
            }
            onChange={(e) =>
              handleToggle(e, yyTranslationKey, spiderTree, setSpiderTree)
            }
          />
        );
      }
      return <div key="key" />;
    });

  if (yyTranslationKey && spiderTree.length > 0) {
    const [root] = spiderTree;
    const { id, name: nodeName, content_url_hash } = root;
    const nodeData = { id, nodeName, content_url_hash };
    const nodeInfo = JSON.stringify(nodeData);

    return (
      <ThemeProvider theme={theme}>
        <TreeView
          data-nodeinfo={nodeInfo}
          aria-label="rich object"
          defaultCollapseIcon={<HiOutlineMinusSm color="#006CD1" size={24} />}
          defaultExpandIcon={<IoMdAdd color="#006CD1" size={24} />}
          onNodeToggle={(e) =>
            handleToggle(e, yyTranslationKey, spiderTree, setSpiderTree)
          }
        >
          {renderTree(spiderTree)}
        </TreeView>
      </ThemeProvider>
    );
  }
  return <></>;
};

export default EverythingTree;
