import { memo, useCallback } from 'react';
import { COOKIE, DOMAIN, FOLDER, QUERY } from './utils';
import { getStringEquality } from 'helpers';
import DefaultCookieMethod from './cookie/DefaultCookieMethod';
import EditCookieMethod from './cookie/EditCookieMethod';
import ConfigureCookieMethod from './cookie/ConfigureCookieMethod';
import DefaultFolderMethod from './folder/DefaultFolderMethod';
import EditFolderMethod from './folder/EditFolderMethod';
import ConfigureFolderMethod from './folder/ConfigureFolderMethod';
import DefaultDomainMethod from './domain/DefaultDomainMethod';
import EditDomainMethod from './domain/EditDomainMethod';
import ConfigureDomainMethod from './domain/ConfigureDomainMethod';
import DefaultQueryMethod from './query/DefaultQueryMethod';
import EditQueryMethod from './query/EditQueryMethod';
import ConfigureQueryMethod from './query/ConfigureQueryMethod';
import { useDeploymentContext } from './DeploymentContextProvider';

type Props = {
  methodType: 'default' | 'edit' | 'configure' | null;
};

const MethodsSwitch = memo(({ methodType }: Props) => {
  const {
    selectedMethod,
    activeProject: { deployment_method: defaultMethod = '' } = {},
  } = useDeploymentContext();

  const isDefaultType = methodType === 'default';
  const isEditType = methodType === 'edit';

  const isDefaultCookieMethod = getStringEquality(defaultMethod, COOKIE);
  const isConfiguringCookieMethod =
    methodType === 'configure' &&
    getStringEquality(selectedMethod as string, COOKIE);

  const isDefaultFolderMethod = getStringEquality(defaultMethod, FOLDER);
  const isConfiguringFolderMethod =
    methodType === 'configure' &&
    getStringEquality(selectedMethod as string, FOLDER);

  const isDefaultDomainMethod = getStringEquality(defaultMethod, DOMAIN);
  const isConfiguringDomainMethod =
    methodType === 'configure' &&
    getStringEquality(selectedMethod as string, DOMAIN);

  const isDefaultQueryMethod = getStringEquality(defaultMethod, QUERY);
  const isConfiguringQueryMethod =
    methodType === 'configure' &&
    getStringEquality(selectedMethod as string, QUERY);

  const renderDeploymentMethodCard = useCallback(() => {
    switch (true) {
      case isDefaultCookieMethod && isDefaultType:
        return <DefaultCookieMethod />;
      case isDefaultCookieMethod && isEditType:
        return <EditCookieMethod />;
      case isConfiguringCookieMethod:
        return <ConfigureCookieMethod />;
      case isDefaultFolderMethod && isDefaultType:
        return <DefaultFolderMethod />;
      case isDefaultFolderMethod && isEditType:
        return <EditFolderMethod />;
      case isConfiguringFolderMethod:
        return <ConfigureFolderMethod />;
      case isDefaultDomainMethod && isDefaultType:
        return <DefaultDomainMethod />;
      case isDefaultDomainMethod && isEditType:
        return <EditDomainMethod />;
      case isConfiguringDomainMethod:
        return <ConfigureDomainMethod />;
      case isDefaultQueryMethod && isDefaultType:
        return <DefaultQueryMethod />;
      case isDefaultQueryMethod && isEditType:
        return <EditQueryMethod />;
      case isConfiguringQueryMethod:
        return <ConfigureQueryMethod />;
      default:
        return <></>;
    }
  }, [
    isConfiguringCookieMethod,
    isConfiguringDomainMethod,
    isConfiguringFolderMethod,
    isConfiguringQueryMethod,
    isDefaultCookieMethod,
    isDefaultDomainMethod,
    isDefaultFolderMethod,
    isDefaultQueryMethod,
    isDefaultType,
    isEditType,
  ]);

  return renderDeploymentMethodCard();
});

export default MethodsSwitch;
