import React from 'react';
import { Box, Flex, Image } from '@mantine/core';
import BrowserTopBar from './images/browser-top-bar.svg';
import classes from './BrowserWindow.module.css';

export default function BrowserWindow({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Flex
      mx="auto"
      w="100%"
      maw="48rem"
      mih="32rem"
      h="auto"
      pt="3rem"
      px="1rem"
      pb="1.5rem"
      direction="column"
      align="center"
      pos="relative"
      className={classes.wrapper}
    >
      <Box
        pos="absolute"
        top={-18}
        w={10}
        h={10}
        bg="background.0"
        style={{ borderRadius: 100 }}
      />
      <Image
        width="100%"
        src={BrowserTopBar}
        alt="browser window top bar with action buttons"
        pos="absolute"
        top={0}
        left={0}
      />
      <Flex
        w="100%"
        px="1.5rem"
        py="1rem"
        direction="column"
        justify="center"
        rowGap="2rem"
        className={classes.wrapperInner}
      >
        {children}
      </Flex>
    </Flex>
  );
}
