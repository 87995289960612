import { create } from 'zustand';
import { AxiosError, AxiosResponse } from 'axios';
import { Address, defaultAddress } from './PayOrderStore';
import PatcherPromise from './xapis-wrappers/PatcherPromise';
import FetcherPromise from './xapis-wrappers/FetcherPromise';
import { failure, success } from 'helpers';
import DeleterPromise from './xapis-wrappers/DeleterPromise';
import isSuccessStatus from 'helpers/utils/isSuccessStatus';
import PosterPromise from './xapis-wrappers/PosterPromise';
import { getUserKey } from './xapis-wrappers/xapis';
import usePayKeyStore from './PayKeyStore';

export type CreditCard = {
  brand: string;
  expirationMonth: number;
  expirationYear: number;
  lastFourDigits: string;
};

export const defaultCreditCard: CreditCard = {
  brand: '',
  expirationMonth: 0,
  expirationYear: 0,
  lastFourDigits: '',
};

type PayPalBilling = {
  offline: boolean;
  payerId: string; // ex. "payerId",
  payerStatus: string; // ex. "verified",
  redirectUrl: string; // ex. "https://api.digitalriverws.com:443/payments/redirects/92001a82-100b-4159-9208-f3907beb3ac2?apiKey=pk_test_b4eeb52619c743fe8cf86b781430687a",
  returnUrl: string; // ex. "https://js.digitalriverws.com/v1/1.20231026.1720/components/paypal-receiver/paypal-receiver.html?componentId=paypal-b98ede81-7ecb-48ae-b910-8c3f0aafec37&controllerId=controller-4db24269-7b67-4190-9af8-2e382f35eaa4&action=return&type=paypal",
  token: string; // ex. "12345"
};

export type Owner = {
  address: Address;
  customerId?: string; // Will be the same as user_key, ex. '835A-9CE3-249D-BFD9'
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
};

export const defaultOwner: Owner = {
  address: defaultAddress,
  email: '',
  firstName: '',
  lastName: '',
};

export type PaymentSource = {
  amount?: number;
  clientSecret: string; // needed to update Payment Source, ex. 'c98154a8-9272-42ad-b25f-a1a11cb0f4a5_b0ac9aae-3a10-4b1a-ba0d-e8bf6c2b66b8'
  createdTime: string; // ex. 2023-10-31T22:05:26Z
  creditCard?: CreditCard;
  currency: string; // ex. 'USD', 'PLN'
  id: string; // ex. '4d99d24a-9e5a-4467-ab5c-4cd60a5a4f44"
  owner: Owner;
  paymentSessionId?: string; // ex. '4d99a244-9e5a-4467-ab5c-4c660a5a4f44'
  payPalBilling?: PayPalBilling;
  reusable: boolean;
  state: string; // ex. 'chargeable'
  type: string; // Linked to the available key that holds the source info, ex. 'payPalBilling'
};

export const defaultPaymentSource: PaymentSource = {
  clientSecret: '',
  createdTime: '',
  currency: '',
  creditCard: defaultCreditCard,
  id: '',
  owner: defaultOwner,
  reusable: false,
  state: '',
  type: 'creditCard',
};

export type PaySourceResponse = {
  default_source_id: string;
  payment_sources: PaymentSource[];
};

type PatchResponseData = {
  createdTime: string;
  defaultSourceId: string;
  email: string;
  enabled: boolean;
  id: string;
  liveMode: boolean;
  locale: string;
  requestToBeForgotten: boolean;
  sources: PaymentSource[];
  type: string;
  updatedTime: string;
};

export type PaySourceStore = {
  defaultSource: PaymentSource;
  loading: boolean;
  sources: PaymentSource[];
  fetchSources: () =>
    | Promise<AxiosResponse<PaySourceResponse>>
    | Promise<Record<string, never>>;
  changeDefaultSource: (
    sourceId: string
  ) =>
    | Promise<AxiosResponse<PatchResponseData>>
    | Promise<Record<string, never>>;
  addPayment: (
    sourceId: string
  ) => Promise<AxiosResponse<PaymentSource>> | Promise<Record<string, never>>;
  deleteSource: (
    sourceId: string
  ) => Promise<AxiosResponse<string>> | Promise<Record<string, never>>;
};

const filterDefaultSource = (sources: PaymentSource[], defaultId: string) => {
  return (
    sources.find(({ id = '' }: PaymentSource) => id === defaultId) ||
    defaultPaymentSource
  );
};

export const sortSourcesByDefault = (
  sources: PaymentSource[],
  defaultId: string
) => {
  const defaultSource = filterDefaultSource(sources, defaultId);
  const isDefaultSourceFound = defaultSource.id !== '';

  let sortedSourcesByDefault: PaymentSource[] = sources;
  sortedSourcesByDefault = sortedSourcesByDefault.filter(
    ({ id = '' }) => id !== defaultId
  );

  if (isDefaultSourceFound) {
    sortedSourcesByDefault.unshift(defaultSource);
    return sortedSourcesByDefault;
  }
  return sources;
};

export const usePaySourceStore = create<PaySourceStore>()((set, get) => ({
  defaultSource: defaultPaymentSource,
  loading: false,
  sources: [],
  fetchSources: async () => {
    const { loading } = get();
    const { payKey } = usePayKeyStore.getState();
    const userKey = getUserKey();

    if (loading) return {};

    set({ loading: true });
    return FetcherPromise(`Pay/Source/${payKey}/${userKey}`)
      .then((response: AxiosResponse) => {
        const {
          data: {
            default_source_id: defaultSourceId = '',
            payment_sources: paymentSources = [],
          } = {},
        } = response;

        set({
          defaultSource: filterDefaultSource(paymentSources, defaultSourceId),
          sources: sortSourcesByDefault(paymentSources, defaultSourceId),
        });

        return response;
      })
      .catch((error: AxiosError) => {
        const { response = {} } = error;
        const {
          data: { message = '' } = {},
          status = 0,
        }: {
          data?: {
            message?: string;
          };
          status?: number;
        } = response;

        const notFoundErrorCode = 404; // TODO: Import the 'http-status' library instead of using this variable
        if (status !== notFoundErrorCode) {
          failure(
            error,
            `Something went wrong with retrieving your cards${message ? `, ${message}` : '.'}`
          );
        }

        set({
          defaultSource: defaultPaymentSource,
          sources: [],
        });

        return response;
      })
      .finally(() => set({ loading: false }));
  },
  changeDefaultSource: async (sourceId) => {
    const { loading } = get();
    const { payKey } = usePayKeyStore.getState();
    const userKey = getUserKey();

    if (loading) return {};

    set({ loading: true });
    return PatcherPromise(`Pay/Source/${payKey}/${userKey}/${sourceId}`)
      .then((response: AxiosResponse) => {
        const { data: { defaultSourceId = '', sources = [] } = {} } = response;

        set({
          defaultSource: filterDefaultSource(sources, defaultSourceId),
          sources: sortSourcesByDefault(sources, defaultSourceId),
        });

        success('Successfully changed your default card!');
        return response;
      })
      .catch((error: AxiosError) => {
        const { response = {} } = error;
        failure(error, 'Something went wrong with changing your default card.');
        return response;
      })
      .finally(() => set({ loading: false }));
  },
  addPayment: async (sourceId: string) => {
    const { loading, sources } = get();
    const { payKey } = usePayKeyStore.getState();
    const userKey = getUserKey();

    if (loading) return {};

    set({ loading: true });
    return PosterPromise(`Pay/Source/${payKey}/${userKey}/${sourceId}`)
      .then((response: AxiosResponse) => {
        const { data: newPaymentSource, status = 0 } = response;

        if (isSuccessStatus(status)) {
          const newSources = sources.concat(newPaymentSource);
          set({ sources: newSources });
        }

        success('Successfully added a payment source');
        return response;
      })
      .catch((error: AxiosError) => {
        const { response = {} } = error;
        failure(error, 'Something went wrong with adding your payment source.');
        return response;
      })
      .finally(() => set({ loading: false }));
  },
  deleteSource: async (sourceId: string) => {
    const { loading, sources } = get();
    const { payKey } = usePayKeyStore.getState();
    const userKey = getUserKey();

    if (loading) return {};

    set({ loading: true });
    return DeleterPromise(`Pay/Source/${payKey}/${userKey}/${sourceId}`)
      .then((response: AxiosResponse) => {
        const { status = 0 } = response;

        if (isSuccessStatus(status)) {
          const newSources = sources.filter(
            ({ id = '' }: PaymentSource) => id !== sourceId
          );
          // NOTE: We shouldn't have to set defaultSource, as attempting to delete it should return a 409 CONFLICT
          set({ sources: newSources });
        }

        success('Successfully deleted a payment source');
        return response;
      })
      .catch((error: AxiosError) => {
        const { response = {} } = error;
        failure(
          error,
          'Something went wrong with deleting your payment source.'
        );
        return response;
      })
      .finally(() => set({ loading: false }));
  },
}));

export default usePaySourceStore;

export const fetchSourcesPromise = (payKey: string) => {
  const userKey = getUserKey();
  return FetcherPromise(`Pay/Source/${payKey}/${userKey}`);
};

export const changeDefaultSourcePromise = (
  payKey: string,
  userKey: string,
  sourceId: string
) => {
  return PatcherPromise(`Pay/Source/${payKey}/${userKey}/${sourceId}`);
};

export const deleteSourcePromise = (
  payKey: string,
  userKey: string,
  sourceId: string
) => {
  return DeleterPromise(`Pay/Source/${payKey}/${userKey}/${sourceId}`);
};

export const addPaymentPromise = (
  payKey: string,
  userKey: string,
  sourceId: string
) => {
  return PosterPromise(`Pay/Source/${payKey}/${userKey}/${sourceId}`);
};
