import { useMantineTheme, UnstyledButton, Image } from '@mantine/core';
import classes from '../SelectorDefaults.module.css';

const PreferenceButton = ({
  onClick,
  disabled,
  isActive,
  src,
  alt,
}: {
  onClick: () => void;
  disabled: boolean;
  isActive: boolean;
  src: string;
  alt: string;
}) => {
  const { colors } = useMantineTheme();

  return (
    <UnstyledButton
      disabled={disabled}
      className={classes.selectorOption}
      style={{
        outline: isActive ? `2px solid ${colors.border[8]}` : 'none',
      }}
      onClick={onClick}
      w={115}
      h={90}
    >
      <Image src={src} alt={alt} />
    </UnstyledButton>
  );
};

export default PreferenceButton;
