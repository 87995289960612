import React, { useMemo, useState } from 'react';
import { Card, Flex, Text, Title } from '@mantine/core';
import { BsExclude, BsIntersect } from 'react-icons/bs';
import { EverythingTree, ExcludeTree, IncludeTree } from 'mui';
import ExcludeRulesSection from './rules-section/ExcludeRulesSection';
import IncludeRulesSection from './rules-section/IncludeRulesSection';
import ScopeDivider from './dividers/ScopeDivider';
import { useLoaderData } from 'react-router-dom';
import { SpiderTreeResponse, useCurrentProjectData } from 'store';
import { getYYTarget, hexToObject } from 'helpers';
import BackButtonSection from './landing-page/BackButtonSection';
import classes from './ScopeUrlsTree.module.css';

const ScopeUrlsTree = () => {
  const { project: { translations = [] } = {} } = useCurrentProjectData();
  const { tree = [] } = useLoaderData() as SpiderTreeResponse;

  const yyTargetFound = getYYTarget(translations) as TranslationKey;

  const [yyTarget, setYYTarget] = useState(yyTargetFound);
  const [spiderTree, setSpiderTree] = useState(tree);

  const { scope_preset = '' } = useMemo(() => {
    return hexToObject(yyTarget?.translation_config || '');
  }, [yyTarget?.translation_config]);

  const currentScope = useMemo(() => {
    return scope_preset.length > 0 &&
      ['all', 'include', 'exclude'].includes(scope_preset)
      ? scope_preset
      : 'all';
  }, [scope_preset]);

  return (
    <>
      <BackButtonSection scope={currentScope} />
      <Card
        mt={15}
        shadow="sm"
        style={{ overflow: 'visible' }}
        padding="lg"
        radius="md"
        withBorder
      >
        <Card.Section
          p="1rem 3rem 1rem 1.5rem"
          className={classes.webPagesSection}
        >
          <Flex
            align={{ base: 'start', xs: 'center' }}
            direction={{ base: 'column', xs: 'row' }}
            w={{ base: '60%', xs: '70%' }}
          >
            <Title order={3} tt="uppercase">
              Web Pages
            </Title>
          </Flex>
          {currentScope === 'all' ? (
            <></>
          ) : (
            <Flex
              align="center"
              h="fit-content"
              justify="end"
              w={{ base: '40%', xs: '30%' }}
            >
              {currentScope === 'exclude' ? (
                <>
                  <BsExclude fill="#5D6B89" />
                  <Text c="font1.0" fw={700} ml="0.5rem">
                    Exclude
                  </Text>
                </>
              ) : (
                <>
                  <BsIntersect fill="#167934" />
                  <Text c="font1.0" fw={700} ml="0.5rem">
                    Include
                  </Text>
                </>
              )}
            </Flex>
          )}
        </Card.Section>
        <Card.Section p="1rem 0">
          {currentScope === 'all' ? (
            <EverythingTree
              yyTarget={yyTarget}
              spiderTree={spiderTree}
              setSpiderTree={setSpiderTree}
            />
          ) : currentScope === 'exclude' ? (
            <ExcludeTree
              yyTarget={yyTarget}
              setYYTarget={setYYTarget}
              spiderTree={spiderTree}
              setSpiderTree={setSpiderTree}
            />
          ) : (
            <IncludeTree
              yyTarget={yyTarget}
              setYYTarget={setYYTarget}
              spiderTree={spiderTree}
              setSpiderTree={setSpiderTree}
            />
          )}
        </Card.Section>
      </Card>
      {currentScope !== 'all' && <ScopeDivider currentScope={currentScope} />}
      {currentScope === 'all' ? (
        <></>
      ) : currentScope === 'exclude' ? (
        <ExcludeRulesSection
          yyTarget={yyTarget}
          setYYTarget={setYYTarget}
          setSpiderTree={setSpiderTree}
        />
      ) : (
        <IncludeRulesSection
          yyTarget={yyTarget}
          setYYTarget={setYYTarget}
          setSpiderTree={setSpiderTree}
        />
      )}
    </>
  );
};

export default ScopeUrlsTree;
