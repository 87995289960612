import { createStore } from 'zustand/vanilla';
import { UndoRedo } from '../classes/UndoRedo';

export type EditHistoryStore = {
  undoRedo: UndoRedo;

  actions: {
    setUndoRedo: (undoRedo: UndoRedo) => void;
  };
};

export function createEditHistoryStore(originalTargetJliff: Jliff[]) {
  return createStore<EditHistoryStore>((set) => ({
    undoRedo: new UndoRedo([...originalTargetJliff]),

    actions: {
      setUndoRedo: (undoRedo) => set({ undoRedo }),
    },
  }));
}
