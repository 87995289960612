import { create } from 'zustand';
import Get from './xapis-wrappers/Fetcher';
import { FetcherPromise } from './xapis-wrappers';

export type WorldTraffic = {
  count: number;
  label: string;
  countryName: string;
};
export type WorldTrafficResponse = {
  count: number;
  label: string;
  country_name: string;
};

export type WorldTrafficStore = {
  worldTraffic: WorldTraffic[];
  loading: boolean;
  fetchWorldTraffic: (
    translationKey: string,
    timesliceFrom: string,
    failed: { errorMessage?: string; action?: () => void }
  ) => void;
};

const parseWorldTraffic = (
  worldTraffic: WorldTrafficResponse[]
): WorldTraffic[] => {
  return worldTraffic.map((traffic) => {
    return {
      count: traffic.count,
      label: traffic.label,
      countryName: traffic.country_name,
    };
  });
};

export const fetchWorldTrafficPromise = async (
  translationKey: string,
  timesliceFrom: string
) => {
  const params = {
    type: 'groupby',
    groupby: 'country_code',
    translation_key: translationKey,
    timeslice_from: timesliceFrom,
  };

  try {
    const response = await FetcherPromise('Metrics', params);
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const useWorldTrafficStore = create<WorldTrafficStore>()((set, get) => ({
  worldTraffic: [],
  loading: false,
  fetchWorldTraffic: (translationKey, timesliceFrom, failed) => {
    const baseParams = { type: 'groupby', groupby: 'country_code' };
    const { loading } = get();
    if (loading) return;

    set({ loading: true });
    Get(
      `Metrics`,
      ({ data: { data = [] } }: { data: { data: WorldTrafficResponse[] } }) => {
        const parsedWorldTraffic = parseWorldTraffic(data);
        set({ worldTraffic: parsedWorldTraffic });
      },
      {
        errorMessage: failed.errorMessage || '',
        action: () => {
          set({ worldTraffic: [] });
          failed.action && failed.action();
        },
      },
      {
        translation_key: translationKey,
        timeslice_from: timesliceFrom,
        ...baseParams,
      },
      () => set({ loading: false })
    );
  },
}));

export default useWorldTrafficStore;
