import React from 'react';
import Deployment from './Deployment';
import { DeploymentProvider } from './DeploymentContextProvider';

const DeploymentWrapper = () => (
  <DeploymentProvider>
    <Deployment />
  </DeploymentProvider>
);

export default DeploymentWrapper;
