import { create } from 'zustand';
import Get from './xapis-wrappers/Fetcher';
import { FetcherPromise } from './xapis-wrappers';

export type Address = {
  city: string;
  country: string;
  line1: string;
  line2?: string;
  postalCode: string;
  state?: string;
};

export const defaultAddress: Address = {
  city: '',
  country: '',
  line1: '',
  postalCode: '',
};

export const defaultOrderItems = [
  {
    sku_threshold_value: 0,
  },
];

export type OrderItems = typeof defaultOrderItems;

export type Order = {
  bill_to_address: Address;
  bill_to_email: string;
  bill_to_name: string;
  created_utc: string;
  currency_code: string;
  invoice_file_id: string;
  is_success: string;
  last_four: string;
  order_id: string;
  order_items: OrderItems;
  order_status: string;
  pay_key: string;
  payment_method: string;
  project_key: string;
  purchase_lang_code: string;
  selling_entity_id: string;
  selling_entity_name: string;
  subtotal: string;
  tax: string;
  total: string;
  user_key: string;
};

export const defaultOrder: Order = {
  bill_to_address: defaultAddress,
  bill_to_email: '',
  bill_to_name: '',
  created_utc: '',
  currency_code: '',
  invoice_file_id: '',
  is_success: '',
  last_four: '',
  order_id: '',
  order_items: defaultOrderItems,
  order_status: '',
  pay_key: '',
  payment_method: '',
  project_key: '',
  purchase_lang_code: '',
  selling_entity_id: '',
  selling_entity_name: '',
  subtotal: '',
  tax: '',
  total: '',
  user_key: '',
};

export type PayOrderStore = {
  loading: boolean;
  totalRows: number;
  orders: Order[];
  setOrders: (orders: Order[]) => void;
  setTotalRows: (rows: number) => void;
  fetchOrders: (payKey: string, params: object) => void;
};

export const usePayOrderStore = create<PayOrderStore>()((set) => ({
  loading: false,
  totalRows: 0,
  orders: [],
  setOrders: (orders) => set(() => ({ orders })),
  setTotalRows: (totalRows) => set(() => ({ totalRows })),
  fetchOrders: (payKey, params) => {
    set({ loading: true });
    Get(
      `Pay/Order/${payKey}`,
      ({
        data: { orders, rows_total },
      }: {
        data: { orders: Order[]; rows_total: number };
      }) => {
        set({ orders });
        set({ totalRows: rows_total });
      },
      { action: () => set({ orders: [] }) },
      params,
      () => set({ loading: false })
    );
  },
}));

export default usePayOrderStore;

export const fetchOrdersPromise = <T extends object | undefined>(
  payKey: string,
  params: T
) => {
  return FetcherPromise(`Pay/Order/${payKey}`, params);
};
