import React from 'react';
import { Text } from '@mantine/core';
import { CustomModal } from 'ui';

type UnsavedChangesModalProps = {
  open: boolean;
  onCancel: () => void;
  onDiscard: () => void;
  onSave: () => void;
};

export const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({
  open,
  onCancel,
  onDiscard,
  onSave,
}) => (
  <CustomModal
    opened={open}
    onClose={onCancel}
    title="Leave Page?"
    footerActions={[
      { label: 'Discard', action: onDiscard },
      { label: 'Save', action: onSave },
    ]}
  >
    <Text fz="1.15rem" ta="center">
      You have unsaved changes. Do you want to discard or save them?
    </Text>
  </CustomModal>
);
