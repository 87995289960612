import React, { useState } from 'react';
import { Box, Button, Flex, Popover, Text } from '@mantine/core';
import { capitilize } from '../../functions/segmentsFunctions';
import { SortAscIcon } from '../../icons/ActionIcons';
import { Success } from '../../icons/IndicatorIcons';
import { SortBy, SortKey } from '../../types/editor';
import classes from './SortSegments.module.css';
import { IoIosArrowDown } from 'react-icons/io';

type SortSegmentProps = {
  setSortBy: React.Dispatch<React.SetStateAction<SortBy>>;
  sortBy: SortBy;
};

const SortSegments = ({ setSortBy, sortBy }: SortSegmentProps) => {
  const [open, setOpen] = useState(false);

  const sortByOptions: SortKey[] = ['status', 'type', 'source', 'date'];

  const handleBySelection = (value: SortKey) => {
    setSortBy((prev) => ({ ...prev, field: value }));
    setTimeout(() => setOpen(false), 600);
  };

  const SelectItem = ({ value }: { value: SortKey }) => {
    const isSelected = value === sortBy.field;
    return (
      <Flex className={classes.item} onClick={() => handleBySelection(value)}>
        <Flex style={{ flexBasis: '18%' }}>
          {isSelected ? <Success size={15} /> : <></>}
        </Flex>
        <Text fz={14} fw={500}>
          {capitilize(value)}
        </Text>
      </Flex>
    );
  };

  const SortIcon = ({ ascending }: { ascending: boolean }) => {
    return (
      <Flex
        className={classes.icon}
        style={{
          transform: `scaleY(${!ascending ? -1 : 1})`,
        }}
        onClick={() =>
          setSortBy((prev) => ({ ...prev, ascending: !prev.ascending }))
        }
      >
        <SortAscIcon />
      </Flex>
    );
  };

  return (
    <Flex gap={5} align={'center'}>
      <Popover
        position="top-end"
        opened={open}
        onChange={setOpen}
        closeOnEscape
        shadow="sm"
        offset={5}
        arrowOffset={10}
      >
        <Popover.Target>
          <Button
            variant="outline"
            rightSection={<IoIosArrowDown size={20} />}
            onClick={() => setOpen(!open)}
          >
            Sort by
          </Button>
        </Popover.Target>
        <Popover.Dropdown className={classes.dropdown}>
          <Box bg="#FFFFFF" className={classes.itemContainer}>
            {sortByOptions.map((key) => (
              <SelectItem key={key} value={key} />
            ))}
          </Box>
        </Popover.Dropdown>
      </Popover>
      <SortIcon ascending={sortBy.ascending} />
    </Flex>
  );
};

export default SortSegments;
