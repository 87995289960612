import React from 'react';

const CustomDot = (
  props: Partial<{
    cx: number;
    cy: number;
    payload: Record<string, string>;
  }> & { date: string }
) => {
  const {
    cx = 0,
    cy = 0,
    payload: { timeslice = '' } = {},
    date = '',
  } = props || {};

  return timeslice === date ? (
    <circle cx={cx} cy={cy} r={7} fill="#0071DB" />
  ) : null;
};

export default CustomDot;
