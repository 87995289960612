import { queryOptions } from '@tanstack/react-query';
import { fetchSkusPromise } from '../../PaySkuStore';
import { SkuWCreatedAndLastMod } from '../../types';
import { isSuccessStatus } from 'helpers';

export const querySkus = (payKey: string) =>
  queryOptions({
    queryKey: ['skus', payKey],
    queryFn: async () => {
      const response = await fetchSkusPromise(payKey);
      if (
        !response ||
        !isSuccessStatus(response.status) ||
        !response.data ||
        !response.data.skus
      ) {
        throw new Error('Failed to fetch SKUs data');
      }
      return response.data.skus.filter(
        (s: SkuWCreatedAndLastMod) => s.price !== 0
      );
    },
  });
