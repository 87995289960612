const countryCodes: {
  Country: string;
  Alpha2: string;
  Alpha3: string;
  Numeric: number;
}[] = [
  {
    Country: 'Afghanistan',
    Alpha2: 'AF',
    Alpha3: 'AFG',
    Numeric: 4,
  },
  {
    Country: 'Albania',
    Alpha2: 'AL',
    Alpha3: 'ALB',
    Numeric: 8,
  },
  {
    Country: 'Algeria',
    Alpha2: 'DZ',
    Alpha3: 'DZA',
    Numeric: 12,
  },
  {
    Country: 'American Samoa',
    Alpha2: 'AS',
    Alpha3: 'ASM',
    Numeric: 16,
  },
  {
    Country: 'Andorra',
    Alpha2: 'AD',
    Alpha3: 'AND',
    Numeric: 20,
  },
  {
    Country: 'Angola',
    Alpha2: 'AO',
    Alpha3: 'AGO',
    Numeric: 24,
  },
  {
    Country: 'Anguilla',
    Alpha2: 'AI',
    Alpha3: 'AIA',
    Numeric: 660,
  },
  {
    Country: 'Antarctica',
    Alpha2: 'AQ',
    Alpha3: 'ATA',
    Numeric: 10,
  },
  {
    Country: 'Antigua and Barbuda',
    Alpha2: 'AG',
    Alpha3: 'ATG',
    Numeric: 28,
  },
  {
    Country: 'Argentina',
    Alpha2: 'AR',
    Alpha3: 'ARG',
    Numeric: 32,
  },
  {
    Country: 'Armenia',
    Alpha2: 'AM',
    Alpha3: 'ARM',
    Numeric: 51,
  },
  {
    Country: 'Aruba',
    Alpha2: 'AW',
    Alpha3: 'ABW',
    Numeric: 533,
  },
  {
    Country: 'Australia',
    Alpha2: 'AU',
    Alpha3: 'AUS',
    Numeric: 36,
  },
  {
    Country: 'Austria',
    Alpha2: 'AT',
    Alpha3: 'AUT',
    Numeric: 40,
  },
  {
    Country: 'Azerbaijan',
    Alpha2: 'AZ',
    Alpha3: 'AZE',
    Numeric: 31,
  },
  {
    Country: 'Bahamas (the)',
    Alpha2: 'BS',
    Alpha3: 'BHS',
    Numeric: 44,
  },
  {
    Country: 'Bahrain',
    Alpha2: 'BH',
    Alpha3: 'BHR',
    Numeric: 48,
  },
  {
    Country: 'Bangladesh',
    Alpha2: 'BD',
    Alpha3: 'BGD',
    Numeric: 50,
  },
  {
    Country: 'Barbados',
    Alpha2: 'BB',
    Alpha3: 'BRB',
    Numeric: 52,
  },
  {
    Country: 'Belarus',
    Alpha2: 'BY',
    Alpha3: 'BLR',
    Numeric: 112,
  },
  {
    Country: 'Belgium',
    Alpha2: 'BE',
    Alpha3: 'BEL',
    Numeric: 56,
  },
  {
    Country: 'Belize',
    Alpha2: 'BZ',
    Alpha3: 'BLZ',
    Numeric: 84,
  },
  {
    Country: 'Benin',
    Alpha2: 'BJ',
    Alpha3: 'BEN',
    Numeric: 204,
  },
  {
    Country: 'Bermuda',
    Alpha2: 'BM',
    Alpha3: 'BMU',
    Numeric: 60,
  },
  {
    Country: 'Bhutan',
    Alpha2: 'BT',
    Alpha3: 'BTN',
    Numeric: 64,
  },
  {
    Country: 'Bolivia (Plurinational State of)',
    Alpha2: 'BO',
    Alpha3: 'BOL',
    Numeric: 68,
  },
  {
    Country: 'Bonaire, Sint Eustatius and Saba',
    Alpha2: 'BQ',
    Alpha3: 'BES',
    Numeric: 535,
  },
  {
    Country: 'Bosnia and Herzegovina',
    Alpha2: 'BA',
    Alpha3: 'BIH',
    Numeric: 70,
  },
  {
    Country: 'Botswana',
    Alpha2: 'BW',
    Alpha3: 'BWA',
    Numeric: 72,
  },
  {
    Country: 'Bouvet Island',
    Alpha2: 'BV',
    Alpha3: 'BVT',
    Numeric: 74,
  },
  {
    Country: 'Brazil',
    Alpha2: 'BR',
    Alpha3: 'BRA',
    Numeric: 76,
  },
  {
    Country: 'British Indian Ocean Territory (the)',
    Alpha2: 'IO',
    Alpha3: 'IOT',
    Numeric: 86,
  },
  {
    Country: 'Brunei Darussalam',
    Alpha2: 'BN',
    Alpha3: 'BRN',
    Numeric: 96,
  },
  {
    Country: 'Bulgaria',
    Alpha2: 'BG',
    Alpha3: 'BGR',
    Numeric: 100,
  },
  {
    Country: 'Burkina Faso',
    Alpha2: 'BF',
    Alpha3: 'BFA',
    Numeric: 854,
  },
  {
    Country: 'Burundi',
    Alpha2: 'BI',
    Alpha3: 'BDI',
    Numeric: 108,
  },
  {
    Country: 'Cabo Verde',
    Alpha2: 'CV',
    Alpha3: 'CPV',
    Numeric: 132,
  },
  {
    Country: 'Cambodia',
    Alpha2: 'KH',
    Alpha3: 'KHM',
    Numeric: 116,
  },
  {
    Country: 'Cameroon',
    Alpha2: 'CM',
    Alpha3: 'CMR',
    Numeric: 120,
  },
  {
    Country: 'Canada',
    Alpha2: 'CA',
    Alpha3: 'CAN',
    Numeric: 124,
  },
  {
    Country: 'Cayman Islands (the)',
    Alpha2: 'KY',
    Alpha3: 'CYM',
    Numeric: 136,
  },
  {
    Country: 'Central African Republic (the)',
    Alpha2: 'CF',
    Alpha3: 'CAF',
    Numeric: 140,
  },
  {
    Country: 'Chad',
    Alpha2: 'TD',
    Alpha3: 'TCD',
    Numeric: 148,
  },
  {
    Country: 'Chile',
    Alpha2: 'CL',
    Alpha3: 'CHL',
    Numeric: 152,
  },
  {
    Country: 'China',
    Alpha2: 'CN',
    Alpha3: 'CHN',
    Numeric: 156,
  },
  {
    Country: 'Christmas Island',
    Alpha2: 'CX',
    Alpha3: 'CXR',
    Numeric: 162,
  },
  {
    Country: 'Cocos (Keeling) Islands (the)',
    Alpha2: 'CC',
    Alpha3: 'CCK',
    Numeric: 166,
  },
  {
    Country: 'Colombia',
    Alpha2: 'CO',
    Alpha3: 'COL',
    Numeric: 170,
  },
  {
    Country: 'Comoros (the)',
    Alpha2: 'KM',
    Alpha3: 'COM',
    Numeric: 174,
  },
  {
    Country: 'Congo (the Democratic Republic of the)',
    Alpha2: 'CD',
    Alpha3: 'COD',
    Numeric: 180,
  },
  {
    Country: 'Congo (the)',
    Alpha2: 'CG',
    Alpha3: 'COG',
    Numeric: 178,
  },
  {
    Country: 'Cook Islands (the)',
    Alpha2: 'CK',
    Alpha3: 'COK',
    Numeric: 184,
  },
  {
    Country: 'Costa Rica',
    Alpha2: 'CR',
    Alpha3: 'CRI',
    Numeric: 188,
  },
  {
    Country: 'Croatia',
    Alpha2: 'HR',
    Alpha3: 'HRV',
    Numeric: 191,
  },
  {
    Country: 'Cuba',
    Alpha2: 'CU',
    Alpha3: 'CUB',
    Numeric: 192,
  },
  {
    Country: 'Curaçao',
    Alpha2: 'CW',
    Alpha3: 'CUW',
    Numeric: 531,
  },
  {
    Country: 'Cyprus',
    Alpha2: 'CY',
    Alpha3: 'CYP',
    Numeric: 196,
  },
  {
    Country: 'Czechia',
    Alpha2: 'CZ',
    Alpha3: 'CZE',
    Numeric: 203,
  },
  {
    Country: "Côte d'Ivoire",
    Alpha2: 'CI',
    Alpha3: 'CIV',
    Numeric: 384,
  },
  {
    Country: 'Denmark',
    Alpha2: 'DK',
    Alpha3: 'DNK',
    Numeric: 208,
  },
  {
    Country: 'Djibouti',
    Alpha2: 'DJ',
    Alpha3: 'DJI',
    Numeric: 262,
  },
  {
    Country: 'Dominica',
    Alpha2: 'DM',
    Alpha3: 'DMA',
    Numeric: 212,
  },
  {
    Country: 'Dominican Republic (the)',
    Alpha2: 'DO',
    Alpha3: 'DOM',
    Numeric: 214,
  },
  {
    Country: 'Ecuador',
    Alpha2: 'EC',
    Alpha3: 'ECU',
    Numeric: 218,
  },
  {
    Country: 'Egypt',
    Alpha2: 'EG',
    Alpha3: 'EGY',
    Numeric: 818,
  },
  {
    Country: 'El Salvador',
    Alpha2: 'SV',
    Alpha3: 'SLV',
    Numeric: 222,
  },
  {
    Country: 'Equatorial Guinea',
    Alpha2: 'GQ',
    Alpha3: 'GNQ',
    Numeric: 226,
  },
  {
    Country: 'Eritrea',
    Alpha2: 'ER',
    Alpha3: 'ERI',
    Numeric: 232,
  },
  {
    Country: 'Estonia',
    Alpha2: 'EE',
    Alpha3: 'EST',
    Numeric: 233,
  },
  {
    Country: 'Eswatini',
    Alpha2: 'SZ',
    Alpha3: 'SWZ',
    Numeric: 748,
  },
  {
    Country: 'Ethiopia',
    Alpha2: 'ET',
    Alpha3: 'ETH',
    Numeric: 231,
  },
  {
    Country: 'Falkland Islands (the) [Malvinas]',
    Alpha2: 'FK',
    Alpha3: 'FLK',
    Numeric: 238,
  },
  {
    Country: 'Faroe Islands (the)',
    Alpha2: 'FO',
    Alpha3: 'FRO',
    Numeric: 234,
  },
  {
    Country: 'Fiji',
    Alpha2: 'FJ',
    Alpha3: 'FJI',
    Numeric: 242,
  },
  {
    Country: 'Finland',
    Alpha2: 'FI',
    Alpha3: 'FIN',
    Numeric: 246,
  },
  {
    Country: 'France',
    Alpha2: 'FR',
    Alpha3: 'FRA',
    Numeric: 250,
  },
  {
    Country: 'French Guiana',
    Alpha2: 'GF',
    Alpha3: 'GUF',
    Numeric: 254,
  },
  {
    Country: 'French Polynesia',
    Alpha2: 'PF',
    Alpha3: 'PYF',
    Numeric: 258,
  },
  {
    Country: 'French Southern Territories (the)',
    Alpha2: 'TF',
    Alpha3: 'ATF',
    Numeric: 260,
  },
  {
    Country: 'Gabon',
    Alpha2: 'GA',
    Alpha3: 'GAB',
    Numeric: 266,
  },
  {
    Country: 'Gambia (the)',
    Alpha2: 'GM',
    Alpha3: 'GMB',
    Numeric: 270,
  },
  {
    Country: 'Georgia',
    Alpha2: 'GE',
    Alpha3: 'GEO',
    Numeric: 268,
  },
  {
    Country: 'Germany',
    Alpha2: 'DE',
    Alpha3: 'DEU',
    Numeric: 276,
  },
  {
    Country: 'Ghana',
    Alpha2: 'GH',
    Alpha3: 'GHA',
    Numeric: 288,
  },
  {
    Country: 'Gibraltar',
    Alpha2: 'GI',
    Alpha3: 'GIB',
    Numeric: 292,
  },
  {
    Country: 'Greece',
    Alpha2: 'GR',
    Alpha3: 'GRC',
    Numeric: 300,
  },
  {
    Country: 'Greenland',
    Alpha2: 'GL',
    Alpha3: 'GRL',
    Numeric: 304,
  },
  {
    Country: 'Grenada',
    Alpha2: 'GD',
    Alpha3: 'GRD',
    Numeric: 308,
  },
  {
    Country: 'Guadeloupe',
    Alpha2: 'GP',
    Alpha3: 'GLP',
    Numeric: 312,
  },
  {
    Country: 'Guam',
    Alpha2: 'GU',
    Alpha3: 'GUM',
    Numeric: 316,
  },
  {
    Country: 'Guatemala',
    Alpha2: 'GT',
    Alpha3: 'GTM',
    Numeric: 320,
  },
  {
    Country: 'Guernsey',
    Alpha2: 'GG',
    Alpha3: 'GGY',
    Numeric: 831,
  },
  {
    Country: 'Guinea',
    Alpha2: 'GN',
    Alpha3: 'GIN',
    Numeric: 324,
  },
  {
    Country: 'Guinea-Bissau',
    Alpha2: 'GW',
    Alpha3: 'GNB',
    Numeric: 624,
  },
  {
    Country: 'Guyana',
    Alpha2: 'GY',
    Alpha3: 'GUY',
    Numeric: 328,
  },
  {
    Country: 'Haiti',
    Alpha2: 'HT',
    Alpha3: 'HTI',
    Numeric: 332,
  },
  {
    Country: 'Heard Island and McDonald Islands',
    Alpha2: 'HM',
    Alpha3: 'HMD',
    Numeric: 334,
  },
  {
    Country: 'Holy See (the)',
    Alpha2: 'VA',
    Alpha3: 'VAT',
    Numeric: 336,
  },
  {
    Country: 'Honduras',
    Alpha2: 'HN',
    Alpha3: 'HND',
    Numeric: 340,
  },
  {
    Country: 'Hong Kong',
    Alpha2: 'HK',
    Alpha3: 'HKG',
    Numeric: 344,
  },
  {
    Country: 'Hungary',
    Alpha2: 'HU',
    Alpha3: 'HUN',
    Numeric: 348,
  },
  {
    Country: 'Iceland',
    Alpha2: 'IS',
    Alpha3: 'ISL',
    Numeric: 352,
  },
  {
    Country: 'India',
    Alpha2: 'IN',
    Alpha3: 'IND',
    Numeric: 356,
  },
  {
    Country: 'Indonesia',
    Alpha2: 'ID',
    Alpha3: 'IDN',
    Numeric: 360,
  },
  {
    Country: 'Iran (Islamic Republic of)',
    Alpha2: 'IR',
    Alpha3: 'IRN',
    Numeric: 364,
  },
  {
    Country: 'Iraq',
    Alpha2: 'IQ',
    Alpha3: 'IRQ',
    Numeric: 368,
  },
  {
    Country: 'Ireland',
    Alpha2: 'IE',
    Alpha3: 'IRL',
    Numeric: 372,
  },
  {
    Country: 'Isle of Man',
    Alpha2: 'IM',
    Alpha3: 'IMN',
    Numeric: 833,
  },
  {
    Country: 'Israel',
    Alpha2: 'IL',
    Alpha3: 'ISR',
    Numeric: 376,
  },
  {
    Country: 'Italy',
    Alpha2: 'IT',
    Alpha3: 'ITA',
    Numeric: 380,
  },
  {
    Country: 'Jamaica',
    Alpha2: 'JM',
    Alpha3: 'JAM',
    Numeric: 388,
  },
  {
    Country: 'Japan',
    Alpha2: 'JP',
    Alpha3: 'JPN',
    Numeric: 392,
  },
  {
    Country: 'Jersey',
    Alpha2: 'JE',
    Alpha3: 'JEY',
    Numeric: 832,
  },
  {
    Country: 'Jordan',
    Alpha2: 'JO',
    Alpha3: 'JOR',
    Numeric: 400,
  },
  {
    Country: 'Kazakhstan',
    Alpha2: 'KZ',
    Alpha3: 'KAZ',
    Numeric: 398,
  },
  {
    Country: 'Kenya',
    Alpha2: 'KE',
    Alpha3: 'KEN',
    Numeric: 404,
  },
  {
    Country: 'Kiribati',
    Alpha2: 'KI',
    Alpha3: 'KIR',
    Numeric: 296,
  },
  {
    Country: "Korea (the Democratic People's Republic of)",
    Alpha2: 'KP',
    Alpha3: 'PRK',
    Numeric: 408,
  },
  {
    Country: 'Korea (the Republic of)',
    Alpha2: 'KR',
    Alpha3: 'KOR',
    Numeric: 410,
  },
  {
    Country: 'Kuwait',
    Alpha2: 'KW',
    Alpha3: 'KWT',
    Numeric: 414,
  },
  {
    Country: 'Kyrgyzstan',
    Alpha2: 'KG',
    Alpha3: 'KGZ',
    Numeric: 417,
  },
  {
    Country: "Lao People's Democratic Republic (the)",
    Alpha2: 'LA',
    Alpha3: 'LAO',
    Numeric: 418,
  },
  {
    Country: 'Latvia',
    Alpha2: 'LV',
    Alpha3: 'LVA',
    Numeric: 428,
  },
  {
    Country: 'Lebanon',
    Alpha2: 'LB',
    Alpha3: 'LBN',
    Numeric: 422,
  },
  {
    Country: 'Lesotho',
    Alpha2: 'LS',
    Alpha3: 'LSO',
    Numeric: 426,
  },
  {
    Country: 'Liberia',
    Alpha2: 'LR',
    Alpha3: 'LBR',
    Numeric: 430,
  },
  {
    Country: 'Libya',
    Alpha2: 'LY',
    Alpha3: 'LBY',
    Numeric: 434,
  },
  {
    Country: 'Liechtenstein',
    Alpha2: 'LI',
    Alpha3: 'LIE',
    Numeric: 438,
  },
  {
    Country: 'Lithuania',
    Alpha2: 'LT',
    Alpha3: 'LTU',
    Numeric: 440,
  },
  {
    Country: 'Luxembourg',
    Alpha2: 'LU',
    Alpha3: 'LUX',
    Numeric: 442,
  },
  {
    Country: 'Macao',
    Alpha2: 'MO',
    Alpha3: 'MAC',
    Numeric: 446,
  },
  {
    Country: 'Madagascar',
    Alpha2: 'MG',
    Alpha3: 'MDG',
    Numeric: 450,
  },
  {
    Country: 'Malawi',
    Alpha2: 'MW',
    Alpha3: 'MWI',
    Numeric: 454,
  },
  {
    Country: 'Malaysia',
    Alpha2: 'MY',
    Alpha3: 'MYS',
    Numeric: 458,
  },
  {
    Country: 'Maldives',
    Alpha2: 'MV',
    Alpha3: 'MDV',
    Numeric: 462,
  },
  {
    Country: 'Mali',
    Alpha2: 'ML',
    Alpha3: 'MLI',
    Numeric: 466,
  },
  {
    Country: 'Malta',
    Alpha2: 'MT',
    Alpha3: 'MLT',
    Numeric: 470,
  },
  {
    Country: 'Marshall Islands (the)',
    Alpha2: 'MH',
    Alpha3: 'MHL',
    Numeric: 584,
  },
  {
    Country: 'Martinique',
    Alpha2: 'MQ',
    Alpha3: 'MTQ',
    Numeric: 474,
  },
  {
    Country: 'Mauritania',
    Alpha2: 'MR',
    Alpha3: 'MRT',
    Numeric: 478,
  },
  {
    Country: 'Mauritius',
    Alpha2: 'MU',
    Alpha3: 'MUS',
    Numeric: 480,
  },
  {
    Country: 'Mayotte',
    Alpha2: 'YT',
    Alpha3: 'MYT',
    Numeric: 175,
  },
  {
    Country: 'Mexico',
    Alpha2: 'MX',
    Alpha3: 'MEX',
    Numeric: 484,
  },
  {
    Country: 'Micronesia (Federated States of)',
    Alpha2: 'FM',
    Alpha3: 'FSM',
    Numeric: 583,
  },
  {
    Country: 'Moldova (the Republic of)',
    Alpha2: 'MD',
    Alpha3: 'MDA',
    Numeric: 498,
  },
  {
    Country: 'Monaco',
    Alpha2: 'MC',
    Alpha3: 'MCO',
    Numeric: 492,
  },
  {
    Country: 'Mongolia',
    Alpha2: 'MN',
    Alpha3: 'MNG',
    Numeric: 496,
  },
  {
    Country: 'Montenegro',
    Alpha2: 'ME',
    Alpha3: 'MNE',
    Numeric: 499,
  },
  {
    Country: 'Montserrat',
    Alpha2: 'MS',
    Alpha3: 'MSR',
    Numeric: 500,
  },
  {
    Country: 'Morocco',
    Alpha2: 'MA',
    Alpha3: 'MAR',
    Numeric: 504,
  },
  {
    Country: 'Mozambique',
    Alpha2: 'MZ',
    Alpha3: 'MOZ',
    Numeric: 508,
  },
  {
    Country: 'Myanmar',
    Alpha2: 'MM',
    Alpha3: 'MMR',
    Numeric: 104,
  },
  {
    Country: 'Namibia',
    Alpha2: 'NA',
    Alpha3: 'NAM',
    Numeric: 516,
  },
  {
    Country: 'Nauru',
    Alpha2: 'NR',
    Alpha3: 'NRU',
    Numeric: 520,
  },
  {
    Country: 'Nepal',
    Alpha2: 'NP',
    Alpha3: 'NPL',
    Numeric: 524,
  },
  {
    Country: 'Netherlands (the)',
    Alpha2: 'NL',
    Alpha3: 'NLD',
    Numeric: 528,
  },
  {
    Country: 'New Caledonia',
    Alpha2: 'NC',
    Alpha3: 'NCL',
    Numeric: 540,
  },
  {
    Country: 'New Zealand',
    Alpha2: 'NZ',
    Alpha3: 'NZL',
    Numeric: 554,
  },
  {
    Country: 'Nicaragua',
    Alpha2: 'NI',
    Alpha3: 'NIC',
    Numeric: 558,
  },
  {
    Country: 'Niger (the)',
    Alpha2: 'NE',
    Alpha3: 'NER',
    Numeric: 562,
  },
  {
    Country: 'Nigeria',
    Alpha2: 'NG',
    Alpha3: 'NGA',
    Numeric: 566,
  },
  {
    Country: 'Niue',
    Alpha2: 'NU',
    Alpha3: 'NIU',
    Numeric: 570,
  },
  {
    Country: 'Norfolk Island',
    Alpha2: 'NF',
    Alpha3: 'NFK',
    Numeric: 574,
  },
  {
    Country: 'Northern Mariana Islands (the)',
    Alpha2: 'MP',
    Alpha3: 'MNP',
    Numeric: 580,
  },
  {
    Country: 'Norway',
    Alpha2: 'NO',
    Alpha3: 'NOR',
    Numeric: 578,
  },
  {
    Country: 'Oman',
    Alpha2: 'OM',
    Alpha3: 'OMN',
    Numeric: 512,
  },
  {
    Country: 'Pakistan',
    Alpha2: 'PK',
    Alpha3: 'PAK',
    Numeric: 586,
  },
  {
    Country: 'Palau',
    Alpha2: 'PW',
    Alpha3: 'PLW',
    Numeric: 585,
  },
  {
    Country: 'Palestine, State of',
    Alpha2: 'PS',
    Alpha3: 'PSE',
    Numeric: 275,
  },
  {
    Country: 'Panama',
    Alpha2: 'PA',
    Alpha3: 'PAN',
    Numeric: 591,
  },
  {
    Country: 'Papua New Guinea',
    Alpha2: 'PG',
    Alpha3: 'PNG',
    Numeric: 598,
  },
  {
    Country: 'Paraguay',
    Alpha2: 'PY',
    Alpha3: 'PRY',
    Numeric: 600,
  },
  {
    Country: 'Peru',
    Alpha2: 'PE',
    Alpha3: 'PER',
    Numeric: 604,
  },
  {
    Country: 'Philippines (the)',
    Alpha2: 'PH',
    Alpha3: 'PHL',
    Numeric: 608,
  },
  {
    Country: 'Pitcairn',
    Alpha2: 'PN',
    Alpha3: 'PCN',
    Numeric: 612,
  },
  {
    Country: 'Poland',
    Alpha2: 'PL',
    Alpha3: 'POL',
    Numeric: 616,
  },
  {
    Country: 'Portugal',
    Alpha2: 'PT',
    Alpha3: 'PRT',
    Numeric: 620,
  },
  {
    Country: 'Puerto Rico',
    Alpha2: 'PR',
    Alpha3: 'PRI',
    Numeric: 630,
  },
  {
    Country: 'Qatar',
    Alpha2: 'QA',
    Alpha3: 'QAT',
    Numeric: 634,
  },
  {
    Country: 'Republic of North Macedonia',
    Alpha2: 'MK',
    Alpha3: 'MKD',
    Numeric: 807,
  },
  {
    Country: 'Romania',
    Alpha2: 'RO',
    Alpha3: 'ROU',
    Numeric: 642,
  },
  {
    Country: 'Russian Federation (the)',
    Alpha2: 'RU',
    Alpha3: 'RUS',
    Numeric: 643,
  },
  {
    Country: 'Rwanda',
    Alpha2: 'RW',
    Alpha3: 'RWA',
    Numeric: 646,
  },
  {
    Country: 'Réunion',
    Alpha2: 'RE',
    Alpha3: 'REU',
    Numeric: 638,
  },
  {
    Country: 'Saint Barthélemy',
    Alpha2: 'BL',
    Alpha3: 'BLM',
    Numeric: 652,
  },
  {
    Country: 'Saint Helena, Ascension and Tristan da Cunha',
    Alpha2: 'SH',
    Alpha3: 'SHN',
    Numeric: 654,
  },
  {
    Country: 'Saint Kitts and Nevis',
    Alpha2: 'KN',
    Alpha3: 'KNA',
    Numeric: 659,
  },
  {
    Country: 'Saint Lucia',
    Alpha2: 'LC',
    Alpha3: 'LCA',
    Numeric: 662,
  },
  {
    Country: 'Saint Martin (French part)',
    Alpha2: 'MF',
    Alpha3: 'MAF',
    Numeric: 663,
  },
  {
    Country: 'Saint Pierre and Miquelon',
    Alpha2: 'PM',
    Alpha3: 'SPM',
    Numeric: 666,
  },
  {
    Country: 'Saint Vincent and the Grenadines',
    Alpha2: 'VC',
    Alpha3: 'VCT',
    Numeric: 670,
  },
  {
    Country: 'Samoa',
    Alpha2: 'WS',
    Alpha3: 'WSM',
    Numeric: 882,
  },
  {
    Country: 'San Marino',
    Alpha2: 'SM',
    Alpha3: 'SMR',
    Numeric: 674,
  },
  {
    Country: 'Sao Tome and Principe',
    Alpha2: 'ST',
    Alpha3: 'STP',
    Numeric: 678,
  },
  {
    Country: 'Saudi Arabia',
    Alpha2: 'SA',
    Alpha3: 'SAU',
    Numeric: 682,
  },
  {
    Country: 'Senegal',
    Alpha2: 'SN',
    Alpha3: 'SEN',
    Numeric: 686,
  },
  {
    Country: 'Serbia',
    Alpha2: 'RS',
    Alpha3: 'SRB',
    Numeric: 688,
  },
  {
    Country: 'Seychelles',
    Alpha2: 'SC',
    Alpha3: 'SYC',
    Numeric: 690,
  },
  {
    Country: 'Sierra Leone',
    Alpha2: 'SL',
    Alpha3: 'SLE',
    Numeric: 694,
  },
  {
    Country: 'Singapore',
    Alpha2: 'SG',
    Alpha3: 'SGP',
    Numeric: 702,
  },
  {
    Country: 'Sint Maarten (Dutch part)',
    Alpha2: 'SX',
    Alpha3: 'SXM',
    Numeric: 534,
  },
  {
    Country: 'Slovakia',
    Alpha2: 'SK',
    Alpha3: 'SVK',
    Numeric: 703,
  },
  {
    Country: 'Slovenia',
    Alpha2: 'SI',
    Alpha3: 'SVN',
    Numeric: 705,
  },
  {
    Country: 'Solomon Islands',
    Alpha2: 'SB',
    Alpha3: 'SLB',
    Numeric: 90,
  },
  {
    Country: 'Somalia',
    Alpha2: 'SO',
    Alpha3: 'SOM',
    Numeric: 706,
  },
  {
    Country: 'South Africa',
    Alpha2: 'ZA',
    Alpha3: 'ZAF',
    Numeric: 710,
  },
  {
    Country: 'South Georgia and the South Sandwich Islands',
    Alpha2: 'GS',
    Alpha3: 'SGS',
    Numeric: 239,
  },
  {
    Country: 'South Sudan',
    Alpha2: 'SS',
    Alpha3: 'SSD',
    Numeric: 728,
  },
  {
    Country: 'Spain',
    Alpha2: 'ES',
    Alpha3: 'ESP',
    Numeric: 724,
  },
  {
    Country: 'Sri Lanka',
    Alpha2: 'LK',
    Alpha3: 'LKA',
    Numeric: 144,
  },
  {
    Country: 'Sudan (the)',
    Alpha2: 'SD',
    Alpha3: 'SDN',
    Numeric: 729,
  },
  {
    Country: 'Suriname',
    Alpha2: 'SR',
    Alpha3: 'SUR',
    Numeric: 740,
  },
  {
    Country: 'Svalbard and Jan Mayen',
    Alpha2: 'SJ',
    Alpha3: 'SJM',
    Numeric: 744,
  },
  {
    Country: 'Sweden',
    Alpha2: 'SE',
    Alpha3: 'SWE',
    Numeric: 752,
  },
  {
    Country: 'Switzerland',
    Alpha2: 'CH',
    Alpha3: 'CHE',
    Numeric: 756,
  },
  {
    Country: 'Syrian Arab Republic',
    Alpha2: 'SY',
    Alpha3: 'SYR',
    Numeric: 760,
  },
  {
    Country: 'Taiwan (Province of China)',
    Alpha2: 'TW',
    Alpha3: 'TWN',
    Numeric: 158,
  },
  {
    Country: 'Tajikistan',
    Alpha2: 'TJ',
    Alpha3: 'TJK',
    Numeric: 762,
  },
  {
    Country: 'Tanzania, United Republic of',
    Alpha2: 'TZ',
    Alpha3: 'TZA',
    Numeric: 834,
  },
  {
    Country: 'Thailand',
    Alpha2: 'TH',
    Alpha3: 'THA',
    Numeric: 764,
  },
  {
    Country: 'Timor-Leste',
    Alpha2: 'TL',
    Alpha3: 'TLS',
    Numeric: 626,
  },
  {
    Country: 'Togo',
    Alpha2: 'TG',
    Alpha3: 'TGO',
    Numeric: 768,
  },
  {
    Country: 'Tokelau',
    Alpha2: 'TK',
    Alpha3: 'TKL',
    Numeric: 772,
  },
  {
    Country: 'Tonga',
    Alpha2: 'TO',
    Alpha3: 'TON',
    Numeric: 776,
  },
  {
    Country: 'Trinidad and Tobago',
    Alpha2: 'TT',
    Alpha3: 'TTO',
    Numeric: 780,
  },
  {
    Country: 'Tunisia',
    Alpha2: 'TN',
    Alpha3: 'TUN',
    Numeric: 788,
  },
  {
    Country: 'Turkey',
    Alpha2: 'TR',
    Alpha3: 'TUR',
    Numeric: 792,
  },
  {
    Country: 'Turkmenistan',
    Alpha2: 'TM',
    Alpha3: 'TKM',
    Numeric: 795,
  },
  {
    Country: 'Turks and Caicos Islands (the)',
    Alpha2: 'TC',
    Alpha3: 'TCA',
    Numeric: 796,
  },
  {
    Country: 'Tuvalu',
    Alpha2: 'TV',
    Alpha3: 'TUV',
    Numeric: 798,
  },
  {
    Country: 'Uganda',
    Alpha2: 'UG',
    Alpha3: 'UGA',
    Numeric: 800,
  },
  {
    Country: 'Ukraine',
    Alpha2: 'UA',
    Alpha3: 'UKR',
    Numeric: 804,
  },
  {
    Country: 'United Arab Emirates (the)',
    Alpha2: 'AE',
    Alpha3: 'ARE',
    Numeric: 784,
  },
  {
    Country: 'United Kingdom of Great Britain and Northern Ireland (the)',
    Alpha2: 'GB',
    Alpha3: 'GBR',
    Numeric: 826,
  },
  {
    Country: 'United States Minor Outlying Islands (the)',
    Alpha2: 'UM',
    Alpha3: 'UMI',
    Numeric: 581,
  },
  {
    Country: 'United States of America (the)',
    Alpha2: 'US',
    Alpha3: 'USA',
    Numeric: 840,
  },
  {
    Country: 'Uruguay',
    Alpha2: 'UY',
    Alpha3: 'URY',
    Numeric: 858,
  },
  {
    Country: 'Uzbekistan',
    Alpha2: 'UZ',
    Alpha3: 'UZB',
    Numeric: 860,
  },
  {
    Country: 'Vanuatu',
    Alpha2: 'VU',
    Alpha3: 'VUT',
    Numeric: 548,
  },
  {
    Country: 'Venezuela (Bolivarian Republic of)',
    Alpha2: 'VE',
    Alpha3: 'VEN',
    Numeric: 862,
  },
  {
    Country: 'Viet Nam',
    Alpha2: 'VN',
    Alpha3: 'VNM',
    Numeric: 704,
  },
  {
    Country: 'Virgin Islands (British)',
    Alpha2: 'VG',
    Alpha3: 'VGB',
    Numeric: 92,
  },
  {
    Country: 'Virgin Islands (U.S.)',
    Alpha2: 'VI',
    Alpha3: 'VIR',
    Numeric: 850,
  },
  {
    Country: 'Wallis and Futuna',
    Alpha2: 'WF',
    Alpha3: 'WLF',
    Numeric: 876,
  },
  {
    Country: 'Western Sahara',
    Alpha2: 'EH',
    Alpha3: 'ESH',
    Numeric: 732,
  },
  {
    Country: 'Yemen',
    Alpha2: 'YE',
    Alpha3: 'YEM',
    Numeric: 887,
  },
  {
    Country: 'Zambia',
    Alpha2: 'ZM',
    Alpha3: 'ZMB',
    Numeric: 894,
  },
  {
    Country: 'Zimbabwe',
    Alpha2: 'ZW',
    Alpha3: 'ZWE',
    Numeric: 716,
  },
  {
    Country: 'Åland Islands',
    Alpha2: 'AX',
    Alpha3: 'ALA',
    Numeric: 248,
  },
];

export const alpha2List = countryCodes.map((country) => country.Alpha2);

export default alpha2List;
