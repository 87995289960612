import React, { useState } from 'react';
import {
  Button,
  Card,
  CopyButton,
  Divider,
  Flex,
  Paper,
  Stack,
  Text,
  Title,
  Tooltip,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { useGLGOParams } from 'hooks';
import { MdContentCopy, MdHelp } from 'react-icons/md';
import { useCurrentProjectData } from 'store';
import { Link } from 'react-router-dom';
import EditDomainForm from './EditDomainForm';
import { GLWEB_SUPPORT_URL } from 'helpers';

export const Injection = () => {
  const { colors } = useMantineTheme();
  const { projectKey } = useGLGOParams();
  const { project: { origin_name = '' } = {} } = useCurrentProjectData();

  const [originName, setOriginName] = useState(origin_name);

  const previewDomain = originName
    ? `https://${originName}?glgo_preview=1`
    : '';

  const longCode = `
    <script referrerPolicy="no-referrer-when-downgrade"
    type="text/javascript" src="https://www.onelink-edge.com/moxie.min.js"
    data-oljs="P${projectKey}"></script>
  `;

  return (
    <>
      <Card
        w="100%"
        maw="44rem"
        p={{ base: '1.25rem', md: '1.875rem' }}
        mt={16}
      >
        <Stack gap="1.5rem">
          <Title order={1}>Injection Details</Title>
          <Divider color={colors.divider[1]} size="xs" />
          <Paper withBorder w="100%" py="1rem" radius="lg" shadow="none">
            <EditDomainForm setOriginName={setOriginName} />
            <Divider w="100%" my="0.5rem" size="xs" />
            <Flex
              py="1rem"
              px={{ base: '1rem', sm: '2rem' }}
              direction="column"
              rowGap="1.5rem"
            >
              <Flex rowGap="1rem" direction="column">
                <Title order={3} tt="uppercase">
                  Snippet
                </Title>
                <Paper
                  withBorder
                  p="1.75rem"
                  radius="lg"
                  shadow="none"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text
                    fw={600}
                    fz="xs"
                    maw={410}
                    mr="1rem"
                    data-testid="pw-project-settings-snippet"
                    style={{ wordBreak: 'break-all' }}
                  >
                    {longCode}
                  </Text>
                  <CopyButton value={longCode} timeout={2000}>
                    {({ copied, copy }) => (
                      <Tooltip label={copied ? 'Copied!' : 'Copy'}>
                        <UnstyledButton onClick={copy}>
                          <MdContentCopy fill={colors.icon[0]} size={24} />
                        </UnstyledButton>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Paper>
              </Flex>
              <Flex
                align={{ base: 'start', xs: 'center' }}
                direction={{ base: 'column', xs: 'row' }}
                justify={{ base: 'start', xs: 'space-between' }}
              >
                <Title order={3} tt="uppercase">
                  Private Website Preview
                </Title>
                <Link
                  style={{ alignSelf: 'flex-end' }}
                  target="_blank"
                  to={`${GLWEB_SUPPORT_URL}/article/141-what-is-private-mode`}
                >
                  <Button
                    px={0}
                    variant="transparent"
                    rightSection={<MdHelp fill={colors.icon[0]} size={18} />}
                  >
                    Need Help?
                  </Button>
                </Link>
              </Flex>
              <Text>
                Go to your private website preview. Remember, your translations
                will not be publicly visible until you’re ready. Go to your
                webpage using the link below and confirm that the language
                selector appears on your site.
              </Text>

              <Link
                target="_blank"
                to={previewDomain}
                style={{ alignSelf: 'center' }}
              >
                <Button w="8rem">Preview</Button>
              </Link>

              <Text>
                Once you see the selector, choose from your available languages
                and the page you’re on will be translated instantly. You can
                edit translations and adjust other settings from your dashboard.
                <Text fw={700} span>
                  {' '}
                  NOTE: You can add /?glgo_preview=1 to the end of any url to
                  enable preview mode.
                </Text>
              </Text>
            </Flex>
          </Paper>
        </Stack>
      </Card>
    </>
  );
};

export default Injection;
