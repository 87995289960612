import { create } from 'zustand';
import { xapisEnv } from './service-wrappers/xapisService';

export type PayKeyStore = {
  payKey: string;
  setPayKey: (payKey: string) => void;
};

export const usePayKeyStore = create<PayKeyStore>()((set) => {
  const { payKey } = xapisEnv.getHost;
  return {
    payKey,
    setPayKey: (payKey) => set(() => ({ payKey })),
  };
});

export default usePayKeyStore;
