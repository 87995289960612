import React from 'react';
import {
  Box,
  Button,
  Flex,
  Paper,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import {
  calculateEstimatedWordsServed,
  calculateMonthlyPageViews,
  getRecommendedPlan,
  getRemainingSubscribedWordsServed,
  getRemainingSubscriptionDays,
} from 'helpers';
import LoadingSkeleton from '../../godashboard/LoadingSkeleton';
import { QuickQuoteResponse, SkuWCreatedAndLastMod, xapisEnv } from 'store';

type Props = {
  nextRenewalDate: string;
  wordsServedUsed: string | number;
  skus: SkuWCreatedAndLastMod[];
  translatedTraffic: number;
  loading: boolean;
  isExpired: boolean;
  isQuickQuoteCached?: boolean;
  quickQuote: QuickQuoteResponse;
};
const FreeTrialInformation = ({
  nextRenewalDate,
  wordsServedUsed,
  skus,
  translatedTraffic,
  isQuickQuoteCached,
  loading,
  isExpired,
  quickQuote,
}: Props) => {
  const { colors } = useMantineTheme();
  const {
    spider_job: { average_words_per_page = 0 } = {},
    traffic: { results: { visits = '0', pages_per_visit = '0' } = {} } = {},
  } = quickQuote || {};

  const monthlyPageViews = calculateMonthlyPageViews(visits, pages_per_visit);
  const estimatedWordsServed = calculateEstimatedWordsServed(
    translatedTraffic,
    average_words_per_page,
    monthlyPageViews
  );
  const {
    name = '',
    threshold_value = 0,
    sku_type = 'NORMAL',
  } = getRecommendedPlan(estimatedWordsServed, skus);

  const remainingDays = getRemainingSubscriptionDays(nextRenewalDate);
  const remainingWordsServed = getRemainingSubscribedWordsServed(
    isExpired ? undefined : Number(wordsServedUsed)
  );

  const isEnterprisePlan = sku_type === 'ENTERPRISE';
  const upgradeRoute = `manage-subscription?threshold=${threshold_value}`;
  const { glgoMarketingUrl = '' } = xapisEnv.getHost;
  const contactUsUrl = `${glgoMarketingUrl}/about-us#contact-us-form`;

  return (
    <Flex w="100%" h="100%" rowGap="1rem" direction="column" justify="center">
      <Flex mih="2.25rem" align="center">
        <Title order={3} tt="uppercase">
          Current Plan
        </Title>
      </Flex>
      <Paper
        py="2rem"
        px="1.5rem"
        style={{
          flex: 1,
          borderRadius: 12,
          border: `1px solid ${colors.border[1]}`,
          boxShadow: 'none',
        }}
      >
        <Flex direction="column" rowGap="1.5rem">
          <Text>
            {isExpired
              ? 'Your free trial has expired.'
              : `You are currently enjoying a Free Trial, which will expire after
            500,000 words served or 30 days, whichever comes first`}
          </Text>
          <Box w="65%">
            <Flex justify="space-between" columnGap="1rem">
              <Text fw={700}>Days Remaining:</Text>
              <Text>{remainingDays}</Text>
            </Flex>
            <Flex justify="space-between" columnGap="1rem" align="center">
              <Text fw={700}>Words Served Remaining:</Text>
              <Text>{remainingWordsServed.toLocaleString()}</Text>
            </Flex>
          </Box>
          <Flex
            mih={90}
            direction="column"
            rowGap={{ base: '1rem', xs: '1.5rem' }}
          >
            {loading ? (
              <LoadingSkeleton />
            ) : isQuickQuoteCached ? (
              <>
                <Text>
                  Based on our scan of your website, here’s what we’d recommend:
                </Text>
                <Flex
                  justify="space-between"
                  align="center"
                  gap="0.75rem"
                  wrap="wrap"
                >
                  <Text fz="lg" fw={700}>
                    {name}
                  </Text>
                  <Link
                    to={isEnterprisePlan ? contactUsUrl : upgradeRoute}
                    target={isEnterprisePlan ? '_blank' : '_self'}
                  >
                    <Button variant="light">
                      {isEnterprisePlan ? 'Contact Us' : 'Upgrade'}
                    </Button>
                  </Link>
                </Flex>
              </>
            ) : null}
          </Flex>
        </Flex>
      </Paper>
    </Flex>
  );
};

export default FreeTrialInformation;
