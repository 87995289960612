import {
  LanguageSelectorTheme,
  SelectorDefault,
} from '../../gosetup/LanguageSelectorAppearances';
import { ColorInput, Flex, Select, Stack } from '@mantine/core';
import React, { useEffect, useState } from 'react';

export const DARK_MODE_THEME = '#000000_#FFFFFF_#00000080';
export const LIGHT_MODE_THEME = '#FFFFFF_#000000_#FFFFFF80';

const themePresets = [
  {
    label: 'Dark Mode',
    value: DARK_MODE_THEME,
  },
  {
    label: 'Light Mode',
    value: LIGHT_MODE_THEME,
  },
  {
    label: 'GlobalLink',
    value: '#071D49_#FFFFFF_#8BC2FF',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];

const getThemeColors = (
  theme: string | null | undefined,
  selectedPreset: SelectorDefault
) => {
  const colorTheme = theme === 'custom' ? selectedPreset.theme : theme;
  const [selectorColor, textColor, backgroundColor] =
    colorTheme?.split('_') ?? [];

  return {
    selector: selectorColor ?? '#000000',
    text: textColor ?? '#FFFFFF',
    background: backgroundColor ?? '#00000080',
  };
};

const CustomThemeButton = ({
  changeTheme,
  currentPreset,
  selectedPreset,
}: {
  changeTheme: (theme: LanguageSelectorTheme) => void;
  currentPreset: SelectorDefault;
  selectedPreset: SelectorDefault;
}) => {
  const [colorTheme, setColorTheme] = useState(
    getThemeColors(currentPreset.theme, currentPreset)
  );

  const [themePreset, setThemePreset] = useState<string | null | undefined>(
    themePresets.find((theme) => theme.value === currentPreset.theme)?.value ??
      DARK_MODE_THEME
  );

  useEffect(() => {
    setThemePreset(currentPreset.theme ?? DARK_MODE_THEME);
  }, [currentPreset]);

  useEffect(() => {
    //handle cancel button press
    if (selectedPreset === currentPreset) {
      setColorTheme(getThemeColors(selectedPreset.theme, currentPreset));
      if (!selectedPreset.theme) {
        setThemePreset(DARK_MODE_THEME);
      } else {
        setThemePreset(
          themePresets.find((theme) => theme.value === selectedPreset.theme)
            ?.value ?? 'custom'
        );
      }
    }
  }, [selectedPreset, currentPreset]);

  return (
    <Flex align="center" justify="center" gap={20}>
      <Stack>
        {Object.entries(colorTheme).map(([key, color]) => (
          <ColorInput
            key={key}
            label={`${key} Color`}
            onChange={(newColor) => {
              const changedTheme = { ...colorTheme, [key]: newColor };
              const { selector, text, background } = changedTheme;
              changeTheme(selector + '_' + text + '_' + background);
              setColorTheme((theme) => ({ ...theme, [key]: newColor }));
              setThemePreset('custom');
            }}
            value={color}
            format="hexa"
            style={{ textTransform: 'capitalize' }}
          />
        ))}
      </Stack>
      <Select
        label="Select a Theme"
        data={themePresets}
        onChange={(theme) => {
          setThemePreset(theme);
          const changedTheme = getThemeColors(theme, selectedPreset);
          setColorTheme(changedTheme);
          const { selector, text, background } = changedTheme;
          changeTheme(selector + '_' + text + '_' + background);
        }}
        value={themePreset}
        allowDeselect={false}
      />
    </Flex>
  );
};

export default CustomThemeButton;
