import React, { useEffect, useMemo, useState } from 'react';
import { Loader } from '@mantine/core';
import { failure, isSuccessStatus } from 'helpers';
import {
  PaymentSource,
  addPaymentPromise,
  useUserProvider,
  xapisEnv,
} from 'store';
import { useRevalidator } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import CustomModal from '../../../CustomModal';

type Props = {
  opened: boolean;
  onClose: () => void;
  cards: PaymentSource[];
  setCards: React.Dispatch<React.SetStateAction<PaymentSource[]>>;
  sources: PaymentSource[];
  default_source_id: string;
};

const NewPaymentModal = ({
  opened,
  onClose,
  cards,
  setCards,
  sources,
  default_source_id,
}: Props) => {
  const dropInId = 'new-payment-modal-drop-in';
  const owner = sources.find((s) => s.id === default_source_id)?.owner;
  const { user_key, phone } = useUserProvider().xapisUser;
  const revalidator = useRevalidator();

  const { digitalRiverKey } = xapisEnv.getHost;

  const [isNewPaymentFormMounted, setIsNewPaymentFormMounted] = useState(false);

  const addPaymentConfig = useMemo(
    () => ({
      billingAddress: {
        ...owner,
        phoneNumber: phone,
      },
      options: {
        flow: 'managePaymentMethods',
        usage: 'subscription',
        showTermsOfSaleDisclosure: true,
      },
      paymentMethodConfiguration: {
        creditCard: {
          cardNumberPlaceholderText: 'Credit Card Number',
          cardExpirationPlaceholderText: 'MM/YY',
          cardCvvPlaceholderText: 'CVV',
          style: {
            base: {
              color: 'black',
            },
            mask: true,
          },
        },
        enabledPaymentMethods: ['creditCard'],
        // As of 11/3/2023, DR's implementation of PayPal is broken, returning either a CORS error or a 400 "missing_parameter" error
        disabledPaymentMethods: ['payPalBilling'],
      },
      onSuccess: ({ source }: { source: { id: string } }) => {
        const { payKey } = xapisEnv.getHost;
        const { id } = source;
        addPaymentPromise(payKey, user_key, id)
          .then(({ data, status }) => {
            if (isSuccessStatus(status)) {
              notifications.show({
                message: 'Successfully added your new payment method!',
              });
              setCards([...cards, data]);
              revalidator.revalidate();
            }
          })
          .catch((e) => {
            console.error(e);
            notifications.show({ message: 'Whoops, something went wrong.' });
          })
          .finally(() => onClose());
      },
      onError: ({ errors }: { errors: Array<{ message: string }> }) => {
        errors.forEach(({ message }) => failure(message));
      },
    }),
    [owner, phone, user_key, setCards, cards, revalidator, onClose]
  );

  const mountNewPaymentMethod = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const digitalRiver = new DigitalRiver(digitalRiverKey);
    try {
      // Creates and opens the edit payment modal
      const dropin = digitalRiver.createDropin(addPaymentConfig);
      dropin.mount(dropInId).then(() => setIsNewPaymentFormMounted(true));
    } catch (error) {
      failure(error);
    }
  };

  const NewPaymentForm = () => {
    // We need this useEffect on component mount to avoid race conditions with the dropin.mount
    useEffect(() => {
      mountNewPaymentMethod();
    }, []);

    return (
      <div
        id={dropInId}
        style={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!isNewPaymentFormMounted && <Loader />}
      </div>
    );
  };

  return (
    <CustomModal
      flex
      size="lg"
      minHeight="100%"
      opened={opened}
      onClose={onClose}
    >
      <NewPaymentForm />
    </CustomModal>
  );
};

export default NewPaymentModal;
