import { createTheme, ThemeOptions } from '@mui/material';
import getNodeProperties from '../../utils/getNodeProperties';
export const exclusionTheme = createTheme({
  components: {
    MuiTreeItem: {
      styleOverrides: {
        label: ({ ownerState }: { ownerState: Record<string, string> }) => {
          const nodeInfoJSON =
            (ownerState && (ownerState['data-nodeinfo'] as string)) || '{}';
          const { hasChildren, id, isExcluded } =
            getNodeProperties(nodeInfoJSON);

          return {
            fontSize: '0.875rem',
            color: isExcluded
              ? '#D93025'
              : hasChildren || id === '0'
                ? '#334552'
                : '#006CD1',
            fontFamily: 'inherit',
            fontStyle: isExcluded ? 'italic' : 'normal',
            textDecoration: isExcluded ? 'line-through' : 'none',
          };
        },
        root: {
          padding: 0,
        },
        content: ({ ownerState }: { ownerState: Record<string, string> }) => {
          const nodeInfoJSON =
            (ownerState && (ownerState['data-nodeinfo'] as string)) || '{}';
          const { depth, additionalPadding } = getNodeProperties(nodeInfoJSON);
          return {
            fontSize: '0.875rem',
            color: '#334552',
            padding: `1rem 1rem 1rem ${depth === 1 ? '1rem' : `${Number(depth) * 1.5 + additionalPadding}rem`}`,
            backgroundColor: 'transparent',
            borderBottom: '0.5px solid #CBD1F9',
            borderTop: '0.5px solid #CBD1F9',
            '&.Mui-selected.Mui-focused': {
              backgroundColor: '#FFF',
            },
            '&:hover': {
              backgroundColor: '#FFF',
            },
          };
        },
        group: {
          marginLeft: 0,
        },
        iconContainer: {
          margin: '0 1rem',
          size: 24,
          width: 'fit-content',
        },
      },
    },
  },
} as ThemeOptions);

export default exclusionTheme;
