import React, { useState } from 'react';
import { Box, Button, Card, Divider, Flex, Text, Title } from '@mantine/core';
import { useSetupStepsContext } from './ProjectSetupProvider';
import BrowserWindow from './BrowserWindow';
import { ProjectStore, useProjectsStore } from 'store';
import { MdCheckCircle, MdOutlineSync } from 'react-icons/md';
import cheet from 'cheet.js';
import { useDisclosure } from '@mantine/hooks';
import { MobileActionHeader } from './MobileActionHeader';
import useInjectionStatus from './hooks/useInjectionStatus';
import { encodeProjectKey } from 'helpers';
import SkipInjectionModal from './SkipInjectionModal';
import fathomEventTracker from '../../fathomAnalytics';
import CustomModal from '../../CustomModal';
import classes from './PreviewStep.module.css';

export const PreviewSideMenuContent = () => {
  const { setActiveStep, isNextButtonDisabled } = useSetupStepsContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { activeProject } = useProjectsStore((state: ProjectStore) => state);
  const { projectKey = '' } = activeProject || {};
  const encodedProjectKey = encodeProjectKey(projectKey);

  return (
    <>
      <SkipInjectionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        encodedProjectKey={encodedProjectKey}
      />
      <Flex
        w="100%"
        maw={480}
        rowGap="1.5rem"
        direction="column"
        justify="center"
        align="center"
      >
        <Title fz="2rem" order={1}>
          Preview your site
        </Title>
        <Divider w="100%" maw={450} color="divider.0" size={2} />
        <Text fz="lg" fw={500} ta="center">
          <Text fz="inherit" fw={700} span>
            NOTE:
          </Text>{' '}
          To see any page of your site in preview mode, just add{' '}
          <Text fz="inherit" fw={700} span>
            /?glgo_preview=1
          </Text>{' '}
          to the end of the url.
        </Text>
        <Flex columnGap="1rem">
          <Button
            variant="outline"
            onClick={() => setActiveStep((prevStep) => prevStep - 1)}
          >
            Back
          </Button>
          <Button
            disabled={isNextButtonDisabled}
            onClick={() => {
              fathomEventTracker('Preview Complete - 6');
              setActiveStep((prevStep) => prevStep + 1);
            }}
          >
            <Text c="inherit" fz="inherit" fw="inherit" id="preview-complete-6">
              Next
            </Text>
          </Button>
        </Flex>
        <Text
          id="preview-site-skip-6"
          onClick={() => {
            fathomEventTracker('Preview Site Skip - 6');
            setIsModalOpen(true);
          }}
          style={{ cursor: 'pointer' }}
          c="text.8"
          td="underline"
          fz="md"
          fw={600}
        >
          Skip for now.
        </Text>
      </Flex>
    </>
  );
};

export const PreviewBodyContent = () => {
  const { activeProject, updateProject } = useProjectsStore(
    (state: ProjectStore) => state
  );
  const { originName: activeDomain = '' } = activeProject;
  const previewDomain = activeDomain ? `//${activeDomain}?glgo_preview=1` : '';

  const [opened, { open, close }] = useDisclosure(false);
  const [isInjected] = useInjectionStatus();

  const { projectKey: activeProjectKey = '' } = activeProject || {};
  const handleSnippetOverride = () => {
    updateProject(activeProjectKey, { project_status: 'VERIFIED' });
    close();
  };

  (cheet as unknown as (str: string, cb: () => void) => void)(
    'ctrl x a p i s',
    () => {
      open();
    }
  );

  return (
    <Box px="2rem">
      <CustomModal
        title="So You Decided to Cheat..."
        opened={opened}
        onClose={close}
        footerActions={[
          { label: 'Bypass Snippet Check', action: handleSnippetOverride },
        ]}
      />
      <BrowserWindow>
        <Flex w="100%" direction="column" rowGap="1rem">
          <Text fz="lg">
            Next, click the “Preview” button to go to your private website
            preview. Remember, your translations will not be publicly visibly
            until you’re ready.
          </Text>
          <Button
            w="8rem"
            mx="auto"
            onClick={() => {
              fathomEventTracker('Preview Site - 6');
              if (previewDomain) {
                window.open(previewDomain);
              }
            }}
          >
            <Text c="inherit" fz="inherit" fw="inherit" id="preview-site-6">
              Preview
            </Text>
          </Button>
          <Text fz="lg">
            Once you see the selector, choose from your available languages and
            the page you’re on will be translated instantly. You can edit
            translations and adjust other settings from your dashboard.
          </Text>
          <Flex
            className={classes.injectStatusBox}
            p={20}
            align="center"
            justify="space-between"
            pos="relative"
            columnGap={10}
          >
            {!isInjected ? (
              <Text fw={700}>Waiting to detect your website...</Text>
            ) : (
              <Text fw={700}>Success! You are ready to GO global!</Text>
            )}
            {!isInjected ? (
              <MdOutlineSync size={20} className={classes.loadingIcon} />
            ) : (
              <MdCheckCircle size={20} color="#008535" />
            )}
          </Flex>
        </Flex>
      </BrowserWindow>
    </Box>
  );
};

export const MobilePreviewStep = () => {
  const { setActiveStep, isNextButtonDisabled } = useSetupStepsContext();
  const { activeProject } = useProjectsStore((state: ProjectStore) => state);
  const { originName: activeDomain = '' } = activeProject;
  const previewDomain = activeDomain ? `//${activeDomain}?glgo_preview=1` : '';
  const { projectKey } = activeProject;
  const encodedProjectKey = encodeProjectKey(projectKey);

  const [isInjected] = useInjectionStatus();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <SkipInjectionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        encodedProjectKey={encodedProjectKey}
      />
      <Card
        w="100%"
        maw={{ base: '100%', xs: 650, lg: 450 }}
        px="1rem"
        py="2rem"
      >
        <Flex
          w="100%"
          h="100%"
          direction="column"
          align="center"
          rowGap="1.5rem"
        >
          <MobileActionHeader title="Add Code Snippet" />
          <Divider w="100%" color="divider.0" size={2} />
          <Text fz="md" fw={500}>
            Next, click the “Preview” button to go to your private website
            preview. Remember, your translations will not be publicly visibly
            until you’re ready.
          </Text>
          <Button
            w="8rem"
            mx="auto"
            onClick={() => {
              fathomEventTracker('Preview Site - 6');
              if (previewDomain) {
                window.open(previewDomain);
              }
            }}
          >
            <Text c="inherit" fz="inherit" fw="inherit" id="preview-site-6">
              Preview
            </Text>
          </Button>
          <Text fz="md" fw={500}>
            Once you see the selector, choose from your available languages and
            the page you’re on will be translated instantly. You can edit
            translations and adjust other settings from your dashboard.
          </Text>
          <Flex
            className={classes.injectStatusBox}
            w="100%"
            p={20}
            align="center"
            justify="space-between"
            pos="relative"
            columnGap={10}
          >
            {!isInjected ? (
              <Text fw={700}>Waiting to detect your website...</Text>
            ) : (
              <Text fw={700}>Success! You are ready to GO global!</Text>
            )}
            {!isInjected ? (
              <MdOutlineSync size={20} className={classes.loadingIcon} />
            ) : (
              <MdCheckCircle size={20} color="#008535" />
            )}
          </Flex>
          <Divider w="100%" color="divider.0" size={2} />
          <Button
            w={{ base: '100%', xs: 'auto' }}
            disabled={isNextButtonDisabled}
            onClick={() => {
              fathomEventTracker('Preview Complete - 6');
              setActiveStep((prevStep) => prevStep + 1);
            }}
          >
            <Text c="inherit" fz="inherit" fw="inherit" id="preview-complete-6">
              Next
            </Text>
          </Button>
          <Text
            id="preview-site-skip-6"
            onClick={() => {
              fathomEventTracker('Preview Site Skip - 6');
              setIsModalOpen(true);
            }}
            style={{ cursor: 'pointer' }}
            c="text.8"
            td="underline"
            fw={600}
          >
            Skip for now.
          </Text>
        </Flex>
      </Card>
    </>
  );
};
