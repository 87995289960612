import { SpiderTreeNode } from './SpiderTreeNode';
import useProjectsStore from './ProjectsStore';
import { hexToObject } from 'helpers';

export const filterCrawlerChildren = (node: SpiderTreeNode, offset: number) => {
  if (node.has_children) {
    return {
      children: [
        {
          label: 'HAS_CHILDREN',
          name: 'fetch',
          parentStringKey: node.stringKey,
          offset,
          key: `${node.stringKey}-${offset}`,
          id: `${node.stringKey}-${offset}`,
          stringKey: `${node.stringKey}-${offset}`,
        },
      ],
      ...node,
    };
  }
  return { ...node };
};

export const filterCrawlerTree = (node: SpiderTreeNode, index: number) => {
  const newNode = filterCrawlerChildren(node, index);
  return { ...newNode, key: `${index}`, stringKey: `${index}` };
};

export const filterNodes = (nodes: SpiderTreeNode[], id: string) => {
  const { activeTranslationConfig } = useProjectsStore.getState();
  const config = hexToObject(activeTranslationConfig);
  const { spider_rules: { exclude_uris: excludeRules = [] } = {} } = config;
  return nodes.map((node, index) => {
    let isExcluded = false;
    if (excludeRules.length > 0) {
      excludeRules.forEach((rule: string) => {
        const nodeString = node.is_fake ? node.label : node.content_url_orig;
        if (new RegExp(rule).test(nodeString)) {
          isExcluded = true;
        }
      });
    }
    if (node.has_children) {
      return {
        ...node,
        name: node.content_url_orig,
        key: `${id}-${index}`,
        id: `${id}-${index}`,
        isExcluded: isExcluded,
        stringKey: `${id}-${index}`,
        children: [
          {
            label: 'HAS_CHILDREN',
            key: `${id}-${index}-0`,
          },
        ],
        children_fetched: false,
      };
    }
    return {
      ...node,
      // name: node.content_url_orig,
      key: `${id}-${index}`,
      id: `${id}-${index}`,
      isExcluded: isExcluded,
      stringKey: `${id}-${index}`,
      children_fetched: true,
    };
  });
};

/*
const removeLast = (stringToManipulate: string, what: string) => {
  const pcs = stringToManipulate.split(what);
  pcs.pop();
  return pcs.join(what);
};

export const FilterMoreNodes = (nodes: SpiderTreeNode[], parentData: SpiderTreeNode) => {
  const parentStringKey = removeLast(parentData.stringKey, '-');
  return nodes.map((node, index) => {
    let { content_url_hash } = node;
    if (content_url_hash === '') {
      content_url_hash = uuidv1();
    }

    if (node.has_children) {
      return {
        ...node,
        key: `${parentStringKey}-${index + parentData.key}`,
        stringKey: `${parentStringKey}-${index + parentData.key}`,
        children: [{ key: `${parentStringKey}-${index + parentData.key}-0`, label: 'HAS_CHILDREN', content_url_hash: uuidv1() }],
        content_url_hash
      };
    }
    return {
      ...node,
      key: `${parentStringKey}-${index}`,
      stringKey: `${parentStringKey}-${index}`,
      content_url_hash
    };
  });
};
*/

// filter fn running on initial tree render
// maps over root node
export const filterRootTree = (tree: SpiderTreeNode[]) =>
  tree.map(
    (node, index) =>
      ({
        ...node,
        id: `${index}`,
        stringKey: `${index}`,
        name: node.label,
        children_fetched: false,
      }) as SpiderTreeNode
  );

export const updateChildren = (
  children: SpiderTreeNode[],
  stringKey: string,
  crawlerTree: SpiderTreeNode[]
): SpiderTreeNode[] => {
  const activeTranslationConfig =
    useProjectsStore.getState().activeTranslationConfig;
  const config = hexToObject(activeTranslationConfig);
  const { exclude_uris: excludeRules } = config.spider_rules;

  return crawlerTree.map((node) => {
    let isExcluded = false;
    if (excludeRules.length > 0) {
      // if page doesn't exist, default to node label since in that case node has no original url
      // NB: is_fake tracks whether page exists or no
      excludeRules.forEach((rule: string) => {
        const nodeString = node.is_fake ? node.label : node.content_url_orig;
        if (new RegExp(rule).test(nodeString)) {
          isExcluded = true;
        }
      });
    }
    if (node.stringKey === stringKey) {
      return {
        ...node,
        children_fetched: true,
        isExcluded: isExcluded,
        children: [...children],
      };
    }
    if (node.has_children && node.children) {
      return {
        ...node,
        children: mergeChildren(children, stringKey, node.children),
        isExcluded: isExcluded,
      };
    }
    return node;
  });
};

const findNode = (node: SpiderTreeNode, stringKey: string) => {
  if (node.stringKey === stringKey) {
    return node;
  } else {
    if (node.children) {
      node.children.forEach((child) => {
        findNode(child, stringKey);
      });
    }
  }
};

export const updateNodeInTree = (
  itemInfo: string,
  crawlerTree: SpiderTreeNode[]
) => {
  const activeTranslationConfig =
    useProjectsStore.getState().activeTranslationConfig;
  const config = hexToObject(activeTranslationConfig);
  const { exclude_uris: excludeRules } = config.spider_rules;

  const { id: stringKey } = JSON.parse(itemInfo);

  return crawlerTree.map((node) => {
    let isExcluded = false;
    if (excludeRules.length > 0) {
      // if page doesn't exist, default to node label since in that case node has no original url
      // NB: is_fake tracks whether page exists or no
      excludeRules.forEach((rule: string) => {
        const nodeString = node.is_fake ? node.label : node.content_url_orig;
        if (new RegExp(rule).test(nodeString)) {
          isExcluded = true;
        }
      });
    }

    if (node.stringKey === stringKey) {
      return { ...node, isExcluded };
    }

    return { ...findNode(node, stringKey), isExcluded };
  });
};

export const mergeChildren = (
  children: SpiderTreeNode[],
  stringKey: string,
  crawlerTree: SpiderTreeNode[]
): SpiderTreeNode[] =>
  crawlerTree.map((node) => {
    if (node.stringKey === stringKey) {
      return { ...node, children_fetched: true, children: [...children] };
    }
    if (node.has_children && node.children) {
      return {
        ...node,
        children: mergeChildren(children, stringKey, node.children),
      };
    }
    return node;
  });

export const getNodeChildren = (
  children: SpiderTreeNode[],
  stringKey: string,
  crawlerTree: SpiderTreeNode[]
): Partial<SpiderTreeNode> => {
  const node = crawlerTree.find((node) => node.stringKey === stringKey);
  // if(node.children)
  return { ...node, children_fetched: true, children: [...children] };
};

export const mergeMoreChildren = (
  newChildren: SpiderTreeNode[],
  content_url_hash: string,
  crawlerTree: SpiderTreeNode[]
): SpiderTreeNode[] =>
  crawlerTree.map((node) => {
    if (node.children && node.content_url_hash === content_url_hash) {
      const filteredChildren = node.children.filter(
        (child) => child.label !== 'MORE'
      );
      return { ...node, children: [...filteredChildren, ...newChildren] };
    }
    if (node.has_children && node.children) {
      return {
        ...node,
        children: mergeMoreChildren(
          newChildren,
          content_url_hash,
          node.children
        ),
      };
    }
    return node;
  });

export const deleteChildren = (
  content_url_hash: string,
  crawlerTree: SpiderTreeNode[]
): SpiderTreeNode[] =>
  crawlerTree.map((node) => {
    const filteredChildren = node.children
      ? node.children.filter(
          (child) => child.content_url_hash !== content_url_hash
        )
      : undefined;
    return filteredChildren
      ? {
          ...node,
          children: deleteChildren(content_url_hash, filteredChildren),
        }
      : node;
  });

const setAllNodesContentStatus = (
  content_status: string,
  crawlerTree: SpiderTreeNode[]
): SpiderTreeNode[] =>
  crawlerTree.map((node) =>
    node.has_children && node.children
      ? {
          ...node,
          content_status,
          children: setAllNodesContentStatus(content_status, node.children),
        }
      : { ...node, content_status }
  );

export const updateContentStatus = (
  content_url_hash: string,
  content_status: string,
  crawlerTree: SpiderTreeNode[]
): SpiderTreeNode[] =>
  crawlerTree.map((node) => {
    if (node.content_url_hash === content_url_hash) {
      return node.has_children && node.children
        ? {
            ...node,
            content_status,
            children: setAllNodesContentStatus(content_status, node.children),
          }
        : { ...node, content_status };
    }
    if (node.has_children && node.children) {
      return {
        ...node,
        children: updateContentStatus(
          content_url_hash,
          content_status,
          node.children
        ),
      };
    }
    return node;
  });

export default {
  filterCrawlerTree: filterCrawlerTree,
  filterNodes,
  updateNodeInTree,
};
