import { useNavigate } from 'react-router-dom';
import { PlanOption } from '../plan-information/PlanInformation';
import {
  UpgradeParams,
  DowngradeParams,
} from '../plan-information/planActions';
import DowngradeConfirmModal from '../plan-information/subs-action-modals/DowngradeConfirmModal';
import UpgradeConfirmModal from '../plan-information/subs-action-modals/UpgradeConfirmModal';
import { ActionModalType } from './SelectPlan';

type ModalProps = {
  actionModalType: ActionModalType | null;
  setActionModalType: React.Dispatch<React.SetStateAction<ActionModalType>>;
  params: UpgradeParams | DowngradeParams;
  selectedPlan: PlanOption | null;
  navigate: ReturnType<typeof useNavigate>;
};

const CurrentActionModal = ({
  actionModalType,
  setActionModalType,
  params,
  selectedPlan,
  navigate,
}: ModalProps) => {
  if (!actionModalType) return <></>;

  if (actionModalType === 'downgrade') {
    return (
      <DowngradeConfirmModal
        params={{ ...params, navigate }}
        newThresholdValue={selectedPlan?.threshold_value || 0}
        modalType={actionModalType}
        setActionModalType={setActionModalType}
      />
    );
  }

  return (
    <UpgradeConfirmModal
      params={{ ...params }}
      modalType={actionModalType}
      setActionModalType={setActionModalType}
    />
  );
};

export default CurrentActionModal;
