import { useEffect, useState } from 'react';
import { Box, Flex } from '@mantine/core';
import { TopBar } from '../JliffActions/TopBar';
import { GlossaryPopover } from '../Glossary/GlossaryPopover';
import { JliffViewer } from './JliffViewer';
import { haveTags } from './jliffFunctions';
import { useSegmentStatus } from '../../store/SegmentsState';
import classes from './Source.module.css';

type Props = {
  // jliffs: Jliff[];
  // segmentStatus: SegmentStatus;
  segment: Segment;
  glossary: GlossaryItem[];
  filterText: string;
};
export const Source = ({
  // jliffs,
  segment,
  filterText,
  // segmentStatus,
  glossary,
}: Props) => {
  const segmentStatus = useSegmentStatus(segment.segment_hash);
  const isSelected = segmentStatus?.isSelected || false;

  const [showAllTags, setShowAllTags] = useState(false);

  // useEffect(() => {
  //   if (!isSelected) setShowAllTags(false);
  // }, [isSelected]);

  return (
    <Flex direction={'column'}>
      {isSelected && (
        <TopBar
          isTarget={false}
          isSelected={isSelected}
          haveTags={haveTags(segment.segment_jliff)}
          showAllTags={showAllTags && isSelected}
          setShowAllTags={setShowAllTags}
        />
      )}
      <Box className={isSelected ? classes.source : classes.selectedSource}>
        <GlossaryPopover glossary={glossary} isSelected={isSelected} />
        <JliffViewer
          jliffs={segment.segment_jliff}
          filterText={filterText}
          showAllTags={showAllTags}
          isSelected={isSelected}
        />
      </Box>
    </Flex>
  );
};
