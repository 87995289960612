import React from 'react';
import ProjectSetupSteps from './ProjectSetupSteps';
import { ProjectSetupProvider } from 'ui';
import RemarketStatsScript from './RemarketStatsScript';

export const ProjectSetupPage = () => {
  return (
    <>
      <ProjectSetupProvider>
        <ProjectSetupSteps />
      </ProjectSetupProvider>
      <RemarketStatsScript />
    </>
  );
};
