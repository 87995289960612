import { Divider, Flex, Switch, Box, Text, Title } from '@mantine/core';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import AnnualDiscountAccent from './AnnualDiscountAccent';
import { SubscriptionType } from './SelectPlan';
import { PlanOption } from '../plan-information/PlanInformation';
import classes from './TopSection.module.css';
import { xapisEnv } from 'store';
import { AnchorLink } from '../../../Generic';

const { glgoMarketingUrl } = xapisEnv.getHost;

const bulletPoints = [
  <Text>Unlimited Translated Languages</Text>,
  <Text>Multiple Users</Text>,
  <AnchorLink
    to={`${glgoMarketingUrl}/pricing#feature-matrix`}
    target="_blank"
    td="none"
  >
    And More!
  </AnchorLink>,
];

const mobileBulletPoints = [
  ...bulletPoints.slice(0, 2),
  <Text>In-context Editor</Text>,
  <Text>No code Deployment</Text>,
  <AnchorLink
    to={`${glgoMarketingUrl}/pricing#feature-matrix`}
    target="_blank"
    td="none"
  >
    And More!
  </AnchorLink>,
];

type Props = {
  value: SubscriptionType;
  isTabletView: boolean | undefined;
  setValue: React.Dispatch<React.SetStateAction<SubscriptionType>>;
  setSelectedPlan: React.Dispatch<React.SetStateAction<PlanOption | null>>;
};

const TopSection = ({
  value,
  isTabletView,
  setValue,
  setSelectedPlan,
}: Props) => (
  <>
    <Title order={1} mb={10} ta={{ base: 'center', md: 'initial' }}>
      Select a plan that works for you!
    </Title>
    <Divider
      w="100%"
      color="divider.1"
      size="sm"
      display={{ base: 'block', md: 'none' }}
    />
    <Flex direction="column" gap={10} py="0.8rem">
      {[...(isTabletView ? mobileBulletPoints : bulletPoints)].map(
        (bulletPoint, i) => (
          <Flex key={i} align="center" gap={10} ta="center">
            <div className={classes.checkmarkWrapper}>
              <BsFillCheckCircleFill
                className={classes.checkmarkIcon}
                size="1.2rem"
                fill="#EFF4FB"
              />
            </div>
            {bulletPoint}
          </Flex>
        )
      )}
    </Flex>
    <Text display={{ base: 'block', md: 'none' }} fw={700}>
      Plans
    </Text>
    <Divider
      w="100%"
      color="divider.1"
      size="sm"
      display={{ base: 'block', md: 'none' }}
    />
    <Flex
      align="center"
      justify={{ base: 'left', md: 'end' }}
      w="100%"
      gap={10}
      m={{ base: '0.5rem 0 1.25rem 0', md: 0 }}
    >
      <Switch
        color="background2.2"
        defaultChecked={value === 'yearly'}
        classNames={{
          input: classes.switchInput,
          track: classes.switchTrack,
        }}
        onChange={() => {
          setValue(value === 'yearly' ? 'monthly' : 'yearly');
          setSelectedPlan(null);
        }}
        size="sm"
      />
      <Box pos="relative">
        <Text fw={700}>
          Pay annually &
          <Text fw={700} c="text.8" ml={5} span>
            get two months free.
          </Text>
        </Text>
        <Box
          display={{ base: 'none', xs: 'block' }}
          pos="absolute"
          top={14}
          right={0}
        >
          <AnnualDiscountAccent />
        </Box>
      </Box>
    </Flex>
  </>
);

export default TopSection;
