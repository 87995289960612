import createRule from './createRule';
import { notifications } from '@mantine/notifications';
import { asciiToHex, hexToObject } from 'helpers';
import { SpiderTreeNode, Xapis, filterNodes, mergeChildren } from 'store';
import getNodeProperties from './getNodeProperties';
import getFullPath from './getFullPath';

type Rule = 'self' | 'parentAndChildren' | 'childrenOnly';

export const handleAddRule = (
  type: Rule,
  itemInfo: string,
  scope: 'include' | 'exclude',
  project: ProjectKey | undefined,
  yyTarget: TranslationKey | undefined,
  setYYTarget: React.Dispatch<React.SetStateAction<TranslationKey>>,
  spiderTree: SpiderTreeNode[],
  setSpiderTree: React.Dispatch<React.SetStateAction<SpiderTreeNode[]>>
) => {
  const { contentUrlOrig, label, isFake, id } = getNodeProperties(itemInfo);
  const { project_key: activeProjectKey = '' } = project || {};

  const yyTranslationKey = yyTarget?.translation_key || '';

  let rule =
    createRule(type, contentUrlOrig.length > 0 ? contentUrlOrig : label) || '';
  if (isFake && type === 'childrenOnly' && id) {
    // add full path to apply exclusion state properly
    const ruleArray = rule.split('');
    rule = ruleArray[0] + getFullPath(id, label) + '.' + ruleArray.at(-1);
  }

  const decodedConfig = hexToObject(yyTarget?.translation_config || '');
  if (!decodedConfig)
    throw new Error(`error w decoding config. value: ${decodedConfig}`);

  let updatedConfig = '';
  if (scope === 'exclude') {
    const updatedExcludeList = [
      ...decodedConfig.spider_rules.exclude_uris,
      rule,
    ];
    const updatedSpiderRules = {
      ...decodedConfig.spider_rules,
      exclude_uris: updatedExcludeList,
    };
    updatedConfig = JSON.stringify({
      ...decodedConfig,
      spider_rules: updatedSpiderRules,
    });
  } else {
    const updatedExcludeList = [
      ...decodedConfig.spider_rules.include_uris,
      rule,
    ];
    const updatedSpiderRules = {
      ...decodedConfig.spider_rules,
      include_uris: updatedExcludeList,
    };
    updatedConfig = JSON.stringify({
      ...decodedConfig,
      spider_rules: updatedSpiderRules,
    });
  }

  if (activeProjectKey && yyTranslationKey) {
    const updatedConfigHex = asciiToHex(updatedConfig);
    const translationConfigHash = yyTarget?.translation_config_hash || '';

    Xapis.ProjectTranslation.put(activeProjectKey, yyTranslationKey, {
      translation_config: updatedConfigHex,
      translation_config_hash: translationConfigHash,
    })
      .then(({ data }) => {
        if (data) {
          const nodeInfo = JSON.parse(itemInfo);
          const { id, content_url_hash } = nodeInfo;

          if (type !== 'self') {
            Xapis.SpiderTree.get(yyTranslationKey, content_url_hash)
              .then(({ tree = [] }) => {
                const filteredData = filterNodes(tree, id) as SpiderTreeNode[];
                const newCrawlerTree = mergeChildren(
                  filteredData,
                  id,
                  spiderTree
                );
                setSpiderTree(newCrawlerTree);
              })
              .catch(() => {
                notifications.show({
                  message: 'Unable to get spider tree node info at this time.',
                });
              });
          }
          setYYTarget(data);
          notifications.show({ message: 'Successfully added the rule!' });
        }
      })
      .catch(() => {
        notifications.show({
          message: "We are unable to update your project's scope at this time.",
        });
      });
  } else {
    console.error(
      `incorrect value(s) for activeProjectKey: ${activeProjectKey} and/or yyTranslationKey: ${yyTranslationKey}`
    );
  }
};

export default handleAddRule;
