import { Xapis, ProjectResponse, getUserKey } from '../xapis-wrappers/xapis';
import { Params } from 'react-router-dom';
import { decodeProjectId } from 'helpers';
import { filterTranslations } from 'helpers';

export async function quotesLoader({
  params,
}: {
  params: Params<string>;
}): Promise<QuotesLoaderResponse> {
  const userKey = getUserKey();
  const { projectId } = params;
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }

  if (!userKey) {
    return {
      projectData: {} as ProjectResponse,
      translations: [],
      yyTarget: undefined,
      quotes: {},
    };
  }

  const projectKey = decodeProjectId(projectId);

  const [projectData, quotes] = await Promise.all([
    Xapis.Project.get(projectKey),
    (async () => {
      if (!window.QuoteAPI)
        throw Error(
          'This route and loader should not be called except on desktop with QuoteAPI defined on window'
        );
      return await window.QuoteAPI.allQuotes();
    })(),
  ]);

  const { translations, yy_translation_key } = projectData.project || {};

  const yyTarget = translations?.find(
    (t) => t.translation_key === yy_translation_key
  );

  const loaded = {
    projectData,
    translations: filterTranslations(translations || []),
    yyTarget,
    quotes,
  };

  return loaded;
}

export type QuotesLoaderResponse = {
  projectData: ProjectResponse;
  translations: TranslationKey[];
  yyTarget: TranslationKey | undefined;
  quotes: Record<string, Quote>;
};
