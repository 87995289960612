import { DatePickerInput } from '@mantine/dates';
import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Group,
  NumberInput,
  Popover,
  Select,
  Stack,
  Tabs,
} from '@mantine/core';
import classes from './MultiSelector.module.css';
import { MdArrowDropDown } from 'react-icons/md';
import moment, { DurationInputArg2 } from 'moment';
import { useSearchParams } from 'react-router-dom';

const DateRangePicker = ({
  timeSliceFrom,
  timeSliceTo,
}: {
  timeSliceFrom: string;
  timeSliceTo: string | undefined;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const applyDateChange = (timeSliceFrom: string, timeSliceTo: string) => {
    searchParams.set('from', timeSliceFrom);
    if (timeSliceTo) {
      searchParams.set('to', timeSliceTo);
    } else {
      searchParams.delete('to');
    }
    setSearchParams(searchParams, { state: { revalidate: true } });
  };

  const relativeRegex = /month|year|day/;
  const isRelative = relativeRegex.test(timeSliceFrom);

  const getDateRange = () => {
    if (isRelative) {
      const [num, unit] = timeSliceFrom.split(' ');

      const to = moment(new Date());
      const from = moment(new Date());

      from.subtract(Number(num), unit as DurationInputArg2);

      const dateTo = new Date(to.format('l'));
      const dateFrom = new Date(from.format('l'));

      return { dateTo, dateFrom };
    }

    const dateFrom = new Date(moment(timeSliceFrom).format('l'));
    const dateTo = timeSliceTo
      ? new Date(moment(timeSliceTo).format('l'))
      : new Date(moment().format('l'));
    return { dateFrom, dateTo };
  };
  const { dateFrom, dateTo } = getDateRange();

  const RangePicker = () => {
    const [dates, setDates] = useState<[Date | null, Date | null]>([
      dateFrom,
      dateTo,
    ]);

    return (
      <Stack>
        <DatePickerInput
          type="range"
          placeholder="Pick dates range"
          value={dates}
          onChange={setDates}
          popoverProps={{ withinPortal: false }}
          mt={10}
        />
        <Button
          onClick={() =>
            applyDateChange(
              moment(dates[0] ?? new Date()).format('YYYY-MM-DD'),
              moment(dates[1] ?? new Date()).format('YYYY-MM-DD')
            )
          }
        >
          Apply
        </Button>
      </Stack>
    );
  };

  const RelativePicker = () => {
    const [num, setNum] = useState<string | undefined | number>(
      isRelative ? Number(timeSliceFrom.split(' ')[0]) : 1
    );
    const [time, setTime] = useState<string | null>(
      isRelative ? timeSliceFrom.split(' ')[1] : 'month'
    );

    return (
      <Stack>
        <Group gap={0} mt={10} wrap="nowrap">
          <NumberInput value={num} onChange={setNum} w={75} max={31} min={1} />
          <Select
            data={[
              { label: 'Day', value: 'day' },
              { label: 'Month', value: 'month' },
              { label: 'Year', value: 'year' },
            ]}
            value={time}
            onChange={setTime}
            comboboxProps={{ withinPortal: false }}
            allowDeselect={false}
          />
        </Group>
        <Button onClick={() => applyDateChange(String(num) + ' ' + time, '')}>
          Apply
        </Button>
      </Stack>
    );
  };

  return (
    <Flex>
      <Popover shadow="sm" position={'bottom-end'} width={300} keepMounted>
        <Popover.Target>
          <Button
            h={35}
            w={165}
            variant="transparent"
            classNames={{
              root: classes.filterButton,
              inner: classes.filterText,
            }}
            rightSection={<MdArrowDropDown size={24} />}
          >
            Date Range
          </Button>
        </Popover.Target>
        <Popover.Dropdown className={classes.filterMenu}>
          <Box
            component="ul"
            mah={250}
            mih={140}
            m={0}
            p={0}
            pr={10}
            style={{ listStyleType: 'none' }}
          >
            <Tabs defaultValue={isRelative.toString()}>
              <Tabs.List>
                <Tabs.Tab value={'true'}>Relative</Tabs.Tab>
                <Tabs.Tab value={'false'}>Range</Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value={'true'}>
                <RelativePicker />
              </Tabs.Panel>
              <Tabs.Panel value={'false'}>
                <RangePicker />
              </Tabs.Panel>
            </Tabs>
          </Box>
        </Popover.Dropdown>
      </Popover>
    </Flex>
  );
};

export default DateRangePicker;
