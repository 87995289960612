import React, { useEffect } from 'react';
import {
  Box,
  Card,
  Divider,
  Flex,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useFieldArray, useForm } from 'react-hook-form';
import { notifications } from '@mantine/notifications';
import {
  DOMAIN,
  addDeploymentValue,
  removeDeploymentValue,
  getDeploymentTargets,
  TargetFormValue,
  FormValues,
  hasValidDeploymentValues,
} from '../utils';
import { MdAdd, MdOutlineRemoveCircleOutline } from 'react-icons/md';
import FooterButtons from '../FooterButtons';
import { useDeploymentContext } from '../DeploymentContextProvider';
import EmptyValueWarning from './EmptyValueWarning';

const EditDomainMethod = () => {
  const { colors } = useMantineTheme();
  const {
    loading,
    setLoading,
    setShowForm,
    targets = [],
    updateDeploymentDetails,
  } = useDeploymentContext();
  const defaultTargetValues = getDeploymentTargets(targets);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { isDirty = false, errors },
  } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      targetValues: defaultTargetValues,
    },
  });
  const { fields: targetValues } = useFieldArray({
    control,
    name: 'targetValues',
  });

  if (window.history.state) window.history.replaceState({}, '');

  const handleUpdate = async (data: { targetValues: TargetFormValue[] }) => {
    setLoading(true);

    const { targetValues = [] } = data || {};
    const deploymentValues = targetValues.map(
      ({ deployment_value }) => deployment_value
    );

    updateDeploymentDetails({ deploymentValues })
      .then(() => {
        notifications.show({
          message: 'Successfully updated deployment details!',
        });
      })
      .finally(() => {
        setShowForm(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    // validate entire form onmount
    trigger(undefined, { shouldFocus: true });
  }, [trigger]);

  return (
    <Box opacity={loading ? 0.5 : 1}>
      <form onSubmit={handleSubmit(handleUpdate)}>
        <Flex direction="column" gap={20}>
          <Card p={0} style={{ boxShadow: 'none' }} opacity={loading ? 0.5 : 1}>
            <Flex h="100%" pl="1.5rem" py="0.5rem">
              <Box w="20%" pt="1.5rem" pr="0.5rem">
                <Text fw={600}>Language</Text>
              </Box>
              <Box w="80%" pl="0.5rem">
                {targetValues.map(
                  (
                    {
                      id = '',
                      target_lang_code = '',
                      target_lang_name = '',
                      deployment_value = [],
                    },
                    targetIndex
                  ) => {
                    const isLastIndex = targetIndex === targetValues.length - 1;
                    const hasMultipleValues = deployment_value.length > 1;

                    return (
                      <Box key={id}>
                        <Flex py={hasMultipleValues ? '1.15rem' : 0}>
                          <Flex
                            w="35%"
                            h={hasMultipleValues ? '2.65rem' : '5rem'}
                            align="center"
                            pr="1rem"
                          >
                            <Text truncate>{target_lang_name}</Text>
                          </Flex>
                          <Flex
                            w="50%"
                            direction="column"
                            rowGap="0.5rem"
                            justify={
                              hasMultipleValues ? 'flex-start' : 'center'
                            }
                            px="1rem"
                          >
                            {deployment_value.map((value, valueIndex) => {
                              const invalid =
                                errors.targetValues?.[targetIndex]
                                  ?.deployment_value?.[valueIndex]?.message ===
                                'invalid';

                              return (
                                <Flex
                                  align="center"
                                  key={`${id}-${valueIndex}`}
                                  pos={invalid ? 'relative' : 'initial'}
                                >
                                  {invalid && (
                                    <EmptyValueWarning
                                      method={DOMAIN}
                                      hasMultipleValues={hasMultipleValues}
                                      color={colors.text[4]}
                                    />
                                  )}
                                  <Flex
                                    h={hasMultipleValues ? '2.65rem' : '5rem'}
                                    align="center"
                                  >
                                    <TextInput
                                      w="100%"
                                      maw="15rem"
                                      size="md"
                                      {...register(
                                        `targetValues.${targetIndex}.deployment_value.${valueIndex}`,
                                        {
                                          validate: (fieldValue) => {
                                            return (
                                              hasValidDeploymentValues(
                                                [fieldValue],
                                                DOMAIN
                                              ) || 'invalid'
                                            );
                                          },
                                        }
                                      )}
                                      defaultValue={value}
                                      error={invalid}
                                    />
                                  </Flex>
                                </Flex>
                              );
                            })}
                          </Flex>
                          <Flex
                            w="15%"
                            mih="5rem"
                            align={hasMultipleValues ? 'flex-end' : 'center'}
                          >
                            <Flex h="2.5rem" align="center" columnGap="0.5rem">
                              {hasMultipleValues && (
                                <Box
                                  h={25}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    removeDeploymentValue(
                                      target_lang_code,
                                      getValues,
                                      setValue
                                    )
                                  }
                                >
                                  <MdOutlineRemoveCircleOutline
                                    size={25}
                                    color={colors.icon[0]}
                                  />
                                </Box>
                              )}
                              <Box
                                h={25}
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  addDeploymentValue(
                                    target_lang_code,
                                    getValues,
                                    setValue
                                  )
                                }
                              >
                                <MdAdd size={25} color={colors.icon[0]} />
                              </Box>
                            </Flex>
                          </Flex>
                        </Flex>
                        {!isLastIndex && (
                          <Divider
                            size="xs"
                            orientation="horizontal"
                            color="divider.1"
                          />
                        )}
                      </Box>
                    );
                  }
                )}
              </Box>
            </Flex>
          </Card>
          <Divider size="xs" orientation="horizontal" color="divider.1" />
          <FooterButtons
            defaultFormSubmit={true}
            disabled={!isDirty}
            loading={loading}
            onCancel={() => {
              setShowForm(false);
            }}
          />
        </Flex>
      </form>
    </Box>
  );
};

export default EditDomainMethod;
