import React from 'react';
import { Box, Card, Divider, Flex, Text, TextInput } from '@mantine/core';
import { useFieldArray, useForm } from 'react-hook-form';
import { notifications } from '@mantine/notifications';
import { COOKIE, hasValidDeploymentValues } from '../utils';
import FooterButtons from '../FooterButtons';
import { useDeploymentContext } from '../DeploymentContextProvider';

const EditCookieMethod = () => {
  const {
    loading,
    setLoading,
    setShowForm,
    activeProject: { deployment_name: currentDeploymentName = '' } = {},
    targets = [],
    updateDeploymentDetails,
  } = useDeploymentContext();

  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty = false },
  } = useForm({
    defaultValues: {
      deploymentName: currentDeploymentName,
      targetValues: targets,
    },
  });
  const { fields: targetValues } = useFieldArray({
    control,
    name: 'targetValues',
  });

  const handleUpdate = async (data: {
    deploymentName: string;
    targetValues: TranslationKey[];
  }) => {
    const { deploymentName = '', targetValues = [] } = data || {};

    const deploymentValues = targetValues.map(
      ({ deployment_value }) => deployment_value
    );

    if (!isDirty) {
      setShowForm(false);
      return;
    } else if (
      !deploymentName ||
      !hasValidDeploymentValues(deploymentValues, COOKIE)
    ) {
      notifications.show({ message: 'A deployment field is missing a value' });
      return;
    } else {
      setLoading(true);

      updateDeploymentDetails({
        deploymentName,
        deploymentValues,
        prevDeploymentName: currentDeploymentName,
      })
        .then(() => {
          notifications.show({
            message: 'Successfully updated deployment details!',
          });
        })
        .finally(() => {
          setShowForm(false);
          setLoading(false);
        });
    }
  };

  return (
    <Flex direction="column" rowGap="2rem" opacity={loading ? 0.5 : 1}>
      <Card p={0} style={{ boxShadow: 'none' }}>
        <Flex pl="1.5rem">
          <Box w="30%" pt="1.65rem" pr="0.5rem">
            <Text fw={600}>Cookie Name</Text>
          </Box>
          <Flex w="70%" h="5rem" pr="1.5rem" pl="0.5rem" align="center">
            <TextInput
              w="100%"
              maw="15rem"
              size="md"
              defaultValue={currentDeploymentName}
              {...register('deploymentName')}
            />
          </Flex>
        </Flex>
        <Divider size="xs" orientation="horizontal" color="divider.1" />
        <Flex pl="1.5rem" py="0.5rem">
          <Box w="30%" pt="1.65rem" pr="0.5rem">
            <Text fw={600}>Cookie Value</Text>
          </Box>
          <Box w="70%" pl="0.5rem">
            {targetValues.map(
              (
                { id = '', target_lang_name = '', deployment_value = '' },
                index
              ) => {
                const isLastIndex = index === targetValues.length - 1;
                return (
                  <Box key={id}>
                    <Flex h="5rem" pr="1.5rem">
                      <Flex w="60%" align="center" pr="1rem">
                        <Text truncate>{target_lang_name}</Text>
                      </Flex>
                      <Flex w="40%" align="center" pl="1rem">
                        <TextInput
                          maw="15rem"
                          size="md"
                          {...register(
                            `targetValues.${index}.deployment_value`
                          )}
                          defaultValue={deployment_value}
                        />
                      </Flex>
                    </Flex>
                    {!isLastIndex && (
                      <Divider
                        size="xs"
                        orientation="horizontal"
                        color="divider.1"
                      />
                    )}
                  </Box>
                );
              }
            )}
          </Box>
        </Flex>
      </Card>
      <Divider size="xs" orientation="horizontal" color="divider.1" />
      <FooterButtons
        loading={loading}
        onCancel={() => {
          setShowForm(false);
        }}
        onSave={handleSubmit(handleUpdate)}
      />
    </Flex>
  );
};

export default EditCookieMethod;
