import { useEffect } from 'react';
import { useAuth } from 'auth';
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
} from 'react-router-dom';
import {
  getDefaultProjectKey,
  encodeProjectKey,
  dashboardRC,
  saveSelectedProjectKey,
  isDesktopApp,
} from 'helpers';
import { useUserProvider } from 'store';
import { LoginOrSignUp, DesktopLogin } from 'pages';
import { Wait } from 'ui';
import { Oops } from 'pages'; // When user is authenticated but xapis/User fail
import { useGLGOParams } from 'hooks';

const getLoadingText = ({
  isSigninRedirect,
  isSwitchingProjects,
  isDesktop,
}: {
  isSigninRedirect: boolean;
  isSwitchingProjects: boolean;
  isDesktop: boolean;
}) => {
  if (isSwitchingProjects) return 'Loading project';
  else if (isSigninRedirect && !isDesktop) return 'Redirecting to login page';
  else if (isSigninRedirect && isDesktop) return 'Login in your browser';
  return 'Loading...';
};

export const Root = () => {
  const { pathname, search } = useLocation();
  const { projectId, projectKey } = useGLGOParams();
  const redirectUrl = `${pathname}${search}`;
  const auth = useAuth();
  const { isAuthenticated, isLoading: isAuthLoading, isSigninRedirect } = auth;

  const { userProjects, xapisUser } = useUserProvider();
  const navigate = useNavigate();
  const { state } = useNavigation();
  const isRouteLoading = state !== 'idle';
  const isSwitchingProjects =
    isRouteLoading && pathname === dashboardRC.PAGE_ROOT;
  const isDesktop = isDesktopApp();

  useEffect(() => {
    window.DashboardAPI &&
      window.DashboardAPI.onNavigate((path: string) => {
        navigate(path);
      });

    window.UserManager &&
      window.UserManager.onSSORedirect((search: string) => {
        const path = search.replace('glwebstudio:/', '');
        navigate(path);
      });
  }, [navigate]);

  useEffect(() => {
    if (window?.fathom) {
      window?.fathom.trackPageview();
    }
  }, [pathname, search]);

  const isValidProject = userProjects.some((p) => p.project_key === projectKey);

  useEffect(() => {
    // Save the project key if user came here through URL
    if (isValidProject && projectKey) saveSelectedProjectKey(projectKey);
  }, [isValidProject, projectKey]);

  if (!isAuthenticated && !isAuthLoading) {
    return isDesktop ? (
      <DesktopLogin redirectUrl={redirectUrl} />
    ) : (
      <LoginOrSignUp redirectUrl={redirectUrl} />
    );
  }

  // Loading states
  if (isSigninRedirect || isSwitchingProjects) {
    return (
      <Wait
        text={getLoadingText({
          isSigninRedirect,
          isSwitchingProjects,
          isDesktop,
        })}
        loader={isSigninRedirect ? 'dots' : 'oval'}
      />
    );
  }

  // User is authenticated but no user data is available...oops
  if (isAuthenticated && !xapisUser.user_key) {
    return <Oops />;
  }

  const shouldNavigate =
    (isAuthenticated && !isAuthLoading && pathname === '/') ||
    (projectId && !isValidProject);

  const defaultProjectId = encodeProjectKey(getDefaultProjectKey(userProjects));
  const navigateTo = defaultProjectId
    ? `/${defaultProjectId}/${dashboardRC.PAGE_DASHBOARD}`
    : `/${dashboardRC.PAGE_CREATE_PROJECT}`;

  return shouldNavigate ? (
    <Navigate to={navigateTo} replace={true} />
  ) : (
    <Outlet />
  );
};
