import {
  Box,
  Button,
  Checkbox,
  Flex,
  Menu,
  ScrollArea,
  Text,
  FloatingPosition,
} from '@mantine/core';
import { MdArrowDropDown } from 'react-icons/md';
import classes from './MultiSelector.module.css';

type SelectorProps = {
  title: string;
  position?: FloatingPosition;
  height?: number | string;
  buttonWidth?: number | string;
  menuWidth?: number | string;
  withinPortal?: boolean;
  selectAll?: boolean;
  options: {
    value: string;
    label: string;
  }[];
  selected: string[];
  onSelect: (value: string) => void;
  limit?: number;
};

export const MultiSelector = ({
  title = 'Select',
  position,
  height,
  buttonWidth,
  menuWidth,
  withinPortal,
  selectAll = false,
  options,
  selected,
  limit,
  onSelect,
}: SelectorProps) => {
  const isAllSelected = selected.length === options.length;

  const isOptionSelected = (value: string, i: number) => {
    if (limit && isAllSelected) {
      return i < limit;
    }
    return selected.includes(value);
  };

  return (
    <Flex>
      <Menu
        shadow="sm"
        position={position || 'bottom-end'}
        width={menuWidth || 300}
        withinPortal={withinPortal !== undefined ? withinPortal : true}
        data-testid="pw-metrics-summary-languages-button"
        closeOnItemClick={false}
        keepMounted
      >
        <Menu.Target>
          <Button
            variant="transparent"
            h={height || 35}
            w={buttonWidth || 165}
            classNames={{
              root: classes.filterButton,
              inner: classes.filterText,
            }}
            rightSection={<MdArrowDropDown size={24} />}
          >
            {title}
          </Button>
        </Menu.Target>
        <Menu.Dropdown
          className={classes.filterMenu}
          data-testid="pw-metrics-summary-languages-dropdown"
        >
          <ScrollArea offsetScrollbars type="always">
            <Box
              component="ul"
              mah={250}
              style={{ listStyleType: 'none', padding: 0, margin: 0 }}
            >
              {selectAll && (
                <Box
                  component="li"
                  px={10}
                  m={0}
                  py={5}
                  className={classes.filterItem}
                >
                  <Flex
                    align="center"
                    columnGap={15}
                    onClick={() => onSelect('All')}
                  >
                    <Checkbox
                      classNames={{
                        input: classes.checkboxInput,
                      }}
                      readOnly
                      checked={isAllSelected}
                    />
                    <Text c="text.1" fz="0.9rem" fw={isAllSelected ? 600 : 400}>
                      All
                    </Text>
                  </Flex>
                </Box>
              )}
              {options.map(({ label = '', value = '' }, i) => {
                const isActive = isOptionSelected(value, i);
                return (
                  <Box
                    component="li"
                    display="flex"
                    px={10}
                    py={5}
                    key={value}
                    onClick={() => onSelect(value)}
                    className={classes.filterItem}
                  >
                    <Flex align="center" columnGap={15}>
                      <Checkbox
                        classNames={{
                          input: classes.checkboxInput,
                          inner: classes.checkboxInner,
                        }}
                        readOnly
                        checked={isActive}
                      />
                      <Text c="text.1" fz="0.9rem" fw={isActive ? 600 : 400}>
                        {label}
                      </Text>
                    </Flex>
                  </Box>
                );
              })}
            </Box>
          </ScrollArea>
        </Menu.Dropdown>
      </Menu>
    </Flex>
  );
};
