import { useGLGOParams } from 'hooks';
import ContactUsModalForm from './ContactUsModalForm';
import { useUserProvider, useGlobalModalStore, GlobalModalStore } from 'store';

export default function ContactUsModal() {
  const { projectKey } = useGLGOParams();
  const { showContactUsModal, setShowContactUsModal } = useGlobalModalStore(
    (state: GlobalModalStore) => state
  );

  const user = useUserProvider().xapisUser;
  const activeProject = user?.project_keys?.find(
    (p) => p.project_key === projectKey
  );

  const { first_name = '', last_name = '', email = '', user_key } = user;

  return (
    <ContactUsModalForm
      showContactUsModal={showContactUsModal}
      setShowContactUsModal={setShowContactUsModal}
      userName={`${first_name} ${last_name}`}
      email={email}
      userKey={user_key}
      projectName={activeProject?.project_name || ''}
      projectKey={projectKey || ''}
    />
  );
}
