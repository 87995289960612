import axios, { AxiosInstance } from 'axios';
let hostName: string = '';

export let http: AxiosInstance;
let accessToken: string | undefined = undefined;

export const createHttpService = (host: string) => {
  if (!host || host === hostName) return;
  hostName = host;

  try {
    http = axios.create({
      baseURL: `${host}`,
    });
    http.defaults.headers.common.Authorization = `Bearer ${accessToken || 'FakeTokenFromSegmentEditor'}`;

    http.interceptors.request.use(
      (config) => {
        if (accessToken && config.headers) {
          config.headers.Authorization = `Bearer ${accessToken || 'FakeTokenFromSegmentEditor'}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  } catch (e) {
    console.error('Error creating Axios instance:', e);
  }
};
export function setAccessToken(newAccessToken: string) {
  if (accessToken === newAccessToken) return;
  accessToken = newAccessToken;
}
