import { create } from 'zustand';
import {
  ALREADY_CREATED_CODE,
  asciiToHex,
  failure,
  hexToAscii,
  hexToObject,
} from 'helpers';
import { AxiosError, AxiosResponse } from 'axios';
import DeleterPromise from './xapis-wrappers/DeleterPromise';
import PosterPromise from './xapis-wrappers/PosterPromise';
import PutterPromise from './xapis-wrappers/PutterPromise';
import Get from './xapis-wrappers/Fetcher';
import { defaultXapisErrorResponse } from './xapis-wrappers';

export type TargetResponse = {
  append_to_rtx: string;
  created_user: string;
  created_utc: string;
  custom_css: string;
  custom_css_hash: string;
  custom_js: string;
  custom_js_hash: string;
  deployment_value: string;
  is_active: boolean;
  is_live: boolean;
  is_monitored: boolean;
  is_spider_multi_domain: boolean;
  lastmod_user: string;
  lastmod_utc: string;
  linked_translation_key: string;
  live_host: string;
  mt_api_code: string;
  mt_api_key: string;
  mt_category: string;
  mt_endpoint: string;
  mt_pivot: string;
  pd_source_lang_code: string;
  pd_target_lang_code: string;
  preserve_ope_conflicts: number;
  pretranslate_mode: string;
  project_key: string;
  proxy_host: string;
  rtx_translation_methods: string;
  sitemap_domain: string;
  sitemap_enable: number;
  sitemap_exclude: string | string[]; // TODO: Fix Xapis so that it will only output a String or Array, not both
  sitemap_folder: string;
  sitemap_protocol: string;
  sitemap_suffix: string;
  source_lang_code: string;
  source_lang_name: string;
  source_native_name: string;
  staging_custom_css: string;
  staging_custom_css_hash: string;
  staging_custom_js: string;
  staging_custom_js_hash: string;
  staging_host: string;
  target_config: string;
  target_config_hash: string;
  target_lang_code: string;
  target_lang_name: string;
  target_name: string;
  target_native_name: string;
  target_subdir: string;
  tmgr_pass: string;
  tmgr_project_shortcode: string;
  tmgr_project_ticket: string;
  tmgr_source_lang_code: string;
  tmgr_target_lang_code: string;
  tmgr_url: string;
  tmgr_user: string;
  tms_cleanup_pass: string;
  tms_pass: string;
  tms_tmlocation: string;
  tms_url: string;
  tms_user: string;
  translation_config: string;
  translation_config_hash: string;
  translation_key: string;
  translation_methods: string;
};

export const defaultTarget = {
  appendToRtx: '',
  createdUser: '',
  createdUTC: '',
  customCSS: '',
  customCSSHash: '',
  customJS: '',
  customJSHash: '',
  deploymentValue: '',
  isActive: false,
  isLive: false,
  isMonitored: false,
  isSpiderMultiDomain: false,
  lastModUser: '',
  lastModUTC: '',
  linkedTranslationKey: '',
  liveHost: '',
  mtApiCode: '',
  mtApiKey: '',
  mtCategory: '',
  mtEndpoint: '',
  mtPivot: '',
  pdSourceLangCode: '',
  pdTargetLangCode: '',
  preserveOPEConflicts: false,
  pretranslateMode: '',
  projectKey: '',
  proxyHost: '',
  rtxTranslationMethods: [] as string[],
  sitemapDomain: '',
  sitemapEnable: false,
  sitemapExclude: [] as string[],
  sitemapFolder: '',
  sitemapProtocol: '',
  sitemapSuffix: '',
  sourceLangCode: '',
  sourceLangName: '',
  sourceNativeName: '',
  stagingCustomCSS: '',
  stagingCustomCSSHash: '',
  stagingCustomJS: '',
  stagingCustomJSHash: '',
  stagingHost: '',
  targetConfig: {},
  targetConfigHash: '',
  targetLangCode: '',
  targetLangName: '',
  targetName: '',
  targetNativeName: '',
  targetSubDir: '',
  tmgrPass: '',
  tmgrProjectShortCode: '',
  tmgrProjectTicket: '',
  tmgrSourceLangCode: '',
  tmgrTargetLangCode: '',
  tmgrUrl: '',
  tmgrUser: '',
  tmsCleanupPass: '',
  tmsPass: '',
  tmsTmLocation: '',
  tmsUrl: '',
  tmsUser: '',
  translationConfig: {
    translation_rules: {
      no_translate: [] as { order?: number; X: string; comment?: string }[],
    },
  },
  translationConfigHash: '',
  translationKey: '',
  translationMethods: [] as string[],
};

export type Target = typeof defaultTarget;

type CreateTargetsArgs = {
  source_lang_code: string;
  target_lang_code?: string;
  is_active?: number | boolean | string; // 1, true, or "true"
  is_live?: boolean;
  custom_css?: string;
  staging_custom_css?: string;
  linked_translation_key?: string;
  mt_api_code?: string;
  mt_api_key?: string;
  mt_endpoint?: string;
  deployment_value?: string;
  target_config?: string; // Hex of JSON string
  translation_methods?: string;
};

export type ProjectTranslationStore = {
  loading: boolean;
  selectedTarget: Target;
  targets: Target[];
  xapisTargets: TranslationKey[];
  yyTarget: Target;
  yyTranslationKey: string;
  fetchTargets: (
    projectKey: string,
    failed?: { errorMessage?: string; action?: () => void },
    success?: (parsedTargets: Target[]) => void,
    cleanup?: () => void
  ) => void;
  fetchTarget: (
    prevTargets: Target[],
    projectKey: string,
    translationKey: string,
    cleanup: () => void
  ) => void;
  createTarget: (
    projectKey: string,
    data: CreateTargetsArgs,
    success?: (response: AxiosResponse) => void,
    failed?: { errorMessage?: string; action?: () => void }
  ) => Promise<AxiosResponse<TargetResponse> | Record<string, never>>;
  deleteTarget: (
    projectKey: string,
    translationKey: string,
    success?: (response: AxiosResponse) => void,
    failed?: { errorMessage?: string; action?: () => void }
  ) => Promise<AxiosResponse | Record<string, never>>;
  updateTarget: (
    projectKey: string,
    translationKey: string,
    data: Partial<TargetResponse>,
    success?: (response: AxiosResponse) => void,
    failed?: { errorMessage?: string; action?: () => void }
  ) => Promise<AxiosResponse<TargetResponse | XapisErrorResponse>>;
  updateTargetsCustomCSS: (
    projectKey: string,
    updatedCustomCSS: string
  ) => Promise<AxiosResponse<TargetResponse | XapisErrorResponse>>;
  setSelectedTarget: (selected: Target) => void;
};

const parseTarget = (target: TargetResponse): Target => {
  const parseStringArray = (inputString: string | string[]) => {
    try {
      // *Shouldn't* be an array, but it will sometimes unfortunately be an array from Xapis
      // TODO: Fix Xapis so that it will only output a String or Array, not both
      if (Array.isArray(inputString)) {
        return inputString;
      }

      return inputString ? inputString.split(',') : [];
    } catch (error) {
      console.error(`Error on parseStringArray("${inputString}"): ${error}`);
      return [];
    }
  };

  return {
    appendToRtx: target.append_to_rtx || '',
    createdUser: target.created_user || '',
    createdUTC: target.created_utc || '',
    customCSS: hexToAscii(target.custom_css),
    customCSSHash: target.custom_css_hash || '',
    customJS: hexToAscii(target.custom_js),
    customJSHash: target.custom_js_hash || '',
    deploymentValue: target.deployment_value || '',
    isActive: target.is_active || false,
    isLive: target.is_live || false,
    isMonitored: target.is_monitored || false,
    isSpiderMultiDomain: target.is_spider_multi_domain || false,
    lastModUser: target.lastmod_user || '',
    lastModUTC: target.lastmod_utc || '',
    linkedTranslationKey: target.linked_translation_key || '',
    liveHost: target.live_host || '',
    mtApiCode: target.mt_api_code || '',
    mtApiKey: target.mt_api_key || '',
    mtCategory: target.mt_category || '',
    mtEndpoint: target.mt_endpoint || '',
    mtPivot: target.mt_pivot || '',
    pdSourceLangCode: target.pd_source_lang_code || '',
    pdTargetLangCode: target.pd_target_lang_code || '',
    preserveOPEConflicts: Boolean(target.preserve_ope_conflicts),
    pretranslateMode: target.pretranslate_mode || '',
    projectKey: target.project_key || '',
    proxyHost: target.proxy_host || '',
    rtxTranslationMethods: parseStringArray(target.rtx_translation_methods),
    sitemapDomain: target.sitemap_domain || '',
    sitemapEnable: Boolean(target.sitemap_enable),
    sitemapExclude: parseStringArray(target.sitemap_exclude),
    sitemapFolder: target.sitemap_folder || '',
    sitemapProtocol: target.sitemap_protocol || '',
    sitemapSuffix: target.sitemap_suffix || '',
    sourceLangCode: target.source_lang_code || '',
    sourceLangName: target.source_lang_name || '',
    sourceNativeName: target.source_native_name || '',
    stagingCustomCSS: hexToAscii(target.staging_custom_css),
    stagingCustomCSSHash: target.staging_custom_css_hash || '',
    stagingCustomJS: hexToAscii(target.staging_custom_js),
    stagingCustomJSHash: target.staging_custom_js_hash || '',
    stagingHost: target.staging_host || '',
    targetConfig: hexToObject(target.target_config),
    targetConfigHash: target.target_config_hash || '',
    targetLangCode: target.target_lang_code || '',
    targetLangName: target.target_lang_name || '',
    targetName: target.target_name || '',
    targetNativeName: target.target_native_name || '',
    targetSubDir: target.target_subdir || '',
    tmgrPass: target.tmgr_pass || '',
    tmgrProjectShortCode: target.tmgr_project_shortcode || '',
    tmgrProjectTicket: target.tmgr_project_ticket || '',
    tmgrSourceLangCode: target.tmgr_source_lang_code || '',
    tmgrTargetLangCode: target.tmgr_target_lang_code || '',
    tmgrUrl: target.tmgr_url || '',
    tmgrUser: target.tmgr_user || '',
    tmsCleanupPass: target.tms_cleanup_pass || '',
    tmsPass: target.tms_pass || '',
    tmsTmLocation: target.tms_tmlocation || '',
    tmsUrl: target.tms_url || '',
    tmsUser: target.tms_user || '',
    translationConfig: hexToObject(target.translation_config),
    translationConfigHash: target.translation_config_hash || '',
    translationKey: target.translation_key || '',
    translationMethods: parseStringArray(target.translation_methods),
  };
};

export const parseTargets = (translationKeys: TargetResponse[]): Target[] =>
  translationKeys.map(parseTarget);

export const useProjectTranslationStore = create<ProjectTranslationStore>()(
  (set, get) => ({
    loading: false,
    selectedTarget: defaultTarget,
    targets: [],
    xapisTargets: [],
    yyTarget: defaultTarget,
    yyTranslationKey: '',
    fetchTargets: (projectKey, failed, success, cleanup) => {
      const { loading } = get();
      if (loading) {
        return;
      }
      set({ loading: true });

      Get(
        `ProjectTranslation/${projectKey}`,
        (response: AxiosResponse) => {
          const {
            data: { translation_keys: fetchedTargets = [] },
          }: {
            data: {
              translation_keys: TranslationKey[];
            };
          } = response;

          const parsedTargets = parseTargets(fetchedTargets);
          const [firstTarget = defaultTarget] = parsedTargets;
          const yyTarget = parsedTargets.find(
            ({ targetLangCode = '' }) => targetLangCode === 'YY'
          );

          set({
            selectedTarget: firstTarget,
            targets: parsedTargets,
            xapisTargets: fetchedTargets,
            yyTarget,
            yyTranslationKey: yyTarget?.translationKey || '',
          });

          success && success(parsedTargets);
        },
        {
          errorMessage: failed?.errorMessage || '',
          action: () => {
            set({ targets: [] });
            failed?.action && failed.action();
          },
        },
        {},
        () => {
          set({ loading: false });
          cleanup && cleanup();
        }
      );
    },
    fetchTarget: (prevTargets, projectKey, translationKey, cleanup) => {
      set({ loading: true });

      Get(
        `ProjectTranslation/${projectKey}/${translationKey}`,
        ({ data }: { data: TargetResponse }) => {
          const parsedTarget = parseTarget(data);
          const newTargets = [...prevTargets, parsedTarget];
          set({ targets: newTargets });
        },
        {},
        {},
        () => {
          set({ loading: false });
          cleanup && cleanup();
        }
      );
    },
    createTarget: async (projectKey, data, success, failed) => {
      const { loading } = get();
      if (!loading) {
        set({ loading: true });
      }

      return PosterPromise(`ProjectTranslation/${projectKey}`, data)
        .then((response: AxiosResponse) => {
          const {
            data: createdTarget,
            status = 0,
          }: {
            data: TranslationKey;
            status: number;
          } = response;

          if (status !== ALREADY_CREATED_CODE) {
            const { targets, xapisTargets } = get();
            const newTargets = targets.concat(parseTarget(createdTarget));
            const newXapisTargets = xapisTargets.concat(createdTarget);
            set({ targets: newTargets, xapisTargets: newXapisTargets });
          }
          success && success(response);
          return response;
        })
        .catch((error: AxiosError) => {
          const { response = {} } = error;
          failure(error, failed?.errorMessage);
          failed?.action && failed.action();
          return response;
        })
        .finally(() => {
          set({ loading: false });
        });
    },
    deleteTarget: async (projectKey, translationKey, success, failed) => {
      const { loading } = get();
      if (!loading) {
        set({ loading: true });
      }

      return DeleterPromise(
        `ProjectTranslation/${projectKey}/${translationKey}`
      )
        .then((response: AxiosResponse) => {
          const { targets, xapisTargets } = get();
          const newTargets = targets.filter(
            ({ translationKey: targetTranslationKey = '' }) =>
              targetTranslationKey !== translationKey
          );
          const newXapisTargets = xapisTargets.filter(
            (t) => t.translation_key !== translationKey
          );
          set({ targets: newTargets, xapisTargets: newXapisTargets });
          success && success(response);
          return response;
        })
        .catch((error: AxiosError) => {
          const { response = {} } = error;
          failure(failed?.errorMessage || error || '');
          failed?.action && failed.action();
          return response;
        })
        .finally(() => {
          set({ loading: false });
        });
    },
    updateTarget: async (projectKey, translationKey, data, success, failed) => {
      const { loading } = get();
      if (!loading) {
        set({ loading: true });
      }

      return PutterPromise(
        `ProjectTranslation/${projectKey}/${translationKey}`,
        data
      )
        .then((response: AxiosResponse<TranslationKey>) => {
          const { data: updatedTarget } = response;

          const { targets, xapisTargets, yyTranslationKey } = get();

          const isYYTarget = translationKey === yyTranslationKey;

          const newTargets = targets.map((target) => {
            const { translationKey: targetTranslationKey = '' } = target;

            return targetTranslationKey === translationKey
              ? parseTarget(updatedTarget)
              : target;
          });
          const newXapisTargets = xapisTargets.map((t) =>
            t.translation_key === translationKey ? updatedTarget : t
          );
          set({
            targets: newTargets,
            xapisTargets: newXapisTargets,
            ...(isYYTarget && { yyTarget: parseTarget(updatedTarget) }),
          });
          success && success(response);
          return response;
        })
        .catch((error: AxiosError<XapisErrorResponse>) => {
          const { response = defaultXapisErrorResponse } = error;
          const { data: { message: responseErrorMessage = '' } = {} } =
            response;

          failure(failed?.errorMessage || responseErrorMessage || error || '');
          failed?.action && failed.action();

          return response;
        })
        .finally(() => {
          set({ loading: false });
        });
    },
    updateTargetsCustomCSS: async (projectKey, updatedCustomCSS) => {
      const { loading, updateTarget, yyTarget, fetchTargets } = get();
      if (!loading) {
        set({ loading: true });
      }

      const cssHex = asciiToHex(updatedCustomCSS);
      const {
        customCSSHash: previousCSSHash = '',
        stagingCustomCSSHash: previousStagingCSSHash = '',
        translationKey = '',
      } = yyTarget;
      return updateTarget(
        projectKey,
        translationKey,
        {
          custom_css: cssHex,
          custom_css_hash: previousCSSHash,
          staging_custom_css: cssHex,
          staging_custom_css_hash: previousStagingCSSHash,
        },
        () => fetchTargets(projectKey),
        {
          errorMessage:
            'Unable to update language selector appearance at this time.',
        }
      ).finally(() => {
        set({ loading: false });
      });
    },
    setSelectedTarget: (selected) => {
      set({ selectedTarget: selected });
    },
  })
);

export default useProjectTranslationStore;
export const useActiveTarget = () =>
  useProjectTranslationStore((state) => state.selectedTarget);
export const useActiveXapisTarget = () =>
  useProjectTranslationStore((state) => {
    const key = state.selectedTarget.translationKey;
    return state.xapisTargets.find((target) => target.translation_key === key);
  });
