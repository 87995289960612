import React from 'react';
import { Box, Card, Flex, Grid, ScrollArea, Text, Title } from '@mantine/core';
import ConfirmDeleteMenu from './ConfirmDeleteMenu';
import { SpiderTreeNode } from 'store';
import classes from './RulesSection.module.css';

export type Rule = {
  pattern: string;
  desc: string;
  uri: string;
};

type Props = {
  detailedRules: Rule[];
  rulesList: string[];
  yyTarget: TranslationKey;
  setYYTarget: React.Dispatch<React.SetStateAction<TranslationKey>>;
  setSpiderTree: React.Dispatch<React.SetStateAction<SpiderTreeNode[]>>;
};

const RulesCard = ({
  detailedRules,
  rulesList,
  yyTarget,
  setYYTarget,
  setSpiderTree,
}: Props) => {
  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      classNames={{
        section: classes.rulesSection,
      }}
      style={{ borderRadius: 15, overflow: 'visible' }}
    >
      <Card.Section
        bg="background2.0"
        p="1rem 3rem 1rem 1.5rem"
        style={{
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
        }}
      >
        <Title
          order={3}
          mr={{ base: '0rem', sm: '2rem', lg: '3rem' }}
          tt="uppercase"
        >
          Rules
        </Title>
      </Card.Section>
      <Card.Section>
        <ScrollArea
          h={280}
          w="100%"
          p={0}
          scrollbarSize={18}
          bg="background.0"
          offsetScrollbars
          style={{
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
          }}
        >
          {detailedRules.length > 0 &&
            detailedRules.map((rule: Rule) => {
              return (
                <Box key={rule.uri}>
                  <Grid
                    className={classes.rulesSection}
                    display={{ base: 'none', lg: 'flex' }}
                    w="100%"
                  >
                    <Grid.Col span={2}>
                      <Text c="text.3" ml="0.25rem">
                        Url {rule.desc}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Text c="text.3" truncate>
                        {rule.uri}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Text c="text.3" truncate>
                        {rule.pattern}
                      </Text>
                    </Grid.Col>
                    <Grid.Col
                      span={2}
                      m={0}
                      pl="1rem"
                      style={{ display: 'flex', justifyContent: 'end' }}
                    >
                      <ConfirmDeleteMenu
                        ruleToRemove={rule.pattern}
                        rulesList={rulesList}
                        yyTarget={yyTarget}
                        setYYTarget={setYYTarget}
                        setSpiderTree={setSpiderTree}
                      />
                    </Grid.Col>
                  </Grid>
                  <Flex
                    className={classes.rulesSection}
                    display={{ lg: 'none' }}
                    justify="space-between"
                  >
                    <Box>
                      <Text c="text.3" mb="0.5rem">
                        Url{' '}
                        <Text c="text.3" fw={600} span>
                          {rule.desc}
                        </Text>{' '}
                        {rule.uri}
                      </Text>
                      <Text c="text.3">
                        <Text c="text.3" fw={600} span>
                          Pattern:
                        </Text>{' '}
                        {rule.pattern}
                      </Text>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        paddingLeft: '1rem',
                      }}
                    >
                      <ConfirmDeleteMenu
                        ruleToRemove={rule.pattern}
                        rulesList={rulesList}
                        yyTarget={yyTarget}
                        setYYTarget={setYYTarget}
                        setSpiderTree={setSpiderTree}
                      />
                    </Box>
                  </Flex>
                </Box>
              );
            })}
        </ScrollArea>
      </Card.Section>
    </Card>
  );
};

export default RulesCard;
