import { create } from 'zustand';
import Get from './xapis-wrappers/Fetcher';

type Response = {
  abbyy_code: string;
  aws_code: string;
  google_code: string;
  is_canonical: '1' | '0';
  langcode_code: string;
  langcode_name: string;
  language_iana_code: string;
  language_iso2_code: string;
  language_iso3_code: string;
  language_name: string;
  locale_code: string;
  locale_name: string;
  microsoft_code: string;
  tesseract_code: string;
  ty_code: string;
};

export type LanguageCodesStore = {
  language_codes: Response[];
  fetchLanguageCodes: () => void;
};

export const useLanguageCodesStore = create<LanguageCodesStore>()((set) => ({
  language_codes: [],
  fetchLanguageCodes: () => {
    Get(
      `LanguageCode/google`,
      ({
        data: { language_codes },
      }: {
        data: { language_codes: Response[] };
      }) => {
        set({ language_codes });
      },
      { action: () => set({ language_codes: [] }) }
    );
  },
}));

export default useLanguageCodesStore;
