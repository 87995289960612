import { create } from 'zustand';
import Get from './xapis-wrappers/Fetcher';
import { FetcherPromise } from './xapis-wrappers';

export type WordsServedUrlsResponse = {
  [translation_key: string]: number;
} & {
  content_url: string;
  content_url_hash: number;
};

export type WordsServedUrlsStore = {
  loading: boolean;
  wordsServedUrls: WordsServedUrlsResponse[];
  fetchWordsServedUrls: (
    translationKeys: string,
    timeSliceFrom: string,
    failed: { errorMessage?: string; action?: () => void }
  ) => void;
};

export const useWordsServedUrlsStore = create<WordsServedUrlsStore>()(
  (set, get) => ({
    loading: false,
    wordsServedUrls: [],
    fetchWordsServedUrls: (translationKeys, timeSliceFrom, failed) => {
      const { loading } = get();
      if (loading) return;

      const params = {
        translation_key: translationKeys,
        timeslice_from: timeSliceFrom,
        type: 'url',
        series: 'tot_words_served',
        rollup: timeSliceFrom === '1 year' ? 'month' : 'day',
      };
      set({ loading: true });
      Get(
        'Metrics',
        ({
          data: { data = [] },
        }: {
          data: { data: WordsServedUrlsResponse[] };
        }) => {
          set({ wordsServedUrls: data });
        },
        {
          errorMessage: failed.errorMessage || '',
          action: () => {
            set({ wordsServedUrls: [] });
            failed.action && failed.action();
          },
        },
        params,
        () => set({ loading: false })
      );
    },
  })
);

export const fetchWordsServedUrlsPromise = (
  translationKeys: string,
  timeSliceFrom: string
) => {
  const params = {
    translation_key: translationKeys,
    timeslice_from: timeSliceFrom,
    type: 'url',
    series: 'tot_words_served',
    rollup: timeSliceFrom === '1 year' ? 'month' : 'day',
  };

  return FetcherPromise('Metrics', params);
};
