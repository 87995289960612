import { Params } from 'react-router-dom';
import { xapisEnv } from '../../service-wrappers/xapisService';
import { queryClient } from '../../queries/queryClient';
import { queryRecommendedPlan } from '../../queries/selectPlanQueries';
import { projectQuery } from '../../queries/projectQuery';

export async function selectPlanLoader({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) {
  const { projectId } = params;
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }

  const { payKey } = xapisEnv.getHost;
  const url = new URL(request.url);
  const threshold = url.searchParams.get('threshold');

  const data = await queryClient.ensureQueryData(projectQuery(projectId));
  const origin_name = data.project?.origin_name || '';

  await queryClient.ensureQueryData(
    queryRecommendedPlan(origin_name, payKey, threshold || '')
  );

  return { threshold, url: origin_name };
}

export type SelectPlanLoaderResponse = Awaited<
  ReturnType<typeof selectPlanLoader>
>;
