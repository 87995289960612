import React, { useMemo } from 'react';
import { Flex } from '@mantine/core';
import {
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip as ReTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomDot from '../../recharts/CustomDot';
import CustomChartTooltip from '../../recharts/CustomChartTooltip';
import { NoDataFoundPlaceHolder } from '../../../Generic';
import { WordsServedResponse } from 'store';
import { WordsServedWithRemaining } from '../types';
import { useMediaQuery } from '@mantine/hooks';
import {
  getYAxisMaxValueAndWidth,
  isThresholdEndpointValid,
} from '../../recharts/utils';
import moment from 'moment';
import { getTick } from './utils';

const WordsServedUsageChart = ({
  todaysDate,
  monthlyWordsServed,
  monthlyWordsServedWithRemaining,
  thresholdValue,
}: {
  todaysDate: string;
  monthlyWordsServed: WordsServedResponse[];
  monthlyWordsServedWithRemaining: WordsServedWithRemaining[];
  thresholdValue: number;
}) => {
  const isMobileSize = useMediaQuery('(max-width: 991px)') || false;

  const hasData = !!monthlyWordsServedWithRemaining.length;

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const lastDayInMonth = new Date(currentYear, currentMonth, 0).getDate();

  const stringMonth =
    currentMonth.toString().length === 1
      ? `0${currentMonth}`
      : `${currentMonth}`;

  const firstTimeSlice = hasData
    ? monthlyWordsServedWithRemaining[0]?.timeslice || ''
    : `${currentYear}-${stringMonth}-01`;

  const lastTimeSlice = hasData
    ? monthlyWordsServedWithRemaining[
        monthlyWordsServedWithRemaining.length - 1
      ]?.timeslice || ''
    : `${currentYear}-${stringMonth}-${lastDayInMonth}`;

  const accumWordsServedToday = useMemo(() => {
    return monthlyWordsServed.reduce((accum, { count = 0 }) => {
      return accum + count;
    }, 0);
  }, [monthlyWordsServed]);

  const { yAxisMaxValue = 0 } = getYAxisMaxValueAndWidth(
    monthlyWordsServedWithRemaining,
    2
  );
  const hasValidThresholdEndpoint = isThresholdEndpointValid(
    todaysDate,
    lastDayInMonth
  );

  const getThresholdEndpoint = () => {
    if (todaysDate && hasValidThresholdEndpoint && !isMobileSize) {
      return (
        moment(todaysDate).add(2, 'days').format('YYYY-MM-DD') || lastTimeSlice
      );
    }
    return lastTimeSlice;
  };

  if (!hasData) {
    return (
      <Flex w="100%" h={400} align="center" justify="center">
        <NoDataFoundPlaceHolder height={240} />
      </Flex>
    );
  }

  return (
    <>
      <ResponsiveContainer height={400} width="100%">
        <AreaChart
          margin={{ top: 50, right: 0, left: -55, bottom: 0 }}
          data={monthlyWordsServedWithRemaining}
        >
          <defs>
            <linearGradient id="wordsServed" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#0071DB" stopOpacity={0.5} />
              <stop offset="95%" stopColor="#0071DB" stopOpacity={0} />
            </linearGradient>
          </defs>
          {yAxisMaxValue && (
            <ReferenceLine
              y={yAxisMaxValue}
              stroke="#071D49"
              strokeDasharray="3 3"
            />
          )}
          <ReferenceLine
            stroke="black"
            segment={[
              { x: todaysDate, y: 0 },
              { x: todaysDate, y: accumWordsServedToday },
            ]}
          />
          <ReferenceLine
            x={lastTimeSlice}
            stroke="black"
            strokeDasharray="3 3"
          />
          <YAxis
            dataKey="count"
            stroke="black"
            axisLine={true}
            tickLine={false}
            tick={false}
            allowDuplicatedCategory={false}
            domain={[0, yAxisMaxValue]}
          />
          <XAxis
            dataKey="timeslice"
            stroke="black"
            tick={(props) =>
              getTick(
                todaysDate,
                firstTimeSlice,
                lastTimeSlice,
                isMobileSize,
                props
              )
            }
            tickLine={false}
            allowDuplicatedCategory={false}
            interval={isMobileSize ? 'preserveStartEnd' : 0}
            minTickGap={0}
          />
          <Area
            dataKey="count"
            fill="url(#wordsServed)"
            stroke="#0071DB"
            strokeWidth={4}
            dot={<CustomDot date={todaysDate} />}
            activeDot={{ r: 5, fill: '#0071DB', strokeWidth: 0 }}
          />
          {thresholdValue && (
            <ReferenceLine
              isFront={true}
              stroke="#D93025"
              strokeDasharray="3 3"
              label={{
                position: 'right',
                value:
                  !hasValidThresholdEndpoint || isMobileSize
                    ? ''
                    : thresholdValue.toLocaleString(),
                fontWeight: 600,
                fontSize: 14,
                fill: '#D93025',
              }}
              segment={[
                { x: firstTimeSlice, y: thresholdValue },
                {
                  x: getThresholdEndpoint(),
                  y: thresholdValue,
                },
              ]}
            />
          )}
          <ReTooltip content={<CustomChartTooltip />} cursor={false} />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default WordsServedUsageChart;
