import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  Skeleton,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { HiPencil } from 'react-icons/hi';
import { IoMdLock } from 'react-icons/io';
import { MdArrowRightAlt } from 'react-icons/md';
import { Header, CircleAvatar } from '../../../Generic';
import { InfoIcon } from '../../../../../StaticIcons';
import EditProfile from './EditProfile';
import PersonalInformation from '../PersonalInformation';
import {
  useCurrentProjectData,
  UserInfo,
  useUserActions,
  useUserProvider,
} from 'store';
import { notifications } from '@mantine/notifications';
import { useFetcher } from 'react-router-dom';
import classes from './MyProfile.module.css';
import { useGetNavigationStatus } from 'hooks';

export type Inputs = {
  firstName: string;
  lastName: string;
  phone: string;
};

export const MyProfile = () => {
  const { colors } = useMantineTheme();
  const { project } = useCurrentProjectData();

  const { xapisUser: user, setXapisUser } = useUserProvider();
  const fetcher = useFetcher();
  const [showForm, setShowForm] = useState(false);
  const [isPasswordResetSent, setIsPasswordResetSent] = useState(false);

  const loading =
    useGetNavigationStatus().isNavigatingInPlace || fetcher.state !== 'idle';

  const {
    first_name = '',
    last_name = '',
    phone = '',
  } = useMemo(() => {
    const isFetcherSuccessful = fetcher.data?.success;
    const first_name = isFetcherSuccessful
      ? fetcher.data?.first_name
      : user.first_name;
    const last_name = isFetcherSuccessful
      ? fetcher.data?.last_name
      : user.last_name;
    const phone = isFetcherSuccessful ? fetcher.data?.phone : user.phone;
    return {
      first_name,
      last_name,
      phone,
    };
  }, [fetcher.data, user]);

  const { origin_name } = project;

  const { user_key, email = '', company_name } = user;

  const updateUser = useUserActions().updateUser;

  const avatarInitials = useMemo(() => {
    if (!first_name || !last_name) return '  ';
    const [first, last] = [first_name, last_name].map((n) =>
      n[0].toLocaleUpperCase()
    );
    return first + last;
  }, [first_name, last_name]);

  useEffect(() => {
    if (fetcher.data) {
      const { first_name = '', last_name = '', phone = '' } = fetcher.data;
      if (
        first_name !== user.first_name ||
        last_name !== user.last_name ||
        phone !== user.phone
      ) {
        setXapisUser({ ...user, phone, first_name, last_name });
      }
    }
  }, [fetcher, setXapisUser, user]);

  const updateUserInfo = (
    data: Partial<UserInfo> & { reset_password_email?: boolean }
  ) => {
    if (isPasswordResetSent) {
      notifications.show({
        message: 'Please check your inbox for a previously sent reset email.',
      });
      return;
    }

    updateUser(
      user_key,
      data,
      () => {
        setIsPasswordResetSent(true);
        notifications.show({
          message: 'Password reset email sent successfully!',
        });
      },
      { errorMessage: 'Unable to sent password reset email at this time.' }
    );
  };

  return (
    <Box maw={{ base: '100%', md: '90%', lg: '65%' }}>
      <Header title="Basic Information" />
      <Card
        className={classes.basicInfoCard}
        shadow="sm"
        p={{ base: '1.25rem', md: '1.875rem' }}
        radius="md"
      >
        <Box className={classes.basicInfoBox}>
          <Card.Section
            w="100%"
            p={{ base: '1rem', md: '1.5rem' }}
            mx="auto"
            className={classes.topCardSection}
          >
            <Group justify="center">
              <Flex>
                <CircleAvatar
                  name={avatarInitials}
                  format={false}
                  backgroundColor="background2.7"
                  textColor="#071D49"
                  circleSize="4.5rem"
                  fontSize="1.75rem"
                />
              </Flex>
              <Flex direction="column">
                <Title order={2}>
                  {first_name} {last_name}
                </Title>
                <Text>{company_name}</Text>
              </Flex>
            </Group>
          </Card.Section>
          <Divider
            mx={{ base: '1rem', md: '2.5rem' }}
            color="divider.0"
            size="xs"
          />
          <Card.Section
            w="100%"
            px={{ base: '1rem', md: '2.5rem' }}
            py={{ base: '1rem', md: '1.5rem' }}
            mx="auto"
            display="flex"
            style={{ flexDirection: 'column' }}
            key={fetcher.data?.first_name}
          >
            <Flex w="100%" justify="space-between" align="center" gap="0.5rem">
              <Title order={3} tt="uppercase">
                Personal Information
              </Title>
              {!showForm && (
                <Button
                  px={0}
                  variant="transparent"
                  onClick={() => setShowForm(true)}
                  rightSection={<HiPencil size={18} />}
                >
                  Edit Info
                </Button>
              )}
            </Flex>
            <Flex py="1rem" wrap="wrap">
              {loading ? (
                <>
                  <Skeleton height={16} mt={20} width="80%" radius="xl" />
                  <Skeleton height={8} mt={60} width="70%" radius="xl" />
                  <Skeleton height={8} mt={6} mb={30} width="60%" radius="xl" />
                </>
              ) : showForm ? (
                <EditProfile
                  setShowForm={setShowForm}
                  fetcher={fetcher}
                  defaultValues={{
                    origin_name,
                    first_name,
                    last_name,
                    phone,
                    email,
                    user_key,
                  }}
                />
              ) : (
                <PersonalInformation
                  firstName={first_name || ''}
                  lastName={last_name || ''}
                  phoneNumber={phone || ''}
                />
              )}
            </Flex>
          </Card.Section>
          <Divider
            mx={{ base: '1rem', md: '2.5rem' }}
            color="divider.0"
            size="xs"
          />
          <Card.Section
            w="100%"
            display="flex"
            px={{ base: '1rem', md: '2.5rem' }}
            py={{ base: '1rem', md: '1.5rem' }}
            pb="3rem"
            mx="auto"
            className={classes.bottomCardSection}
          >
            <Flex
              className={classes.emailSection}
              w={{ base: '100%', lg: '50%' }}
              justify="start"
              direction="column"
              rowGap="0.75rem"
            >
              <Flex align="center">
                <Text fw={700} mr={10}>
                  Email Address
                </Text>
                <IoMdLock />
              </Flex>
              <Text truncate>{email}</Text>
            </Flex>
            <Flex
              w={{ base: '100%', lg: '50%' }}
              justify="start"
              direction="column"
              rowGap="0.75rem"
            >
              <Flex align="center">
                <Group gap="xs">
                  <Text fw={700}>Password</Text>
                  <Tooltip label="Passwords are managed by TransPerfect SSO. You will be redirected to a TransPerfect page.">
                    <Box>
                      <InfoIcon size={15} fill={colors.secondary1[0]} />
                    </Box>
                  </Tooltip>
                </Group>
              </Flex>
              <Text>********************</Text>
              <Flex
                columnGap="0.5rem"
                style={{ cursor: 'pointer' }}
                onClick={() => updateUserInfo({ reset_password_email: true })}
              >
                <Text c="text.8" fw={700}>
                  Change Password
                </Text>
                <MdArrowRightAlt size={24} color="#0071DB" />
              </Flex>
            </Flex>
          </Card.Section>
        </Box>
      </Card>
    </Box>
  );
};
