import { Xapis } from '../../xapis-wrappers/xapis';
import { Params } from 'react-router-dom';
import { decodeProjectId } from 'helpers';
import { xapisEnv } from '../../service-wrappers/xapisService';

import { Order, fetchOrdersPromise } from '../../PayOrderStore';

export type OrderConfirmationLoaderData = {
  status: number;
  project?: ProjectKey | undefined;
  message?: string | undefined;
  currentOrderId: string;
  currentOrderThreshold: number;
};

export async function orderConfirmationLoader({
  params,
}: {
  params: Params<string>;
  request: Request;
}) {
  const { projectId } = params;
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }
  const projectKey = decodeProjectId(projectId);
  const response = await Xapis.Project.get(projectKey);

  const data = {
    currentOrderId: '',
    currentOrderThreshold: 0,
    ...response,
  };

  const { payKey } = xapisEnv.getHost;

  const queryParams = new URLSearchParams(window.location.search);
  const idInParams = queryParams.get('orderId') || '';
  const idInStorage = sessionStorage.getItem('orderId') || '';

  try {
    if (idInParams) {
      const order = await getCurrentOrder(payKey, idInParams);
      if (order) {
        const { currentOrderThreshold = 0, currentOrderId = '' } = order;
        return { ...data, currentOrderThreshold, currentOrderId };
      }
    }

    if (idInStorage) {
      const order = await getCurrentOrder(payKey, idInStorage);
      if (order) {
        const { currentOrderThreshold = 0, currentOrderId = '' } = order;
        return { ...data, currentOrderThreshold, currentOrderId };
      }
    }
  } catch (err) {
    console.error('error in fetchOrders fn in order conf loader:', err);
  }

  return data;
}

async function getCurrentOrder(payKey: string, orderId: string) {
  const res = await fetchOrdersPromise(payKey, { order_id: orderId });
  const { orders = [] } = res.data as { orders: Order[] };

  const order = orders.find(({ order_id }) => order_id === orderId);
  if (order) {
    return {
      currentOrderThreshold: order?.order_items[0].sku_threshold_value || 0,
      currentOrderId: order?.order_id || '',
    };
  }
  return null;
}
