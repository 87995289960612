import React from 'react';
import { Flex, UnstyledButton } from '@mantine/core';
import { Tip } from './Tip';
import classes from './QuickAction.module.css';

export default function QuickAction({
  icon = <></>,
  width = 30,
  height = 30,
  borderRadius = 5,
  isActive = false,
  backgroundColor = 'transparent',
  onClick = () => undefined,
  onMouseDown = () => undefined,
  isDisabled = false,
  label = '',
}: {
  icon: React.ReactNode;
  label?: React.ReactNode;
  width: number | string;
  height: number | string;
  borderRadius?: number | string;
  isActive?: boolean;
  backgroundColor: string;
  onClick?: () => void;
  onMouseDown?: () => void;
  isDisabled?: boolean;
}) {
  return (
    <Tip label={label} variant="dark">
      <UnstyledButton
        p={0}
        m={0}
        w={width}
        h={height}
        bg={
          !isActive || isDisabled ? 'transparent' : backgroundColor
        }
        className={classes.quickAction}
        style={{
          '--hover-bg': `${backgroundColor}`,
          borderRadius: borderRadius,
        }}
        disabled={isDisabled}
        onClick={onClick}
        onMouseDown={onMouseDown}
      >
        <Flex h={30} align="center" justify="center">
          {icon}
        </Flex>
      </UnstyledButton>
    </Tip>
  );
}
