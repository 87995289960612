import React from 'react';
import { SkuWCreatedAndLastMod } from 'store';
import { PlanOption } from '../plan-information/PlanInformation';
import { Flex, ScrollArea } from '@mantine/core';
import MobilePlan from './MobilePlan';

type Props = {
  plans: PlanOption[];
  handleSubSelect(item: PlanOption): void;
  currentPlan: PlanOption;
  selectedPlan: PlanOption | null;
  recommendedPlan: Partial<SkuWCreatedAndLastMod>;
};
const MobilePlanOptions = ({
  plans,
  handleSubSelect,
  currentPlan,
  selectedPlan,
  recommendedPlan,
}: Props) => (
  <ScrollArea type="auto" h={300} offsetScrollbars>
    <Flex direction="column" gap={10}>
      {plans.map((plan) => {
        const isCurrentPlan =
          plan.sku_name === currentPlan.sku_name &&
          plan.price === currentPlan.price;
        const isSelectedPlan =
          !isCurrentPlan && plan.sku_name === selectedPlan?.sku_name;
        const isRecommendedPlan =
          plan.threshold_value === recommendedPlan.threshold_value;
        return (
          <MobilePlan
            key={plan.sku_name}
            plan={plan}
            isCurrentPlan={isCurrentPlan}
            isSelectedPlan={isSelectedPlan}
            isRecommendedPlan={isRecommendedPlan}
            handleSubSelect={handleSubSelect}
          />
        );
      })}
    </Flex>
  </ScrollArea>
);

export default MobilePlanOptions;
