import { AxiosError, AxiosResponse } from 'axios';
import { PosterPromise } from './xapis-wrappers';
import useProjectTranslationStore from './ProjectTranslationStore';
import useProjectsStore from './ProjectsStore';
import { getUserKey } from './xapis-wrappers/xapis';

export const startSpiderJob = async (): Promise<
  AxiosResponse | Record<string, never>
> => {
  const userKey = getUserKey();
  const { translationKey } = useProjectTranslationStore.getState().yyTarget;
  const projectName = useProjectsStore.getState().activeProject.projectName;

  const url = `SpiderJob/${translationKey}`;
  const body = {
    job_name: `${projectName}-topology-crawl`,
    user_key: userKey,
    debug_level: 0,
    status: 'START',
    seo_only: 'OFF',
    max_spider_minutes: 10,
  };

  return PosterPromise(url, body)
    .then((response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      const { response = {} } = error;
      return response;
    });
};
