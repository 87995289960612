import { useMediaQuery } from '@mantine/hooks';

export const useViewSize = (): {
  isMobileSize: boolean;
  isTabletSize: boolean;
  isDesktopSize: boolean;
} => {
  const isMobileSize = useMediaQuery('(max-width: 991px)');
  const isTabletSize = useMediaQuery(
    '(min-width: 992px) and (max-width: 1199px)'
  );
  const isDesktopSize = useMediaQuery('(min-width: 1200px)');

  return {
    isMobileSize: Boolean(isMobileSize),
    isTabletSize: Boolean(isTabletSize),
    isDesktopSize: Boolean(isDesktopSize),
  };
};
