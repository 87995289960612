import { AxiosError, AxiosResponse } from 'axios';
import usePayKeyStore from './PayKeyStore';
import { FetcherPromise, PosterPromise } from './xapis-wrappers';
import { isSuccessStatus } from 'helpers';

export type QuickQuoteResponse = {
  qmessage: string;
  spider_job: {
    num_words: string;
    num_pages: string;
    average_words_per_page: number;
  };
  traffic: {
    results: { visits: string; pages_per_visit: string };
  };
};

export const startQuickQuote = async (
  domain: string,
  email: string
): Promise<AxiosResponse<QuickQuoteResponse> | Record<string, never>> => {
  const payKey = usePayKeyStore.getState().payKey;
  const encodedDomain = encodeURIComponent(domain);
  const params = {
    email,
    start_url: encodedDomain,
    pay_key: payKey,
    max_spider_minutes: 10,
  };
  return PosterPromise('QuickQuote', {}, params)
    .then((response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      const { response = {} } = error;
      return response;
    });
};

export const getQuickQuote = async (
  domain: string
): Promise<AxiosResponse<QuickQuoteResponse> | Record<string, never>> => {
  return FetcherPromise('QuickQuote', { domain: encodeURI(domain) })
    .then((response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      const { response = {} } = error;
      return response;
    });
};

export const handleQuickQuote = async (
  domain: string,
  email: string
): Promise<AxiosResponse<QuickQuoteResponse> | Record<string, never>> => {
  try {
    const { data: { qmessage = '' } = {}, status = 0 } =
      await getQuickQuote(domain);
    if (isSuccessStatus(status) && !qmessage.includes('result was cached')) {
      return await startQuickQuote(domain, email);
    }
    return Promise.reject({});
  } catch (error) {
    return Promise.reject({});
  }
};
