import moment from 'moment/moment';
import { AccountMTUsageResponse } from 'store';

export const formatChartDateLabel = (date: string) => {
  if (date.length <= 7) {
    return moment(date).format('MM/YY') || date;
  } else if (date.split(' ').length === 2) {
    return moment(date).format('l') + ' ' + moment(date).format('LT') || date;
  } else {
    return moment(date).format('l') || date;
  }
};

export const calculateMaxYAxis = (
  accumulatedData: { timeslice: string; num_words_engine: number }[],
  mtUsageData: AccountMTUsageResponse
) => {
  if (!mtUsageData.length) {
    return { yAxisMaxCumulative: 0, yAxisMaxDaily: 0 };
  }

  const multiplier = 1.2;

  const lastAccumulatedData = accumulatedData[accumulatedData.length - 1];

  const yAxisMaxCumulative = lastAccumulatedData.num_words_engine * multiplier;
  const yAxisMaxDaily =
    Math.max(...mtUsageData.map((d) => Number(d.num_words_engine ?? 0))) *
    multiplier;

  return { yAxisMaxCumulative, yAxisMaxDaily };
};

export const formatTooltipDateLabel = (date: string) => {
  if (date.length <= 7) {
    return moment(date).format('MMMM YYYY') || date;
  } else if (date.split(' ').length === 2) {
    return moment(date).format('LLL');
  } else {
    return moment(date).format('LL') || date;
  }
};
