import React from 'react';
import { Text } from '@mantine/core';
import { dashboardRC } from 'helpers';
import CustomModal from '../../CustomModal';

type ModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  encodedProjectKey: string;
};

const SkipInjectionModal = ({
  isModalOpen,
  setIsModalOpen,
  encodedProjectKey,
}: ModalProps) => {
  return (
    <CustomModal
      opened={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      footerActions={[
        {
          label: 'Confirm',
          to: `/${encodedProjectKey}/${dashboardRC.PAGE_DASHBOARD}`,
        },
      ]}
    >
      <Text fz="md" ta="center">
        Skip the injection and verification step for now and continue to the
        dashboard.
        <br />
        <br />
        You'll have to inject the javascript on your site to preview your
        translations and enable all the functionality of the dashboard.
      </Text>
    </CustomModal>
  );
};

export default SkipInjectionModal;
