import { Xapis } from '../http/xapis';
import { segmenetEditorGlobals } from '../context/SegmentEditorProvider';
import { UnsavedSegment } from '../classes/segmentList';

export async function fetchBlockSegmentData(
  url: string,
  blocksData: BlockData[] | null
) {
  const { translationKey } = segmenetEditorGlobals;
  const api =
    blocksData && blocksData.length
      ? Xapis.BlockSegmentData.post(blocksData, translationKey, url)
      : Xapis.BlockSegmentData.get(translationKey, url);

  const response: {
    status: number;
    blockSegments: BlockSegment;
    error?: string;
  } = await api
    .then((res) => ({
      status: res.status,
      blockSegments: res.data?.blocks || {},
    }))
    .catch((e) => ({
      status: e.response?.status ?? 500,
      blockSegments: {},
      error: e.response?.data?.message ?? e.message,
    }));

  return response;
}

export async function saveSegmentsTranslation(
  unsavedList: UnsavedSegment[],
  segments: SegmentsObj
) {
  const { translationKey, userKey } = segmenetEditorGlobals;

  const saveData: SegmentContentData[] = unsavedList.map((s) => {
    const segment = segments[s.segment_hash];
    const { is_staging, segment_hash, target_jliff, segment_jliff } = segment;
    const status_code = s.setToLive ? 'live' : undefined;
    const target = s.target || target_jliff || segment_jliff;
    return {
      segment_hash,
      is_staging: is_staging as number,
      status_code,
      source: segment_jliff,
      target,
      translation_source: userKey,
    };
  });

  const { data, status } = await Xapis.SegmentContent.post(
    translationKey,
    userKey,
    saveData
  )
    .then((res) => res)
    .catch((e) => {
      return { status: e.response.status, data: e.response.data.message };
    });

  return { status, data };
}

export async function fetchTranslationHistory(
  segmentHash: string
): Promise<{ status: number; targets: SegmentTranslationHistory[] }> {
  const { translationKey, userKey } = segmenetEditorGlobals;

  const response = await Xapis.SegmentTranslationHistory.get(
    userKey,
    translationKey,
    segmentHash
  )
    .then((res) => ({
      status: res.status,
      targets: res.data?.targets,
    }))
    .catch((e) => {
      console.log('Error getting segment translation history:', e.response);
      return {
        status: e.response?.status || 500,
        targets: [],
      };
    });
  return response;
}

export async function deleteSegmentTranslation(segment: Segment) {
  const { translationKey, userKey } = segmenetEditorGlobals;

  const response = await Xapis.SegmentContent.delete(
    translationKey,
    userKey,
    segment.segment_hash,
    segment.is_staging
  )
    .then((res) => ({ status: res.status, data: res.data as Segment[] }))
    .catch((e) => {
      console.error('Error deleting segment translation:', e);
      return {
        status: e.response?.status || 500,
        data: e.response?.message || '',
      };
    });
  return response;
}
