import { SkuWCreatedAndLastMod } from 'store';

export const calculateMonthlyPageViews = (
  visits: string,
  pagesPerVisit: string
) => {
  const visitsNumber = Number(visits);
  const pagesPerVisitNumber = Number(pagesPerVisit);
  const monthlyPageViews = Math.round(visitsNumber * pagesPerVisitNumber);
  return monthlyPageViews > 5000 ? monthlyPageViews : 5000;
};

export const calculateEstimatedWordsServed = (
  translatedTraffic: number,
  averageWordsPerPage: number,
  monthlyPageViews: number
) => {
  const translatedTrafficDecimal = translatedTraffic / 100;
  return Math.round(
    averageWordsPerPage * (monthlyPageViews * translatedTrafficDecimal)
  );
};

/* Gets recommended plan from MONTHLY or YEARLY SKUs based on words served
- accounts for obscure SKUs and Enterprise */
export const getRecommendedPlan = (
  wordsServed: number,
  skus: SkuWCreatedAndLastMod[],
  subType: SkuWCreatedAndLastMod['subscription_type'] = 'MONTHLY'
): Partial<SkuWCreatedAndLastMod> => {
  if (skus.length === 0) {
    return {};
  }

  // Filter out free trial and unrelated sku types
  const filteredSkus = skus.filter(
    ({ price, subscription_type }) =>
      price != 0 && subscription_type === subType
  );

  const thresholdMap = filteredSkus.reduce(
    (accum: { [threshold: number]: SkuWCreatedAndLastMod }, filteredSkus) => {
      const threshold = filteredSkus.threshold_value;
      if (threshold !== undefined) {
        accum[threshold] = {
          ...filteredSkus,
        };
      }
      return accum;
    },
    {}
  );

  const maxThreshold = Math.max(
    ...filteredSkus.map(({ threshold_value }) => threshold_value)
  );
  const minThreshold = Math.min(
    ...filteredSkus.map(({ threshold_value }) => threshold_value)
  );
  const minPlan = thresholdMap[minThreshold];

  if (minPlan && wordsServed <= minThreshold) {
    return minPlan;
  }
  if (wordsServed > maxThreshold) {
    return {
      name: 'GlobalLink GO Enterprise',
      sku_type: 'ENTERPRISE',
    };
  }

  const sortedThresholds = Object.keys(thresholdMap)
    .map((threshold) => Number(threshold))
    .sort((thresholdA, thresholdB) => {
      return thresholdA - thresholdB;
    });

  let plan = {};
  for (let i = 0; i < sortedThresholds.length - 1; i++) {
    const currThreshold = sortedThresholds[i];
    const nextThreshold = sortedThresholds[i + 1];
    if (wordsServed > currThreshold && wordsServed <= nextThreshold) {
      plan = thresholdMap[nextThreshold];
    }
  }
  return plan;
};
