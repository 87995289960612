import React, { useCallback } from 'react';
import { useSetupStepsContext } from './ProjectSetupProvider';
import { MobileGetStartedStep } from './GetStartedStep';
import { MobileCreateProjectStep } from './CreateProjectStep';
import { MobileSelectLanguagesStep } from './SelectLanguagesStep';
import { MobileCustomizeAppearanceStep } from './CustomizeAppearanceStep';
import { MobileAddSnippetStep } from './AddSnippetStep';
import { MobileCopySnippetStep } from './CopySnippetStep';
import { MobilePreviewStep } from './PreviewStep';
import { MobileGoGlobalStep } from './GoGlobalStep';

export const MobileStepsSwitch = () => {
  const { activeStep } = useSetupStepsContext();

  const renderMobileProjectSetupSteps = useCallback(() => {
    switch (true) {
      case activeStep === 0:
        return <MobileGetStartedStep />;
      case activeStep === 0.5:
        return <MobileCreateProjectStep />;
      case activeStep === 1:
        return <MobileSelectLanguagesStep />;
      case activeStep === 1.5:
        return <MobileCustomizeAppearanceStep />;
      case activeStep === 2:
        return <MobileAddSnippetStep />;
      case activeStep === 3:
        return <MobileCopySnippetStep />;
      case activeStep === 4:
        return <MobilePreviewStep />;
      case activeStep === 5:
        return <MobileGoGlobalStep />;
      default:
        return <MobileGetStartedStep />;
    }
  }, [activeStep]);
  return renderMobileProjectSetupSteps();
};
