import {
  Card,
  Flex,
  Box,
  Divider,
  Text,
  Tooltip,
  ScrollArea,
  useMantineTheme,
} from '@mantine/core';
import { InfoIcon, PlanOption } from 'ui';
import { SubscriptionType } from './SelectPlan';
import Plan from './Plan';
import { SkuWCreatedAndLastMod } from 'store';

const categories = [
  'Plan',
  'Price',
  'Words Served',
  'In-context Editor',
  'No-code Deployment',
];

export type DiscountedPlan = PlanOption & { regularPrice: number };

type Props = {
  value: SubscriptionType;
  plans: PlanOption[];
  handleSubSelect(item: PlanOption): void;
  currentPlan: PlanOption;
  selectedPlan: PlanOption | null;
  recommendedPlan: Partial<SkuWCreatedAndLastMod>;
};

const PlanOptions = ({
  plans,
  value,
  handleSubSelect,
  currentPlan,
  selectedPlan,
  recommendedPlan,
}: Props) => {
  const { colors } = useMantineTheme();
  return (
    <Card display="flex" radius="md" style={{ boxShadow: 'none' }}>
      <Flex justify="space-between" gap={20}>
        <Flex
          direction="column"
          justify="center"
          mah={310}
          maw={250}
          my="auto"
          pt="1rem"
          w="100%"
        >
          {categories.map((text) => (
            <Box key={text}>
              {text === 'Words Served' ? (
                <Flex align="center" gap={10}>
                  <Text fw={700}>{text}</Text>
                  <Tooltip
                    label={
                      <Text inherit>
                        Total number of Words Served your project is allotted{' '}
                        <em>each</em> month. Unused words do not rollover.
                      </Text>
                    }
                  >
                    <div>
                      <InfoIcon fill={colors.secondary1[0]} size={15} />
                    </div>
                  </Tooltip>
                </Flex>
              ) : (
                <Text fw={700}>
                  {text === 'Price' ? (
                    <>
                      {text}{' '}
                      <Text
                        display={{ base: 'none', lg: 'inline' }}
                        fs="italic"
                        span
                      >
                        {value === 'yearly' ? '(annually)' : '(monthly)'}
                      </Text>
                    </>
                  ) : (
                    text
                  )}
                </Text>
              )}
              <Divider w="100%" my={{ base: 15, lg: 20 }} />
            </Box>
          ))}
        </Flex>
        <ScrollArea py="1rem" type="auto" w={750}>
          <Flex gap={30} pl={7}>
            {plans.map((plan) => {
              const isCurrentPlan =
                'sku_name' in currentPlan &&
                currentPlan.sku_name.includes(plan.sku_name) &&
                plan.price === currentPlan.price;
              const isSelectedPlan =
                !isCurrentPlan && plan.sku_name === selectedPlan?.sku_name;
              const isRecommendedPlan =
                plan.threshold_value === recommendedPlan.threshold_value;

              return (
                <Plan
                  key={plan.sku_name}
                  plan={plan}
                  isCurrentPlan={isCurrentPlan}
                  isSelectedPlan={isSelectedPlan}
                  isRecommendedPlan={isRecommendedPlan}
                  handleSubSelect={handleSubSelect}
                />
              );
            })}
          </Flex>
        </ScrollArea>
      </Flex>
    </Card>
  );
};

export default PlanOptions;
