import { useRef, useEffect, CompositionEventHandler } from 'react';
import { Insertable } from '../JliffActions/InsertElement';
import { Box, MantineStyleProp } from '@mantine/core';
import { TextSelection } from '../../store/JliffEditorStore';

// Workaround: React does not support 'plaintext-only' as a value for contentEditable
type ContentEditable = 'inherit' | boolean | undefined;

interface Props {
  value?: string;
  selection?: TextSelection;
  className?: string;
  sx?: MantineStyleProp;
  setValue?: (text: string, addToUndo: boolean) => void;
  addElement?: (insert: Insertable) => void;
  onUndo: () => void;
  onRedo: () => void;
  onCompositionStart: CompositionEventHandler<HTMLSpanElement>;
  onCompositionEnd: CompositionEventHandler<HTMLSpanElement>;
  saveSelection: (type?: string) => void;
}

export const EditableText = ({
  value = '',
  selection = null,
  setValue,
  className = '',
  sx = {},
  addElement,
  onUndo,
  onRedo,
  onCompositionStart,
  onCompositionEnd,
  saveSelection,
}: Props) => {
  const isDisabled = !setValue;
  const textElement = useRef<HTMLSpanElement>(null);
  const textChangesCount = useRef<number>(0);

  useEffect(() => {
    const shouldGainFocus =
      !document.activeElement ||
      !(
        document.activeElement instanceof HTMLElement &&
        document.activeElement !== document.body &&
        !document.activeElement.isContentEditable
      );
    if (!isDisabled && shouldGainFocus) {
      placeCaretAt(selection);
    }
  }, [value, selection, isDisabled]);

  function placeCaretAt(selection: TextSelection | null) {
    if (!selection) return;
    const el = textElement.current;
    if (!el) {
      console.error('Error: No element');
      return;
    }
    if (!el.childNodes.length) el.appendChild(document.createTextNode(''));
    const sel = window.getSelection();
    if (sel) {
      const range = document.createRange();
      try {
        range.setStart(el.childNodes[0], selection.start);
        range.setEnd(el.childNodes[0], selection.end);
      } catch (e) {
        range.setStart(
          el.childNodes[0],
          (el.childNodes[0] as Text).length || 0
        );
        range.setEnd(el.childNodes[0], (el.childNodes[0] as Text).length || 0);
      }
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  function addToUndo(type: string, text1: string, text2: string) {
    // If text is pasted or cut, add to undo list
    if (type === 'deleteByCut' || type === 'insertFromPaste') return true;
    // If number of words changes, add to undo list
    if (text1.split(/\s+/).length !== text2.split(/\s+/).length) return true;
    const changes = Math.abs(text1.length - text2.length);
    // If number of characters changes by 3 or more, add to undo list
    if (changes + textChangesCount.current > 2) {
      textChangesCount.current = 0;
      return true;
    } else {
      textChangesCount.current += changes;
    }

    return false;
  }

  function handleChange(ev: React.SyntheticEvent<HTMLSpanElement, InputEvent>) {
    ev.preventDefault();
    const inputType = ev.nativeEvent.inputType;

    if (inputType === 'historyUndo') {
      onUndo();
      return false;
    }
    if (inputType === 'historyRedo') {
      onRedo();
      return false;
    }

    const el = textElement.current;
    if (!el) {
      console.error('Error: No element');
      return;
    }

    saveSelection(inputType);

    const nodeText = el.innerHTML || '';
    setValue &&
      setValue(nodeText, addToUndo(ev.nativeEvent.inputType, value, nodeText));
  }

  function handelKeys(ev: React.KeyboardEvent<HTMLSpanElement>) {
    // Meta key blocks keyup events on Mac
    if (ev.metaKey) ev.preventDefault();
    // Save caret location when using arrow keys
    // Selection is captured on keyup
    if (/^Arrow/.test(ev.key) && ev.type === 'keyup') {
      saveSelection();
      // Undo/Redo functionality
    } else if (ev.metaKey && ev.key === 'z') {
      if (ev.shiftKey) onRedo();
      else onUndo();
      // Hot keys for adding tags
    } else if (ev.metaKey && ['b', 'u', 'i'].includes(ev.key)) {
      addElement && addElement({ type: 'tag', values: [ev.key] });
    }
  }

  return (
    <Box
      component="span"
      style={sx}
      ref={textElement}
      className={className}
      contentEditable={
        isDisabled ? false : ('plaintext-only' as ContentEditable)
      }
      onCompositionStart={onCompositionStart}
      onCompositionEnd={onCompositionEnd}
      onKeyUp={handelKeys}
      onKeyDown={handelKeys}
      onMouseUp={() => saveSelection()}
      onInput={(ev: React.SyntheticEvent<HTMLSpanElement, InputEvent>) =>
        handleChange(ev)
      }
      dangerouslySetInnerHTML={{ __html: value }}
      suppressContentEditableWarning={true}
    ></Box>
  );
};
