import { create } from 'zustand';
import { AxiosError, AxiosResponse } from 'axios';
import { failure, hexToObject } from 'helpers';
import {
  defaultAxiosResponse,
  FetcherPromise,
  PutterPromise,
} from './xapis-wrappers';
import { createJSONStorage, persist } from 'zustand/middleware';

export type Project = {
  baseDomain: string;
  deploymentMethod: string;
  deploymentName: string;
  originName: string;
  projectKey: string;
  projectName: string;
  projectStatus: string;
  projectType: string;
  translations: TranslationKey[];
};

export const defaultProject: Project = {
  baseDomain: '',
  deploymentMethod: '',
  deploymentName: '',
  originName: '',
  projectKey: '',
  projectName: '',
  projectStatus: '',
  projectType: '',
  translations: [],
};

export type ProjectStore = {
  hasInitiallyFetched: boolean;
  loading: boolean;
  numApiCalls: number;
  projects: Project[];
  xapisProjects: ProjectKey[];
  activeProject: Project;
  activeTranslationConfig: string;
  setActiveTranslationConfig: (newConfig: string) => void;
  activeTranslationConfigHash: string;
  setActiveTranslationConfigHash: (newHash: string) => void;
  setActiveProject: (project: ProjectKey | Project) => void; // TODO: Make this Project instead of both (ProjectResponse | Project)
  setProjects: (projects: Project[]) => void;
  setXapisProjects: (projects: ProjectKey[]) => void;
  fetchProjects: () => Promise<AxiosResponse>;
  updateProject: (
    projectKey: string,
    data: Partial<ProjectKey>
  ) => Promise<AxiosResponse>;
  fetchProject: (
    projectKey: string,
    setAsActive?: boolean
  ) => Promise<AxiosResponse>;
};

export const getTranslationConfig = (project: Project | null) => {
  if (!project) return;
  const yyTranslation = project.translations?.find(
    ({ target_lang_code }) => target_lang_code === 'YY'
  );
  return {
    config: yyTranslation?.translation_config || '',
    configHash: yyTranslation?.translation_config_hash || '',
    translationKey: yyTranslation?.translation_key,
  };
};

export const parseConfigHex = (project: Project | null) => {
  if (!project) return;
  const yyConfigHex = getTranslationConfig(project)?.config || '';
  if (!yyConfigHex) return;

  return hexToObject(yyConfigHex);
};

export const filterProject = (projectResponse: ProjectKey): Project => ({
  baseDomain: projectResponse?.base_domain || '',
  deploymentMethod: projectResponse?.deployment_method || '',
  deploymentName: projectResponse?.deployment_name || '',
  originName: projectResponse?.origin_name || '',
  projectKey: projectResponse?.project_key || '',
  projectName: projectResponse?.project_name || '',
  projectStatus: projectResponse?.project_status || '',
  projectType: projectResponse?.project_type || '',
  translations: projectResponse?.translations || [],
});

export const useProjectsStore = create<ProjectStore>()(
  persist(
    (set, get) => ({
      hasInitiallyFetched: false,
      loading: false,
      numApiCalls: 0,
      projects: [],
      xapisProjects: [],
      activeProject: defaultProject,
      activeTranslationConfig: '',
      activeTranslationConfigHash: '',
      setActiveTranslationConfigHash: (newHash: string) =>
        set({ activeTranslationConfigHash: newHash }),
      setActiveTranslationConfig: (newConfig: string) =>
        set({ activeTranslationConfig: newConfig }),
      setActiveProject: (project) => {
        // TODO: Remove this in the future, and only grab from Projects rather than ProjectResponse
        const isProjectSnakeCase = Object.keys(project).some((key) => {
          return key.includes('_');
        });
        const parsedProject: Project = isProjectSnakeCase
          ? filterProject(project as ProjectKey)
          : (project as Project);
        const yyTranslationConfig = getTranslationConfig(
          parsedProject as Project
        )?.config;
        const yyTranslationConfigHash = parsedProject.translations.find(
          (t) => t.target_lang_code === 'YY'
        )?.translation_config_hash;
        // save in desktop app

        set({
          activeProject: parsedProject,
          activeTranslationConfig: yyTranslationConfig,
          activeTranslationConfigHash: yyTranslationConfigHash,
        });
      },
      setProjects: (projects: Project[]) => set({ projects }),
      setXapisProjects: (projects: ProjectKey[]) =>
        set({ xapisProjects: projects }),
      fetchProjects: () => {
        const { numApiCalls } = get();

        set({ loading: true, numApiCalls: numApiCalls + 1 });
        return FetcherPromise(`Project`, { limit: 0, details: 1 })
          .then((response: AxiosResponse) => {
            const {
              data: { projects = [] },
            }: { data: { projects: ProjectKey[] } } = response;
            // const parsedProjects: Project[] = projects.map(filterProject);
            set(() => ({
              xapisProjects: projects,
              projects: projects.map(filterProject),
            }));
            return response;
          })
          .catch((error: AxiosError) => {
            set({ projects: [] });
            const { response = defaultAxiosResponse } = error;
            failure(error, 'Unable to get projects at this time.');
            return response;
          })
          .finally(() => {
            const { numApiCalls } = get();
            set({
              hasInitiallyFetched: true,
              loading: numApiCalls <= 1,
              numApiCalls: numApiCalls - 1,
            });
          });
      },
      fetchProject: (projectKey, setAsActive = false) => {
        const { activeProject, numApiCalls, projects } = get();

        set({ loading: true, numApiCalls: numApiCalls + 1 });
        return FetcherPromise(`Project/${projectKey}`, { details: 1 })
          .then((response: AxiosResponse) => {
            const { data }: { data: ProjectKey } = response;
            const parsedProject: Project = filterProject(data);

            const projectIndex = projects.findIndex(
              ({ projectKey: storeProjectKey = '' }) =>
                storeProjectKey === projectKey
            );
            if (projectIndex < 0) {
              const newProjects = projects.concat(parsedProject);
              set({ projects: newProjects });
            } else {
              const newProjects = projects.map((storeProject, index) => {
                return index === projectIndex ? parsedProject : storeProject;
              });
              set({ projects: newProjects });
            }

            if (setAsActive || projectKey === activeProject?.projectKey) {
              set({
                activeProject: parsedProject,
                activeTranslationConfig:
                  getTranslationConfig(parsedProject)?.config,
                activeTranslationConfigHash:
                  getTranslationConfig(parsedProject)?.configHash,
              });
            }

            return response;
          })
          .catch((error: AxiosError) => {
            set({ projects: [] });
            const { response = defaultAxiosResponse } = error;
            failure(error, 'Unable to get project at this time.');
            return response;
          })
          .finally(() => {
            const { numApiCalls } = get();
            set({
              hasInitiallyFetched: true,
              loading: numApiCalls <= 1,
              numApiCalls: numApiCalls - 1,
            });
          });
      },
      updateProject: (projectKey, data) => {
        const { activeProject, numApiCalls, xapisProjects } = get();

        set({ loading: true, numApiCalls: numApiCalls + 1 });
        return PutterPromise(`Project/${projectKey}`, data)
          .then((response: AxiosResponse<ProjectKey>) => {
            const { data } = response;
            const parsedProject: Project = filterProject(data);

            const index = xapisProjects.findIndex(
              (p) => p.project_key === projectKey
            );
            const newXapisProjects =
              index === -1
                ? xapisProjects.concat(data)
                : xapisProjects.map((p, i) => (i === index ? data : p));

            set({
              xapisProjects: newXapisProjects,
              projects: newXapisProjects.map(filterProject),
            });

            if (projectKey === activeProject?.projectKey) {
              set({
                activeProject: parsedProject,
                activeTranslationConfig:
                  getTranslationConfig(parsedProject)?.config,
                activeTranslationConfigHash:
                  getTranslationConfig(parsedProject)?.configHash,
              });
            }

            return response;
          })
          .catch((error: AxiosError) => {
            set({ projects: [], xapisProjects: [] });
            const { response = defaultAxiosResponse } = error;
            failure(error, 'Unable to update project at this time.');
            return response;
          })
          .finally(() => {
            const { numApiCalls } = get();
            set({
              hasInitiallyFetched: true,
              loading: numApiCalls <= 1,
              numApiCalls: numApiCalls - 1,
            });
          });
      },
    }),
    {
      name: 'activeProject',
      partialize: (state) => ({ activeProject: state.activeProject }),
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useProjectsStore;
export const useActiveProject = () =>
  useProjectsStore((state) => state.activeProject);
export const useActiveProjectKey = () =>
  useProjectsStore((state) => state.activeProject.projectKey);
export const useActiveXapisProject = () =>
  useProjectsStore((state) => {
    const key = state.activeProject.projectKey;
    return state.xapisProjects.find((project) => project.project_key === key);
  });
export const useProject = (projectKey: string) => {
  const project = useProjectsStore((state) => {
    return state.xapisProjects.find((p) => p.project_key === projectKey);
  });
  return project;
};
