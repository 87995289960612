import * as React from 'react';
import classes from './Generic.module.css';
import {
  Badge,
  Box,
  Button,
  Container,
  CloseButton,
  Divider,
  Flex,
  Loader,
  Input,
  Tooltip,
  useMantineTheme,
  Text,
  Avatar,
  PolymorphicComponentProps,
  FlexProps,
  Title,
  TitleOrder,
  Anchor,
  MantineStyleProps,
  TitleProps,
} from '@mantine/core';
import { InfoIcon } from '../../StaticIcons';
import { NoDataFoundIcon } from 'images';
import { MdSearch } from 'react-icons/md';
import LoadingSkeleton from './dashboard/godashboard/LoadingSkeleton';
import { Link, LinkProps, To } from 'react-router-dom';
import {
  hasPageViewPermissions,
  UserRole,
} from 'helpers/utils/projectPermissions';
import { useCurrentProjectData, useUserProvider } from 'store';

export const LinkWrapper = ({
  children,
  to,
  target = '_self',
  isGLGODesktop,
  onClick,
}: {
  children: React.ReactNode;
  to?: To;
  target?: React.HTMLAttributeAnchorTarget;
  isGLGODesktop?: boolean;
  onClick?: () => void;
}) => {
  return !to || isGLGODesktop ? (
    children
  ) : (
    <Link
      onClick={onClick}
      to={to}
      target={target}
      style={{ textDecoration: 'none' }}
    >
      {children}
    </Link>
  );
};

export const ActionButton = ({ title }: { title: React.ReactNode }) => (
  <Button style={{ display: 'flex', margin: '20px auto 0 auto' }}>
    {title}
  </Button>
);

// Replaces Mantine's Card component to improve responsiveness
// Accepts any Flex component props
export const FlexCard = (
  props: PolymorphicComponentProps<'div', FlexProps>
) => {
  const { children, className, ...others } = props;
  const classNames = className
    ? `${classes.flexCard} ${className}`
    : classes.flexCard;
  return (
    <Flex className={classNames} {...others}>
      {children}
    </Flex>
  );
};

export const NoDataFoundPlaceHolder = ({
  height = 0,
}: {
  height?: number | string;
}) => (
  <Flex
    h={height || 'inherit'}
    flex={height ? 0 : 1}
    align="center"
    justify="center"
  >
    <NoDataFoundIcon name="no-data" />
  </Flex>
);

export const BasicHorizontalDivider = ({
  mobileMargin,
  borderWidth,
}: {
  mobileMargin?: string | number;
  borderWidth?: number;
}) => (
  <Divider
    orientation="horizontal"
    className={classes.divider}
    m={{ base: mobileMargin ?? '0 20px', sm: 'auto' }}
    style={{ borderWidth: borderWidth ?? 1 }}
  />
);

type HeaderProps = {
  title: React.ReactNode;
  tooltipLabel?: string;
  wrapContent?: boolean;
  flexContainerProps?: FlexProps;
  flexItems?: React.ReactNode;
  flexItemsProps?: FlexProps;
  titleProps?: TitleProps;
  blur?: boolean;
  variant?: 'h1' | 'h2' | 'h3';
};

export const Header = ({
  title,
  tooltipLabel,
  wrapContent = false,
  flexContainerProps,
  flexItems,
  flexItemsProps,
  titleProps,
  blur,
  variant = 'h1',
}: HeaderProps) => {
  const { colors } = useMantineTheme();
  const order = (variant ? Number(variant.charAt(1)) : 2) as TitleOrder;

  const mainProps: FlexProps = {
    ...(!wrapContent && {
      wrap: 'nowrap',
    }),
    ...(wrapContent && {
      pt: { base: 15, md: 0 },
    }),
    ...flexContainerProps,
  };

  const itemsProps: FlexProps = {
    ...(wrapContent && {
      w: { base: '100%', sm: 'fit-content' },
      pb: { base: 10, md: 0 },
    }),
    ...flexItemsProps,
  };

  return (
    <Flex
      w="100%"
      mih={55}
      wrap={{ base: 'wrap', sm: 'nowrap' }}
      justify="space-between"
      align="center"
      gap={10}
      style={{ filter: blur ? 'blur(1.25px)' : 'none' }}
      {...mainProps}
    >
      <Title order={order} lineClamp={2} display="inline" {...titleProps}>
        {title}
        {tooltipLabel && (
          <Tooltip disabled={blur} label={tooltipLabel}>
            <Box display="inline-block" ml={5}>
              <InfoIcon fill={colors.secondary1[0]} size={15} />
            </Box>
          </Tooltip>
        )}
      </Title>
      {flexItems && (
        <Flex
          align="flex-end"
          justify={{ base: 'flex-start', sm: 'end' }}
          columnGap={10}
          {...itemsProps}
        >
          {flexItems}
        </Flex>
      )}
    </Flex>
  );
};

export const DashboardCard = ({
  noPadding,
  children,
}: {
  noPadding?: string;
  children: React.ReactNode;
}) => {
  const getPadding = () => {
    let padding;
    switch (true) {
      case noPadding === 'left':
        padding = '0 5px 0 0';
        break;
      case noPadding === 'right':
        padding = '0 0 0 5px';
        break;
      default:
        padding = '0';
        break;
    }
    return padding;
  };

  return (
    <Box w="100%" maw="100%" p={{ base: 0, lg: getPadding() }}>
      {children}
    </Box>
  );
};

export const BlueCardHeader = ({ children }: { children: React.ReactNode }) => (
  <Container maw="100%" py={20} px={20} bg="background2.5" w="100%">
    {children}
  </Container>
);

export const StatusCircleIcon = ({
  status,
  size,
  borderSize,
  float,
}: {
  status: 'live' | 'active' | 'inactive' | 'private';
  size?: string | number;
  borderSize?: number;
  float?: 'right';
}) => {
  const theme = useMantineTheme();

  const getColor = (property: string) => {
    let color;
    if (property === 'backgroundColor') {
      switch (true) {
        case status === 'live':
          color = theme.colors.background2[1];
          break;
        case status === 'active':
          color = theme.colors.background2[2];
          break;
        case status === 'inactive':
          color = theme.colors.background2[3];
          break;
        case status === 'private':
        default:
          color = theme.colors.background2[5];
          break;
      }
      return color || '#707070';
    }
    switch (true) {
      case status === 'live':
        color = theme.colors.border[3];
        break;
      case status === 'active':
        color = theme.colors.border2[1];
        break;
      case status === 'inactive':
        color = theme.colors.border2[2];
        break;
      case status === 'private':
      default:
        color = theme.colors.border[2];
        break;
    }
    return color || '#C8DFF8';
  };

  return (
    <Box w={size ? Number(size) : 12}>
      <Box
        style={{
          width: size || 12,
          height: size ? Number(size) : 12,
          backgroundColor: getColor('backgroundColor'),
          border: `${borderSize || 2}px solid ${getColor('border')}`,
          borderRadius: 50,
          float: float || 'none',
        }}
      />
    </Box>
  );
};

export const StatusBadge = ({ isLive }: { isLive: boolean }) => (
  <Badge
    w={80}
    bg={isLive ? 'badge.2' : 'badge.8'}
    c={isLive ? 'text.5' : 'text2.7'}
    py={10}
  >
    {isLive ? 'Live' : 'Private'}
  </Badge>
);

export const LanguageBadge = ({
  targetLangCode,
  isLive,
}: {
  targetLangCode: string;
  isLive: boolean;
}) => {
  const { colors } = useMantineTheme();

  return (
    <Tooltip label={targetLangCode.length > 5 && targetLangCode}>
      <Badge
        variant="outline"
        radius="sm"
        className={classes.languageBadge}
        style={{ color: isLive ? colors?.badge[3] : colors?.badge[1] }}
      >
        {targetLangCode}
      </Badge>
    </Tooltip>
  );
};

export const SearchBar = ({
  maxWidth,
  searchText,
  setSearchText,
  setPrevSearchText = () => {},
}: {
  maxWidth: string | number;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setPrevSearchText?: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <Input
    w="100%"
    maw={maxWidth}
    value={searchText}
    classNames={{
      input: classes.searchBarInput,
      section: classes.searchBarRightSection,
    }}
    rightSection={<MdSearch color="#00328D" size={20} />}
    placeholder="Search..."
    onChange={(e) => {
      setPrevSearchText(searchText);
      setSearchText(e?.target?.value || '');
    }}
  />
);

export const SearchInput = ({
  maxWidth,
  searchText,
  setSearchText,
  placeholder,
}: {
  maxWidth: string | number;
  searchText: string;
  setSearchText: (value: string) => void;
  placeholder?: string;
}) => (
  <Input
    w="100%"
    maw={maxWidth}
    value={searchText}
    classNames={{
      input: classes.searchBarInput,
      section: classes.searchBarRightSection,
    }}
    rightSection={
      searchText ? (
        <CloseButton onClick={() => setSearchText('')} />
      ) : (
        <MdSearch color="#00328D" size={20} />
      )
    }
    rightSectionPointerEvents="all"
    placeholder={placeholder || 'Search...'}
    onChange={(e) => {
      setSearchText(e?.target?.value || '');
    }}
  />
);

export const StatusBanner = ({
  statusBody,
  statusType,
}: {
  statusBody: React.ReactNode;
  statusType: 'translations' | 'subscription';
}) => (
  <Flex
    h={65}
    w="100%"
    align="center"
    justify="center"
    py="1rem"
    px={{ base: '1rem', sm: '2rem' }}
    bg={statusType === 'translations' ? '#FDF5B2' : '#F5DDDE'}
    style={{
      borderRadius: 6,
    }}
  >
    <Box fw={500} ta="center">
      {statusBody}
    </Box>
  </Flex>
);

export const CircleAvatar = ({
  name,
  format = true,
  circleSize,
  backgroundColor,
  textColor,
  fontSize,
}: {
  name: string | undefined;
  format?: boolean;
  circleSize?: number | string;
  backgroundColor?: string;
  textColor?: string;
  fontSize?: number | string;
}) => (
  <Avatar
    variant="filled"
    size={circleSize || 28}
    color={backgroundColor || 'background.5'}
    radius="50%"
  >
    <Text fw={700} c={textColor || 'font1.0'} fz={fontSize || '0.85rem'} lh={1}>
      {name ? (format ? name[0].toUpperCase() : name) : 'P'}
    </Text>
  </Avatar>
);

export const CloseIcon = <
  FnType extends React.MouseEventHandler<HTMLButtonElement>,
>({
  onClick,
  size,
  color,
}: {
  onClick: FnType;
  size?: string | number;
  color?: string;
}) => (
  <CloseButton
    onClick={onClick}
    c={color || 'text.0'}
    bg="transparent"
    size={size || 30}
  />
);

export const DataWrapper = ({
  loading,
  noDataFound,
  children,
}: {
  loading: boolean;
  noDataFound: boolean;
  children: React.ReactNode;
}) => {
  if (loading) {
    return <LoadingSkeleton />;
  }

  if (noDataFound) {
    return <NoDataFoundPlaceHolder />;
  }

  return children;
};

export const Wait = ({
  children,
  loader,
  text,
  direction,
}: {
  loader?: 'oval' | 'bars' | 'dots';
  text?: string;
  direction?: 'column' | 'row';
  children?: React.ReactNode;
}) => {
  return (
    <>
      <DragArea />
      <Flex
        direction={direction || 'column'}
        align="center"
        justify="center"
        gap={20}
        h="100vh"
      >
        <Loader variant={loader || 'oval'} color="#0071DB" size="lg" />
        {text && <Text fz="md">{text || ''}</Text>}
        {children}
      </Flex>
    </>
  );
};

export const DragArea = () => (
  <Box style={{ height: 40, WebkitAppRegion: 'drag' }} />
);

export const AnchorLink = ({
  to,
  target,
  onClick,
  state,
  children,
  ...props
}: {
  to?: To;
  target?: React.HTMLAttributeAnchorTarget;
  state?: Record<string, unknown>;
  onClick?: () => void;
  children: React.ReactNode;
} & MantineStyleProps &
  Partial<LinkProps>) => (
  <Anchor
    to={to || ''}
    target={target}
    onClick={onClick}
    component={to ? Link : undefined}
    state={state}
    {...props}
  >
    {children}
  </Anchor>
);

export const RestrictedComponent = ({
  allowedRoles,
  allowedProjectTypes,
  children,
}: {
  allowedRoles?: Set<UserRole>;
  allowedProjectTypes?: Set<string>;
  children: JSX.Element;
}) => {
  const {
    xapisUser: { user_key: userKey },
  } = useUserProvider();

  const {
    projectUserPermissionMap,
    project: { project_type: projectType },
  } = useCurrentProjectData();
  const permissions =
    projectUserPermissionMap[userKey]?.rolePermissionSet ?? new Set();

  if (allowedRoles && !hasPageViewPermissions(permissions, allowedRoles)) {
    return <></>;
  }

  if (allowedProjectTypes && !allowedProjectTypes.has(projectType)) {
    return <></>;
  }

  return children;
};
