import { EditorColors } from 'helpers';
import { Insertable } from './InsertElement';

export const styleSx = (
  type: Insertable['type'],
  isActive: boolean,
  colors: EditorColors['insertMore']
) => {
  return {
    width: '50%',
    height: 40,
    zIndex: !isActive ? 0 : 5,
    color: !isActive ? colors.button.text : colors.activeButton.text,
    borderRadius: 15,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    border: !isActive ? 'none' : `1px solid ${colors.activeButton.border}`,
    backgroundColor: !isActive
      ? colors.button.background
      : colors.activeButton.background,
    '&:hover': {
      backgroundColor: !isActive
        ? colors.button.background
        : colors.activeButton.background,
    },
  };
};
